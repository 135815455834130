import { AbstractRepo } from '../../../../ts/Database/AbstractRepo';
import { KanbanColumnsItem } from './KanbanColumnsItem';

export class KanbanColumnsRepo extends AbstractRepo<KanbanColumnsItem> {
    public static indexDbStoreName = 'kanbanColumns';

    public constructor(db: IDBDatabase) {
        super(db, KanbanColumnsRepo.indexDbStoreName);
    }

    public async getList(spaceId: string): Promise<KanbanColumnsItem[]> {
        return new Promise<KanbanColumnsItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: KanbanColumnsItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        items.push(item);
                    }

                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }
}
