import HelperFactory from '@/ts/Helpers/HelperFactory';
import SockJS from 'sockjs-client';

export class WebsocketManager {
    private socketConnected = false;
    private socketInterval: any = false;
    private helperFactory: HelperFactory;
    private store: any;
    private sock: any;
    private lastPing: number = 0;
    private dispatchedCheckMustLogout = false;

    constructor(helperFactory: HelperFactory, store: any) {
        this.helperFactory = helperFactory;
        this.store = store;
    }

    public checkSockConnection(): void {
        if (this.socketInterval) {
            clearInterval(this.socketInterval);
        }

        this.socketInterval = setInterval(() => {
            if (!this.socketConnected) {
                if (this.isLoggedIn) {
                    this.setupSocket();
                }
            } else {
                const numSecondsSinceLastPing = (Math.floor(Date.now() / 1000)) - this.lastPing;
                if (numSecondsSinceLastPing > 60) {
                    const pingRequest = {
                        ping: true,
                        token: this.userToken,
                    };

                    if (this.sock !== null) {
                        this.sock.send(JSON.stringify(pingRequest));
                    } else {
                        this.setupSocket();
                    }
                }
            }
        }, 5000);
    }

    get isLoggedIn(): boolean {
        return this.store.state.userModule.userLoggedIn;
    }

    get userToken(): string {
        return this.store.state.userModule.token;
    }

    private setupSocket(): void {
        /* tslint:disable */
        console.log('SETUP SOCKET: ' + this.helperFactory.settingsHelper.getBaseUrl() + 'sock/');
        /* tslint:enable */
        this.sock = new SockJS(this.helperFactory.settingsHelper.getBaseUrl() + 'sock/');

        // Every time the connection opens, send the auth request.
        this.sock.onopen = () => {
            /* tslint:disable */
            console.log('SOCKET CONNECTED');
            /* tslint:enable */
            this.socketConnected = true;
            this.updateSockConnectionStatusInStore();

            // Authenticate with the server so it knows who the user is
            const authRequest = {
                token: this.userToken,
            };

            this.sock.send(JSON.stringify(authRequest));

            // TODO: If we get no response from the connection,
            // Dispatch 'checkMustLogout' action to the store
            setTimeout(() => {
                if (!this.dispatchedCheckMustLogout) {
                    this.store.dispatch('checkMustLogout');
                    this.dispatchedCheckMustLogout = true;
                }
            }, 3000);
        };

        this.sock.onmessage = (e: any) => {
            const message = JSON.parse(e.data);
            this.processSocketMessage(message);
            this.dispatchedCheckMustLogout = false;
        };

        this.sock.onclose = () => {
            this.socketConnected = false;
            this.updateSockConnectionStatusInStore();
            this.sock = null;
        };
    }

    /**
     * Handles any messages sent from the web socket
     */
    private processSocketMessage(message: any) {
        this.lastPing = Math.floor(Date.now() / 1000);

        // Handle change log messages.
        if (message.hasOwnProperty('change_log_id')) {
            const changeLogId: number = message.change_log_id;
            this.handleChangeLogUpdated(changeLogId);
        } else {
            if (message.hasOwnProperty('status')) {
                if (!message.status) {
                    this.store.dispatch('logoutUser');
                }
            }
        }
    }

    private async handleChangeLogUpdated(changeLogId: number) {
        // If the change log id is 0, nothing has been updated recently
        if (changeLogId === 0) {
            return;
        }

        /**
         * Is the new change log ID greater than anything we've encountered before?
         * If so, load and process all the changes since the last change log state was stored
         * and then update the store with the new last change id.
         */
        const lastChangeLogId = this.store.state.userModule.lastChangeLogId;

        if (changeLogId > lastChangeLogId) {
            await this.helperFactory.changeLogHelper.checkChangeLog(lastChangeLogId);
            this.store.commit('setLastChangeLogId', changeLogId);
        }
    }

    private updateSockConnectionStatusInStore(): void {
        this.store.commit('setSocketConnected', this.socketConnected);
    }
}
