import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import FileItem from '@/ts/Database/Files/FileItem';

export interface UploadFileParams {
    formData: FormData;
}

export default class UploadFileRequest extends AbstractRequest {
    public async execute(params: UploadFileParams, imageType: string = ''): Promise<FileItem> {

        return new Promise<FileItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.uploadFile(
                    `/private/file?imageType=${imageType}`, params.formData);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('file'))) {
                    reject(Error('UploadFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.file);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to upload a file failed.  Please try again later'));
            }
        });
    }
}
