import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export interface ImportCredentialsRequestParams {
    space_id: string;
    vault_access_id: string;
    token_part_a: string;
    file_id: string;
}

export interface ImportCredentialsResponse {
    num_lines_successfully_imported: number;
    num_lines_with_errors: number;
    csv_error_file_path: string;
}

export class ImportCredentialRequest extends AbstractRequest {
    public async execute(params: ImportCredentialsRequestParams): Promise<ImportCredentialsResponse> {
        return new Promise<ImportCredentialsResponse>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/credential/import`, params);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('num_lines_successfully_imported'))
                    || (!response.data.hasOwnProperty('num_lines_with_errors'))
                    || (!response.data.hasOwnProperty('csv_error_file_path'))
                )  {
                    reject(Error('ImportCredentialRequest - Invalid response from API'));
                    return;
                }

                resolve({
                    num_lines_successfully_imported: response.data.num_lines_successfully_imported,
                    num_lines_with_errors: response.data.num_lines_with_errors,
                    csv_error_file_path: response.data.csv_error_file_path,
                });
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to import credentials failed.  Please try again later'));
            }
        });
    }
}
