
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        components: {
        },
    })
    export default class GridItem extends Vue {
        @Prop({required: true}) private id!: string;
        @Prop({required: true}) private title!: string;
        @Prop({required: true}) private photoUrl!: string;
        @Prop({required: false, type: String, default: ''}) private iconName!: string;
        @Prop({required: false}) private iconColour!: string;
        @Prop({required: false}) private isVideo!: boolean;

        private handleItemClicked() {
            this.$emit('item-clicked', this.id);
        }

        get getImageBackgroundUrl(): string {
            if (this.hasPhoto) {
                return `background-image:url('${this.photoUrl}');`;
            }

            return '';
        }

        get hasPhoto(): boolean {
            return this.photoUrl.length > 0;
        }
    }
