import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { KanbanTaskItem } from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';

export interface CreateKanbanTaskParams {
    space_id: string;
    title: string;
    description: string;
    column_no: number;
    sequence_no: number;
    shared: boolean;
}

export class CreateKanbanTaskRequest extends AbstractRequest {
    public async execute(params: CreateKanbanTaskParams): Promise<KanbanTaskItem> {
        return new Promise<KanbanTaskItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/kanban_task`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('kanban_task')))  {
                    reject(Error('CreateKanbanTaskRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.kanban_task);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to create a kanban task failed.  Please try again later'));
            }
        });
    }
}
