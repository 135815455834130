import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import { ModuleGroupItem } from '@/domains/module/database/moduleGroup/ModuleGroupItem';
import RequestFactory from '@/ts/Requests/RequestFactory';

export default class ModuleGroupRepo extends AbstractRepo<ModuleGroupItem> {
    public static indexDbStoreName = 'module_groups';
    private requestFactory: RequestFactory;

    public constructor(db: IDBDatabase, requestFactory: RequestFactory) {
        super(db, ModuleGroupRepo.indexDbStoreName);
        this.requestFactory = requestFactory;
    }

    public async getList(): Promise<ModuleGroupItem[]> {
        return new Promise<ModuleGroupItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;

            request = this.getDb().transaction([this.getStoreName()], 'readonly')
                .objectStore(this.getStoreName()).openCursor();

            const items: ModuleGroupItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;
                    items.push(item);
                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }
}
