import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module
export default class NoteModule extends VuexModule {
    public noteId: string = '';
    public noteCategoryId: string = '';
    public searchKeywords: string = '';
    public lastUpdated: number = 0;
    public showSharedOnly: boolean = false;

    @Mutation
    public setNoteId(value: string) {
        this.noteId = value;
    }

    @Mutation
    public setNoteCategoryId(value: string) {
        this.noteCategoryId = value;
    }

    @Mutation
    public setNoteSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setNoteModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Action
    public clearNoteModuleState() {
        this.context.commit('setNoteId', '');
        this.context.commit('setNoteCategoryId', '');
        this.context.commit('setNoteSearchKeywords', '');
        this.context.dispatch('flagNoteModuleUpdated');
    }

    @Action
    public flagNoteModuleUpdated() {
        this.context.commit('setNoteModuleLastUpdated', (new Date()).getTime());
    }

    @Action
    public setSearchFilters(params: {
        categoryId: string,
        searchKeywords: string,
    }) {
        this.context.commit('setNoteCategoryId', params.categoryId);
        this.context.commit('setNoteSearchKeywords', params.searchKeywords);
    }
}
