import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteItem from '@/domains/notes/database/notes/NoteItem';

export interface UpdateNoteParams {
    id: string;
    title: string;
    description: string;
    note_category_id: string;
}

export default class UpdateNoteRequest extends AbstractRequest {
    public async execute(params: UpdateNoteParams): Promise<NoteItem> {
        return new Promise<NoteItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/note/${params.id}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('note')))  {
                    reject(Error('UpdateNoteRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.note);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a note failed.  Please try again later'));
            }
        });
    }
}
