import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { ModuleType } from '@/ts/Enums/ModuleType';
import { ModuleSpaceItem } from '@/domains/module/database/moduleSpace/ModuleSpaceItem';

export interface CreateModuleSpaceParams {
    space_id: string;
    module_type: ModuleType;
}

export class CreateModuleSpaceRequest extends AbstractRequest {
    public async execute(params: CreateModuleSpaceParams): Promise<ModuleSpaceItem> {
        return new Promise<ModuleSpaceItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/module_spaces`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('module_space')))  {
                    reject(Error('CreateModuleSpaceRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.module_space);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to create a gallery failed.  Please try again later'));
            }
        });
    }
}
