import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { CredentialViewItem } from '@/domains/credentials/interfaces/CredentialViewItem';

export interface ViewCredentialRequestParams {
    space_id: string;
    vault_access_id: string;
    token_part_a: string;
}

export class ViewCredentialRequest extends AbstractRequest {
    public async execute(credentialId: string, params: ViewCredentialRequestParams): Promise<CredentialViewItem> {
        return new Promise<CredentialViewItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/credential/view/${credentialId}`, params);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('credential_view'))
                )  {
                    reject(Error('ViewCredentialRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.credential_view);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to view a credential failed.  Please try again later'));
            }
        });
    }
}
