import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

/**
 * Allows a non-logged in user to REQUEST to disable 2FA.  An email will be sent
 * with a pin number requiring verification.
 */
export class DisableUserTwoFactorAuthRequestRequest extends AbstractRequest {
    public async execute(
        password: string,
        emailAddress: string,
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const params = {
                    email_address: emailAddress,
                    password,
                };

                const response = await this.apiClient.post(`/user/disable_two_factor_auth`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DisableUserTwoFactorAuthRequestRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not request to disable two factor auth'));
            }
        });
    }
}
