import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { GalleryItem } from '@/domains/galleries/database/galleries/GalleryItem';

export class GalleryListRequest extends AbstractRequest {
    public async execute(): Promise<GalleryItem[]> {
        return new Promise<GalleryItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(
                    `/private/galleries`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('galleries')))  {
                    reject(Error('GalleryListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.galleries);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load galleries from the API'));
            }
        });
    }
}
