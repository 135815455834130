import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { ModuleSpaceItem } from '@/domains/module/database/moduleSpace/ModuleSpaceItem';

export default class ModuleSpaceListRequest extends AbstractRequest {
    public async execute(): Promise<ModuleSpaceItem[]> {
        return new Promise<ModuleSpaceItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/module_spaces`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('module_group_spaces')))  {
                    reject(Error('ModuleSpaceListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.module_group_spaces);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load module spaces from the API'));
            }
        });
    }
}
