
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import { ErrorObject } from '@/ts/Interfaces/ErrorObject';
import GalleryFileGrid from './GalleryFileGrid.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import FileItem from '@/ts/Database/Files/FileItem';
import { GalleryItem } from '@/domains/galleries/database/galleries/GalleryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import ValidationHelper from '@/ts/Helpers/ValidationHelper';
import StringHelper from '@/ts/Helpers/StringHelper';
import SelectOptionInterface from '@/ts/Interfaces/SelectOptionInterface';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import { CreateGalleryParams } from '@/domains/galleries/requests/galleries/CreateGalleryRequest';
import { FileName } from '@/ts/ValueObjects/FileName';
import { GalleryFileItem } from '@/domains/galleries/database/galleryFiles/GalleryFileItem';

interface SendMessageEvent {
    subject: string;
    message: string;
}

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      DeleteItemSpinner,
      GalleryFileGrid,
  },
})
export default class Gallery extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;
    private $helperFactory!: HelperFactory;

    private loaded = false;
    private galleryItem!: GalleryItem;
    private galleryName!: string;
    private galleryDescription!: string;
    private galleryShared!: boolean;
    private coverPhotoFile: FileItem|null = null;

    private errorMessage: string = '';
    private coverPhotoPopped = false;

    private mounted(): void {
        this.init();
    }

    @Watch(`galleryId`)
    private init(): void {
        this.loaded = false;

        if (this.$repoManager.recipe) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private get galleryId(): string {
        return this.$store.state.galleryModule.galleryId;
    }

    private get lastUpdated(): string {
        return this.$store.state.galleryModule.lastUpdated;
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    get lastChangeLogId(): string {
        return this.$store.state.userModule.lastChangeLogId;
    }

    get galleryBelongsToLoggedInUser(): boolean {
        return this.$store.getters.isCurrentUser(this.galleryItem.owner_user_id);
    }

    @Watch('lastUpdated')
    private async loadDataFromRepos(): Promise<void> {
        await this.loadGallery();
        this.loaded = true;
    }

    private async loadGallery(): Promise<void> {
        try {
            this.errorMessage = '';
            this.galleryItem = await this.$repoManager.gallery.getItem(this.galleryId);
            this.setGalleryAttributesFromObject();

            if (this.galleryItem.cover_photo_file_id.length > 0) {
                this.coverPhotoFile = await this.$repoManager.file.getItem(this.galleryItem.cover_photo_file_id);
            }
        } catch (error) {
            this.errorMessage = 'Error: Gallery could not be loaded.';
        }
    }

    private setGalleryAttributesFromObject(): void {
        this.galleryName = this.galleryItem.name;
        this.galleryDescription = this.galleryItem.description;
        this.galleryShared = this.galleryItem.shared;
    }

    private editGallery(): void {
        this.$router.push({name: Routes.GALLERY_EDIT, params: {
            id: this.galleryId,
        }});
    }

    private async deleteGallery(): Promise<void> {
        if (!confirm('Delete this gallery?  Are you sure?')) {
            return;
        }

        const deleteGalleryFromRepo = async () => {
            await this.$repoManager.gallery.delete(this.galleryItem);

            this.galleryItem = this.$repoManager.gallery.getEmptyItem();
            this.setGalleryAttributesFromObject();

            await this.$store.dispatch('flagGalleryModuleUpdated');

            this.navigateToGalleriesScreen();
        };

        try {
            const deletedOk =
                await this.$requestFactory.gallery.deleteGalleryRequest.execute(this.galleryId);

            if (!deletedOk) {
                this.errorMessage = 'The gallery could not be deleted.  Please try again later.';
                return;
            }

            deleteGalleryFromRepo();
        } catch (error) {
            // If the error reported by the server is a RecordNotFound error, delete locally anyway.
            // Otherwise show the error
            if (this.$helperFactory.serverErrorHelper.isRecordNotFound(error)) {
                deleteGalleryFromRepo();
                return;
            }

            this.errorMessage = `The gallery could not be deleted.  Error was: ${error}`;
        }
    }

    private navigateToGalleriesScreen(): void {
        this.$router.push({name: Routes.GALLERY_LIST});
    }

    // If the user has selected a different workspace, redirect back to contacts screen.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.navigateToGalleriesScreen();
        }
    }

    get coverPhotoBackgroundUrl(): string {
        if ((this.coverPhotoFile) && (!this.coverPhotoPopped)) {
            return `background-image:url('${this.coverPhotoFile.url}');`;
        }

        return '';
    }

    @Watch('lastChangeLogId')
    private async onChangeLogUpdated(): Promise<void> {
        // await this.loadMessages();
    }
}
