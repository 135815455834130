import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export interface PasswordResetParams {
    email_address: string;
    password: string;
}

export default class PasswordResetRequest extends AbstractRequest {
    public async execute(params: PasswordResetParams): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/user/password_reset`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('PasswordResetRequest - Invalid response from API'));
                    return;
                }

                const requestOk: boolean = response.data.status;
                resolve(requestOk);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your password could not be reset.  Please try again later'));
            }
        });
    }
}
