
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    import RepoManager from '@/ts/Database/RepoManager';
    import RequestFactory from '@/ts/Requests/RequestFactory';
    import {GalleryFileItem} from '@/domains/galleries/database/galleryFiles/GalleryFileItem';
    import { FileItem} from '@/ts/Database/Files/FileItem';
    import { MimeType } from '@/ts/ValueObjects/MimeType';
    import { MediaViewerMediaInfo } from '@/domains/ui/views/Modals/MediaViewer.vue';
    import { MessageCreatorType } from '@/ts/ValueObjects/MessageCreatorType';

    @Component({components: {
      Notification,
      IconButton,
      SpinnerButton,
  },
})
export default class MessageAttachments extends Vue {
    @Prop({required: true, type: String}) private messageId!: string;
    @Prop({required: true, type: Number}) private numAttachments!: number;
    @Prop({required: true}) private creatorType!: MessageCreatorType;

    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;

    private loadingAttachments = false;
    private galleryFiles: GalleryFileItem[] = [];
    private files: FileItem[] = [];

    private async loadAttachments(): Promise<void> {
        this.loadingAttachments = true;
        await this.loadDataFromRepos();

        if (!this.attachmentsLoaded) {
            if (this.creatorType === MessageCreatorType.CONTACT) {
                await this.loadGalleryFilesFromServer();
            } else {
                await this.loadMessageAttachmentsFromServer();
            }
        }
    }

    private async loadDataFromRepos(): Promise<void> {
        this.galleryFiles = await this.$repoManager.galleryFile.getListForMessage(this.messageId);

        for (const galleryFile of this.galleryFiles) {
            this.files.push(await this.$repoManager.file.getItem(galleryFile.file_id));
        }
    }

    private async loadGalleryFilesFromServer(): Promise<void> {
        const tempGalleryFiles: GalleryFileItem[] =
            (await this.$requestFactory.galleryFiles.galleryFileListRequest
                .execute('', '', this.messageId)).gallery_files;

        const tempFiles: FileItem[] = [];

        // Load the file record for each gallery file
        for (const galleryFile of tempGalleryFiles) {
            tempFiles.push(await this.$requestFactory.getFileRequest.execute(galleryFile.file_id));
        }

        await this.$repoManager.file.saveAll(tempFiles);
        await this.$repoManager.galleryFile.saveAll(tempGalleryFiles);

        this.files = tempFiles;
        this.galleryFiles = tempGalleryFiles;
    }

    private async loadMessageAttachmentsFromServer(): Promise<void> {
        const response = await this.$requestFactory.messageAttachmentListRequest.execute(this.messageId);
        this.files = response.files;
    }

    private isImage(mimeTypeString: string) {
        return (new MimeType(mimeTypeString)).isImage;
    }

    get attachmentsLoaded(): boolean {
        return this.files.length > 0;
    }

    private smallImageUrl(file: FileItem): string {
        return file.url + '_small.jpg';
    }

    private broadcastViewImageIntent(file: FileItem) {
        this.$emit('view-image-intent', {
            mediaUrl: file.url,
            mediaName: file.name,
        } as MediaViewerMediaInfo);
    }
}
