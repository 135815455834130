import RequestFactory from '../Requests/RequestFactory';
import ChangeLogType from '../Enums/ChangeLogType';
import RepoManager from '../Database/RepoManager';

export default class ChangeLogHelper {
    private requestFactory: RequestFactory;
    private repoManager: RepoManager;
    private vueStore: any;

    constructor(
        requestFactory: RequestFactory,
        repoManager: RepoManager,
        vueStore: any,
    ) {
        this.requestFactory = requestFactory;
        this.repoManager = repoManager;
        this.vueStore = vueStore;
    }

    public async checkChangeLog(changeLogId: number) {
        const changeLogItems = await this.requestFactory.getChangeLogRequest.execute(changeLogId);

        if (changeLogItems.length === 0) {
            return;
        }

        let newMessageDetected = false;

        const spaceMap = await this.repoManager.space.getSpaceMap();

        for (const changeLogItem of changeLogItems) {
            const item = JSON.parse(changeLogItem.payload);
            let itemDeleted = item.hasOwnProperty('deleted') && (item.deleted);

            // If the item is not deleted, but the item has a space_id attribute and
            // that space_id is not one the user has access to, delete the item.
            if ((!itemDeleted) && (item.hasOwnProperty('space_id')) &&
                (!spaceMap.hasOwnProperty(item.space_id))) {
                itemDeleted = true;
            }

            switch (changeLogItem.change_type) {
                case ChangeLogType.CREDENTIAL:
                    if (itemDeleted) {
                        if (this.repoManager.credential.exists(item.id)) {
                            this.repoManager.credential.delete(item);
                        }
                    } else {
                        this.repoManager.credential.save(item);
                    }
                    break;

                case ChangeLogType.CONTACT:
                    if (itemDeleted) {
                        if (this.repoManager.contact.exists(item.id)) {
                            this.repoManager.contact.delete(item);
                        }
                    } else {
                        this.repoManager.contact.save(item);
                    }
                    break;

                case ChangeLogType.NOTE:
                    if (itemDeleted) {
                        if (this.repoManager.note.exists(item.id)) {
                            this.repoManager.note.delete(item);
                        }
                    } else {
                        this.repoManager.note.save(item);
                    }
                    break;

                case ChangeLogType.NOTE_CATEGORY:
                    if (itemDeleted) {
                        if (this.repoManager.noteCategory.exists(item.id)) {
                            this.repoManager.noteCategory.delete(item);
                        }
                    } else {
                        // @todo need to handle checking if user has the space the note category
                        // is in and delete the note if not.
                        this.repoManager.noteCategory.save(item);
                    }
                    break;

                case ChangeLogType.FILE:
                    if (itemDeleted) {
                        if (this.repoManager.file.exists(item)) {
                            this.repoManager.file.delete(item);
                        }
                    } else {
                        this.repoManager.file.save(item);
                    }
                    break;

                case ChangeLogType.GALLERY_VIDEO:
                    if (itemDeleted) {
                        if (this.repoManager.file.exists(item)) {
                            this.repoManager.file.delete(item);
                        }
                    } else {
                        this.repoManager.file.save(item);
                    }

                    await this.flagGalleryUpdated();

                    break;

                case ChangeLogType.MESSAGE:
                    if (itemDeleted) {
                        if (this.repoManager.message.exists(item)) {
                            this.repoManager.message.delete(item);
                        }
                    } else {
                        this.repoManager.message.save(item);
                        newMessageDetected = true;
                    }
                    break;

                case ChangeLogType.NOTE_FILE:
                    if (itemDeleted) {
                        if (this.repoManager.noteFile.exists(item)) {
                            this.repoManager.noteFile.delete(item);
                        }
                    } else {
                        this.repoManager.noteFile.save(item);
                    }
                    break;

                case ChangeLogType.RECIPE:
                    if (itemDeleted) {
                        if (this.repoManager.recipe.exists(item)) {
                            this.repoManager.recipe.delete(item);
                        }
                    } else {
                        this.repoManager.recipe.save(item);
                    }
                    break;

                case ChangeLogType.RECIPE_CATEGORY:
                    if (itemDeleted) {
                        if (this.repoManager.recipeCategory.exists(item)) {
                            this.repoManager.recipeCategory.delete(item);
                        }
                    } else {
                        this.repoManager.recipe.save(item);
                    }
                    break;
            }
        }

        if (newMessageDetected) {
            await this.reloadContactMessageSummary();
        }
    }

    private async reloadContactMessageSummary(): Promise<void> {
        const summaryItems = await this.requestFactory.contactMessageSummaryListRequest.execute();
        await this.repoManager.contactMessageSummary.saveAll(summaryItems);

        this.vueStore.dispatch('flagContactModuleUpdated');
    }

    private async flagGalleryUpdated(): Promise<void> {
        this.vueStore.dispatch('flagGalleryModuleUpdated');
    }
}
