import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import MessageItem from '@/domains/messages/database/messages/MessageItem';

export default class SetMessageRequiresResponseRequest extends AbstractRequest {
    public async execute(messageId: string, requiresResponse: boolean): Promise<MessageItem> {
        return new Promise<MessageItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/message/${messageId}/requires_response`, {
                    requires_response: requiresResponse,
                });

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('message')))  {
                    reject(Error('SetMessageRequiresResponseRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.message);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a message failed.  Please try again later'));
            }
        });
    }
}
