import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module
export default class GalleryModule extends VuexModule {
    public galleryId: string = '';
    public searchKeywords: string = '';
    public lastUpdated: number = 0;
    public showSharedOnly: boolean = false;

    @Mutation
    public setGalleryId(value: string) {
        this.galleryId = value;
    }

    @Mutation
    public setGalleryShowSharedOnly(value: boolean) {
        this.showSharedOnly = value;
    }

    @Mutation
    public setGallerySearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setGalleryModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Action
    public clearGalleryModuleState() {
        this.context.commit('setGalleryId', '');
        this.context.commit('setGalleryShowSharedOnly', false);
        this.context.commit('setGallerySearchKeywords', '');
    }

    @Action
    public flagGalleryModuleUpdated() {
        this.context.commit('setGalleryModuleLastUpdated', (new Date()).getTime());
    }
}
