import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { SymetricCipherType } from '@/ts/Enums/SymetricCipherType';

export interface DecryptDataParams {
    cipher_type: SymetricCipherType;
    password: string;
    encrypted_text: string;
    integrity_hash: string;
    metadata: {
        iv: string;
        salt: string;
    };
}

export class DecryptDataRequest extends AbstractRequest {
    public async execute(params: DecryptDataParams): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/encryption/decrypt`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('decrypted_string')))  {
                    reject(Error('DecryptDataRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.decrypted_string);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to decrypt data failed.  Please try again later'));
            }
        });
    }
}
