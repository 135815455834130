
import {Component, Vue} from 'vue-property-decorator';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import {CompletionItem} from '@/domains/assistant/database/CompletionItem';
import Container from '@/domains/app/views/Container.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import Form from '@/domains/ui/views/Form.vue';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import RepoManager from '@/ts/Database/RepoManager';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import Trilean from '@/ts/Trilean';
import {AiSophisticationLevel} from '@/domains/ai/types/AiSophisticationLevel';
import {AiMaxResponseLength} from '@/domains/ai/types/AiMaxResponseLength';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

interface ParsedCompletionResponse {
    responseType: string;
    items: string[];
}

@Component({
    components: {
        SpinnerButton,
        BasicButton,
        Form,
        Container,
        InputChecker,
        SidebarSection,
        Notification,
        ErrorMessage,
        ActionHeading,
        IncludeIf,
        Spinner,
    },
})
export default class Assistant extends Vue {
    private $repoManager!: RepoManager;

    private loaded: boolean = false;
    private errorMessage: string = '';
    public searchKeywords = '';

    private prompt: string = '';
    private promptValidationError: boolean = false;
    private promptOk = Trilean.Unknown;

    private sophisticationLevel: string = AiSophisticationLevel.SOPHISTICATED.valueOf();
    private waitingForResponse: boolean = false;

    private sophisticationLevels = [
        {
            id: AiSophisticationLevel.SOPHISTICATED.valueOf(),
            name: AiSophisticationLevel.SOPHISTICATED,
        },
        {
            id: AiSophisticationLevel.INTELLIGENT.valueOf(),
            name: AiSophisticationLevel.INTELLIGENT,
        },
        {
            id: AiSophisticationLevel.AVERAGE.valueOf(),
            name: AiSophisticationLevel.AVERAGE,
        },
        {
            id: AiSophisticationLevel.SIMPLE.valueOf(),
            name: AiSophisticationLevel.SIMPLE,
        },
    ];

    public mounted(): void {
        if (this.$repoManager.completion) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    /**
     * Fires when all repos have been initialised
     */
    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            await this.loadDataFromRepos();
        }, false);
    }

    public async loadDataFromRepos(): Promise<void> {
        if (!this.$repoManager.completion) {
            return;
        }

        await this.loadCompletions();
        this.loaded = true;
    }

    public handleSearchIntent(keywords: string): void {
        this.setSearchKeywords(keywords);
        this.loadCompletions();
    }

    public setSearchKeywords(newKeywords: string) {
        this.searchKeywords = newKeywords;
    }

    public async handleAskQuestion(): Promise<void> {
        this.waitingForResponse = true;
        this.errorMessage = '';

        try {
            const sophisticationLevel: AiSophisticationLevel = <AiSophisticationLevel>this.sophisticationLevel;

            await this.$repoManager.completion.requestCompletion(
                this.spaceId,
                this.prompt,
                AiMaxResponseLength.ESSAY,
                sophisticationLevel
            );

            this.prompt = '';
            this.promptOk = Trilean.Unknown;
        } catch (error) {
            this.errorMessage = `Sorry, there was an error: ${error}`;
        } finally {
            this.waitingForResponse = false;
        }
    }

    public async clearChatHistory(): Promise<void> {
        if (!confirm("Are you sure you wish to clear all of the Assistant chat history for this space?")) {
            return;
        }

        await this.$repoManager.completion.clearForSpace(this.spaceId);
    }

    public async deleteItem(item: CompletionItem): Promise<void> {
        try {
            await this.$repoManager.completion.delete(item);
            await this.loadDataFromRepos();
        } catch (error) {
            this.errorMessage = `There was a problem trying to delete the item: ${error}`;
        }
    }

    public handleCopyDataToClipboard(textElementId: string): void
    {
        const elementToCopyTextFrom: any = document.getElementById(textElementId);
        const copyRange = document.createRange();

        copyRange.selectNodeContents(elementToCopyTextFrom);

        // Select the text inside the range
        const selection = window.getSelection();
        if (!selection) {
            return;
        }

        // Need to do this for Chrome for some reason
        selection.removeAllRanges();

        selection.addRange(copyRange);

        // Copy the text to the clipboard
        document.execCommand('copy');

        // Remove the selection range
        selection.removeRange(copyRange);
    }

    private getAsCompletionResponse(completionString: string): ParsedCompletionResponse[]
    {
        const tokens = completionString.split("\n");

        const list: string[] = [];

        for (let token of tokens) {
            token = token.trim();
            if (token.length === 0) {
                continue;
            }

            const dotPos = token.indexOf(".");
            if ((dotPos <= -1) || (dotPos > 5)) {
                return [{
                    responseType: "plain",
                    items: [completionString]
                }];
            }

            const prefix: any = token.substring(0, dotPos);

            if (isNaN(prefix)) {
                return [{
                    responseType: "plain",
                    items: [completionString]
                }];
            }

            const suffix = token.substr(dotPos + 1).trim();

            list.push(suffix);
        }

        return [{
            responseType: "list",
            items: list
        }];
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private get askQuestionButtonText(): string {
        return this.waitingForResponse ? 'Please wait' : 'Submit';
    }

    private async loadCompletions(): Promise<void> {
        await this.$repoManager.completion.getList(this.spaceId, this.searchKeywords);
    }

    public get completionItems(): CompletionItem[] {
        return this.$store.state.completionStore.completions;
    }

    private checkPrompt(): void {
        if (this.prompt === '') {
            this.promptOk = Trilean.False;
        } else {
            this.promptOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private formValid(): boolean {
        return this.promptOk === Trilean.True;
    }
}
