import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class SpaceExistsRequest extends AbstractRequest {
    public async execute(name: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/space/exists/${name}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('exists')))  {
                    reject(Error('SpaceExistsRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.exists);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not check if space exists'));
            }
        });
    }
}
