import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import ContactItem from '@/domains/contacts/database/ContactItem';

export default class ContactListRequest extends AbstractRequest {
    public async execute(): Promise<ContactItem[]> {
        return new Promise<ContactItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/contact`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('contacts')))  {
                    reject(Error('ContactListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.contacts);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load contacts from the API'));
            }
        });
    }
}
