
import { Component, Vue } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';

@Component({
  components: {},
})
export default class Admin extends Vue {
    private navigateToSpaceManagement(): void {
        this.$router.push({name: Routes.ADMIN_SPACES});
    }

    private navigateToNoteCategoryManagement(): void {
        this.$router.push({name: Routes.ADMIN_NOTE_CATEGORIES});
    }

    private navigateToRecipeCategoryManagement(): void {
        this.$router.push({name: Routes.ADMIN_RECIPE_CATEGORIES});
    }

    private navigateToUserManagement(): void {
        this.$store.dispatch('clearAdminUserModuleState');
        this.$router.push({name: Routes.USERS});
    }

    private navigateToKanbanManagement(): void {
        this.$router.push({name: Routes.ADMIN_KANBAN});
    }
}
