import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import ChangeLogItem from '@/ts/Interfaces/ChangeLogItem';

export default class GetChangeLogRequest extends AbstractRequest {
    public async execute(lastReceivedId: number = 0): Promise<ChangeLogItem[]> {
        return new Promise<ChangeLogItem[]>(async (resolve, reject) => {
            try {
                let url = '/private/changelog';

                if (lastReceivedId > 0) {
                    url = url + '?last_received_id=' + lastReceivedId;
                }

                const response = await this.apiClient.get(url);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('change_log')))  {
                    reject(Error('GetChangeLogRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.change_log);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load change log from the API'));
            }
        });
    }
}
