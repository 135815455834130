import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteFileItem from '@/domains/notes/database/noteFIles/NoteFileItem';

export default class NoteFileListRequest extends AbstractRequest {
    public async execute(noteId: string): Promise<NoteFileItem[]> {
        return new Promise<NoteFileItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/note/files/list?note_id=${noteId}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('note_files')))  {
                    reject(Error('NoteFileListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.note_files);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load note files from the API'));
            }
        });
    }
}
