import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';

export interface AddRecipeCategoryParams {
    space_id: string;
    name: string;
}

export default class AddRecipeCategoryRequest extends AbstractRequest {
    public async execute(params: AddRecipeCategoryParams): Promise<RecipeCategoryItem> {
        return new Promise<RecipeCategoryItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/recipe_category`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('recipeCategory')))  {
                    reject(Error('LoginRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.recipeCategory);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add a recipe category failed.  Please try again later'));
            }
        });
    }
}
