import { AbstractRequestFactory } from '../../../../ts/Requests/AbstractRequestFactory';
import { CreateGalleryRequest } from './CreateGalleryRequest';
import { DeleteGalleryRequest } from './DeleteGalleryRequest';
import { GalleryListRequest } from './GalleryListRequest';
import { UpdateGalleryRequest } from './UpdateGalleryRequest';


export class GalleryRequestFactory extends AbstractRequestFactory {
    public get createGalleryRequest(): CreateGalleryRequest {
        return new CreateGalleryRequest(this.apiClient);
    }

    public get deleteGalleryRequest(): DeleteGalleryRequest {
        return new DeleteGalleryRequest(this.apiClient);
    }

    public get galleryListRequest(): GalleryListRequest {
        return new GalleryListRequest(this.apiClient);
    }

    public get updateGalleryRequest(): UpdateGalleryRequest {
        return new UpdateGalleryRequest(this.apiClient);
    }
}
