import HelperFactory from '@/ts/Helpers/HelperFactory';
import RequestFactory from '@/ts/Requests/RequestFactory';

export class TokenRefreshManager {
    private helperFactory: HelperFactory;
    private requestFactory: RequestFactory;
    private store: any;
    private intervalId: any = false;

    constructor(helperFactory: HelperFactory, requestFactory: RequestFactory, store: any) {
        this.helperFactory = helperFactory;
        this.requestFactory = requestFactory;
        this.store = store;
    }

    public updateTokenPeriodically(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.intervalId = setInterval(async () => {
            if (!this.isLoggedIn) {
                return;
            }

            // The allow roaming flag is stored in the store when the user logs in.
            // We need to honor this setting when regenerating a token otherwise the new token will
            // not honor it.
            const allowRoaming = this.store.state.userModule.allowRoaming;

            const newToken = await this.requestFactory.refreshTokenRequest.execute(allowRoaming);
            this.requestFactory.updateToken(newToken);
            this.store.commit('setToken', newToken);
        }, this.sessionRefreshInterval);
    }

    get sessionRefreshInterval(): number {
        return (60 * 1000) * 10;    // 10 minutes
    }

    get isLoggedIn(): boolean {
        return this.store.state.userModule.userLoggedIn;
    }

    get userToken(): string {
        return this.store.state.userModule.token;
    }

}
