
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class Toggle extends Vue {
        @Prop({required: true, type: Boolean}) private checked!: boolean;

        private isChecked: boolean = this.checked;

        private toggle(): void {
            this.isChecked = !this.isChecked;
            this.$emit('toggled', this.isChecked);
        }

        @Watch('checked')
        private checkedChanged(updatedState: boolean): void {
            this.isChecked = updatedState;
        }
    }
