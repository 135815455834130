import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import SpaceItem from '@/domains/spaces/database/SpaceItem';

export default class DeleteSpaceRequest extends AbstractRequest {
    public async execute(spaceId: string): Promise<SpaceItem> {
        return new Promise<SpaceItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/space/${spaceId}`, {});

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')) ||
                    (!response.data.status))  {
                    reject(Error('DeleteSpaceRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.space);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a space failed.  Please try again later'));
            }
        });
    }
}
