

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Spinner from '@/domains/ui/views/Spinner.vue';
import Form from '@/domains/ui/views/Form.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import Trilean from '@/ts/Trilean';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import StringHelper from '@/ts/Helpers/StringHelper';
import { PopSelectorItemInterface } from '@/domains/ui/views/Modals/PopSelector/PopSelectorItemInterface';

@Component({
  components: {
      Spinner,
      Form,
      BasicButton,
      SpinnerButton,
      InputChecker,
      ErrorMessage,
  },
})
export default class PopSelector extends Vue {
    @Prop({required: true})
    private items!: PopSelectorItemInterface[];

    @Prop({required: true, type: String})
    private headingText!: string;

    @Prop({required: true, type: String})
    private itemSingularCaption!: string;

    @Prop({required: true, type: Boolean})
    private popSelectorOpen!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    private canAddNewItems!: boolean;

    @Prop({required: false, type: Boolean, default: true})
    private searchEnabled!: boolean;

    @Prop({required: true, type: Boolean, default: true})
    private loading!: boolean;

    @Prop({required: false, type: String, default: ''})
    private errorMessage!: string;

    private addingNewItem: boolean = false;
    private windowTopBeforeScroll = 0;
    private searchTerm: string = '';
    private newItemValue: string = '';
    private newValueOk: Trilean = Trilean.Unknown;

    public mounted() {
        this.handlePopSelectorOpen();
    }

    public broadcastClosePopSelector(): void {
        this.$emit('close-popselector-intent', {});

        // Scroll to wherever the user had been previously
        // and show the scrollbars again.
        window.scrollTo(0, this.windowTopBeforeScroll);
        document.body.style.overflow = 'visible';
    }

    @Watch('popSelectorOpen')
    public handlePopSelectorOpen() {
        if (this.popSelectorOpen) {
            // Remember how far down the user had scrolled before scrolling back to top.
            this.windowTopBeforeScroll = window.pageYOffset || 0;

            // Scroll to top and hide sidebars.
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';

            this.searchTerm = '';
            this.addingNewItem = false;
        }
    }

    public get filteredItems(): PopSelectorItemInterface[] {
        if ((this.items.length === 0) || (this.searchTerm.length === 0)) {
            return this.items;
        }

        const matchingItems: PopSelectorItemInterface[] = [];
        for (const thisItem of this.items) {
            if (this.itemLabelMatchesSearch(thisItem.label)) {
                matchingItems.push(thisItem);
            }
        }

        return matchingItems;
    }

    public switchToAddNewItemMode(): void {
        this.newItemValue = '';
        this.newValueOk = Trilean.Unknown;
        this.addingNewItem = true;

        this.$nextTick(() => {
            const newItemInput: any = this.$refs.newItemInput;
            newItemInput.focus();
        });
    }

    public handleNewItemNameChanged(): void {
        this.checkNewItemValueOk();
    }

    public broadcastAddNewItemIntent(): void {
        this.checkNewItemValueOk();
        if (this.newValueOk !== Trilean.True) {
            return;
        }

        this.$emit('add-new-item-intent', this.newItemValue);
    }

    public broadcastPopSelectorItemClicked(itemId: string): void {
        this.$emit('clicked', itemId);

        // Scroll to wherever the user had been previously
        // and show the scrollbars again.
        window.scrollTo(0, this.windowTopBeforeScroll);
        document.body.style.overflow = 'visible';
    }

    public itemLabelMatchesSearch(label: string): boolean {
        if (this.searchTerm.length === 0) {
            return true;
        }

        return StringHelper.searchInText(this.searchTerm.split(' '), label);
    }

    private checkNewItemValueOk(): void {
        this.newValueOk = (this.newItemValue.length > 0) ?
            Trilean.True : Trilean.False;
    }
}
