import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { GalleryFileItem } from '@/domains/galleries/database/galleryFiles/GalleryFileItem';
import FileItem from '@/ts/Database/Files/FileItem';

export interface GalleryFileListResponse {
    gallery_files: GalleryFileItem[];
    files: FileItem[];
}

export class GalleryFileListRequest extends AbstractRequest {
    public async execute(galleryId: string, contactId: string, messageId: string): Promise<GalleryFileListResponse> {
        return new Promise<GalleryFileListResponse>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(
                    `/private/gallery_files?gallery_id=${galleryId}&contact_id=${contactId}&message_id=${messageId}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('gallery_files')))  {
                    reject(Error('GalleryFileListRequest - Invalid response from API'));
                    return;
                }

                const listResponse = {
                    gallery_files: response.data.gallery_files,
                    files: [],
                };

                if (response.data.hasOwnProperty('files')) {
                    listResponse.files = response.data.files;
                }

                resolve(listResponse);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load gallery files from the API'));
            }
        });
    }
}
