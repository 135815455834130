import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class RefreshTokenRequest extends AbstractRequest {
    public async execute(allowRoaming: boolean): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/user/refresh/token`, {
                    allow_roaming: allowRoaming,
                });

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('token')))  {
                    reject(Error('RefreshTokenRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.token);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not refresh token'));
            }
        });
    }
}
