import UserItem from '@/domains/users/database/users/UserItem';
import UserRoleType from '@/ts/Enums/UserRoleType';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Store } from 'vuex';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';

export default class UserRepo {
    private db: IDBDatabase;
    private requestFactory: RequestFactory;
    private store: Store<any>;
    private userStore: any;

    public constructor(
        db: IDBDatabase,
        requestFactory: RequestFactory,
        store: Store<any>,
        userStore: any,
    ) {
        this.db = db;
        this.requestFactory = requestFactory;
        this.store = store;
        this.userStore = userStore;
    }

    public async cacheTwoFactorAuthMethod(twoFactorAuthMethod: UserTwoFactorAuthItem|null): Promise<void> {
        this.store.commit('setTwoFactorAuthMethod', twoFactorAuthMethod);
    }

    public getCurrentTwoFactorAuthMethodFromCache(): UserTwoFactorAuthItem|null {
        return this.userStore.twoFactorAuthMethod;
    }

    public async saveAll(items: UserItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: UserItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getList(search: string = '', roleType: UserRoleType|null = null): Promise<UserItem[]> {
        const searchUpperCase = search.toUpperCase();

        return new Promise<UserItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;

            if (roleType) {
                const index = store.index('role_type');
                request = index.openCursor(IDBKeyRange.only(roleType));
            } else {
                request = this.db.transaction([this.storeName], 'readonly')
                    .objectStore(this.storeName).openCursor();
            }

            const items: UserItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (search === '') {
                        items.push(item);
                    } else {
                        if (item.display_name.toUpperCase().indexOf(searchUpperCase) >= 0) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: UserItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.display_name}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptyUserItem(roleType: string = ''): UserItem {
        return {
            id: '',
            display_name: '',
            email_address: '',
            created_dtm: 0,
            updated_dtm: 0,
            role_type: roleType,
        };
    }

    public async getItem(id: string): Promise<UserItem> {
        return new Promise<UserItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error('Unable to load user item with id: ' + id));
                }
            };

            request.onerror = () => {
                reject(new Error('Unable to load user item with id: ' + id));
            };
        });
    }

    private get storeName(): string {
        return 'users';
    }
}
