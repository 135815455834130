import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export class DeleteKanbanTaskRequest extends AbstractRequest {
    public async execute(kanbanTaskId: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/kanban_task/${kanbanTaskId}`, {});

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DeleteKanbanTaskRequest - Invalid response from API'));
                    return;
                }

                if (!response.data.status) {
                    reject(Error('DeleteKanbanTaskRequest - Delete request failed'));
                    return;
                }

                resolve();
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a kanban task failed.  Please try again later'));
            }
        });
    }
}
