import SpaceItem from '@/domains/spaces/database/SpaceItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export default class SpaceRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: SpaceItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: SpaceItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<SpaceItem> {
        return new Promise<SpaceItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error('Unable to load space item with id: ' + id));
                }
            };

            request.onerror = () => {
                reject(new Error('Unable to load space item with id: ' + id));
            };
        });
    }

    public async getList(search: string = ''): Promise<SpaceItem[]> {
        const searchUpperCase = search.toUpperCase();

        return new Promise<SpaceItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;

            request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).openCursor();

            const items: SpaceItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (search === '') {
                        items.push(item);
                    } else {
                        const foundInName = StringHelper.searchInText(searchKeywords, item.name);

                        if (foundInName) {
                            items.push(item);
                        }
                    }
                    cursor.continue();
                } else {
                    // Spaces should always be returned in alphabetical order.
                    items.sort((a: SpaceItem, b: SpaceItem) => {
                        const lowerA = a.name.toLowerCase();
                        const lowerB = b.name.toLowerCase();

                        if (lowerA < lowerB) {
                            return - 1;
                        } else if (lowerA > lowerB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    /**
     * Returns an object where the available spaceIds are the properties
     * of that object.  Useful for knowing which spaces a user has and
     * being able to query that in hashmap fashion.
     */
    public async getSpaceMap(): Promise<{[key: string]: boolean }> {
        const spaces = await this.getList();
        const spaceMap: {[key: string]: boolean } = {};

        for (const index in spaces) {
            if (spaces.hasOwnProperty(index)) {
                const space = spaces[index];
                spaceMap[space.id] = true;
            }
        }

        return spaceMap;
    }

    public async delete(item: SpaceItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.name}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptySpaceItem(spaceCategoryId: string = ''): SpaceItem {
        return {
            id: '',
            created_by_user_id: '',
            name: '',
            shared: false,
            created_dtm: 0,
            updated_dtm: 0,
        };
    }

    private get storeName(): string {
        return 'spaces';
    }
}
