
import { Component, Prop, Vue } from 'vue-property-decorator';
import { KanbanTaskColumnChangedEvent } from '@/domains/kanban/interfaces/KanbanEventInterfaces';
import KanbanTaskItem from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';

@Component({components: {}})
export default class KanbanTaskCard extends Vue {
    @Prop({required: true}) public kanbanTask!: KanbanTaskItem;
    @Prop({required: true, type: Number}) public columnNo!: number;

    public focused = false;
    public expanded = false;

    public truncatedDescription(description: string): string {
        let shortDesc = description;
        if ((!this.expanded) && (shortDesc.length > 100)) {
            shortDesc = shortDesc.substring(0, 97) + '...';
        }

        shortDesc = shortDesc.replace('\n', '<br/>');

        return shortDesc;
    }

    public getTaskPriorityClass(kanbanTask: KanbanTaskItem): string {
        switch (kanbanTask.sequence_no) {
            case 1:
                return 'priority-high';

            case 2:
                return 'priority-medium';

            default:
                return 'priority-low';
        }
    }

    public getExpandableClass(kanbanTask: KanbanTaskItem): string {
        return (kanbanTask.description.length > 100) ?
            'expandable' : 'not-expandable';
    }

    public handleDragStart(event: any): void {
        event.dataTransfer.setData('id', event.target.id);
        event.dataTransfer.setData('columnNo', this.columnNo);
    }

    public taskIsDeletableByUser(kanbanTask: KanbanTaskItem): boolean {
        if (!kanbanTask.shared) {
            return true;
        }

        return kanbanTask.created_by_user_id === this.loggedInUserId;
    }

    private get loggedInUserId(): string {
        return this.$store.state.userModule.userId;
    }

    public handleViewTaskIntent(): void {
        this.$emit('view-task', this.kanbanTask);
    }

    public handleEditTaskIntent(): void {
        this.$emit('edit-task', this.kanbanTask);
    }

    public handleDeleteTaskIntent(): void {
        this.$emit('delete-task', this.kanbanTask);
    }

    public handleMoveTaskIntent(): void {
        this.$emit('move-task', this.kanbanTask);
    }
}
