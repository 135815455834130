
import { Component, Vue } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue'; // @ is an alias to /src
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';

import {Routes} from '@/domains/app/router/router';
import {ConfirmPasswordResetParams} from '@/domains/users/requests/ConfirmPasswordResetRequest.js';
import {PasswordResetParams} from '@/domains/users/requests/PasswordResetRequest.js';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Sync from '@/ts/Database/Sync';
import Trilean from '@/ts/Trilean';
import ValidationHelper from '@/ts/Helpers/ValidationHelper';
import Container from '@/domains/app/views/Container.vue';

@Component({
  components: {
      Form,
      ErrorMessage,
      SpinnerButton,
      InputChecker,
      Container,
  },
})
export default class PasswordReset extends Vue {
    private $http!: ApiClient;
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private errorMessage: string = '';
    private loading: boolean = false;
    private confirmPinMode: boolean = false;
    private pinResetComplete: boolean = false;

    // Model fields
    private emailAddress: string = '';
    private password: string = '';
    private passwordRepeat: string = '';
    private pin: string = '';

    // Input Checker Fields
    private emailOk: Trilean = Trilean.Unknown;
    private passwordOk: Trilean = Trilean.Unknown;
    private passwordRepeatOk: Trilean = Trilean.Unknown;
    private pinOk: Trilean = Trilean.Unknown;

    private mounted(): void {
        this.confirmPinMode = false;
        this.pinResetComplete = false;
    }

    private async resetPassword(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        const passwordResetParams: PasswordResetParams = {
            email_address: this.emailAddress,
            password: this.password,
        };

        try {
            this.errorMessage = '';
            this.loading = true;
            const ok = await this.$requestFactory.passwordResetRequest.execute(passwordResetParams);
            this.loading = false;

            if (!ok) {
                this.errorMessage = 'Your request failed.  Please try again later.';
            }

            this.resetPinForm();
            this.confirmPinMode = true;

        } catch (error) {
            this.loading = false;
            this.errorMessage = error.toString();
        }
    }

    private formValid(): boolean {
        if (this.emailOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid email address.';
            return false;
        }

        if (this.passwordOk !== Trilean.True) {
            this.errorMessage = 'Please enter a valid password.';
            return false;
        }

        if (this.passwordRepeatOk !== Trilean.True) {
            this.errorMessage = 'Please ensure your passwords match.';
            return false;
        }

        return true;
    }

    private async checkEmailOk(): Promise<void> {
        if (this.emailAddress === '') {
            this.emailOk = Trilean.False;
            return;
        }

        if (!ValidationHelper.emailAddressValid(this.emailAddress)) {
            this.emailOk = Trilean.False;
            return;
        }

        this.emailOk = Trilean.True;
    }

    private checkPassword(): void {
        this.passwordOk = (this.password.length > 7) ? Trilean.True : Trilean.False;
        if (this.passwordOk === Trilean.False) {
            this.errorMessage = 'You password must be at least 8 characters long.';
        } else {
            this.errorMessage = '';

            if (this.passwordRepeat !== '') {
                this.passwordRepeat = '';
                this.passwordRepeatOk = Trilean.False;
            }
        }
    }

    private checkPasswordRepeat(): void {
        this.passwordRepeatOk = (this.password === this.passwordRepeat) ? Trilean.True : Trilean.False;
        if (this.passwordRepeatOk === Trilean.False) {
            this.errorMessage = 'You passwords do not match.  Please enter them again carefully.';
        } else {
            this.errorMessage = '';
        }
    }

    private async checkPinOk(): Promise<void> {
        this.pin = this.pin.trim();

        if (this.pin.length !== 6) {
            this.pinOk = Trilean.False;
            return;
        }

        if (!ValidationHelper.isNumber(this.pin)) {
            this.pinOk = Trilean.False;
            return;
        }

        this.pinOk = Trilean.True;
    }

    private resetPinForm(): void {
        this.pin = '';
        this.pinOk = Trilean.Unknown;
        this.errorMessage = '';
    }

    private pinFormValid(): boolean {
        if (this.pinOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid pin.';
            return false;
        }

        return true;
    }

    private async confirmPin(): Promise<void> {
        if (!this.pinFormValid()) {
            return;
        }

        const confirmPasswordResetParams: ConfirmPasswordResetParams = {
            email_address: this.emailAddress,
            pin: parseInt(this.pin.trim(), 10),
        };

        try {
            this.errorMessage = '';
            this.loading = true;
            const ok = await this.$requestFactory.confirmPasswordResetRequest.execute(confirmPasswordResetParams);
            this.loading = false;

            if (!ok) {
                this.errorMessage = 'Your request failed.  Please try again later.';
            }

            this.resetPinForm();
            this.confirmPinMode = false;
            this.pinResetComplete = true;
        } catch (error) {
            this.loading = false;
            this.errorMessage = error.toString();
        }
    }
}
