import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { KanbanTaskItem } from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';

export class KanbanTaskListRequest extends AbstractRequest {
    public async execute(): Promise<KanbanTaskItem[]> {
        return new Promise<KanbanTaskItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(
                    `/private/kanban_task`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('kanban_tasks')))  {
                    reject(Error('KanbanTaskListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.kanban_tasks);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load kanban tasks from the API'));
            }
        });
    }
}
