import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { UserTwoFactorAuthMethod } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthMethod';

export class DeleteUserTwoFactorAuthRequest extends AbstractRequest {
    public async execute(type: UserTwoFactorAuthMethod): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const params = {
                    type,
                };

                const response = await this.apiClient.delete(`/private/two_factor_auth`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DeleteUserTwoFactorAuthRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not delete two factor auth'));
            }
        });
    }
}
