import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class GetLastChangeLogIdRequest extends AbstractRequest {
    public async execute(): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/changelog/last_change_log_id`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('change_log_id')))  {
                    reject(Error('FileListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.change_log_id);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load last change log id from the API'));
            }
        });
    }
}
