
    import {Component, Vue} from 'vue-property-decorator';
    import RepoManager from '@/ts/Database/RepoManager';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    import InputChecker from '@/domains/ui/views/InputChecker.vue';
    import Trilean from '@/ts/Trilean';
    import StringHelper from '@/ts/Helpers/StringHelper';

    @Component({
        components: {
            InputChecker,
            SpinnerButton,
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class GetVaultAccess extends Vue {
        private $repoManager!: RepoManager;
        public loading = false;
        public errorMessage = '';
        public totpCodeRequired = false;

        public vaultPassword = '';
        public vaultPasswordOk = Trilean.Unknown;
        public totpCode = '';
        public totpCodeOk = Trilean.Unknown;

        public mounted(): void {
            if (this.$repoManager.credential) {
                this.loadDataFromRepos();
            } else {
                this.onReposReady();
            }
        }

        /**
         * Fires when all repos have been initialised
         */
        public async onReposReady(): Promise<void> {
            document.addEventListener('reposReady', async () => {
                this.loadDataFromRepos();
            }, false);
        }

        public checkVaultPasswordOk() {
            this.errorMessage = '';

            if (this.vaultPassword.length >= 10) {
                this.vaultPasswordOk = Trilean.True;
            } else {
                this.vaultPasswordOk = Trilean.False;
            }
        }

        public checkTotpCodeOk() {
            this.errorMessage = '';

            if (this.totpCodeWithoutSpaces.length === 6) {
                this.totpCodeOk = Trilean.True;
            } else {
                this.totpCodeOk = Trilean.False;
            }
        }

        public async handleGetVaultAccess(): Promise<void> {
            try {
                const vaultAccessDetails = await this.$repoManager.credential.requestVaultAccess(
                    this.vaultPassword,
                    this.totpCodeWithoutSpaces,
                );

                this.$emit('vault-access-granted', vaultAccessDetails);
            } catch (error) {
                if ((error.hasOwnProperty('code')) && (error.code === 'PermissionDenied')) {
                    this.errorMessage = 'Permission denied.  Please adjust your password and try again.  Please note that your vault will be locked if you enter the incorrect password too many times.';
                } else {
                    this.errorMessage = error.toString();
                }
            }
        }

        public get showGetVaultAccessButton(): boolean {
            return ((this.vaultPasswordOk === Trilean.True) &&
                ((!this.totpCodeRequired) || (this.totpCodeOk === Trilean.True)));
        }

        private get totpCodeWithoutSpaces(): string {
            return StringHelper.removeSpaces(this.totpCode);
        }

        private async loadDataFromRepos(): Promise<void> {
            const twoFactorAuthItem = await this.$repoManager.user.getCurrentTwoFactorAuthMethodFromCache();
            if ((twoFactorAuthItem === null) || (!twoFactorAuthItem.enabled)) {
                this.totpCodeRequired = false;
                return;
            }

            if (twoFactorAuthItem.two_factor_auth_type !== 'GoogleAuthenticator') {
                this.totpCodeRequired = false;
                throw new Error(
                    `GetVaultAccess - Unhandled two factor auth type: ${twoFactorAuthItem.two_factor_auth_type}`,
                );
            }

            this.totpCodeRequired = true;
        }
    }
