
    import { Component, Vue } from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import Form from '@/domains/ui/views/Form.vue';
    import Container from '@/domains/app/views/Container.vue';
    import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
    import {Routes} from '@/domains/app/router/router';
    import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
    import Trilean from '@/ts/Trilean';
    import InputChecker from '@/domains/ui/views/InputChecker.vue';
    import ValidationHelper from '@/ts/Helpers/ValidationHelper';
    import PasswordGenerator from '@/domains/credentials/views/PasswordGenerator.vue';
    import GetVaultAccess from '@/domains/vault/views/GetVaultAccess.vue';
    import {VaultAccessDetails} from '@/domains/vault/interfaces/VaultAccessDetails';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    import VaultAccessTicker from '@/domains/vault/views/VaultAccessTicker.vue';
    import RepoManager from '@/ts/Database/RepoManager';
    import Spinner from '@/domains/ui/views/Spinner.vue';

    @Component({
        components: {
            Spinner,
            VaultAccessTicker,
            SpinnerButton,
            GetVaultAccess,
            PasswordGenerator,
            InputChecker,
            ActionHeading,
            IconButton,
            Container,
            Form,
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class CredentialEdit extends Vue {
        private $repoManager!: RepoManager;

        public credentialId = '';
        public errorMessage = '';
        private ready = false;
        public loaded = false;
        public saving = false;
        public noVaultAccess = true;
        public vaultAccessDetails: VaultAccessDetails|null = null;

        // Model attributes
        public credentialTitle = '';
        public credentialUrl = '';
        public credentialEmailAddress = '';
        public credentialUsername = '';
        public credentialPassword = '';
        public credentialNotes = '';

        // Input validity states
        public credentialTitleOk = Trilean.Unknown;
        public credentialUrlOk = Trilean.Unknown;
        public credentialEmailAddressOk = Trilean.Unknown;
        public credentialUsernameOk = Trilean.Unknown;
        public credentialPasswordOk = Trilean.Unknown;

        public mounted(): void {
            if (this.$route.params.id) {
                this.credentialId = this.$route.params.id;
            } else {
                this.credentialId = '';
                this.clearAllFields();
            }

            if (this.$repoManager.credential) {
                this.ready = true;
            } else {
                this.onReposReady();
            }
        }

        /**
         * Fires when all repos have been initialised
         */
        public async onReposReady(): Promise<void> {
            document.addEventListener('reposReady', async () => {
                this.ready = true;
            }, false);
        }

        public async loadDataFromRepos(): Promise<void> {
            this.vaultAccessDetails = await this.$repoManager.credential.getVaultAccessDetails();

            if (!this.addingNewItem) {
                const credentialItem = await this.$repoManager.credential.getItem(this.credentialId);
                this.credentialTitle = credentialItem.title;
                this.credentialUrl = credentialItem.url;

                if (this.vaultAccessDetails) {
                    try {
                        const credentialView = await this.$repoManager.credential.requestCreateView(
                            this.credentialId,
                            this.spaceId,
                            this.vaultAccessDetails.vaultAccessId,
                            this.vaultAccessDetails.tokenPartA,
                        );

                        this.credentialEmailAddress = credentialView.email_address;
                        this.credentialUsername = credentialView.username;
                        this.credentialPassword = credentialView.password;
                        this.credentialNotes = credentialView.notes;
                    } catch (error) {
                        this.errorMessage = error.toString();
                    }
                }

                this.checkAllFieldsOk();
            }

            this.loaded = true;
        }

        // Input validity checkers
        public checkCredentialTitleOk(): void {
            this.credentialTitleOk = this.credentialTitle.length > 1 ? Trilean.True : Trilean.False;
        }

        public checkCredentialUrlOk(): void {
            this.credentialUrlOk = ValidationHelper.isUrl(this.credentialUrl) ? Trilean.True : Trilean.False;
        }

        public checkCredentialEmailAddressOrUsernameOk(): void {
            this.credentialEmailAddressOk =
                (ValidationHelper.emailAddressValid(this.credentialEmailAddress) || this.credentialUsername.length > 2) ?
                    Trilean.True : Trilean.False;

            this.credentialUsernameOk = this.credentialEmailAddressOk;
        }

        public checkCredentialPasswordOk(): void {
            this.credentialPasswordOk = this.credentialPassword.length > 0 ? Trilean.True : Trilean.False;
        }

        public get addingNewItem(): boolean {
            return this.credentialId.length === 0;
        }

        public handleGoBackToCredentialList(): void {
            this.$router.push({name: Routes.CREDENTIAL_LIST});
        }

        public handleGoToCredentialView(credentialId: string): void {
            this.$router.push({
                name: Routes.CREDENTIAL_VIEW,
                params: {
                  id: credentialId,
                },
            });
        }

        public handleCancelEdit(): void {
            this.handleGoBackToCredentialList();
        }

        public handlePasswordGenerated(password: string): void {
            this.credentialPassword = password;
            this.checkCredentialPasswordOk();
        }

        public handleVaultAccessGranted(vaultAccessDetails: VaultAccessDetails): void {
            this.noVaultAccess = false;
            this.vaultAccessDetails = vaultAccessDetails;
            this.loadDataFromRepos();
        }

        public handleVaultAccessStatusChange(vaultAccessExpired: boolean): void {
            this.noVaultAccess = vaultAccessExpired;

            if (this.noVaultAccess) {
                this.vaultAccessDetails = null;

                this.clearSensitiveFields();
            } else {
                if (!this.loaded) {
                    this.loadDataFromRepos();
                }
            }
        }

        public get spaceId(): string {
            return this.$store.state.spaceModule.selectedSpace;
        }

        public async handleSaveCredential(): Promise<void> {
            if (!this.formValid()) {
                this.errorMessage = 'You seem to be missing some required form fields.  ' +
                    'Please enter all required fields and try again.';
                return;
            }

            if (!this.vaultAccessDetails) {
                return;
            }

            this.saving = true;
            this.errorMessage = '';

            try {
                if (this.addingNewItem) {
                    const credentialItem = await this.$repoManager.credential.requestCreateCredential(
                        this.spaceId,
                        this.vaultAccessDetails.vaultAccessId,
                        this.vaultAccessDetails.tokenPartA,
                        this.credentialTitle,
                        this.credentialUrl,
                        this.credentialEmailAddress,
                        this.credentialUsername,
                        this.credentialPassword,
                        this.credentialNotes,
                    );

                    this.handleGoToCredentialView(credentialItem.id);
                } else {
                    await this.$repoManager.credential.requestUpdateCredential(
                        this.credentialId,
                        this.spaceId,
                        this.vaultAccessDetails.vaultAccessId,
                        this.vaultAccessDetails.tokenPartA,
                        this.credentialTitle,
                        this.credentialUrl,
                        this.credentialEmailAddress,
                        this.credentialUsername,
                        this.credentialPassword,
                        this.credentialNotes,
                    );

                    this.handleGoToCredentialView(this.credentialId);
                }
            } catch (error) {
                this.errorMessage = error.toString();
            } finally {
                this.saving = false;
            }
        }

        private checkAllFieldsOk(): void {
            this.checkCredentialTitleOk();
            this.checkCredentialUrlOk();
            this.checkCredentialEmailAddressOrUsernameOk();
            this.checkCredentialPasswordOk();
        }

        private formValid(): boolean {
            return ((this.credentialTitleOk === Trilean.True) &&
                (this.credentialUrlOk === Trilean.True) &&
                (this.credentialEmailAddressOk === Trilean.True) &&
                (this.credentialUsernameOk === Trilean.True) &&
                (this.credentialPasswordOk === Trilean.True));
        }

        private clearAllFields(): void {
            this.credentialTitle = '';
            this.credentialUrl = '';
            this.clearSensitiveFields();
        }

        private clearSensitiveFields(): void {
            this.credentialEmailAddress = '';
            this.credentialUsername = '';
            this.credentialPassword = '';
            this.credentialNotes = '';
        }
    }
