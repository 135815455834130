
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import ValidationHelper from '@/ts/Helpers/ValidationHelper';
    import StringHelper from '@/ts/Helpers/StringHelper';
    import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';

    @Component({
        components: {
            BasicButton,
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class PasswordGenerator extends Vue {
        public includeNumbers = true;
        public includeSymbols = true;
        public veryStrong = true;

        public generatePassword(): void {
            const candidates = this.getCandidateCharacters();
            const passwordLength = this.veryStrong ? 20 : 10;

            let password = '';

            while (password.length < passwordLength) {
                const index = Math.floor(Math.random() * candidates.length);
                password += candidates[index];
            }

            this.$emit('password-generated', password);
        }

        private getCandidateCharacters(): string {
            let candidates = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            candidates += 'abcdefghijklmnopqrstuvwxyz';

            if (this.includeNumbers) {
                candidates += '0123456789';
            }

            if (this.includeSymbols) {
                candidates += '!"£$%^&*();#~|\'?/\.,@-_+={}[]';
            }

            return candidates;
        }
    }
