import { AbstractRepo } from '../../../../ts/Database/AbstractRepo';
import { MessageAttachmentItem } from '@/domains/messages/database/messageAttachments/MessageAttachmentItem';
import DateHelper from '@/ts/Helpers/DateHelper';

export class MessageAttachmentRepo extends AbstractRepo<MessageAttachmentItem> {
    public static indexDbStoreName = 'messageAttachments';

    public constructor(db: IDBDatabase) {
        super(db, MessageAttachmentRepo.indexDbStoreName);
    }

    public async getList(messageId: string): Promise<MessageAttachmentItem[]> {
        return new Promise<MessageAttachmentItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('message_id');
            request = index.openCursor(IDBKeyRange.only(messageId));

            const items: MessageAttachmentItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;
                    items.push(item);
                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    public getEmptyItem(): MessageAttachmentItem {
        const dateHelper = new DateHelper();
        const unixTimestampNow = dateHelper.unixTimestamp();

        return {
            id: '',
            message_id: '',
            file_id: '',
            created_dtm: unixTimestampNow,
            updated_dtm: unixTimestampNow,
        };
    }
}
