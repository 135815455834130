
import { Component, Vue, Watch } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';
import RequestFactory from '@/ts/Requests/RequestFactory';
import RepoManager from '@/ts/Database/RepoManager';
import { SpaceItem } from '@/domains/spaces/database/SpaceItem';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Modal from '@/domains/ui/views/Modal.vue';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';
import { Trilean } from '@/ts/Trilean';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import { ModuleGroupItem } from '@/domains/module/database/moduleGroup/ModuleGroupItem';
import { ModuleType } from '@/ts/Enums/ModuleType';

@Component({
  components: {
      ActionHeading,
      Modal,
      InputChecker,
      SpinnerButton,
      ErrorMessage,
      DeleteConfirmationModal,
  },
})
export default class SpacesAdmin extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;

    private loaded = false;
    private errorMessage = '';
    private spaces: SpaceItem[] = [];
    private editSpaceModalOpen = false;
    private currentSpaceItem: SpaceItem|null = null;
    private spaceNameOk: Trilean = Trilean.Unknown;
    private spaceName = '';
    private spaceShared = false;
    private spaceNameLastChangedTime = 0;
    private savingSpace = false;
    private spaceCategoryMap: { [key: string]: number } = {};
    private moduleGroups: ModuleGroupItem[] = [];
    private enabledModulesForSpace: { [key: string]: boolean } = {};
    private enabledModulesForSpaceOriginal: { [key: string]: boolean } = {};
    private deleteModalOpen = false;
    private deleteInProgress = false;

    public mounted() {
        if (this.$repoManager.user) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    public navigateToAdminMenu(): void {
        this.$router.push({name: Routes.ADMIN});
    }

    public async editSpace(spaceId: string): Promise<void> {
        try {
            this.currentSpaceItem = await this.$repoManager.space.getItem(spaceId);
            this.setModelFromItem(this.currentSpaceItem);
            this.editSpaceModalOpen = true;
        } catch (error) {
            this.errorMessage = error.toString();
        }
    }

    public async checkSpaceNameAvailable(): Promise<void> {
        if (this.spaceName === '') {
            this.spaceNameOk = Trilean.False;
            return;
        }

        if ((this.currentSpaceItem !== null) && (this.spaceName === this.currentSpaceItem.name)) {
            this.spaceNameOk = Trilean.True;
            return;
        }

        const exists = await this.$requestFactory.spaceExistsRequest.execute(this.spaceName);
        this.spaceNameOk = exists ? Trilean.False : Trilean.True;
    }

    public handleSpaceNameChanged() {
        this.spaceNameLastChangedTime = (new Date()).getTime();

        setTimeout(() => {
            const diff = (new Date()).getTime() - this.spaceNameLastChangedTime;

            if (diff >= 750) {
                this.checkSpaceNameAvailable();
            }
        }, 750);
    }

    public async handleUpdateSpace(): Promise<void> {
        if (!this.currentSpaceItem) {
            return;
        }

        if (this.spaceName === '') {
            this.spaceNameOk = Trilean.False;
        }

        if (this.spaceNameOk === Trilean.False) {
            return;
        }

        const changedModules = this.getChangedModuleStates();

        try {
            this.savingSpace = true;

            const spaceItem = await this.$requestFactory.updateSpaceRequest.execute({
                id: this.currentSpaceItem.id,
                name: this.spaceName,
                shared: this.spaceShared ? true : false,
            });

            await this.$repoManager.space.save(spaceItem);
            await this.loadSpaces();

            for (const changedModuleType of Object.keys(changedModules)) {
                const moduleIsEnabled = changedModules[changedModuleType];

                if (moduleIsEnabled) {
                    await this.$repoManager.moduleSpace.enableModuleSpace(
                        changedModuleType as ModuleType,
                        spaceItem.id,
                    );
                } else {
                    await this.$repoManager.moduleSpace.deleteModuleSpace(
                        changedModuleType as ModuleType,
                        spaceItem.id,
                    );
                }
            }

            this.$store.commit('setSpaceLastUpdatedTimestamp', (new Date().getTime()));
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.savingSpace = false;
            this.editSpaceModalOpen = false;
        }
    }

    public async handledeleteSpaceIntent(spaceId: string): Promise<void> {
        this.currentSpaceItem = await this.$repoManager.space.getItem(spaceId);
        this.deleteModalOpen = true;
    }

    public async deleteSpace(spaceId: string): Promise<void> {
        if (!this.currentSpaceItem) {
            return;
        }

        try {
            await this.$requestFactory.deleteSpaceRequest.execute(spaceId);
            await this.$repoManager.space.delete(this.currentSpaceItem);
            await this.loadSpaces();

            this.$store.commit('setSpaceLastUpdatedTimestamp', (new Date().getTime()));
            this.currentSpaceItem = null;

            // If the user has deleted the currently selected global space,
            // set the active space to the first space in our list.
            if (this.$store.state.spaceModule.selectedSpace === spaceId) {
                if (this.spaces.length > 0) {
                    this.$store.commit('setSelectedSpace', this.spaces[0].id);
                }
            }
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.deleteModalOpen = false;
        }
    }

    public numCategoriesInSpace(spaceId: string): number {
        return this.spaceCategoryMap[spaceId];
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadSpaces();
        await this.loadModuleGroups();
        this.loaded = true;
    }

    private async loadSpaces(): Promise<void> {
        const spaces = await this.$repoManager.space.getList();
        this.spaceCategoryMap = {};

        // For each space, load the note categories so we can list how many categories are in each space.
        for (const spaceItem of spaces) {
            this.spaceCategoryMap[spaceItem.id] =
                await this.$repoManager.noteCategory.countCategoriesInSpace(spaceItem.id);
        }

        this.spaces = spaces;
    }

    private async loadModuleGroups(): Promise<void> {
        this.moduleGroups = await this.$repoManager.moduleGroup.getList();
    }

    private async setModelFromItem(item: SpaceItem): Promise<void> {
        this.spaceName = item.name;
        this.spaceShared = item.shared;

        // Load all the available modules and write them into a map with a false "not enabled" state.
        const newMap: { [key: string]: boolean } = {};
        for (const moduleGroup of this.moduleGroups ) {
           newMap[moduleGroup.module_type] = false;
        }

        // Load all the currently enabled modules for this space and set those modules as enabled in the map.
        const moduleSpaces = await this.$repoManager.moduleSpace.getList(item.id);
        for (const moduleSpace of moduleSpaces) {
            newMap[moduleSpace.module_type] = true;
        }

        this.enabledModulesForSpace = newMap;

        // Make a copy of the enabled modules so that we can compute a delta later
        this.enabledModulesForSpaceOriginal = {};
        Object.assign(this.enabledModulesForSpaceOriginal, newMap);
    }

    private getChangedModuleStates(): { [key: string]: boolean } {
        const changedModules: { [key: string]: boolean } = {};

        // Loop through all the module types
        for (const moduleType of Object.keys(this.enabledModulesForSpaceOriginal)) {
            // Has the enabled state changed?  If so, write that state into our new map
            if (this.enabledModulesForSpace[moduleType] !== this.enabledModulesForSpaceOriginal[moduleType]) {
                changedModules[moduleType] = this.enabledModulesForSpace[moduleType];
            }
        }

        // Return the changed map.
        return changedModules;
    }

    private moduleIsEnabled(moduleType: string): boolean {
        if (!this.enabledModulesForSpace.hasOwnProperty(moduleType)) {
            return false;
        }

        return this.enabledModulesForSpace[moduleType];
    }

    @Watch('spaceLastUpdatedTimestamp')
    private onSpaceUpdatedExternally(): void {
        if (this.$repoManager.space) {
            this.loadSpaces();
        }
    }

    get spaceLastUpdatedTimestamp(): number {
        return this.$store.state.spaceModule.lastUpdatedTimestamp;
    }
}
