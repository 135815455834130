
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Home extends Vue {
    get appName(): string {
        return this.$store.state.settingsModule.appSettings.appName;
    }
}
