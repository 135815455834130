import ApiClient from '@/ts/ApiClient';

import AddFilesToNoteRequest from '@/domains/notes/requests/notes/AddFilesToNoteRequest';
import AddNoteCategoryRequest from '@/domains/notes/requests/noteCategories/AddNoteCategoryRequest';
import AddRecipeRequest from '@/domains/recipies/requests/recipies/AddRecipeRequest';
import AddRecipeCategoryRequest from '@/domains/recipies/database/recipeCategories/AddRecipeCategoryRequest';
import AddSpaceRequest from '@/domains/spaces/requests/AddSpaceRequest';
import ConfirmPasswordResetRequest from '@/domains/users/requests/ConfirmPasswordResetRequest';
import DeleteNoteCategoryRequest from '@/domains/notes/requests/noteCategories/DeleteNoteCategoryRequest';
import DeleteNoteFileRequest from '@/domains/notes/requests/noteFiles/DeleteNoteFileRequest';
import DeleteRecipeCategoryRequest from '@/domains/recipies/database/recipeCategories/DeleteRecipeCategoryRequest';
import DeleteRecipeRequest from '@/domains/recipies/requests/recipies/DeleteRecipeRequest';
import DeleteSpaceRequest from '@/domains/spaces/requests/DeleteSpaceRequest';
import NoteCategoryListRequest from '@/domains/notes/requests/noteCategories/NoteCategoryListRequest';
import NoteFileListRequest from '@/domains/notes/requests/noteFiles/NoteFileListRequest';
import RecipeCategoryListRequest from '@/domains/recipies/database/recipeCategories/RecipeCategoryListRequest';
import RefreshTokenRequest from '@/domains/users/requests/RefreshTokenRequest';
import RecipeListRequest from '@/domains/recipies/requests/recipies/RecipeListRequest';
import RegisterUserRequest from '@/domains/users/requests/RegisterUserRequest';
import SpaceListRequest from '@/domains/spaces/requests/SpaceListRequest';
import SpaceExistsRequest from '@/domains/spaces/requests/SpaceExistsRequest';
import UpdateRecipeRequest from '@/domains/recipies/requests/recipies/UpdateRecipeRequest';
import UpdateRecipeCategoryRequest from '@/domains/recipies/database/recipeCategories/UpdateRecipeCategoryRequest';

import UpdateNoteCategoryRequest from '@/domains/notes/requests/noteCategories/UpdateNoteCategoryRequest';
import UpdateSpaceRequest from '../../domains/users/requests/UpdateSpaceRequest';


// CHANGELOG
import GetLastChangeLogIdRequest from '@/ts/Requests/ChangeLog/GetLastChangeLogIdRequest';
import GetChangeLogRequest from './ChangeLog/GetChangeLogRequest';
import DeleteNoteRequest from '@/domains/notes/requests/notes/DeleteNoteRequest';

// CONTACTS
import AddContactRequest from '@/domains/contacts/requests/AddContactRequest';
import UpdateContactRequest from '@/domains/contacts/requests/UpdateContactRequest';
import ContactListRequest from '@/domains/contacts/requests/ContactListRequest';
import DeleteContactRequest from '@/domains/contacts/requests/DeleteContactRequest';
import ContactMessageSummaryListRequest from '@/domains/contacts/requests/ContactMessageSummaryListRequest';
import ContactRecentMessageListRequest from '@/domains/contacts/requests/ContactRecentMessageListRequest';
import SetContactIsFavouriteRequest from '@/domains/contacts/requests/SetContactIsFavouriteRequest';

// FILES
import UploadFileRequest from '@/ts/Requests/File/UploadFileRequest';
import DeleteFileRequest from '@/ts/Requests/File/DeleteFileRequest';
import FileListRequest from '@/ts/Requests/File/FileListRequest';
import { GetFileRequest } from '@/ts/Requests/File/GetFileRequest';

// GALLERIES
import { GalleryRequestFactory } from '@/domains/galleries/requests/galleries/GalleryRequestFactory';

// MESSAGES
import DeleteMessageRequest from '@/domains/messages/requests/messages/DeleteMessageRequest';
import SendMessageRequest from '@/domains/messages/requests/messages/SendMessageRequest';
import SetMessageReadRequest from '@/domains/messages/requests/messages/SetMessageReadRequest';
import SetMessageRequiresResponseRequest from '@/domains/messages/requests/messages/SetMessageRequiresResponseRequest';
import { MessageListRequest } from '@/domains/messages/requests/messages/MessageListRequest';

// MODULE
import ModuleGroupListRequest from '@/domains/module/requests/moduleGroup/ModuleGroupListRequest';
import ModuleSpaceListRequest from '@/domains/module/requests/moduleSpace/ModuleSpaceListRequest';
import { CreateModuleSpaceRequest } from '@/domains/module/requests/moduleSpace/CreateModuleSpaceRequest';
import { DeleteModuleSpaceRequest } from '@/domains/module/requests/moduleSpace/DeleteModuleSpaceRequest';

// NOTES
import AddNoteRequest from '@/domains/notes/requests/notes/AddNoteRequest';
import UpdateNoteRequest from '@/domains/notes/requests/notes/UpdateNoteRequest';
import NoteListRequest from '@/domains/notes/requests/notes/NoteListRequest';
import { MessageAttachmentListRequest } from '@/domains/messages/requests/messageAttachments/MessageAttachmentListRequest';
import { EncryptDataRequest } from './Encryption/EncryptDataRequest';
import { DecryptDataRequest } from './Encryption/DecryptDataRequest';

// KANBAN
import { KanbanColumnsRequestFactory } from '@/domains/kanban/requests/kanbanColumns/KanbanColumnsRequestFactory';
import { KanbanTasksRequestFactory } from '@/domains/kanban/requests/kanbanTasks/KanbanTasksRequestFactory';

// USERS
import AddUserRequest from '@/domains/users/requests/AddUserRequest';
import UserListRequest from '@/domains/users/requests/UserListRequest';
import DeleteUserRequest from '@/domains/users/requests/DeleteUserRequest';
import DisplayNameCheckRequest from '@/domains/users/requests/DisplayNameCheckRequest';
import EmailCheckRequest from '@/domains/users/requests/EmailCheckRequest';
import { UserDetailsRequest } from '@/domains/users/requests/UserDetailsRequest';
import PasswordResetRequest from '@/domains/users/requests/PasswordResetRequest';
import UpdateUserRequest from '@/domains/users/requests/UpdateUserRequest';
import { UserTwoFactorAuthListPublicRequest } from './Auth/UserTwoFactorAuthListPublicRequest';
import { UserTwoFactorAuthListRequest } from './Auth/UserTwoFactorAuthListRequest';
import { DisableUserTwoFactorAuthRequest } from './Auth/DisableUserTwoFactorAuthRequest';
import { EnableUserTwoFactorAuthRequest } from './Auth/EnableUserTwoFactorAuthRequest';
import { CreateUserTwoFactorAuthRequest } from './Auth/CreateUserTwoFactorAuthRequest';
import { VerifyUserTwoFactorAuthRequest } from './Auth/VerifyUserTwoFactorAuthRequest';
import { DeleteUserTwoFactorAuthRequest } from './Auth/DeleteUserTwoFactorAuthRequest';
import { DisableUserTwoFactorAuthRequestRequest } from './Auth/DisableUserTwoFactorAuthRequestRequest';
import { VerifyDisableUserTwoFactorAuthRequest } from './Auth/VerifyDisableUserTwoFactorAuthRequest';

// VAULT
import { VaultRequestFactory } from '@/domains/vault/requests/VaultRequestFactory';
import { CredentialRequestFactory } from '@/domains/credentials/requests/CredentialRequestFactory';
import { LoginRequest } from '@/ts/Requests/Auth/LoginRequest';
import { GalleryFileRequestFactory } from '@/domains/galleries/requests/galleryFiles/GalleryFileRequestFactory';
import MoveEntityToSpaceRequest from '@/domains/spaces/requests/MoveEntityToSpaceRequest';
import GetFileBinaryRequest from '@/domains/files/requests/GetFileBinaryRequest';
import {AiRequestFactory} from '@/domains/ai/requests/AiRequestFactory';

export default class RequestFactory {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    public install(vue: any) {
        Object.defineProperty(vue.prototype, '$requestFactory', { value: this });
    }

    public updateToken(token: string): void {
        this.apiClient.setToken(token);
    }

    public get addNoteCategoryRequest(): AddNoteCategoryRequest {
        return new AddNoteCategoryRequest(this.apiClient);
    }

    public get addFilesToNoteRequest(): AddFilesToNoteRequest {
        return new AddFilesToNoteRequest(this.apiClient);
    }

    public get addRecipeRequest(): AddRecipeRequest {
        return new AddRecipeRequest(this.apiClient);
    }

    public get addRecipeCategoryRequest(): AddRecipeCategoryRequest {
        return new AddRecipeCategoryRequest(this.apiClient);
    }

    public get addUserRequest(): AddUserRequest {
        return new AddUserRequest(this.apiClient);
    }

    public get confirmPasswordResetRequest(): ConfirmPasswordResetRequest {
        return new ConfirmPasswordResetRequest(this.apiClient);
    }

    public get deleteNoteCategoryRequest(): DeleteNoteCategoryRequest {
        return new DeleteNoteCategoryRequest(this.apiClient);
    }

    public get deleteNoteFileRequest(): DeleteNoteFileRequest {
        return new DeleteNoteFileRequest(this.apiClient);
    }

    public get deleteRecipeCategoryRequest(): DeleteRecipeCategoryRequest {
        return new DeleteRecipeCategoryRequest(this.apiClient);
    }

    public get deleteRecipeRequest(): DeleteRecipeRequest {
        return new DeleteRecipeRequest(this.apiClient);
    }

    public get deleteSpaceRequest(): DeleteSpaceRequest {
        return new DeleteSpaceRequest(this.apiClient);
    }

    public get deleteUserRequest(): DeleteUserRequest {
        return new DeleteUserRequest(this.apiClient);
    }

    public get displayNameCheckRequest(): DisplayNameCheckRequest {
        return new DisplayNameCheckRequest(this.apiClient);
    }

    public get emailCheckRequest(): EmailCheckRequest {
        return new EmailCheckRequest(this.apiClient);
    }

    public get getChangeLogRequest(): GetChangeLogRequest {
        return new GetChangeLogRequest(this.apiClient);
    }

    public get getLastChangeLogIdRequest(): GetLastChangeLogIdRequest {
        return new GetLastChangeLogIdRequest(this.apiClient);
    }

    public get loginRequest(): LoginRequest {
        return new LoginRequest(this.apiClient);
    }

    public get messageAttachmentListRequest(): MessageAttachmentListRequest {
        return new MessageAttachmentListRequest(this.apiClient);
    }

    public get noteCategoryListRequest(): NoteCategoryListRequest {
        return new NoteCategoryListRequest(this.apiClient);
    }

    public get noteFileListRequest(): NoteFileListRequest {
        return new NoteFileListRequest(this.apiClient);
    }

    public get passwordResetRequest(): PasswordResetRequest {
        return new PasswordResetRequest(this.apiClient);
    }

    public get recipeCategoryListRequest(): RecipeCategoryListRequest {
        return new RecipeCategoryListRequest(this.apiClient);
    }

    public get recipeListRequest(): RecipeListRequest {
        return new RecipeListRequest(this.apiClient);
    }

    public get refreshTokenRequest(): RefreshTokenRequest {
        return new RefreshTokenRequest(this.apiClient);
    }

    public get userDetailsRequest(): UserDetailsRequest {
        return new UserDetailsRequest(this.apiClient);
    }

    public get updateNoteCategoryRequest(): UpdateNoteCategoryRequest {
        return new UpdateNoteCategoryRequest(this.apiClient);
    }

    public get updateRecipeRequest(): UpdateRecipeRequest {
        return new UpdateRecipeRequest(this.apiClient);
    }

    public get updateRecipeCategoryRequest(): UpdateRecipeCategoryRequest {
        return new UpdateRecipeCategoryRequest(this.apiClient);
    }

    public get updateSpaceRequest(): UpdateSpaceRequest {
        return new UpdateSpaceRequest(this.apiClient);
    }

    // ************* USERS ***************
    public get registerUserRequest(): RegisterUserRequest {
        return new RegisterUserRequest(this.apiClient);
    }

    public get updateUserRequest(): UpdateUserRequest {
        return new UpdateUserRequest(this.apiClient);
    }

    public get userListRequest(): UserListRequest {
        return new UserListRequest(this.apiClient);
    }

    public get userTwoFactorAuthListPublicRequest(): UserTwoFactorAuthListPublicRequest {
        return new UserTwoFactorAuthListPublicRequest(this.apiClient);
    }

    public get userTwoFactorAuthListRequest(): UserTwoFactorAuthListRequest {
        return new UserTwoFactorAuthListRequest(this.apiClient);
    }

    public get createUserTwoFactorAuthRequest(): CreateUserTwoFactorAuthRequest {
        return new CreateUserTwoFactorAuthRequest(this.apiClient);
    }

    public get verifyUserTwoFactorAuthRequest(): VerifyUserTwoFactorAuthRequest {
        return new VerifyUserTwoFactorAuthRequest(this.apiClient);
    }

    public get disableUserTwoFactorAuthRequest(): DisableUserTwoFactorAuthRequest {
        return new DisableUserTwoFactorAuthRequest(this.apiClient);
    }

    public get enableUserTwoFactorAuthRequest(): EnableUserTwoFactorAuthRequest {
        return new EnableUserTwoFactorAuthRequest(this.apiClient);
    }

    public get deleteUserTwoFactorAuthRequest(): DeleteUserTwoFactorAuthRequest {
        return new DeleteUserTwoFactorAuthRequest(this.apiClient);
    }

    public get disableUserTwoFactorAuthRequestRequest(): DisableUserTwoFactorAuthRequestRequest {
        return new DisableUserTwoFactorAuthRequestRequest(this.apiClient);
    }

    public get verifyDisableUserTwoFactorAuthRequest(): VerifyDisableUserTwoFactorAuthRequest {
        return new VerifyDisableUserTwoFactorAuthRequest(this.apiClient);
    }

    // ************* CONTACTS ***************
    public get addContactRequest(): AddContactRequest {
        return new AddContactRequest(this.apiClient);
    }

    public get updateContactRequest(): UpdateContactRequest {
        return new UpdateContactRequest(this.apiClient);
    }

    public get contactListRequest(): ContactListRequest {
        return new ContactListRequest(this.apiClient);
    }

    public get deleteContactRequest(): DeleteContactRequest {
        return new DeleteContactRequest(this.apiClient);
    }

    public get contactMessageSummaryListRequest(): ContactMessageSummaryListRequest {
        return new ContactMessageSummaryListRequest(this.apiClient);
    }

    public get contactRecentMessageListRequest(): ContactRecentMessageListRequest {
        return new ContactRecentMessageListRequest(this.apiClient);
    }

    public get setContactIsFavouriteRequest(): SetContactIsFavouriteRequest {
        return new SetContactIsFavouriteRequest(this.apiClient);
    }

    // ENCRYPTION
    public get decryptDataRequest(): DecryptDataRequest {
        return new DecryptDataRequest(this.apiClient);
    }

    public get encryptDataRequest(): EncryptDataRequest {
        return new EncryptDataRequest(this.apiClient);
    }

    // FILES
    public get uploadFileRequest(): UploadFileRequest {
        return new UploadFileRequest(this.apiClient);
    }

    public get deleteFileRequest(): DeleteFileRequest {
        return new DeleteFileRequest(this.apiClient);
    }

    public get fileListRequest(): FileListRequest {
        return new FileListRequest(this.apiClient);
    }

    public get getFileRequest(): GetFileRequest {
        return new GetFileRequest(this.apiClient);
    }

    public get getFileBinaryRequest(): GetFileBinaryRequest {
        return new GetFileBinaryRequest(this.apiClient);
    }

    // SPACE
    public get addSpaceRequest(): AddSpaceRequest {
        return new AddSpaceRequest(this.apiClient);
    }

    public get spaceExistsRequest(): SpaceExistsRequest {
        return new SpaceExistsRequest(this.apiClient);
    }

    public get spaceListRequest(): SpaceListRequest {
        return new SpaceListRequest(this.apiClient);
    }

    public get moveEntityToSpaceRequest(): MoveEntityToSpaceRequest {
        return new MoveEntityToSpaceRequest(this.apiClient);
    }

    // GALLERY
    public get gallery(): GalleryRequestFactory {
        return new GalleryRequestFactory(this.apiClient);
    }

    // GALLERY FILES
    public get galleryFiles(): GalleryFileRequestFactory {
        return new GalleryFileRequestFactory(this.apiClient);
    }

    // MESSAGES
    public get deleteMessageRequest(): DeleteMessageRequest {
        return new DeleteMessageRequest(this.apiClient);
    }

    public get sendMessageRequest(): SendMessageRequest {
        return new SendMessageRequest(this.apiClient);
    }

    public get setMessageReadRequest(): SetMessageReadRequest {
        return new SetMessageReadRequest(this.apiClient);
    }

    public get setMessageRequiresResponseRequest(): SetMessageRequiresResponseRequest {
        return new SetMessageRequiresResponseRequest(this.apiClient);
    }

    public get messageListRequest(): MessageListRequest {
        return new MessageListRequest(this.apiClient);
    }

    // ************* MODULES ***************
    public get createModuleSpaceRequest(): CreateModuleSpaceRequest {
        return new CreateModuleSpaceRequest(this.apiClient);
    }

    public get deleteModuleSpaceRequest(): DeleteModuleSpaceRequest {
        return new DeleteModuleSpaceRequest(this.apiClient);
    }

    public get moduleGroupListRequest(): ModuleGroupListRequest {
        return new ModuleGroupListRequest(this.apiClient);
    }

    public get moduleSpaceListRequest(): ModuleSpaceListRequest {
        return new ModuleSpaceListRequest(this.apiClient);
    }

    // ************* NOTES ***************
    public get addNoteRequest(): AddNoteRequest {
        return new AddNoteRequest(this.apiClient);
    }

    public get updateNoteRequest(): UpdateNoteRequest {
        return new UpdateNoteRequest(this.apiClient);
    }

    public get noteListRequest(): NoteListRequest {
        return new NoteListRequest(this.apiClient);
    }

    public get deleteNoteRequest(): DeleteNoteRequest {
        return new DeleteNoteRequest(this.apiClient);
    }

    // KANBAN
    public get kanbanColumns(): KanbanColumnsRequestFactory {
        return new KanbanColumnsRequestFactory(this.apiClient);
    }

    public get kanbanTasks(): KanbanTasksRequestFactory {
        return new KanbanTasksRequestFactory(this.apiClient);
    }

    // VAULT
    public get vault(): VaultRequestFactory {
        return new VaultRequestFactory(this.apiClient);
    }

    // Credentials
    public get credential(): CredentialRequestFactory {
        return new CredentialRequestFactory(this.apiClient);
    }

    // AI
    public get ai(): AiRequestFactory {
        return new AiRequestFactory(this.apiClient);
    }
}
