import MessageItem from '@/domains/messages/database/messages/MessageItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export default class MessageRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: MessageItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: MessageItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<MessageItem> {
        return new Promise<MessageItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
                }
            };

            request.onerror = () => {
                reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
            };
        });
    }

    public async getList(
        contactId: string,
        search: string = '',
    ): Promise<MessageItem[]> {
        return new Promise<MessageItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;
            const index = store.index('contact_id');
            request = index.openCursor(IDBKeyRange.only(contactId));

            const items: MessageItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (search === '') {
                        items.push(item);
                    } else {
                        const foundInMessage =
                            StringHelper.searchInText(searchKeywords, item.message_markdown);

                        if (foundInMessage) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    // Messages should always be returned in date order descending.
                    items.sort((a: MessageItem, b: MessageItem) => {
                        const timeA = a.created_dtm;
                        const timeB = b.created_dtm;

                        if (timeA > timeB) {
                            return - 1;
                        } else if (timeA < timeB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: MessageItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.id}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptyMessageItem(noteCategoryId: string = ''): MessageItem {
        return {
            id: '',
            space_id: '',
            sender_user_id: '',
            recipient_user_id: '',
            contact_id: '',
            recipient_contact_id: '',
            sender_contact_id: '',
            message_markdown: '',
            num_attachments: 0,
            created_dtm: 0,
            updated_dtm: 0,
            sent: 0,
            read: 0,
            requires_response: 0,
        };
    }

    private get storeName(): string {
        return 'messages';
    }
}
