
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Spinner from '@/domains/ui/views/Spinner.vue';

    @Component({
        components: {
            Spinner,
        },
    })
    export default class DeleteItemSpinner extends Vue {
        @Prop({required: true}) private deleting!: boolean;
        @Prop({required: true}) private item: any;

        private notifyDeleteClicked(): void {
            if (!this.deleting) {
                this.$emit('delete:clicked', this.item);
            }
        }
    }
