export enum ModuleType {
    ADMIN = 'Admin',
    ASSISTANT = 'Assistant',
    CREDENTIALS = 'Credentials',
    CONTACTS = 'Contacts',
    GALLERIES = 'Galleries',
    KANBAN = 'Kanban',
    NOTES = 'Notes',
    PROFILE = 'Profile',
    RECIPES = 'Recipes',
    RELOAD = 'Reload',
}
