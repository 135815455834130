
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Trilean } from '@/ts/Trilean';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';
import { UserTwoFactorAuthMethod } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthMethod';
import StringHelper from '@/ts/Helpers/StringHelper';

@Component({
  components: {
      SpinnerButton,
      ErrorMessage,
      InputChecker,
  },
})
export default class EnableTwoFactorAuth extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private errorMessage: string = '';
    private loading: boolean = false;

    public enable2FAPassword = '';
    private enable2FAPasswordOk: Trilean = Trilean.Unknown;

    public enable2FACode = '';
    private enable2FACodeOk: Trilean = Trilean.Unknown;

    public mounted() {
        this.reset();
    }

    public checkEnable2FAPassword(): void {
        if (this.enable2FAPassword.length > 0) {
            this.enable2FAPasswordOk = Trilean.True;
        } else {
            this.enable2FAPasswordOk = Trilean.False;
        }
    }

    public checkEnable2FACode(): void {
        if (this.enable2FACode.length >= 6) {
            if (this.twoFactorCodeAsNumber > 0) {
                this.enable2FACodeOk = Trilean.True;
            } else {
                this.enable2FACodeOk = Trilean.False;
            }
        } else {
            this.enable2FACodeOk = Trilean.False;
        }
    }

    public async handleEnableTwoFactorAuthIntent(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        try {
            this.loading = true;
            this.errorMessage = '';

            await this.$requestFactory.enableUserTwoFactorAuthRequest.execute(
                UserTwoFactorAuthMethod.TOTP,
                this.enable2FAPassword,
                this.twoFactorCodeAsNumber,
            );


            this.$emit('twoFactorAuthEnabled');
        } catch (error) {
            if (error.code === 'TwoFactorAuthFailed') {
                this.errorMessage = 'Two factor authentication was not enabled.  Please check ' +
                    'your password and code and try again.';
            } else {
                this.errorMessage = 'An unknown error occured.  Please try again later.';
            }
        } finally {
            this.loading = false;
        }
    }

    private get twoFactorCodeAsNumber(): number {
        const codeWithoutSpaces = StringHelper.removeSpaces(this.enable2FACode);
        return StringHelper.toInt(codeWithoutSpaces);
    }

    private formValid(): boolean {
        this.checkEnable2FAPassword();
        this.checkEnable2FACode();

        if (this.enable2FAPasswordOk !== Trilean.True) {
            this.errorMessage = 'Please enter your account password';
            return false;
        }

        if (this.enable2FACodeOk !== Trilean.True) {
            this.errorMessage = 'Please enter the authenticator code';
            return false;
        }

        this.errorMessage = '';

        return true;
    }

    private reset(): void {
        this.enable2FAPassword = '';
        this.enable2FACode = '';
    }
}
