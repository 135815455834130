import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import UserItem from '@/domains/users/database/users/UserItem';

export interface AddUserParams {
    display_name: string;
    email_address: string;
    password: string;
    role_type: string;
}

export default class AddUserRequest extends AbstractRequest {
    public async execute(params: AddUserParams): Promise<UserItem> {
        return new Promise<UserItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post('/private/user', params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('user')))  {
                    reject(Error('AddUserRequest - Invalid response from API'));
                    return;
                }

                const user: UserItem = response.data.user;
                user.role_type = params.role_type;

                resolve(user);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add a user failed.  Please try again later'));
            }
        });
    }
}
