
import { Component, Vue, Prop } from 'vue-property-decorator';
import ContactItem from '@/domains/contacts/database/ContactItem';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import FileItem from '@/ts/Database/Files/FileItem';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import ObjectHelper from '@/ts/Helpers/ObjectHelper';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import SelectOptionInterface from '@/ts/Interfaces/SelectOptionInterface';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import StringHelper from '@/ts/Helpers/StringHelper';
import Trilean from '@/ts/Trilean';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import ValidationHelper from '@/ts/Helpers/ValidationHelper';

@Component({
  components: {
      ErrorMessage,
      IconButton,
      InputChecker,
      Spinner,
      SpinnerButton,
      Toggle,
  },
})
export default class ContactEdit extends Vue {
    @Prop({required: true})
    private contactItem!: ContactItem;

    @Prop({required: true})
    private primaryPhotoFile!: FileItem|null;

    @Prop({required: true, type: Boolean})
    private saving!: boolean;

    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $helperFactory!: HelperFactory;

    private deletingFile = false;
    private errorMessage: string = '';
    private fileUploading = false;

    public contact: ContactItem|null = null;

    public contactFirstNameOk = Trilean.Unknown;
    public contactLastNameOk = Trilean.Unknown;
    public contactEmailPrimaryOk = Trilean.Unknown;
    public contactEmailSecondaryOk = Trilean.Unknown;
    public contactDateOfBirthOk = Trilean.Unknown;

    public created(): void {
        this.copyContactItem();
    }

    public mounted(): void {
        this.copyContactItem();

        if (!this.addingNewItem) {
            this.checkFields();
        }
    }

    private checkFields(): void {
        this.checkContactFirstNameOk();
        this.checkContactLastNameOk();
        this.checkPrimaryEmailOk();
        this.checkSecondaryEmailOk();
        this.checkDateOfBirthOk();
    }

    private copyContactItem(): void {
        this.contact = ObjectHelper.cloneObject(this.contactItem);
    }

    public get addingNewItem(): boolean {
        return this.contactItem.id.length === 0;
    }

    get birthdayReminderDaysInAdvanceOptions(): SelectOptionInterface[] {
        return [
            { val: '0', text: 'None Selected'},
            { val: '1', text: '1 day in advance'},
            { val: '2', text: '2 days in advance'},
            { val: '3', text: '3 days in advance'},
            { val: '5', text: '5 days in advance'},
            { val: '7', text: '7 days in advance'},
            { val: '14', text: '14 days in advance'},
            { val: '30', text: '30 days in advance'},
        ];
    }

    get contactBelongsToLoggedInUser(): boolean {
        return this.$store.getters.isCurrentUser(this.contactItem.created_by_user_id);
    }

    public setContactShared(newValue: boolean): void {
        if (this.contact == null) {
            return;
        }

        this.contact.shared = newValue;
    }

    private checkContactFirstNameOk(): void {
        if (this.contact == null) {
            this.contactFirstNameOk = Trilean.False;
            return;
        }

        if ((this.contact.first_name.length === 0) || (this.contact.first_name.length > 255)) {
            this.contactFirstNameOk = Trilean.False;
        } else {
            this.contactFirstNameOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkContactLastNameOk(): void {
        if (this.contact == null) {
            this.contactLastNameOk = Trilean.False;
            return;
        }

        if ((this.contact.last_name.length === 0) || (this.contact.last_name.length > 255)) {
            this.contactLastNameOk = Trilean.False;
        } else {
            this.contactLastNameOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkPrimaryEmailOk(): void {
        if (this.contact == null) {
            this.contactEmailPrimaryOk = Trilean.False;
            return;
        }

        if (this.contact.email_primary === '') {
            this.contactEmailPrimaryOk = Trilean.True;
            return;
        }

        if (ValidationHelper.emailAddressValid(this.contact.email_primary)) {
            this.contactEmailPrimaryOk = Trilean.True;
            return;
        }

        this.contactEmailPrimaryOk = Trilean.False;
    }

    private checkSecondaryEmailOk(): void {
        if (this.contact == null) {
            this.contactEmailSecondaryOk = Trilean.False;
            return;
        }

        if (this.contact.email_secondary === '') {
            this.contactEmailSecondaryOk = Trilean.True;
            return;
        }

        if (ValidationHelper.emailAddressValid(this.contact.email_secondary)) {
            this.contactEmailSecondaryOk = Trilean.True;
            return;
        }

        this.contactEmailSecondaryOk = Trilean.False;
    }

    private checkDateOfBirthOk(): void {
        if (this.contact == null) {
            this.contactDateOfBirthOk = Trilean.False;
            return;
        }

        if (this.contact.date_of_birth === '') {
            this.contactDateOfBirthOk = Trilean.True;
            return;
        }

        if (ValidationHelper.isISODate(this.contact.date_of_birth)) {
            this.contactDateOfBirthOk = Trilean.True;
            return;
        }

        this.contactDateOfBirthOk = Trilean.False;
    }

    private async handleUploadPhoto(): Promise<void> {
        const uploadFile: any = this.$refs.primary_photo;
        const fileName: string = uploadFile.files[0].name;

        // Ensure the selected file looks like an image.
        if (!StringHelper.filenameHasValidImageExtension(fileName)) {
            this.errorMessage = `$fileName does not appear to be a valid image file. ` +
                `Images should have a .jpg, .jpeg, .png or .gif extension.`;
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile.files[0]);

        try {
            const params: UploadFileParams = {
                formData,
            };

            this.fileUploading = true;

            const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'contact');
            await this.$repoManager.file.save(fileItem);

            await this.$emit('photo-uploaded', fileItem);

            this.fileUploading = false;
        } catch (error) {
            this.errorMessage = 'Error uploading file: ' + error.toString();
        } finally {
            this.fileUploading = false;
        }
    }

    private async deletePrimaryPhoto(): Promise<void> {
        if (!this.primaryPhotoFile) {
            return;
        }

        if (!(confirm(`Delete the uploaded primary photo?  Are you sure?`))) {
            return;
        }

        try {
            this.deletingFile = true;

            const tempFile = this.primaryPhotoFile;

            await this.$emit('clear-primary-photo');

            // If we're editing an existing contact, save the contact to clear the foreign key to the file
            await this.$emit('save-contact', this.contact, false);

            // Delete the file from the server
            const ok = await this.$requestFactory.deleteFileRequest.execute(tempFile.id);
            if (!ok) {
                this.errorMessage = 'The hero image could not be deleted.  Please try again later.';
            }

            // Remove the file from the local repo too.
            await this.$repoManager.file.delete(tempFile);
        } catch (error) {
            this.errorMessage = 'The file could not be deleted.  Please try again later.';
        } finally {
            this.deletingFile = false;
        }
    }

    private broadcastGoBackToContactList(): void {
        this.$emit('go-back-to-contact-list');
    }

    private handleCancelEdit(): void {
        if (this.addingNewItem) {
            this.broadcastGoBackToContactList();
        } else {
            this.$emit('cancel-edit');
        }
    }

    private formValid(): boolean {
        if (this.contactFirstNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid contact first name.';
            return false;
        }

        if (this.contactLastNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid contact last name.';
            return false;
        }

        if (this.contactEmailPrimaryOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid primary email address or make it empty.';
            return false;
        }

        if (this.contactEmailSecondaryOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid secondary email address or make it empty.';
            return false;
        }

        if (this.contactDateOfBirthOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid date of birth or make it empty.';
            return false;
        }

        return true;
    }

    private handleSaveContact(): void {
        this.checkFields();

        if (!this.formValid()) {
            return;
        }

        this.$emit('save-contact', this.contact, true);
    }
}
