import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteCategoryItem from '@/domains/notes/database/noteCategories/NoteCategoryItem';

export default class NoteCategoryListRequest extends AbstractRequest {
    public async execute(): Promise<NoteCategoryItem[]> {
        return new Promise<NoteCategoryItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/note_category`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('noteCategories')))  {
                    reject(Error('NoteCategoryListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.noteCategories);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load note categories from the API'));
            }
        });
    }
}
