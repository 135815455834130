import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteItem from '@/domains/notes/database/notes/NoteItem';
import { SymetricCipherType } from '@/ts/Enums/SymetricCipherType';

export interface AddNoteParams {
    note_category_id: string;
    title: string;
    description: string;
    encryption_cipher_type?: SymetricCipherType;
    encryption_integrity_hash?: string;
    encryption_metadata?: {
        iv: string;
        salt: string;
    };
}

export default class AddNoteRequest extends AbstractRequest {
    public async execute(params: AddNoteParams): Promise<NoteItem> {
        return new Promise<NoteItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/note`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('note')))  {
                    reject(Error('AddNoteRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.note);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add a note failed.  Please try again later'));
            }
        });
    }
}
