import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { GalleryItem } from '@/domains/galleries/database/galleries/GalleryItem';
import {PublicationMode} from '@/domains/credentials/types/PublicationMode';

export interface UpdateGalleryParams {
    space_id: string;
    name: string;
    description: string;
    shared: boolean;
    publication_mode: PublicationMode;
    cover_photo_file_id?: string;
}

export class UpdateGalleryRequest extends AbstractRequest {
    public async execute(galleryId: string, params: UpdateGalleryParams): Promise<GalleryItem> {
        return new Promise<GalleryItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.patch(`/private/galleries/${galleryId}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('gallery')))  {
                    reject(Error('UpdateGalleryRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.gallery);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a gallery failed.  Please try again later'));
            }
        });
    }
}
