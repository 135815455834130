import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ModuleType } from '@/ts/Enums/ModuleType';
import { AppSettings } from '@/domains/app/interfaces/AppSettings';
import {ModuleSpaceItem} from '@/domains/module/database/moduleSpace/ModuleSpaceItem';

@Module
class SettingsModule extends VuexModule {
    public screenWidth: number = 0;
    public moduleType: ModuleType = ModuleType.NOTES;

    public appSettings: AppSettings = {
        appName: '',
        version: '',
    };

    public enabledModulesForSpace: ModuleSpaceItem[] = [];

    @Mutation
    public setScreenWidth(value: number) {
        this.screenWidth = value;
    }

    @Mutation
    public setModuleType(value: ModuleType) {
        this.moduleType = value;
    }

    @Mutation
    public setAppSettings(value: AppSettings) {
        this.appSettings = value;
    }

    @Mutation
    public setEnabledModulesForSpace(enabledModules: ModuleSpaceItem[]) {
        this.enabledModulesForSpace = enabledModules;
    }
}

export default SettingsModule;
