
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import DocumentList from '@/domains/ui/views/DocumentList.vue';

import {Routes} from '@/domains/app/router/router';
import FileItem from '@/ts/Database/Files/FileItem';
import NoteCategoryItem from '@/domains/notes/database/noteCategories/NoteCategoryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { SymetricCipherType } from '../../../ts/Enums/SymetricCipherType';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      DeleteItemSpinner,
      DocumentList,
  },
})
export default class Note extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;

    private noteTitle: string = '';
    private noteDescription: string = '';
    private noteAttachments: FileItem[] = [];
    private encryptionCipherType: SymetricCipherType = SymetricCipherType.AES256;
    private encryptionIntegrityHash = '';
    private encryptionIV = '';
    private encryptionSalt = '';
    private encryptionPassword = '';
    private decryptionInProgress = false;
    private decryptedNoteDescription = '';

    private loaded: boolean = false;
    private loading: boolean = false;
    private noteCategories: NoteCategoryItem[] = [];
    private errorMessage: string = '';

    public mounted(): void {
        if (this.$repoManager.note) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    public async loadDataFromRepos(): Promise<void> {
        this.loaded = false;

        await this.loadNoteCategories();
        await this.loadNote();
        await this.loadNoteAttachments();

        this.loaded = true;
    }

    public async deleteNote(): Promise<void> {
        if (!confirm('Delete this note?  Are you sure?')) {
            return;
        }

        try {
            // Load any attachments associated with the note
            const noteFiles = await this.$repoManager.noteFile.getList(this.noteId);
            let allNoteFilesDeletedOk = true;

            // For each note file, ask the server to delete it (and any associated files)
            // Also delete the note file from the local repo
            // Also delete the file from the local repo
            for (const index in noteFiles) {
                if (noteFiles.hasOwnProperty(index)) {
                    const noteFile = noteFiles[index];
                    const noteFileDeletedOk = await
                        this.$requestFactory.deleteNoteFileRequest.execute(this.noteId, noteFile.id);

                    if (!noteFileDeletedOk) {
                        allNoteFilesDeletedOk = false;
                    } else {
                        const fileToDelete = await this.$repoManager.file.getItem(noteFile.file_id);
                        await this.$repoManager.noteFile.delete(noteFile);
                        await this.$repoManager.file.delete(fileToDelete);
                    }
                }
            }

            if (!allNoteFilesDeletedOk) {
                this.errorMessage = 'The note attachments could not be deleted.  Please try again later';
                return;
            }

            const deletedOk = await this.$requestFactory.deleteNoteRequest.execute(this.noteId);
            if (!deletedOk) {
                this.errorMessage = 'The note could not be deleted.  Please try again later.';
                return;
            }

            this.loading = true;

            const noteItem = await this.$repoManager.note.getItem(this.noteId);
            await this.$repoManager.note.delete(noteItem);

            await this.$store.dispatch('flagNoteModuleUpdated');

            this.navigateToNotesScreen();
        } catch (error) {
            this.errorMessage = 'The note could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    public editNote(): void {
        this.$router.push({name: Routes.NOTE_EDIT, params: {id: this.noteId}});
    }

    public async navigateToCategory(categoryId: string): Promise<void> {
        await this.$store.commit('setNoteCategoryId', categoryId);
        this.navigateToNotesScreen();
    }

    public getFormattedNoteDescription(): string {
        if (this.decryptedNoteDescription !== '') {
            return this.decryptedNoteDescription.replace(/(\r\n|\n|\r)/g, '<br />');
        } else {
            return this.noteDescription.replace(/(\r\n|\n|\r)/g, '<br />');
        }
    }

    private async loadNoteCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            this.noteCategories = await this.$repoManager.noteCategory.getList(this.selectedSpace, '');
        } catch (error) {
            this.errorMessage = 'Error: Note categories could not be loaded.';
        }
    }

    private async loadNote(): Promise<void> {
        try {
            this.errorMessage = '';
            const noteItem = await this.$repoManager.note.getItem(this.noteId);

            this.noteTitle = noteItem.title;
            this.noteDescription = noteItem.description;

            this.encryptionIntegrityHash = noteItem.encryption_integrity_hash ?
                noteItem.encryption_integrity_hash : '';

            this.encryptionCipherType = noteItem.encryption_symmetric_cipher ?
                noteItem.encryption_symmetric_cipher : SymetricCipherType.AES256;

            this.encryptionIV = noteItem.encryption_metadata ?
                noteItem.encryption_metadata.iv : '';

            this.encryptionSalt = noteItem.encryption_metadata ?
                noteItem.encryption_metadata.salt : '';

            this.decryptedNoteDescription = '';

        } catch (error) {
            this.errorMessage = 'Error: Note could not be loaded.';
        }
    }

    private async loadNoteAttachments(): Promise<void> {
        this.noteAttachments = [];

        const noteFileItems = await this.$repoManager.noteFile.getList(this.noteId);
        if (noteFileItems.length > 0) {
            for (const index in noteFileItems) {
                if (noteFileItems.hasOwnProperty(index)) {
                    const noteFileItem = noteFileItems[index];
                    const thisFile = await this.$repoManager.file.getItem(noteFileItem.file_id);
                    this.noteAttachments.push(thisFile);
                }
            }
        }
    }

    public handleEncryptionPasswordChanged(): void {
        if (this.encryptionPassword.length > 0) {
            this.errorMessage = '';
        } else {
            this.errorMessage = 'You must enter your encryption password to decrypt this note.';
        }
    }

    public async decryptNote(): Promise<void> {
        if (this.encryptionPassword === '') {
            this.errorMessage = 'You must enter your encryption password to decrypt this note.';
            return;
        }

        this.decryptionInProgress = true;

        try {
            const decryptionParams = {
                cipher_type: this.encryptionCipherType,
                password: this.encryptionPassword,
                encrypted_text: this.noteDescription,
                integrity_hash: this.encryptionIntegrityHash,
                metadata: {
                    iv: this.encryptionIV,
                    salt: this.encryptionSalt,
                },
            };

            this.decryptedNoteDescription = await this.$requestFactory.decryptDataRequest.execute(decryptionParams);
            this.errorMessage = '';
        } catch (error) {
            this.errorMessage = 'Sorry decryption failed.  Please check your password and try again.';
            this.encryptionPassword = '';
        } finally {
            this.decryptionInProgress = false;
        }
    }

    private get noteId(): string {
        return this.$store.state.noteModule.noteId;
    }

    get noteCategoryId(): string {
        return this.$store.state.noteModule.noteCategoryId;
    }

    set noteCategoryId(value: string) {
        this.$store.commit('setNoteCategoryId', value);
    }

    private navigateToNotesScreen(): void {
        this.$router.push({name: Routes.NOTE_LIST});
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    public get lastUpdated(): string {
        return this.$store.state.noteModule.lastUpdated;
    }

    public get noteIsEncrypted(): boolean {
        return this.encryptionIntegrityHash !== '' && this.encryptionIV !== '' && this.encryptionSalt !== '';
    }

    // If the user has selected a different workspace, redirect back to notes screen.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.navigateToNotesScreen();
        }
    }
}
