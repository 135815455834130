
import { Component, Vue } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import ApiClient from '@/ts/ApiClient';
import Trilean from '@/ts/Trilean';
import RegisterUserRequest from '@/domains/users/requests/RegisterUserRequest';
import Container from '@/domains/app/views/Container.vue';
import DisplayNameCheckRequest from '@/domains/users/requests/DisplayNameCheckRequest';
import EmailCheckRequest from '@/domains/users/requests/EmailCheckRequest';

@Component({
  components: {
      Form,
      ErrorMessage,
      InputChecker,
      Container,
  },
})
export default class Register extends Vue {
    private $http!: ApiClient;
    private errorMessage: string = '';
    private registrationComplete: boolean = false;

    private displayName: string = '';
    private groupName: string = '';
    private emailAddress: string = '';
    private password: string = '';
    private passwordRepeat: string = '';

    private displayNameOk: Trilean = Trilean.Unknown;
    private groupNameOk: Trilean = Trilean.Unknown;
    private emailOk: Trilean = Trilean.Unknown;
    private passwordOk: Trilean = Trilean.Unknown;
    private passwordRepeatOk: Trilean = Trilean.Unknown;

    private checkDisplayNameRequest!: DisplayNameCheckRequest;
    private checkEmailRequest!: EmailCheckRequest;

    private created(): void {
        this.registrationComplete = false;
        this.checkDisplayNameRequest = new DisplayNameCheckRequest(this.$http);
        this.checkEmailRequest = new EmailCheckRequest(this.$http);
    }

    private async checkDisplayNameAvailable(): Promise<void> {
        if (this.displayName === '') {
            return;
        }

        try {
            if (await this.checkDisplayNameRequest.execute(encodeURI(this.displayName))) {
                this.displayNameOk = Trilean.True;
                this.errorMessage = '';
            } else {
                this.displayNameOk = Trilean.False;
            }
        } catch (error) {
            this.errorMessage = error.toString();
            this.displayNameOk = Trilean.False;
        }
    }

    private async checkEmailOk(): Promise<void> {
        if (this.emailAddress === '') {
            return;
        }

        try {
            if (await this.checkEmailRequest.execute(encodeURI(this.emailAddress))) {
                this.emailOk = Trilean.True;
                this.errorMessage = '';
            } else {
                this.emailOk = Trilean.False;
            }
        } catch (error) {
            this.errorMessage = error.toString();
            this.emailOk = Trilean.False;
        }
    }

    private checkPassword(): void {
        this.passwordOk = (this.password.length > 7) ? Trilean.True : Trilean.False;
        if (this.passwordOk === Trilean.False) {
            this.errorMessage = 'You password must be at least 8 characters long.';
        } else {
            this.errorMessage = '';

            if (this.passwordRepeat !== '') {
                this.passwordRepeat = '';
                this.passwordRepeatOk = Trilean.False;
            }
        }
    }

    private checkPasswordRepeat(): void {
        this.passwordRepeatOk = (this.password === this.passwordRepeat) ? Trilean.True : Trilean.False;
        if (this.passwordRepeatOk === Trilean.False) {
            this.errorMessage = 'You passwords do not match.  Please enter them again carefully.';
        } else {
            this.errorMessage = '';
        }
    }

    private checkGroupName(): void {
        this.groupNameOk = (this.groupName.length > 2) ? Trilean.True : Trilean.False;
        if (this.groupNameOk === Trilean.False) {
            this.errorMessage = 'You group name must be at least 3 characters long.';
        } else {
            this.errorMessage = '';
        }
    }

    private formValid(): boolean {
        if (this.displayNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid display name.';
            return false;
        }

        if (this.groupNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid group name.';
            return false;
        }

        if (this.emailOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid email address.';
            return false;
        }

        if (this.passwordOk !== Trilean.True) {
            this.errorMessage = 'Please enter a valid password.';
            return false;
        }

        if (this.passwordRepeatOk !== Trilean.True) {
            this.errorMessage = 'Please ensure your passwords match.';
            return false;
        }

        return true;
    }

    private async register(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        const request = new RegisterUserRequest(this.$http);

        const requestParams = {
            display_name: this.displayName,
            group_name: this.groupName,
            email_address: this.emailAddress,
            password: this.password,
        };

        try {
            const userRegisteredOk = await request.execute(requestParams);

            if (!userRegisteredOk) {
                this.errorMessage = 'Sorry, something went wrong whilst trying to create ' +
                    'your account.  Please try again later.';
            } else {
                this.registrationComplete = true;
            }

        } catch (error) {
            this.errorMessage = error.toString();
        }
    }
}
