import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';

export default class RecipeRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: RecipeCategoryItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: RecipeCategoryItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const exists = await this.exists(item.id);

            let request: IDBRequest;

            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<RecipeCategoryItem> {
        return new Promise<RecipeCategoryItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error('Unable to load recipe category item with id: ' + id));
                }
            };

            request.onerror = () => {
                reject(new Error('Unable to load recipe category item with id: ' + id));
            };
        });
    }

    public async getList(spaceId: string, search: string = ''): Promise<RecipeCategoryItem[]> {
        return new Promise<RecipeCategoryItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            const index = store.index('space_id');
            const request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: RecipeCategoryItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;
                    items.push(item);
                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: RecipeCategoryItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.name}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    private get storeName(): string {
        return 'recipe_categories';
    }
}
