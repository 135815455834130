import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module
export default class RecipeModule extends VuexModule {
    public recipeId: string = '';
    public recipeCategoryId: string = '';
    public recipeSearchKeywords: string = '';
    public lastUpdated: number = 0;

    @Mutation
    public setRecipeId(value: string) {
        this.recipeId = value;
    }

    @Mutation
    public setRecipeCategoryId(value: string) {
        this.recipeCategoryId = value;
    }

    @Mutation
    public setRecipeSearch(value: string) {
        this.recipeSearchKeywords = value;
    }

    @Mutation
    public setRecipeModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Action
    public clearRecipeModuleState() {
        this.context.commit('setRecipeId', '');
        this.context.commit('setRecipeCategoryId', '');
        this.context.commit('setRecipeSearch', '');
    }

    @Action
    public flagRecipeModuleUpdated() {
        this.context.commit('setRecipeModuleLastUpdated', (new Date()).getTime());
    }
}
