import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { KanbanViewType } from '@/domains/kanban/enums/KanbanViewType';

@Module
export default class KanbanModule extends VuexModule {
    public lastUpdated: number = 0;
    public searchKeywords: string = '';
    public selectedKanbanViewType: KanbanViewType = KanbanViewType.ACTION;

    @Mutation
    public setKanbanModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Mutation
    public setKabanSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setSelectedKabanViewType(value: KanbanViewType) {
        this.selectedKanbanViewType = value;
    }

    @Action
    public clearKanbanModuleState() {
        this.context.commit('setKabanSearchKeywords', '');

        this.context.dispatch('flagKanbanModuleUpdated');
    }

    @Action
    public flagKanbanModuleUpdated() {
        this.context.commit('setKanbanModuleLastUpdated', (new Date()).getTime());
    }
}
