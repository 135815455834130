import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import KanbanColumnsItem from '@/domains/kanban/database/kanbanColumns/KanbanColumnsItem';

export interface SetKanbanColumnParams {
    space_id: string;
    column1: string;
    column2: string;
    column3: string;
    column4: string;
    column5: string;
    column6: string;
    column7: string;
    column8: string;
}

export class SetKanbanColumnsRequest extends AbstractRequest {
    public async execute(params: SetKanbanColumnParams): Promise<KanbanColumnsItem> {
        return new Promise<KanbanColumnsItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/kanban_columns`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('kanban_columns')))  {
                    reject(Error('SetKanbanColumnsRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.kanban_columns);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update the kanban columns failed.  Please try again later'));
            }
        });
    }
}
