import { AbstractRequestFactory } from '../../../../ts/Requests/AbstractRequestFactory';
import { CreateGalleryFileRequest } from './CreateGalleryFileRequest';
import { DeleteGalleryFileRequest } from './DeleteGalleryFileRequest';
import { GalleryFileListRequest } from './GalleryFileListRequest';
import {UpdateGalleryFileRequest} from '@/domains/galleries/requests/galleryFiles/UpdateGalleryFileRequest';

export class GalleryFileRequestFactory extends AbstractRequestFactory {
    public get createGalleryFileRequest(): CreateGalleryFileRequest {
        return new CreateGalleryFileRequest(this.apiClient);
    }

    public get deleteGalleryFileRequest(): DeleteGalleryFileRequest {
        return new DeleteGalleryFileRequest(this.apiClient);
    }

    public get galleryFileListRequest(): GalleryFileListRequest {
        return new GalleryFileListRequest(this.apiClient);
    }

    public get updateGalleryFileRequest(): UpdateGalleryFileRequest {
        return new UpdateGalleryFileRequest(this.apiClient);
    }
}
