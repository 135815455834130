import { CompletionRepo } from '@/domains/assistant/database/CompletionRepo';
import ContactMessageSummaryRepo from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryRepo';
import ContactRepo from '@/domains/contacts/database/ContactRepo';
import { CredentialRepo } from '@/domains/credentials/database/CredentialRepo';
import FileRepo from '@/ts/Database/Files/FileRepo';
import { GalleryRepo } from '@/domains/galleries/database/galleries/GalleryRepo';
import { GalleryFileRepo } from '@/domains/galleries/database/galleryFiles/GalleryFileRepo';
import { KanbanColumnsRepo } from '@/domains/kanban/database/kanbanColumns/KanbanColumnsRepo';
import { KanbanTaskRepo } from '@/domains/kanban/database/kanbanTasks/KanbanTaskRepo';
import MessageRepo from '@/domains/messages/database/messages/MessageRepo';
import { MessageAttachmentRepo } from '@/domains/messages/database/messageAttachments/MessageAttachmentRepo';
import ModuleGroupRepo from '@/domains/module/database/moduleGroup/ModuleGroupRepo';
import ModuleSpaceRepo from '@/domains/module/database/moduleSpace/ModuleSpaceRepo';
import NoteRepo from '@/domains/notes/database/notes/NoteRepo';
import NoteCategoryRepo from '@/domains/notes/database/noteCategories/NoteCategoryRepo';
import NoteFileRepo from '@/domains/notes/database/noteFIles/NoteFileRepo';
import RecipeCategoryRepo from '@/domains/recipies/database/recipeCategories/RecipeCategoryRepo';
import RecipeRepo from '@/domains/recipies/database/recipies/RecipeRepo';
import SpaceRepo from '@/domains/spaces/database/SpaceRepo';
import UserRepo from '@/domains/users/database/users/UserRepo';

import { Store } from 'vuex';
import RequestFactory from '../Requests/RequestFactory';

export default class RepoManager {
    private db!: IDBDatabase;
    private completionRepo!: CompletionRepo;
    private contactRepo!: ContactRepo;
    private contactMessageSummaryRepo!: ContactMessageSummaryRepo;
    private credentialRepo!: CredentialRepo;
    private fileRepo!: FileRepo;
    private galleryRepo!: GalleryRepo;
    private galleryFileRepo!: GalleryFileRepo;
    private kanbanColumnsRepo!: KanbanColumnsRepo;
    private kanbanTaskRepo!: KanbanTaskRepo;
    private messageRepo!: MessageRepo;
    private messageAttachmentRepo!: MessageAttachmentRepo;
    private moduleGroupRepo!: ModuleGroupRepo;
    private moduleSpaceRepo!: ModuleSpaceRepo;
    private noteRepo!: NoteRepo;
    private noteCategoryRepo!: NoteCategoryRepo;
    private noteFileRepo!: NoteFileRepo;
    private recipeCategoryRepo!: RecipeCategoryRepo;
    private recipeRepo!: RecipeRepo;
    private spaceRepo!: SpaceRepo;
    private userRepo!: UserRepo;

    private readonly store: Store<any>;
    private readonly requestFactory: RequestFactory;

    constructor(store: Store<any>, requestFactory: RequestFactory) {
        this.store = store;
        this.requestFactory = requestFactory;

        this.ensureIndexDbIsSupportedByBrowser();
        this.getConnection();
    }

    get completion(): CompletionRepo {
        return this.completionRepo;
    }

    get contact(): ContactRepo {
        return this.contactRepo;
    }

    get contactMessageSummary(): ContactMessageSummaryRepo {
        return this.contactMessageSummaryRepo;
    }

    get credential(): CredentialRepo {
        return this.credentialRepo;
    }

    get file(): FileRepo {
        return this.fileRepo;
    }

    get gallery(): GalleryRepo {
        return this.galleryRepo;
    }

    get galleryFile(): GalleryFileRepo {
        return this.galleryFileRepo;
    }

    get kanbanColumns(): KanbanColumnsRepo {
        return this.kanbanColumnsRepo;
    }

    get kanbanTask(): KanbanTaskRepo {
        return this.kanbanTaskRepo;
    }

    get message(): MessageRepo {
        return this.messageRepo;
    }

    get messageAttachments(): MessageAttachmentRepo {
        return this.messageAttachmentRepo;
    }

    get moduleGroup(): ModuleGroupRepo {
        return this.moduleGroupRepo;
    }

    get moduleSpace(): ModuleSpaceRepo {
        return this.moduleSpaceRepo;
    }

    get note(): NoteRepo {
        return this.noteRepo;
    }

    get noteCategory(): NoteCategoryRepo {
        return this.noteCategoryRepo;
    }

    get recipe(): RecipeRepo {
        return this.recipeRepo;
    }

    get recipeCategory(): RecipeCategoryRepo {
        return this.recipeCategoryRepo;
    }

    get noteFile(): NoteFileRepo {
        return this.noteFileRepo;
    }

    get space(): SpaceRepo {
        return this.spaceRepo;
    }

    get user(): UserRepo {
        return this.userRepo;
    }

    // Install the RepoManager as a Vue plugin
    public install(vue: any) {
        Object.defineProperty(vue.prototype, '$repoManager', { value: this });
    }

    private getDbVersion(): number {
        return 16;
    }

    // ************** PRIVATE METHODS ************************/
    private upgradeSchema(oldVersion: number, transaction: IDBTransaction|null): void {
        if (oldVersion < 1) {
            const fileStore = this.db.createObjectStore('files', { keyPath: 'id' });
            fileStore.createIndex('mime_type', 'mime_type');
            fileStore.createIndex('name', 'name');

            const noteStore = this.db.createObjectStore('notes', { keyPath: 'id' });
            noteStore.createIndex('category_id', 'note_category_id');
            noteStore.createIndex('title', 'title');

            const noteCategoryStore = this.db.createObjectStore('note_categories', { keyPath: 'id' });
            noteCategoryStore.createIndex('name', 'name');

            const noteFileStore = this.db.createObjectStore('note_files', { keyPath: 'id' });
            noteFileStore.createIndex('note_id', 'note_id');

            const userStore = this.db.createObjectStore('users', { keyPath: 'id' });
            userStore.createIndex('display_name', 'display_name');
            userStore.createIndex('role_type', 'role_type');
        }

        const currentDbVersion = this.getDbVersion();
        let upgradeVersionNumber = oldVersion;

        if (transaction === null) {
            return;
        }

        while (upgradeVersionNumber < currentDbVersion) {
            upgradeVersionNumber++;

            switch (upgradeVersionNumber) {
                case 3:
                    this.handleVersion3Upgrade();
                    break;

                case 4:
                    this.handleVersion4Upgrade(transaction);
                    break;

                case 5:
                    this.handleVersion5Upgrade(transaction);
                    break;

                case 6:
                    this.handleVersion6Upgrade(transaction);
                    break;

                case 7:
                    this.handleVersion7Upgrade(transaction);
                    break;

                case 8:
                    this.handleVersion8Upgrade(transaction);
                    break;

                case 9:
                    this.handleVersion9Upgrade(transaction);
                    break;

                case 10:
                    this.handleVersion10Upgrade(transaction);
                    break;

                case 11:
                    this.handleVersion11Upgrade(transaction);
                    break;

                case 12:
                    this.handleVersion12Upgrade(transaction);
                    break;

                case 13:
                    this.handleVersion13Upgrade(transaction);
                    break;

                case 14:
                    this.handleVersion14Upgrade(transaction);
                    break;

                case 15:
                    this.handleVersion15Upgrade(transaction);
                    break;

                case 16:
                    this.handleVersion16Upgrade(transaction);
                    break;
            }
        }
    }

    private handleVersion3Upgrade(): void {
        const recipeCategoryStore = this.db.createObjectStore('recipe_categories', { keyPath: 'id' });

        recipeCategoryStore.createIndex('name', 'name');

        const reipeStore = this.db.createObjectStore('recipes', { keyPath: 'id' });
        reipeStore.createIndex('category_id', 'recipe_category_id');
        reipeStore.createIndex('title', 'title');
    }

    private handleVersion4Upgrade(transaction: IDBTransaction): void {
        const spaceStore = this.db.createObjectStore('spaces', { keyPath: 'id' });
        spaceStore.createIndex('name', 'name');

        // Add the space_id index to the existing note categories store.
        const noteCategoryStore = transaction.objectStore('note_categories');
        noteCategoryStore.createIndex('space_id', 'space_id');
    }

    private handleVersion5Upgrade(transaction: IDBTransaction): void {
        const contactsStore = this.db.createObjectStore('contacts', { keyPath: 'id' });
        contactsStore.createIndex('space_id', 'space_id');
    }

    private handleVersion6Upgrade(transaction: IDBTransaction): void {
        const contactMessageSummaryStore = this.db.createObjectStore('contactMessageSummaryItems',
            { keyPath: 'contact_id' });

        contactMessageSummaryStore.createIndex('space_id', 'space_id');
    }

    private handleVersion7Upgrade(transaction: IDBTransaction): void {
        const messageStore = this.db.createObjectStore('messages',
            { keyPath: 'id' });

        messageStore.createIndex('contact_id', 'contact_id');
        messageStore.createIndex('space_id', 'space_id');
    }

    private handleVersion8Upgrade(transaction: IDBTransaction): void {
        const galleryFilesStore = this.db.createObjectStore('galleryFiles',
            { keyPath: 'id' });

        galleryFilesStore.createIndex('gallery_id', 'gallery_id');
        galleryFilesStore.createIndex('contact_id', 'contact_id');
        galleryFilesStore.createIndex('message_id', 'message_id');
    }

    private handleVersion9Upgrade(transaction: IDBTransaction): void {
        const galleryStore = this.db.createObjectStore(
            GalleryRepo.indexDbStoreName,
            { keyPath: 'id' },
        );

        galleryStore.createIndex('space_id', 'space_id');
        galleryStore.createIndex('contact_id', 'contact_id');
    }

    private handleVersion10Upgrade(transaction: IDBTransaction): void {
        // Add the space_id index to the existing recipe categories store.
        const recipeCategoriesStore = transaction.objectStore('recipe_categories');
        recipeCategoriesStore.createIndex('space_id', 'space_id');
    }

    private handleVersion11Upgrade(transaction: IDBTransaction): void {
        const messageAttachmentsRepo = this.db.createObjectStore(MessageAttachmentRepo.indexDbStoreName,
            { keyPath: 'id' });

        messageAttachmentsRepo.createIndex('message_id', 'message_id');
    }

    private handleVersion12Upgrade(transaction: IDBTransaction): void {
        const kanbanTasksRepo = this.db.createObjectStore(KanbanTaskRepo.indexDbStoreName,
            { keyPath: 'id' });

        kanbanTasksRepo.createIndex('space_id', 'space_id');

        const kanbanColumnsRepo = this.db.createObjectStore(KanbanColumnsRepo.indexDbStoreName,
            { keyPath: 'id' });

        kanbanColumnsRepo.createIndex('space_id', 'space_id');
    }

    private handleVersion13Upgrade(transaction: IDBTransaction): void {
        const credentialsRepo = this.db.createObjectStore(CredentialRepo.indexDbStoreName,
            { keyPath: 'id' });

        credentialsRepo.createIndex('space_id', 'space_id');
    }

    private handleVersion14Upgrade(transaction: IDBTransaction): void {
        const moduleGroupRepo = this.db.createObjectStore(ModuleGroupRepo.indexDbStoreName,
            { keyPath: 'id' });

        const moduleSpaceRepo = this.db.createObjectStore(ModuleSpaceRepo.indexDbStoreName,
            { keyPath: 'id' });

        moduleSpaceRepo.createIndex('space_id', 'space_id');
    }

    private handleVersion15Upgrade(transaction: IDBTransaction): void {
        // Add an index to the existing kanban tasks store.
        const kanbanTasksStore = transaction.objectStore(KanbanTaskRepo.indexDbStoreName);
        kanbanTasksStore.createIndex('column_no', 'column_no');
    }

    private handleVersion16Upgrade(transaction: IDBTransaction): void {
        const completionRepo = this.db.createObjectStore(CompletionRepo.indexDbStoreName,
            { keyPath: 'id' });

        completionRepo.createIndex('space_id', 'space_id');
    }

    private ensureIndexDbIsSupportedByBrowser(): void {
        if (!window.indexedDB) {
            throw new Error('IndexDb is not supported by browser');
        }
    }

    private createRepos(): void {
        this.completionRepo = new CompletionRepo(this.db, this.store, this.requestFactory);
        this.contactRepo = new ContactRepo(this.db);
        this.contactMessageSummaryRepo = new ContactMessageSummaryRepo(this.db);
        this.credentialRepo = new CredentialRepo(
            this.db,
            this.store,
            this.store.state.credentialStore,
            this.requestFactory,
        );
        this.fileRepo = new FileRepo(this.db);
        this.galleryRepo = new GalleryRepo(this.db);
        this.galleryFileRepo = new GalleryFileRepo(this.db);
        this.kanbanColumnsRepo = new KanbanColumnsRepo(this.db);
        this.kanbanTaskRepo = new KanbanTaskRepo(this.db);
        this.messageRepo = new MessageRepo(this.db);
        this.messageAttachmentRepo = new MessageAttachmentRepo(this.db);
        this.moduleGroupRepo = new ModuleGroupRepo(this.db, this.requestFactory);
        this.moduleSpaceRepo = new ModuleSpaceRepo(this.db, this.requestFactory);
        this.noteRepo = new NoteRepo(this.db);
        this.noteCategoryRepo = new NoteCategoryRepo(this.db);
        this.noteFileRepo = new NoteFileRepo(this.db);
        this.recipeCategoryRepo = new RecipeCategoryRepo(this.db);
        this.recipeRepo = new RecipeRepo(this.db);
        this.spaceRepo = new SpaceRepo(this.db);

        this.userRepo = new UserRepo(
            this.db,
            this.requestFactory,
            this.store,
            this.store.state.userModule,
        );

        this.broadcastReposReady();
    }

    private broadcastReposReady(): void {
        const readyEvent = new Event('reposReady');
        document.dispatchEvent(readyEvent);
    }

    private getConnection(): void {
        const request: IDBOpenDBRequest = window.indexedDB.open(this.getDbName(), this.getDbVersion());

        request.onsuccess = (event: any) => {
            this.db = request.result;
            this.createRepos();
        };

        request.onerror = (event: any) => {
            throw new Error('IndexDb could not be opened');
        };

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            this.db = request.result;
            this.upgradeSchema(event.oldVersion, request.transaction);
        };
    }

    private getDbName(): string {
        return 'SleepingMind';
    }
}
