
// Components
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import Container from '@/domains/app/views/Container.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import Form from '@/domains/ui/views/Form.vue';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import Tabs from '@/domains/ui/views/Tabs/Tabs.vue';
import { TabItem } from '@/domains/ui/views/Tabs/TabItem';

// Typescript
import ApiClient from '@/ts/ApiClient';
import ContactItem from '@/domains/contacts/database/ContactItem';
import { getContactFullName } from '@/domains/contacts/database/ContactItem';
import FileItem from '@/ts/Database/Files/FileItem';
import MarkdownIt from 'markdown-it';
import { MimeType } from '@/ts/ValueObjects/MimeType';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Routes } from '@/domains/app/router/router';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '../../../ts/Requests/File/UploadFileRequest';
import { SidebarWidth } from '../../../ts/Enums/SidebarWidth';


@Component({
  components: {
      BasicButton,
      Container,
      ErrorMessage,
      Form,
      IncludeIf,
      InputChecker,
      SidebarSection,
      Spinner,
      SpinnerButton,
      Tabs,
  },
})
export default class ContactSendMessage extends Vue {
    public contactId: string = '';
    public contactItem: ContactItem|null = null;
    public deletingFile = false;
    public errorMessage: string = '';
    public fileUploading = false;
    public loaded: boolean = false;
    public message: string = '';
    public messageOK: Trilean = Trilean.Unknown;
    public messageSubjectOK: Trilean = Trilean.Unknown;
    public primaryPhotoFile: FileItem|null = null;
    public selectedTabId = 'message';
    public sendMessageInProgress: boolean = false;
    public showPreviousMessage: boolean = false;
    public stageNo: number = 1;
    public subject: string = '';
    public uploadedFiles: FileItem[] = [];
    private subjectAlreadySet = false;

    // Private attributes
    private markdown: MarkdownIt = require('markdown-it')({
        breaks: true,
    });

    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;

    public mounted(): void {
        this.contactId = this.$route.params.id;
        if (!this.contactId) {
            throw new Error('ContactSendMessage::mounted - No ContactId provided in URL!');
        }

        if (this.$repoManager.contact) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadContact();
        this.loaded = true;
    }

    private async loadContact(): Promise<void> {
        try {
            this.errorMessage = '';
            this.contactItem = await this.$repoManager.contact.getItem(this.contactId);

            if (this.contactItem.photo_primary_file_id.length > 0) {
                this.primaryPhotoFile = await this.$repoManager.file.getItem(this.contactItem.photo_primary_file_id);
            }
        } catch (error) {
            this.errorMessage = 'Error: Contact could not be loaded.';
        }
    }

    public checkMessage(): void {
        this.messageOK = (this.message.length > 1) ? Trilean.True : Trilean.False;
    }

    public checkSubject(): void {
        this.messageSubjectOK = (this.subject.length > 1) ? Trilean.True : Trilean.False;
    }

    public get contactName(): string {
        if (this.contactItem) {
            return getContactFullName(this.contactItem);
        }

        return '';
    }

    private formValid(): boolean {
        if (this.messageSubjectOK !== Trilean.True) {
            this.errorMessage = 'You must enter a subject of at least 2 characters.';
            return false;
        }

        if (this.messageOK !== Trilean.True) {
            this.errorMessage = 'You must enter a message of at least 2 characters.';
            return false;
        }

        this.errorMessage = '';

        return true;
    }

    public getTabItems(): TabItem[] {
        const items: TabItem[] = [
            { id: 'message', name: 'Message'},
            { id: 'attachments', name: 'Attachments'},
        ];

        return items;
    }

    public handleBackCancelButton(): void {
        if (this.stageNo === 2) {
            this.stageNo = 1;
        } else {
            this.goBackToContact();
        }
    }

    public goBackToContact(): void {
        this.$router.push({name: Routes.CONTACT, params: { id: this.contactId }});
    }

    private setSubject(markdown: string): void {
        // If the subject has already been set - don't do it again.
        // I.e. the user may have manually deleted the preset heading.
        if (this.subjectAlreadySet) {
            return;
        }

        // If the subject is already set - do nothing
        if (this.subject.length > 0) {
            return;
        }

        // If the markdown has no content - there's nothing to do.
        if (markdown.length === 0) {
            return;
        }

        // Extract the first line
        const lines = markdown.split('\n');
        const firstLine = lines[0];

        // Is the first line a heading?  If not, do nothing
        if (firstLine.indexOf('#') !== 0) {
            return;
        }

        // Compose the subject which should be "Re: <heading>"
        let heading = firstLine.substr(2).trim();

        if (heading.toUpperCase().indexOf('RE:') === -1) {
            heading = 'Re: ' + heading;
        }

        this.subject = heading;
        this.subjectAlreadySet = true;

        this.checkSubject();
    }

    public async handleNextStage(): Promise<void> {
        // TODO - handle delete item
        if (this.stageNo === 1) {
            if (this.formValid()) {
                this.stageNo++;
            } else {
                this.selectedTabId = 'message';
            }
            return;
        } else {
            this.sendMessage();
        }
    }

    public async handleRemoveAttachmentIntent(file: FileItem): Promise<void> {
        // Remove the file from the uploaded file list.
        const newFileList: FileItem[] = [];
        for (const thisFileItem of this.uploadedFiles) {
            if (thisFileItem.id !== file.id) {
                newFileList.push(thisFileItem);
            }
        }

        this.uploadedFiles = newFileList;

        try {
            // Delete the file from the server
            await this.$requestFactory.deleteFileRequest.execute(file.id);
        } catch (error) {
            // This shouldn't happen but if it does,
            // report the error and add the file back to the uploaded attachments
            this.errorMessage = error.toString();
            this.uploadedFiles.push(file);
        }
    }

    public handleTabSelected(tabId: string) {
        this.selectedTabId = tabId;
    }

    public isImage(mimeTypeString: string) {
        return (new MimeType(mimeTypeString)).isImage;
    }

    get previewMessageHtml(): string {
        return this.markdown.render(this.message);
    }

    public get previousMessageHtml(): string {
        const previousMessageItem = this.$store.state.contactModule.mostRecentMessage;
        if (previousMessageItem === null) {
            return '';
        }

        this.setSubject(previousMessageItem.message_markdown);

        return this.markdown.render(previousMessageItem.message_markdown);
    }

    get sidebarWidth(): SidebarWidth {
        return SidebarWidth.WIDER;
    }

    get stageButtonText(): string {
        if (this.stageNo === 1) {
            return 'Proceed >>';
        } else {
            return 'Send It';
        }
    }

    private async sendMessage(): Promise<void> {
        this.sendMessageInProgress = true;

        // Render the markdown to HTML before sending it.
        const html = this.previewMessageHtml;

        try {
            // Send the message
            const message = await this.$requestFactory.sendMessageRequest.execute(
                this.contactId,
                this.subject,
                html,
                this.uploadedFiles,
            );

            // Store the message in the local repo
            await this.$repoManager.message.save(message);

            this.goBackToContact();
        } catch (error) {
            this.errorMessage = 'An error occured whilst trying to send your message.  ' +
                'Please try again later!';
        } finally {
            this.sendMessageInProgress = false;
        }
    }

    private async uploadAttachment(): Promise<void> {
        const uploadFiles: any = this.$refs.attachment;
        const numFiles = uploadFiles.files.length;

        for (let fileIndex = 0; fileIndex < numFiles; fileIndex++) {
            try {
                const formData = new FormData();
                formData.append('file', uploadFiles.files[fileIndex]);

                this.fileUploading = true;

                const params: UploadFileParams = {
                    formData,
                };

                const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'message');
                this.uploadedFiles.push(fileItem);

                this.fileUploading = false;
            } catch (error) {
                this.errorMessage = 'Error uploading file: ' + error.toString();
            } finally {
                this.fileUploading = false;
            }
        }
    }
}
