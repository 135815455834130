
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class StringListEditor extends Vue {
    @Prop({required: false})
    private value!: string[];

    @Prop({required: true})
    private singularName!: string;

    @Prop({required: false})
    private heading!: string;

    private items: string[] = [];
    private newItemName: string = '';

    private mounted() {
        this.init();
    }

    @Watch('value')
    private init() {
        this.items = this.value;
        this.newItemName = '';
    }

    private handleNewItemKeyPress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.addNewItem();
            return;
        }
    }

    private addNewItem() {
        if (this.newItemName === '') {
            return;
        }

        this.items.push(this.newItemName);
        this.newItemName = '';
        this.emitCurrentItems();
    }

    private deleteItem(index: number) {
        this.items.splice(index, 1);
        this.emitCurrentItems();
    }

    private emitCurrentItems(): void {
        this.$emit('input', this.items);
    }
}
