import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export interface ConfirmPasswordResetParams {
    email_address: string;
    pin: number;
}

export default class ConfirmPasswordResetRequest extends AbstractRequest {
    public async execute(params: ConfirmPasswordResetParams): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/user/confirm_password_reset`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('ConfirmPasswordResetRequest - Invalid response from API'));
                    return;
                }

                const requestOk: boolean = response.data.status;
                resolve(requestOk);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your pin number could not be confirmed.  Please try again later'));
            }
        });
    }
}
