
// COMPONENTS
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import KanbanAddEditTaskModal from '@/domains/kanban/views/KanbanAddEditTaskModal.vue';
import KanbanColumn from '@/domains/kanban/views/KanbanColumn.vue';
import KanbanColumnsItem from '@/domains/kanban/database/kanbanColumns/KanbanColumnsItem';
import KanbanTaskItem from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';
import { KanbanViewType } from '@/domains/kanban/enums/KanbanViewType';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { SaveKanbanTaskEvent } from '@/domains/kanban/interfaces/KanbanEventInterfaces';
import { KanbanTaskColumnChangedEvent } from '@/domains/kanban/interfaces/KanbanEventInterfaces';
import StringHelper from '@/ts/Helpers/StringHelper';
import Tabs from '@/domains/ui/views/Tabs/Tabs.vue';
import { TabItem } from '@/domains/ui/views/Tabs/TabItem';
import Trilean from '@/ts/Trilean';
import UserItem from '@/domains/users/database/users/UserItem';

@Component({
  components: {
      ActionHeading,
      Container,
      DeleteConfirmationModal,
      ErrorMessage,
      Form,
      KanbanAddEditTaskModal,
      KanbanColumn,
      Notification,
      SidebarSection,
      SpinnerButton,
      Tabs,
  },
})
export default class Kanban extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;
    private $helperFactory!: HelperFactory;

    public loaded: boolean = false;
    public kanbanColumns: KanbanColumnsItem|null = null;
    public users: UserItem[] = [];
    public errorMessage: string = '';

    public kanbanTaskToEdit: KanbanTaskItem|null = null;
    public kanbanTaskEditModalOpen = false;
    public kanbanTaskSaving = false;

    public column1Tasks: KanbanTaskItem[] = [];
    public column2Tasks: KanbanTaskItem[] = [];
    public column3Tasks: KanbanTaskItem[] = [];
    public column4Tasks: KanbanTaskItem[] = [];
    public column5Tasks: KanbanTaskItem[] = [];
    public column6Tasks: KanbanTaskItem[] = [];
    public column7Tasks: KanbanTaskItem[] = [];
    public column8Tasks: KanbanTaskItem[] = [];
    public kanbanTaskCount: number = 0;

    // Delete modal
    public deleteModalOpen = false;
    public deleteInProgress = false;
    public kanbanTaskToDeleteId = '';
    public kanbanTaskToDeleteName = '';

    private loading = false;

    public mounted(): void {
        if (this.$repoManager.kanbanTask) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    /**
     * Fires when all repos have been initialised
     */
    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    @Watch('lastUpdated')
    private async loadDataFromRepos(): Promise<void> {
        if (this.loading) {
            return;
        }

        this.loading = true;
        await this.loadKanbanColumns();
        await this.loadKanbanTasks();
        this.loaded = true;
        this.loading = false;
    }

    public get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    public get searchKeywords(): string {
        return this.$store.state.kanbanModule.searchKeywords;
    }

    public set searchKeywords(newValue: string) {
        this.$store.commit('setKabanSearchKeywords', newValue);
        this.loadKanbanTasks();
    }

    private get lastUpdated(): string {
        return this.$store.state.kanbanModule.lastUpdated;
    }

    private async loadKanbanColumns(): Promise<void> {
        try {
            this.errorMessage = '';

            const kanbanColumns = await this.$repoManager.kanbanColumns.getList(this.spaceId);
            if (kanbanColumns.length > 0) {
                this.kanbanColumns = kanbanColumns[0];
            } else {
                const now = new Date();

                const kanbanColumnsItem: KanbanColumnsItem = {
                    id: '',
                    space_id: this.selectedSpace,
                    column1: 'Backlog',
                    column2: 'This Year',
                    column3: 'This Month',
                    column4: 'This Week',
                    column5: 'Today',
                    column6: 'In Progress',
                    column7: 'Waiting',
                    column8: 'Done',
                    created_dtm: now.getTime(),
                    updated_dtm: now.getTime(),
                };

                this.kanbanColumns = kanbanColumnsItem;
            }
        } catch (error) {
            this.errorMessage = 'Error: Kanban Tasks could not be loaded';
        }
    }

    private async loadKanbanTasks(): Promise<void> {
        try {
            this.errorMessage = '';
            const columnNo = 0;

            this.column1Tasks = [];
            this.column2Tasks = [];
            this.column3Tasks = [];
            this.column4Tasks = [];
            this.column5Tasks = [];
            this.column6Tasks = [];
            this.column7Tasks = [];
            this.column8Tasks = [];

            const kanbanTasks = await this.$repoManager.kanbanTask.getList(
                this.spaceId,
                columnNo,
                this.searchKeywords,
            );

            if (kanbanTasks.length === 0) {
                return;
            }

            for (const kanbanTask of kanbanTasks) {
                switch (kanbanTask.column_no) {
                    case 1:
                        this.column1Tasks.push(kanbanTask);
                        break;

                    case 2:
                        this.column2Tasks.push(kanbanTask);
                        break;

                    case 3:
                        this.column3Tasks.push(kanbanTask);
                        break;

                    case 4:
                        this.column4Tasks.push(kanbanTask);
                        break;

                    case 5:
                        this.column5Tasks.push(kanbanTask);
                        break;

                    case 6:
                        this.column6Tasks.push(kanbanTask);
                        break;

                    case 7:
                        this.column7Tasks.push(kanbanTask);
                        break;

                    default:
                        this.column8Tasks.push(kanbanTask);
                        break;
                }
            }

            this.kanbanTaskCount = kanbanTasks.length;
        } catch (error) {
            this.errorMessage = 'Error: Kanban Tasks could not be loaded';
            this.kanbanTaskCount = 0;
        }
    }

    public showAddKanbanTask(): void {
        this.kanbanTaskToEdit =
            this.$repoManager.kanbanTask.getEmptyItem(this.spaceId, this.userId);

        this.kanbanTaskEditModalOpen = true;
    }

    public async handleSaveKanbanTaskIntent(event: SaveKanbanTaskEvent): Promise<void> {
        this.kanbanTaskSaving = true;

        try {
            if (event.id === '') {
                const kanbanTask =
                    await this.$requestFactory.kanbanTasks.createKanbanTaskRequest.execute({
                        space_id: this.spaceId,
                        title: event.title,
                        description: event.description,
                        column_no: event.columnNo,
                        sequence_no: event.sequenceNo,
                        shared: event.shared,
                    });

                await this.$repoManager.kanbanTask.save(kanbanTask);

                this.addTaskToColumn(kanbanTask, kanbanTask.column_no);
            } else {
                if (this.kanbanTaskToEdit === null) {
                    throw new Error('Existing Kanban task removed!');
                }

                const kanbanTask =
                    await this.$requestFactory.kanbanTasks.updateKanbanTaskRequest.execute(event.id, {
                        space_id: this.spaceId,
                        title: event.title,
                        description: event.description,
                        column_no: event.columnNo,
                        sequence_no: event.sequenceNo,
                        shared: event.shared,
                    });

                await this.$repoManager.kanbanTask.save(kanbanTask);

                this.removeTaskFromColumn(this.kanbanTaskToEdit, this.kanbanTaskToEdit.column_no);
                this.addTaskToColumn(kanbanTask, kanbanTask.column_no);
            }

            this.loadKanbanTasks();
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.kanbanTaskSaving = false;
            this.kanbanTaskEditModalOpen = false;
        }
    }

    public getTabItems(): TabItem[] {
        const items: TabItem[] = [
            { id: KanbanViewType.PLANNING, name: KanbanViewType.PLANNING},
            { id: KanbanViewType.ACTION, name: KanbanViewType.ACTION},
        ];

        return items;
    }

    public handleTabSelected(tabId: KanbanViewType) {
        this.$store.commit('setSelectedKabanViewType', tabId);
    }

    public handleCloseKanbanTaskEditModal(): void {
        this.kanbanTaskEditModalOpen = false;
        this.kanbanTaskToEdit =
            this.$repoManager.kanbanTask.getEmptyItem(this.spaceId, this.userId);
    }

    public async handleKanbanColumnChange(columnChangedEvent: KanbanTaskColumnChangedEvent): Promise<void> {
        const kanbanTask = this.findKanbanTask(columnChangedEvent.id, columnChangedEvent.oldColumnNo);
        if (kanbanTask === null) {
            throw new Error(`Could not find kanban task with id ` +
                `${columnChangedEvent.id} in column ${columnChangedEvent.oldColumnNo}`);
        }

        this.removeTaskFromColumn(kanbanTask, columnChangedEvent.oldColumnNo);

        kanbanTask.column_no = columnChangedEvent.newColumnNo;
        this.addTaskToColumn(kanbanTask, columnChangedEvent.newColumnNo);

        const savedKanbanTask = await this.$requestFactory.kanbanTasks.updateKanbanTaskRequest.execute(
            kanbanTask.id, {
                space_id: this.spaceId,
                title: kanbanTask.title,
                description: kanbanTask.description,
                column_no: kanbanTask.column_no,
                sequence_no: kanbanTask.sequence_no,
                shared: true,
            },
        );

        await this.$repoManager.kanbanTask.save(savedKanbanTask);
    }

    public handleEditTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.kanbanTaskToEdit = kanbanTask;
        this.kanbanTaskEditModalOpen = true;
    }

    public handleDeleteTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.kanbanTaskToDeleteId = kanbanTask.id;
        this.kanbanTaskToDeleteName = kanbanTask.title;
        this.deleteModalOpen = true;
    }

    public async handleMoveTaskIntent(kanbanTask: KanbanTaskItem): Promise<void> {
        this.removeTaskFromColumn(kanbanTask, kanbanTask.column_no);

        kanbanTask.column_no = kanbanTask.column_no + 1;

        this.addTaskToColumn(kanbanTask, kanbanTask.column_no);

        await this.$repoManager.kanbanTask.save(kanbanTask);
    }

    public async handleDeleteKanbanTask(kanbanTaskToDeleteId: string): Promise<void> {
        this.deleteInProgress = true;

        try {
            // Delete the task from the server and then locally
            const success =
                await this.$requestFactory.kanbanTasks.deleteKanbanTaskRequest.execute(kanbanTaskToDeleteId);

            const kanbanTask = await this.$repoManager.kanbanTask.getItem(kanbanTaskToDeleteId);

            await this.$repoManager.kanbanTask.delete(kanbanTask);

            // Remove the item from the board.
            this.removeTaskFromColumn(kanbanTask, kanbanTask.column_no);
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.deleteInProgress = false;
            this.deleteModalOpen = false;
        }
    }

    private findKanbanTask(taskId: string, columnNo: number): KanbanTaskItem|null {
        const taskList = this.getKanbanTasksInColumn(columnNo);

        for (const kanbanTask of taskList) {
            if (kanbanTask.id === taskId) {
                return kanbanTask;
            }
        }

        return null;
    }

    private getKanbanTasksInColumn(columnNo: number): KanbanTaskItem[] {
        if ((columnNo < 1) || (columnNo > 8)) {
            throw new Error('Invalid Kanban Task Column Number: ' + columnNo);
        }

        switch (columnNo) {
            case 1:
                return this.column1Tasks;
            case 2:
                return this.column2Tasks;
            case 3:
                return this.column3Tasks;
            case 4:
                return this.column4Tasks;
            case 5:
                return this.column5Tasks;
            case 6:
                return this.column6Tasks;
            case 7:
                return this.column7Tasks;
            default:
                return this.column8Tasks;
        }
    }

    private addTaskToColumn(kanbanTask: KanbanTaskItem, columnNo: number): void {
        const columnTasks = this.getKanbanTasksInColumn(columnNo);
        columnTasks.push(kanbanTask);
        this.sortKanbanTasks(columnTasks);
    }

    private removeTaskFromColumn(kanbanTask: KanbanTaskItem, columnNo: number): void {
        let columnTasks = this.getKanbanTasksInColumn(columnNo);

        columnTasks = columnTasks.filter((currentTask: KanbanTaskItem) => {
            return currentTask.id !== kanbanTask.id;
        });

        switch (columnNo) {
            case 1:
                this.column1Tasks = columnTasks;
                break;
            case 2:
                this.column2Tasks = columnTasks;
                break;
            case 3:
                this.column3Tasks = columnTasks;
                break;
            case 4:
                this.column4Tasks = columnTasks;
                break;
            case 5:
                this.column5Tasks = columnTasks;
                break;
            case 6:
                this.column6Tasks = columnTasks;
                break;
            case 7:
                this.column7Tasks = columnTasks;
                break;
            default:
                this.column8Tasks = columnTasks;
                break;
        }
    }

    private sortKanbanTasks(kanbanTasks: KanbanTaskItem[]): void {
        if (kanbanTasks.length === 0) {
            return;
        }

        kanbanTasks.sort((taskItemA: KanbanTaskItem, taskItemB: KanbanTaskItem) => {
            return taskItemA.sequence_no - taskItemB.sequence_no;
        });
    }

    // If a sync has occurred - refresh the UI.
    public get lastSyncTimestamp(): number {
        return this.$store.state.lastSyncTimestamp;
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.kanbanTask) {
            this.loadDataFromRepos();
        }
    }

    public get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    // If the user has selected a different space, refresh the galleries.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.kanbanTask) {
            this.loadDataFromRepos();
        }
    }

    public get selectedTabId(): KanbanViewType {
        return this.$store.state.kanbanModule.selectedKanbanViewType;
    }

    public get userId(): string {
        return this.$store.state.userModule.userId;
    }

    public get kanbanTaskId(): string {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.id;
        }

        return '';
    }

    public get kanbanTaskTitle(): string {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.title;
        }

        return '';
    }

    public get kanbanTaskDescription(): string {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.description;
        }

        return '';
    }

    public get kanbanTaskColumnNo(): number {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.column_no;
        }

        const defaultKanbanColumnNo =
            (this.selectedTabId === KanbanViewType.PLANNING) ? 1 : 5;

        return defaultKanbanColumnNo;
    }

    public get kanbanTaskSequenceNo(): number {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.sequence_no;
        }

        return 2;
    }

    public get kanbanTaskShared(): boolean {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.shared;
        }

        return false;
    }

    public get kanbanTaskCreatedByUserId(): string {
        if ((this.kanbanTaskToEdit !== null) && (this.editingExitingKanbanTask)) {
            return this.kanbanTaskToEdit.created_by_user_id;
        }

        return '';
    }

    public get editingExitingKanbanTask(): boolean {
        return ((this.kanbanTaskToEdit !== null) && (this.kanbanTaskToEdit.id.length > 0));
    }
}
