import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class DeleteNoteFileRequest extends AbstractRequest {
    public async execute(noteId: string, noteFileId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/note/${noteId}/files/${noteFileId}`, {});

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DeleteNoteFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a note file failed.  Please try again later'));
            }
        });
    }
}
