import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import FileItem from '@/ts/Database/Files/FileItem';
import { MessageAttachmentItem } from '@/domains/messages/database/messageAttachments/MessageAttachmentItem';

export interface MessageAttachmentListResponse {
    message_attachments: MessageAttachmentItem[];
    files: FileItem[];
}

export class MessageAttachmentListRequest extends AbstractRequest {
    public async execute(messageId: string): Promise<MessageAttachmentListResponse> {
        return new Promise<MessageAttachmentListResponse>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(
                    `/private/message_attachments?message_id=${messageId}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('message_attachments')))  {
                    reject(Error('MessageAttachmentListRequest - Invalid response from API'));
                    return;
                }

                const listResponse = {
                    message_attachments: response.data.message_attachments,
                    files: [],
                };

                if (response.data.hasOwnProperty('files')) {
                    listResponse.files = response.data.files;
                }

                resolve(listResponse);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load message attachments from the API'));
            }
        });
    }
}
