import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class EmailCheckRequest extends AbstractRequest {
    public async execute(emailAddress: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/user/email_available/${emailAddress}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DisplayNameCheckRequest - Invalid response from API'));
                    return;
                }

                const requestOk: boolean = response.data.status;
                resolve(requestOk);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your email address seems to be invalid. Please try another.'));
            }
        });
    }
}
