
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';

import {Routes} from '@/domains/app/router/router';
import StringHash from '@/ts/StringHash';
import ApiClient from '@/ts/ApiClient';
import UserItem from '@/domains/users/database/users/UserItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { UserRoleType } from '@/ts/Enums/UserRoleType';
import UserRoleTypeInterface from '@/ts/Interfaces/UserRoleTypeInterface';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
  },
})
export default class Users extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private loaded: boolean = false;
    private waitingForAddNoteCategoryRequest: boolean = false;
    private users: UserItem[] = [];
    private errorMessage: string = '';
    private searchReference: any = null;

    public mounted(): void {
        this.init();
    }

    @Watch('lastUpdated')
    public init(): void {
        if (this.$repoManager.user) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    public get lastUpdated(): number {
        return this.$store.state.adminUserModule.lastUpdated;
    }

    public get searchKeywords(): string {
        return this.$store.state.adminUserModule.searchKeywords;
    }

    public set searchKeywords(value: string) {
        this.$store.commit('setAdminUserSearchKeywords', value);
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadUsers();
        this.loaded = true;
    }

    @Watch('searchKeywords')
    @Watch('role')
    private loadUsers(): void {
        if (this.searchReference) {
            clearTimeout(this.searchReference);
        }

        this.searchReference = setTimeout(async () => {
            try {
                this.errorMessage = '';
                this.users = await this.$repoManager.user.getList(this.searchKeywords, this.role);
            } catch (error) {
                this.errorMessage = 'Error: Users could not be loaded.';
            }
        }, 100);
    }

    private showUser(userId: string): void {
        this.$router.push({name: Routes.USER, params: {id: userId}});
    }

    private timestampToDate(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    private addUser(): void {
        this.$router.push({name: Routes.USER_ADD});
    }

    private navigateToAdminScreen(): void {
        this.$router.push({name: Routes.ADMIN});
    }

    private search(keywords: string): void {
        this.searchKeywords = keywords;
        this.loadUsers();
    }

    get roles(): UserRoleTypeInterface[] {
        return [
            { type: UserRoleType.ADMINISTRATOR, name: 'Administrator' },
            { type: UserRoleType.GENERAL_USER, name: 'General User' },
        ];
    }

    private clearSelectedRole(): void {
        this.role = null;
        this.loadUsers();
    }

    // Getters and settings
    get role(): UserRoleType|null {
        return this.$store.state.adminUserModule.searchRoleType;
    }

    set role(value: UserRoleType|null) {
        this.$store.commit('setAdminUserModuleRoleType', value);
    }
}
