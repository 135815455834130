export default class UUID {
    private id: string;

    constructor(id: string = '') {
        if (id.length === 36) {
            this.id = id;
        } else {
            this.id = this.uuidv4();
        }
    }

    get value(): string {
        return this.id;
    }

    private uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
