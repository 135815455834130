
    import {Component, Vue} from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import RepoManager from '@/ts/Database/RepoManager';
    import {ModuleType} from '@/ts/Enums/ModuleType';
    import {CredentialRepo} from '@/domains/credentials/database/CredentialRepo';

    @Component({
        components: {
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class VaultAccessTicker extends Vue {
        private $repoManager!: RepoManager;

        public expired = false;
        public timeUntilExpiryDisplay = '';
        private sentInitialStatus = false;

        public mounted(): void {
            if (this.$repoManager.credential) {
                this.checkVaultStatus();
            } else {
                this.onReposReady();
            }
        }

        /**
         * Fires when all repos have been initialised
         */
        public async onReposReady(): Promise<void> {
            document.addEventListener('reposReady', async () => {
                this.checkVaultStatus();
            }, false);
        }

        private checkVaultStatus(): void {
            // Only returns the vault access if it is present AND if it has not expired.
            const vaultAccessDetails = this.$repoManager.credential.getVaultAccessDetails();

            if (!vaultAccessDetails) {
                // If vault access was previously not expired but it is now,
                // throw an event to say it's expired.
                if ((!this.expired) || (!this.sentInitialStatus)) {
                    this.expired = true;
                    this.sentInitialStatus = true;

                    this.$emit('vault-access-expired', this.expired);
                }

                this.timeUntilExpiryDisplay = '';
            } else {
                const currentTimestamp = (new Date()).getTime() / 1000;
                const expiryInSecs = vaultAccessDetails.timeToLive - currentTimestamp - 10;

                const numMinutes = Math.floor(expiryInSecs / 60);
                const remainingSeconds = expiryInSecs - (numMinutes * 60);

                let timeString = '';

                if (numMinutes < 10) {
                    timeString += '0';
                }

                timeString += numMinutes.toString(10);
                timeString += ':';

                if (remainingSeconds < 10) {
                    timeString += '0';
                }

                timeString += Math.floor(remainingSeconds);

                this.timeUntilExpiryDisplay = timeString + ' secs';

                // If vault access was previously expired but is not now
                // throw an event to say it's no longer expired.
                if ((this.expired) || (!this.sentInitialStatus)) {
                    this.expired = false;
                    this.sentInitialStatus = true;

                    this.$emit('vault-access-expired', this.expired);
                }
            }

            setTimeout(() => {
                // Only continue the ticker if the user is still in the credentials module.
                if (this.$store.state.settingsModule.moduleType === ModuleType.CREDENTIALS) {
                    this.checkVaultStatus();
                }
            }, 1000);
        }
    }
