import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';

export default class RecipeCategoryListRequest extends AbstractRequest {
    public async execute(): Promise<RecipeCategoryItem[]> {
        return new Promise<RecipeCategoryItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/recipe_category`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('recipeCategories')))  {
                    reject(Error('RecipeCategoryListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.recipeCategories);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load recipe categories from the API'));
            }
        });
    }
}
