
import {Component, Prop, Vue} from 'vue-property-decorator';
import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
import { KanbanTaskColumnChangedEvent } from '@/domains/kanban/interfaces/KanbanEventInterfaces';
import KanbanTaskCard from '@/domains/kanban/views/KanbanTaskCard.vue';
import KanbanTaskItem from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

@Component({components: {
    Notification,
    IconButton,
    KanbanTaskCard,
    SpinnerButton,
  },
})
export default class KanbanColumn extends Vue {
    @Prop({required: true, type: Number}) private columnNo!: number;
    @Prop({required: true, type: String}) private columnName!: string;
    @Prop({required: true}) private kanbanTasks!: KanbanTaskItem[];

    public handleDrop(event: any): void {
        event.preventDefault();
        const droppedTaskId = event.dataTransfer.getData('id');
        const oldColumnNo = parseInt(event.dataTransfer.getData('columnNo'), 10);

        if (oldColumnNo === this.columnNo) {
            return;
        }

        const columnChangedEvent: KanbanTaskColumnChangedEvent = {
            id: droppedTaskId,
            oldColumnNo,
            newColumnNo: this.columnNo,
        };

        this.$emit('column-changed', columnChangedEvent);
    }

    public handleAllowDrop(event: any): void {
        // Only allow drop if the old column number is the same as this column number
        // Note, the default action of the event is to prevent drop, so by preventing
        // the default, we ALLOW the drop.
        const oldColumnNo = event.dataTransfer.getData('columnNo');
        if (oldColumnNo !== this.columnNo) {
            event.preventDefault();
        }
    }

    public handleViewTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.$emit('view-task', kanbanTask);
    }

    public handleEditTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.$emit('edit-task', kanbanTask);
    }

    public handleDeleteTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.$emit('delete-task', kanbanTask);
    }

    public handleMoveTaskIntent(kanbanTask: KanbanTaskItem): void {
        this.$emit('move-task', kanbanTask);
    }
}
