
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Spinner from '@/domains/ui/views/Spinner.vue';
    import { DisplayType } from '@/ts/Enums/DisplayType';
    import { DisplayTypeHelper } from '@/ts/Enums/DisplayType';

    @Component({
        components: {
            Spinner,
        },
    })
    export default class BasicButton extends Vue {
        @Prop({required: true})
        private buttonText!: string;

        @Prop({required: false})
        private displayType!: DisplayType;

        @Prop({required: false, type: Boolean, default: false})
        private disabled!: boolean;

        private buttonClicked(): void {
            if (this.disabled) {
                return;
            }

            this.$emit('clicked', {});
        }

        get displayTypeClass(): string {
            const helper = new DisplayTypeHelper(this.displayType);
            return helper.className;
        }

        get disabledClass(): string {
            return this.disabled ? 'disabled' : '';
        }
    }
