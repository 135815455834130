import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import FileItem from '@/ts/Database/Files/FileItem';

export class GetFileRequest extends AbstractRequest {
    public async execute(fileId: string): Promise<FileItem> {
        return new Promise<FileItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/file/${fileId}`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('file')))  {
                    reject(Error('GetFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.file);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load file from the API'));
            }
        });
    }
}
