
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import Container from '@/domains/app/views/Container.vue';
    import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
    import {Routes} from '@/domains/app/router/router';
    import RepoManager from '@/ts/Database/RepoManager';
    import {CredentialItem} from '@/domains/credentials/database/CredentialItem';
    import VaultAccessTicker from '@/domains/vault/views/VaultAccessTicker.vue';
    import GetVaultAccess from '@/domains/vault/views/GetVaultAccess.vue';
    import {VaultAccessDetails} from '@/domains/vault/interfaces/VaultAccessDetails';
    import Form from '@/domains/ui/views/Form.vue';
    import { CredentialViewItem } from '@/domains/credentials/interfaces/CredentialViewItem';
    import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';
    import SuccessMessage from '@/domains/ui/views/Messages/SuccessMessage.vue';

    @Component({
        components: {
            SuccessMessage,
            DeleteConfirmationModal,
            Form,
            GetVaultAccess,
            VaultAccessTicker,
            ActionHeading,
            Container,
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class CredentialView extends Vue {
        public loaded = false;
        private $repoManager!: RepoManager;
        private credentialItem!: CredentialItem;
        private credentialId!: string;
        public errorMessage = '';
        public noVaultAccess = false;
        public credentialViewItem!: CredentialViewItem|null;
        public credentialViewLoaded = false;
        private vaultAccessDetails: VaultAccessDetails|null = null;
        public deleting = false;
        public deleteModalOpen = false;
        public showCopiedToClipboard = false;

        public mounted(): void {
            if (this.$repoManager.credential) {
                this.loadDataFromRepos();
            } else {
                this.onReposReady();
            }
        }

        /**
         * Fires when all repos have been initialised
         */
        public async onReposReady(): Promise<void> {
            document.addEventListener('reposReady', async () => {
                this.loadDataFromRepos();
            }, false);
        }

        public handleGoBackToCredentialList(): void {
            this.$router.push({name: Routes.CREDENTIAL_LIST});
        }

        public handleVaultAccessGranted(vaultAccessDetails: VaultAccessDetails): void {
            this.noVaultAccess = false;
            this.vaultAccessDetails = vaultAccessDetails;
            this.loadCredentialView();
        }

        public handleVaultAccessStatusChange(vaultAccessExpired: boolean): void {
            this.noVaultAccess = vaultAccessExpired;

            if (this.noVaultAccess) {
                this.vaultAccessDetails = null;
                this.credentialViewLoaded = false;
                this.credentialViewItem = null;
            }
        }

        public handleEditCredential(): void {
            this.$router.push({
                name: Routes.CREDENTIAL_EDIT,
                params: {
                    id: this.credentialId,
                },
            });
        }

        public handleDeleteCredentialIntent(): void {
            this.deleteModalOpen = true;
        }

        public async handleDeleteCredential(): Promise<void> {
            this.deleting = true;

            try {
                await this.$repoManager.credential.requestDeleteCredential(this.credentialItem);
                this.handleGoBackToCredentialList();
            } catch (error) {
                this.errorMessage = error.toString();
            } finally {
                this.deleting = false;
                this.deleteModalOpen = false;
            }
        }

        public handleCopyDataToClipboard(textElementId: string): void {
            const elementToCopyTextFrom: any = document.getElementById(textElementId);
            const copyRange = document.createRange();

            copyRange.selectNodeContents(elementToCopyTextFrom);

            // Select the text inside the range
            const selection = window.getSelection();
            if (!selection) {
                return;
            }

            // Need to do this for Chrome for some reason
            selection.removeAllRanges();

            selection.addRange(copyRange);

            // Copy the text to the clipboard
            document.execCommand('copy');

            // Remove the selection range
            selection.removeRange(copyRange);

            this.showCopiedToClipboard = true;
        }

        public async loadDataFromRepos(): Promise<void> {
            this.loaded = false;
            this.credentialViewLoaded = false;
            this.credentialId = this.$route.params.id;

            try {
                this.credentialItem = await this.$repoManager.credential.getItem(this.credentialId);

                this.vaultAccessDetails = await this.$repoManager.credential.getVaultAccessDetails();
                if (this.vaultAccessDetails) {
                    await this.loadCredentialView();
                    this.noVaultAccess = false;
                }

                this.loaded = true;
            } catch (error) {
                this.errorMessage = error.toString();
            }

            this.loaded = true;
        }

        public get spaceId(): string {
            return this.$store.state.spaceModule.selectedSpace;
        }

        private async loadCredentialView(): Promise<void> {
            if (!this.vaultAccessDetails) {
                return;
            }

            this.credentialViewItem = await this.$repoManager.credential.requestCreateView(
                this.credentialId,
                this.spaceId,
                this.vaultAccessDetails.vaultAccessId,
                this.vaultAccessDetails.tokenPartA,
            );

            this.credentialViewLoaded = true;
        }
    }
