import SpaceItem from '../database/SpaceItem';

const SpaceModule = {
    state: {
        spaces: [],
        selectedSpace: '',
        lastUpdatedTimestamp: 0,
    },
    mutations: {
        setSpaces(state: any, spaces: SpaceItem[]) {
            state.spaces = spaces;
        },
        setSelectedSpace(state: any, value: string) {
            state.selectedSpace = value;
        },
        setSpaceLastUpdatedTimestamp(state: any, timestamp: number) {
            state.lastUpdatedTimestamp = timestamp;
        },
    },
    actions: {

    },
    getters: {

    },
};

export default SpaceModule;
