
    // Components
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    
    // Typescript
    import { ModuleType } from '@/ts/Enums/ModuleType';

    @Component({
        components: {
            IncludeIf,
        },
    })
    export default class AppFooter extends Vue {
        get isAdmin(): boolean {
            return this.$store.state.userModule.isAdmin;
        }

        public get isAdminModuleActive(): boolean {
            return this.isSelectedModule(ModuleType.ADMIN);
        }

        private isSelectedModule(moduleName: string): boolean {
            return moduleName === this.selectedModule;
        }

        public get isProfileModuleActive(): boolean {
            return this.isSelectedModule(ModuleType.PROFILE);
        }

        get isLoggedIn(): boolean {
            return this.$store.state.userModule.userLoggedIn;
        }

        public get isReloadActive(): boolean {
            return this.isSelectedModule(ModuleType.RELOAD);
        }

        get selectedModule(): ModuleType {
            return this.$store.state.settingsModule.moduleType;
        }

        get userDisplayName(): string {
            return this.$store.state.userModule.userDisplayName;
        }

        public get version(): string {
            return this.$store.state.settingsModule.appSettings.version;
        }
    }
