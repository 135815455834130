
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import { CredentialItem } from '@/domains/credentials/database/CredentialItem';
import RepoManager from '@/ts/Database/RepoManager';
import Spinner from '@/domains/ui/views/Spinner.vue';
import VaultPasswordNotSet from '@/domains/vault/views/VaultPasswordNotSet.vue';
import {Routes} from '@/domains/app/router/router';
import CredentialImport from '@/domains/credentials/views/CredentialImport.vue';
import GetVaultAccess from '@/domains/vault/views/GetVaultAccess.vue';
import {VaultAccessDetails} from '@/domains/vault/interfaces/VaultAccessDetails';
import Form from '@/domains/ui/views/Form.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import VaultAccessTicker from '@/domains/vault/views/VaultAccessTicker.vue';

@Component({
  components: {
      VaultAccessTicker,
      BasicButton,
      Form,
      GetVaultAccess,
      CredentialImport,
    VaultPasswordNotSet,
      Container,
      SidebarSection,
      Notification,
      ErrorMessage,
      ActionHeading,
      IncludeIf,
      Spinner,
  },
})
export default class CredentialList extends Vue {
    private $repoManager!: RepoManager;

    private loaded: boolean = false;
    private credentials: CredentialItem[] = [];
    private errorMessage: string = '';
    public vaultPasswordIsSet: boolean = false;
    public showImportCredentials = false;
    public vaultAccessDetails: VaultAccessDetails|null = null;
    public searchKeywords = '';

    public mounted(): void {
        if (this.$repoManager.credential) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    /**
     * Fires when all repos have been initialised
     */
    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    public async loadDataFromRepos(): Promise<void> {
        if (this.$repoManager.credential) {
            this.updatedSearchKeywords();
            this.vaultPasswordIsSet = await this.$repoManager.credential.vaultPasswordIsSet();
            await this.loadCredentials();
            this.loaded = true;
        }
    }

    public handleSearchIntent(keywords: string): void {
        this.setSearchKeywords(keywords);
        this.loadCredentials();
    }

    public updatedSearchKeywords(): void {
        if (this.$repoManager.credential) {
            this.searchKeywords = this.$repoManager.credential.getSearchKeywords();
        }
    }

    public setSearchKeywords(newKeywords: string) {
        this.$repoManager.credential.setSearchKeywords(newKeywords);
        this.searchKeywords = newKeywords;
    }

    public addCredential(): void {
        this.$router.push({name: Routes.CREDENTIAL_ADD});
    }

    public handleUploadCredentialsIntent(): void {
        this.showImportCredentials = true;
    }

    public showCredential(credentialId: string): void {
        this.$router.push({
            name: Routes.CREDENTIAL_VIEW,
            params: {
                id: credentialId,
            },
        });
    }

    public timestampToDate(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    public handleVaultAccessGranted(vaultAccessDetails: VaultAccessDetails): void {
        this.vaultAccessDetails = vaultAccessDetails;
    }

    public handleVaultAccessExpired(hasExpired: boolean): void {
        if (hasExpired) {
            this.vaultAccessDetails = null;
        } else {
            this.vaultAccessDetails = this.$repoManager.credential.getVaultAccessDetails();
        }
    }

    // If a sync has occured - refresh the UI.
    get lastSyncTimestamp(): number {
        return this.$store.state.userModule.lastSyncTimestamp;
    }

    private get lastUpdated(): string {
        return this.$store.state.credentialStore.lastUpdated;
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private async loadCredentials(): Promise<void> {
        this.credentials = await this.$repoManager.credential.getList(this.spaceId, this.searchKeywords);
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }

    @Watch('lastUpdated')
    private onLastUpdatedChanged(): void {
        this.loadDataFromRepos();
    }

    // If the user has selected a different workspace, refresh the notes.
    @Watch('spaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }

    @Watch('lastChangeLogId')
    private onChangeLogUpdated(): void {
        this.loadDataFromRepos();
    }
}
