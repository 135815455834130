
import { Component, Vue } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';

import Container from '@/domains/app/views/Container.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Form from '@/domains/ui/views/Form.vue'; // @ is an alias to /src
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

import StringHelper from '@/ts/Helpers/StringHelper';

@Component({
  components: {
      ActionHeading,
      Form,
      ErrorMessage,
      SpinnerButton,
      Container,
  },
})
export default class RequestDisableTwoFactorAuth extends Vue {
    private $http!: ApiClient;
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private errorMessage: string = '';
    private loading: boolean = false;
    private step = 1;

    // Model fields
    private emailAddress: string = '';
    private password: string = '';
    private pin: string = '';

    public mounted(): void {
        this.reset();
    }

    private reset(): void {
        this.errorMessage = '';
        this.emailAddress = '';
        this.password = '';
        this.pin = '';
    }

    public async handleSendVerificationRequest(): Promise<void> {
        if (!this.step1FormValid()) {
            return;
        }

        try {
            this.errorMessage = '';
            this.loading = true;

            await this.$requestFactory.disableUserTwoFactorAuthRequestRequest.execute(
                this.password,
                this.emailAddress,
            );

            this.step = 2;
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }

    public async handleVerifyDisableRequest(): Promise<void> {
        if (!this.step2FormValid()) {
            return;
        }

        try {
            this.errorMessage = '';
            this.loading = true;

            await this.$requestFactory.verifyDisableUserTwoFactorAuthRequest.execute(
                this.password,
                this.emailAddress,
                this.getPinAsNumber,
            );

            this.step = 3;
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }

    public navigateToLoginScreen(): void {
        this.$router.push({name: Routes.LOGIN});
    }

    private step1FormValid(): boolean {
        if (this.emailAddress.length < 5) {
            this.errorMessage = 'You must enter a valid email address.';
            return false;
        }

        if (this.password.length === 0) {
            this.errorMessage = 'Please enter your password.';
            return false;
        }

        return true;
    }

    private step2FormValid(): boolean {
        if ((this.pin.length < 6) || (isNaN(Number(this.pin)))) {
            this.errorMessage = 'You must enter a valid pin number.';
            return false;
        }

        return true;
    }

    private get getPinAsNumber(): number {
        const pinWithoutSpaces = StringHelper.removeSpaces(this.pin);
        return StringHelper.toInt(pinWithoutSpaces);
    }
}
