import { AbstractRequestFactory } from '../../../../ts/Requests/AbstractRequestFactory';
import { GetKanbanColumnsRequest } from './GetKanbanColumnsRequest';
import { SetKanbanColumnsRequest } from './SetKanbanColumnsRequest';

export class KanbanColumnsRequestFactory extends AbstractRequestFactory {
    public get getKanbanColumnsRequest(): GetKanbanColumnsRequest {
        return new GetKanbanColumnsRequest(this.apiClient);
    }

    public get setKanbanColumnsRequest(): SetKanbanColumnsRequest {
        return new SetKanbanColumnsRequest(this.apiClient);
    }
}
