import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import MessageItem from '@/domains/messages/database/messages/MessageItem';
import FileItem from '@/ts/Database/Files/FileItem';

export default class SendMessageRequest extends AbstractRequest {
    public async execute(
        contactId: string,
        subject: string,
        messageHtml: string,
        attachments: FileItem[],
    ): Promise<MessageItem> {
        return new Promise<MessageItem>(async (resolve, reject) => {
            try {
                const params: any = {
                    contact_id: contactId,
                    subject,
                    message: messageHtml,
                };

                if (attachments.length > 0) {
                    params.attachment_files = attachments.map((item) => {
                        return item.id;
                    });
                }

                const response = await this.apiClient.post(`/private/message/send`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('message')))  {
                    reject(Error('SendMessageRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.message);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to send a message failed.  Please try again later'));
            }
        });
    }
}
