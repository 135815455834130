import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { SymetricCipherType } from '@/ts/Enums/SymetricCipherType';

export interface EncryptDataParams {
    cipher_type: SymetricCipherType;
    password: string;
    clear_text: string;
}

export interface EncryptDataResult {
    encrypted_string: string;
    cipher_type: SymetricCipherType;
    integrity_hash: string;
    metadata: {
        salt: string;
        iv: string;
    };
}

export class EncryptDataRequest extends AbstractRequest {
    public async execute(params: EncryptDataParams): Promise<EncryptDataResult> {
        return new Promise<EncryptDataResult>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/encryption/encrypt`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('encryption_result')))  {
                    reject(Error('EncryptDataRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.encryption_result);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to create encrypt data failed.  Please try again later'));
            }
        });
    }
}
