export enum ContactFilterType {
    'FAVOURITES' = 'favourites',
    'NEED_TO_RESPOND' = 'need-to-respond',
    'SHARED' = 'shared',
    'UNREAD_MESSAGES' = 'unread-messages',
}

export function getContactFilterTypeLabel(filterType: ContactFilterType): string {
    switch (filterType) {
        case ContactFilterType.FAVOURITES:
            return 'Favourites';
        case ContactFilterType.NEED_TO_RESPOND:
            return 'Need To Respond';
        case ContactFilterType.SHARED:
            return 'Shared';
        case ContactFilterType.UNREAD_MESSAGES:
            return 'Unread Messages';
        default:
            throw Error('Invalid filterType: ' + filterType);
    }
}
