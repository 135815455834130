import { AbstractRequestFactory } from '@/ts/Requests/AbstractRequestFactory';
import { CheckVaultPasswordSetRequest } from './CheckVaultPasswordSetRequest';
import {SetVaultPasswordRequest} from '@/domains/vault/requests/SetVaultPasswordRequest';
import {RequestVaultAccessRequest} from '@/domains/vault/requests/RequestVaultAccessRequest';

export class VaultRequestFactory extends AbstractRequestFactory {
    public get checkVaultPasswordSet(): CheckVaultPasswordSetRequest {
        return new CheckVaultPasswordSetRequest(this.apiClient);
    }

    public get requestVaultAccess(): RequestVaultAccessRequest {
        return new RequestVaultAccessRequest(this.apiClient);
    }

    public get setVaultPassword(): SetVaultPasswordRequest {
        return new SetVaultPasswordRequest(this.apiClient);
    }
}
