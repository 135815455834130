import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteCategoryItem from '@/domains/notes/database/noteCategories/NoteCategoryItem';

export interface UpdateNoteCategoryParams {
    id: string;
    name: string;
    space_id: string;
}

export default class UpdateNoteCategoryRequest extends AbstractRequest {
    public async execute(params: UpdateNoteCategoryParams): Promise<NoteCategoryItem> {
        return new Promise<NoteCategoryItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/note_category/${params.id}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('noteCategory')))  {
                    reject(Error('UpdateNoteCategoryRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.noteCategory);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a note category failed.  Please try again later'));
            }
        });
    }
}
