
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import KanbanColumnsItem from '@/domains/kanban/database/kanbanColumns/KanbanColumnsItem';
import { SaveKanbanTaskEvent } from '@/domains/kanban/interfaces/KanbanEventInterfaces';
import Modal from '@/domains/ui/views/Modal.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';
import Trilean from '@/ts/Trilean';

@Component({
  components: {
      Form,
      InputChecker,
      Modal,
      SpinnerButton,
      Toggle,
  },
})
export default class KanbanAddEditTaskModal extends Vue {
    @Prop({required: true}) public kanbanColumns!: KanbanColumnsItem;
    @Prop({required: true, type: String}) private kanbanTaskId!: string;
    @Prop({required: true, type: String}) private kanbanTaskTitle!: string;
    @Prop({required: true, type: String}) private kanbanTaskDescription!: string;
    @Prop({required: true, type: Number}) private kanbanTaskColumnNo!: number;
    @Prop({required: true, type: Number}) private kanbanTaskSequenceNo!: number;
    @Prop({required: true, type: Boolean}) private kanbanTaskShared!: boolean;
    @Prop({required: true, type: Boolean}) private modalOpen!: boolean;
    @Prop({required: true, type: Boolean}) private saveInProgress!: boolean;
    @Prop({required: true, type: String}) private kanbanTaskCreatedByUserId!: string;

    public title = '';
    public description = '';
    public columnNo = 1;
    public sequenceNo = 2;
    public taskShared = false;

    private taskTitleOk: Trilean = Trilean.Unknown;
    private taskDescriptionOk: Trilean = Trilean.Unknown;

    public handleCloseModalIntent(): void {
        this.$emit('close-modal-intent', {});
    }

    public handleSaveKanbanTask(): void {
        if (!this.formValid) {
            return;
        }

        this.$emit('save-kanban-task', {
            id: this.kanbanTaskId,
            title: this.title,
            description: this.description,
            columnNo: this.columnNo,
            sequenceNo: this.sequenceNo,
            shared: this.taskShared,
        } as SaveKanbanTaskEvent);
    }

    @Watch('modalOpen')
    public handleModalOpen(): void {
        if (!this.modalOpen) {
            return;
        }

        this.title = this.kanbanTaskTitle;
        this.description = this.kanbanTaskDescription;
        this.columnNo = this.kanbanTaskColumnNo;
        this.sequenceNo = this.kanbanTaskSequenceNo;
        this.taskShared = this.kanbanTaskShared;

        if (this.editingExistingTask) {
            this.handleTaskTitleChanged();
            this.handleTaskDescriptionChanged();
        } else {
            // We're adding a new task
            this.taskTitleOk = Trilean.Unknown;
            this.taskDescriptionOk = Trilean.Unknown;
        }
    }

    get buttonText(): string {
        return (this.kanbanTaskId.length > 0) ? 'Save Task' : 'Add Task';
    }

    public handleTaskTitleChanged(): void {
        if (this.title.length > 0) {
            this.taskTitleOk = Trilean.True;
        } else {
            this.taskTitleOk = Trilean.False;
        }
    }

    public handleTaskDescriptionChanged(): void {
        if (this.description.length > 0) {
            this.taskDescriptionOk = Trilean.True;
        } else {
            this.taskDescriptionOk = Trilean.False;
        }
    }

    public get editingExistingTask(): boolean {
        return this.kanbanTaskId.length > 0;
    }

    public get formValid(): boolean {
        this.handleTaskTitleChanged();
        this.handleTaskDescriptionChanged();

        return ((this.taskTitleOk === Trilean.True) &&
            (this.taskDescriptionOk === Trilean.True));
    }

    public get descriptionCharactersLeft(): number {
        return this.description.length;
    }

    public get showSharedToggle(): boolean {
        return ((!this.editingExistingTask) ||
        (this.kanbanTaskCreatedByUserId === this.loggedInUserId));
    }

    private get loggedInUserId(): string {
        return this.$store.state.userModule.userId;
    }
}
