import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LoginResponse } from '@/ts/Requests/Auth/Interfaces/LoginResponse';
import UserRoleType from '@/ts/Enums/UserRoleType';

@Module
export class AdminUserModule extends VuexModule {
    public userId: string = '';
    public lastUpdated: number = 0;
    public searchKeywords: string = '';
    public searchRoleType: UserRoleType|null = null;

    @Mutation
    public setAdminUserId(value: string) {
        this.userId = value;
    }

    @Mutation
    public setAdminUserModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Mutation
    public setAdminUserSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setAdminUserModuleRoleType(value: UserRoleType|null) {
        this.searchRoleType = value;
    }

    @Action
    public clearAdminUserModuleState() {
        this.context.commit('setAdminUserId', '');
        this.context.commit('setAdminUserSearchKeywords', '');
        this.context.commit('setAdminUserModuleRoleType', null);
    }

    @Action
    public flagAdminUserModuleUpdated() {
        this.context.commit('setAdminUserModuleLastUpdated', (new Date()).getTime());
    }
}
