import { AbstractRepo } from '../../../../ts/Database/AbstractRepo';
import NoteItem from '@/domains/notes/database/notes/NoteItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export default class NoteRepo extends AbstractRepo<NoteItem> {
    public static indexDbStoreName = 'notes';

    public constructor(db: IDBDatabase) {
        super(db, NoteRepo.indexDbStoreName);
    }

    public async getList(
        noteCategoryId: string = '',
        noteCategoryMap: { [noteCategoryId: string]: boolean },
        search: string = '',
    ): Promise<NoteItem[]> {
        const searchUpperCase = search.toUpperCase();

        return new Promise<NoteItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;

            const searchingWithinCategory = (noteCategoryId !== '');

            if (searchingWithinCategory) {
                const index = store.index('category_id');
                request = index.openCursor(IDBKeyRange.only(noteCategoryId));
            } else {
                request = this.getDb().transaction([this.getStoreName()], 'readonly')
                    .objectStore(this.getStoreName()).openCursor();
            }

            const items: NoteItem[] = [];
            const searchKeywords = search.split(' ');

            const shouldContinueLoop = ((currentNoteCategoryId: string) => {
                if (searchingWithinCategory) {
                    return true;
                }

                if (noteCategoryMap.hasOwnProperty(currentNoteCategoryId)) {
                    return true;
                }

                return false;
            });

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (shouldContinueLoop(item.note_category_id)) {
                        if (search === '') {
                            items.push(item);
                        } else {
                            const foundInTitle = StringHelper.searchInText(searchKeywords, item.title);
                            let foundInBody = false;

                            if (!item.encryption_symmetric_cipher) {
                                foundInBody = StringHelper.searchInText(searchKeywords, item.description);
                            }

                            if (foundInTitle || foundInBody) {
                                items.push(item);
                            }
                        }
                    }

                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    public async countNotesInCategory(categoryId: string): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            const index = store.index('category_id');
            const request = index.count(categoryId);

            request.onsuccess = (event: Event) => {
                resolve(request.result);
            };

            request.onerror = () => {
                reject(new Error(`Failed to perform count for store ${this.getStoreName()}!`));
            };
        });
    }

    public getEmptyNoteItem(noteCategoryId: string = ''): NoteItem {
        return {
            id: '',
            note_category_id: noteCategoryId,
            created_by_user_id: '',
            title: '',
            description: '',
            created_dtm: 0,
            updated_dtm: 0,
        };
    }
}
