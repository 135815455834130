
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue';

@Component({
  components: {
      Form,
  },
})
export default class ActionHeading extends Vue {
    @Prop({required: true}) private headingText!: string;
    @Prop({required: true}) private largeHeading!: boolean;
    @Prop({required: false}) private search!: boolean;
    @Prop({required: false}) private goBack!: boolean;
    @Prop({required: false, type: String, default: ''}) private value!: string;

    private searchText: string = '';
    private searchInterval: number|undefined = undefined;

    public mounted(): void {
        this.setSearchText();
    }

    @Watch('value')
    private setSearchText() {
        this.searchText = this.value;
    }

    private broadcastSearchIntent(): void {
        // Debounce - only search every 500 ms
        if (this.searchInterval === undefined) {
            this.searchInterval = setInterval(() => {
                this.$emit('input', this.searchText);
                this.$emit('search', this.searchText);
                clearInterval(this.searchInterval);
                this.searchInterval = undefined;
            }, 250);
        }
    }

    private broadcastGoBack(): void {
        this.$emit('go-back');
    }
}
