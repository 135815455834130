import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';

export default class Sync {
    private apiClient: ApiClient;
    private repoManager: RepoManager;
    private requestFactory: RequestFactory;

    constructor(
        apiClient: ApiClient,
        repoManager: RepoManager,
        requestFactory: RequestFactory,
    ) {
        this.apiClient = apiClient;
        this.repoManager = repoManager;
        this.requestFactory = requestFactory;
    }

    public async execute(): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            try {
                // Clear all existing records
                const clearPromises: [Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                    Promise<void>, Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                    Promise<void>, Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                    Promise<void>, Promise<void>, Promise<void>, Promise<void>] = [
                    this.repoManager.file.clear(),
                    this.repoManager.note.clear(),
                    this.repoManager.noteCategory.clear(),
                    this.repoManager.noteFile.clear(),
                    this.repoManager.recipe.clear(),
                    this.repoManager.recipeCategory.clear(),
                    this.repoManager.space.clear(),
                    this.repoManager.user.clear(),
                    this.repoManager.contact.clear(),
                    this.repoManager.contactMessageSummary.clear(),
                    this.repoManager.message.clear(),
                    this.repoManager.gallery.clear(),
                    this.repoManager.kanbanTask.clear(),
                    this.repoManager.kanbanColumns.clear(),
                    this.repoManager.credential.clear(),
                    this.repoManager.moduleGroup.clear(),
                    this.repoManager.moduleSpace.clear(),
                    this.repoManager.completion.clear(),
                ];

                Promise.all(clearPromises).then(async () => {
                    // Download all the data and store
                    const downloadPromises: [Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                        Promise<void>, Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                        Promise<void>, Promise<void>, Promise<void>, Promise<void>, Promise<void>,
                        Promise<void>, Promise<void>, Promise<void>] = [
                        this.downloadAndSaveUsers(),
                        this.downloadAndSaveSpaces(),
                        this.downloadAndSaveNoteCategories(),
                        this.downloadAndSaveFiles(),
                        this.downloadAndSaveNoteFiles(),
                        this.downloadAndSaveNotes(),
                        this.downloadAndSaveRecipeCategories(),
                        this.downloadAndSaveRecipes(),
                        this.downloadAndSaveContacts(),
                        this.downloadAndSaveContactMessageSummary(),
                        this.downloadAndSaveContactRecentMessages(),
                        this.downloadAndSaveGalleries(),
                        this.downloadAndSaveKanbanColumns(),
                        this.downloadAndSaveKanbanTasks(),
                        this.downloadAndSaveCredentials(),
                        this.downloadAndSaveModuleGroups(),
                        this.downloadAndSaveModuleSpaces(),
                    ];

                    Promise.all(downloadPromises).then(async () => {
                        // Get the last change log id for the group and store it in the state
                        const lastChangeLogId = await this.requestFactory.getLastChangeLogIdRequest.execute();

                        resolve(lastChangeLogId);
                    }).catch((error) => {
                        reject(error);
                    });
                }).catch((error) => {
                    reject(error);
                });
            } catch (error) {
                reject('An error occured whilst trying to sync your data. ' + error.toString());
            }
        });
    }

    private async downloadAndSaveUsers(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const users = await this.requestFactory.userListRequest.execute();
                await this.repoManager.user.saveAll(users);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveSpaces(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const spaces = await this.requestFactory.spaceListRequest.execute();
                await this.repoManager.space.saveAll(spaces);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveNoteCategories(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const noteCategories = await this.requestFactory.noteCategoryListRequest.execute();
                await this.repoManager.noteCategory.saveAll(noteCategories);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveFiles(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const files = await this.requestFactory.fileListRequest.execute();
                await this.repoManager.file.saveAll(files);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveNoteFiles(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const noteFiles = await this.requestFactory.noteFileListRequest.execute('');
                await this.repoManager.noteFile.saveAll(noteFiles);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveNotes(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const notes = await this.requestFactory.noteListRequest.execute();
                await this.repoManager.note.saveAll(notes);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveRecipeCategories(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const recipeCategories = await this.requestFactory.recipeCategoryListRequest.execute();
                await this.repoManager.recipeCategory.saveAll(recipeCategories);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveRecipes(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const recipes = await this.requestFactory.recipeListRequest.execute();
                await this.repoManager.recipe.saveAll(recipes);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveContacts(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const contacts = await this.requestFactory.contactListRequest.execute();
                await this.repoManager.contact.saveAll(contacts);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveContactMessageSummary(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const summaryItems = await this.requestFactory.contactMessageSummaryListRequest.execute();
                await this.repoManager.contactMessageSummary.saveAll(summaryItems);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveContactRecentMessages(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const messages = await this.requestFactory.contactRecentMessageListRequest.execute();
                await this.repoManager.message.saveAll(messages);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveGalleries(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const galleries = await this.requestFactory.gallery.galleryListRequest.execute();
                await this.repoManager.gallery.saveAll(galleries);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveKanbanColumns(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const kanbanColumns = await this.requestFactory.kanbanColumns.getKanbanColumnsRequest.execute();
                await this.repoManager.kanbanColumns.saveAll(kanbanColumns);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveKanbanTasks(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const kanbanTasks = await this.requestFactory.kanbanTasks.kanbanTaskListRequest.execute();
                await this.repoManager.kanbanTask.saveAll(kanbanTasks);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveCredentials(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const credentials = await this.repoManager.credential.requestListCredentials();
                await this.repoManager.credential.saveAll(credentials);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveModuleGroups(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const moduleGroups = await this.requestFactory.moduleGroupListRequest.execute();
                await this.repoManager.moduleGroup.saveAll(moduleGroups);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }

    private async downloadAndSaveModuleSpaces(): Promise<void> {
        return new Promise<void>((async (resolve, reject) => {
            try {
                const moduleSpaces = await this.requestFactory.moduleSpaceListRequest.execute();
                await this.repoManager.moduleSpace.saveAll(moduleSpaces);
                resolve();
            } catch (error) {
                reject (error);
            }
        }));
    }
}
