
import {Component, Vue, Prop} from 'vue-property-decorator';
import { Trilean } from '@/ts/Trilean';

@Component({
    components: {},
})
export default class InputChecker extends Vue {
    @Prop({required: true}) private status!: Trilean;
}
