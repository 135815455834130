import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import UserItem from '@/domains/users/database/users/UserItem';

export interface UpdateUserParams {
    id: string;
    display_name: string;
    email_address: string;
    role_type: string;
}

export default class UpdateUserRequest extends AbstractRequest {
    public async execute(params: UpdateUserParams): Promise<UserItem> {
        return new Promise<UserItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/user/${params.id}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('user')))  {
                    reject(Error('UpdateUserRequest - Invalid response from API'));
                    return;
                }

                const user: UserItem = response.data.user;
                user.role_type = params.role_type;

                resolve(user);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a user failed.  Please try again later'));
            }
        });
    }
}
