
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import StringListEditor from '@/domains/ui/views/StringListEditor.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import DocumentList from '@/domains/ui/views/DocumentList.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import FileItem from '@/ts/Database/Files/FileItem';
import RecipeItem from '@/domains/recipies/database/recipies/RecipeItem';
import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import StringHelper from '@/ts/Helpers/StringHelper';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      DeleteItemSpinner,
      DocumentList,
      StringListEditor,
  },
})
export default class Recipe extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private recipeTitle: string = '';
    private recipeDescription: string = '';
    private heroImageFile: FileItem|null = null;
    private preparationTimeTemp: string = '';
    private cookingTimeTemp: string = '';
    private instructions: string[] = [];
    private ingredients: string[] = [];

    private loaded: boolean = false;
    private loading: boolean = false;
    private recipeCategories: RecipeCategoryItem[] = [];
    private errorMessage: string = '';

    // Add new category form
    private categoryNameOk: Trilean = Trilean.Unknown;
    private addingNewCategory: boolean = false;
    private newCategoryName: string = '';
    private addCategoryValidationError: boolean = false;
    private waitingForAddRecipeCategoryRequest: boolean = false;

    private recipeTitleOk = Trilean.Unknown;
    private recipeCategoryOk = Trilean.Unknown;
    private recipeDescriptionOk = Trilean.Unknown;
    private preparationTimeOk = Trilean.Unknown;
    private cookingTimeOk = Trilean.Unknown;
    private heroImageFileId: string = '';

    // Uploading hero image to a recipe
    private fileUploading = false;
    private uploadedFiles: FileItem[] = [];
    private deletingFile = false;
    private heroImagePopped = false;

    private mounted(): void {
        this.init();
    }

    @Watch(`recipeId`)
    private init(): void {
        this.preparationTimeTemp = '';
        this.cookingTimeTemp = '';
        this.heroImageFile = null;

        this.recipeTitle = '';
        this.recipeDescription = '';
        this.heroImageFileId = '';
        this.cookingTimeTemp = Trilean.Unknown;
        this.preparationTimeTemp = Trilean.Unknown;
        this.instructions = [];
        this.ingredients = [];

        this.recipeTitleOk = Trilean.Unknown;
        this.recipeCategoryOk = Trilean.Unknown;
        this.recipeDescriptionOk = Trilean.Unknown;
        this.preparationTimeOk = Trilean.Unknown;
        this.cookingTimeOk = Trilean.Unknown;

        if (this.$repoManager.recipe) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private get recipeId(): string {
        return this.$store.state.recipeModule.recipeId;
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadRecipeCategories();
        if (this.recipeId !== '') {
            await this.loadRecipe();
        } else {
            const recipeItem = this.$repoManager.recipe.getEmptyRecipeItem(this.recipeCategoryId);
            this.setRecipeAttributesFromObject(recipeItem);
            this.checkRecipeCategoryOk();
        }

        this.loaded = true;
    }

    private async loadRecipeCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            this.recipeCategories = await this.$repoManager.recipeCategory.getList(this.spaceId);
        } catch (error) {
            this.errorMessage = 'Error: Recipe categories could not be loaded.';
        }
    }

    private async loadRecipe(): Promise<void> {
        try {
            this.errorMessage = '';
            const recipeItem = await this.$repoManager.recipe.getItem(this.recipeId);
            this.setRecipeAttributesFromObject(recipeItem);

            if (this.heroImageFileId.length > 0) {
                this.heroImageFile = await this.$repoManager.file.getItem(this.heroImageFileId);
            }

            this.checkRecipeTitleOk();
            this.checkRecipeCategoryOk();
            this.checkRecipeDescriptionOk();
            this.checkCookingTimeOk();
            this.checkCookingTimeOk();
        } catch (error) {
            this.errorMessage = 'Error: Recipe could not be loaded.';
        }
    }

    private setRecipeAttributesFromObject(recipeItem: RecipeItem): void {
        this.recipeTitle = recipeItem.title;
        this.recipeCategoryId = recipeItem.recipe_category_id;
        this.recipeDescription = recipeItem.description;
        this.preparationTimeTemp = recipeItem.preparation_time.toString();
        this.cookingTimeTemp = recipeItem.cooking_time.toString();
        this.instructions = recipeItem.instructions;
        this.ingredients = recipeItem.ingredients;
        this.heroImageFileId = recipeItem.hero_image_file_id;
    }

    private async deleteRecipe(): Promise<void> {
        if (!confirm('Delete this recipe?  Are you sure?')) {
            return;
        }

        try {
            this.loading = true;

            // If there is a hero image file associated with this post, delete it.
            if (this.heroImageFile) {
                const fileDeletedOk = await this.$requestFactory.deleteFileRequest.execute(this.heroImageFile.id);
                await this.$repoManager.file.delete(this.heroImageFile);
            }

            const deletedOk = await this.$requestFactory.deleteRecipeRequest.execute(this.recipeId);
            if (!deletedOk) {
                this.errorMessage = 'The recipe could not be deleted.  Please try again later.';
                return;
            }

            const recipeItem = await this.$repoManager.recipe.getItem(this.recipeId);
            await this.$repoManager.recipe.delete(recipeItem);

            await this.$store.dispatch('flagRecipeModuleUpdated');

            this.navigateToRecipesScreen();
        } catch (error) {
            this.errorMessage = 'The recipe could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    private cancelEdit(): void {
        this.navigateToRecipesScreen();
    }

    private formValid(): boolean {
        if (this.recipeTitleOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid recipe title.';
            return false;
        }

        if (this.recipeCategoryOk !== Trilean.True) {
            this.errorMessage = 'You must select a valid recipe category.';
            return false;
        }

        if (this.recipeDescriptionOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid recipe description.';
            return false;
        }

        if (this.preparationTimeOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid preparation time.  Enter 0 if you don\'t know it.';
            return false;
        }

        if (this.cookingTimeOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid cooking time.  Enter 0 if you don\'t know it.';
            return false;
        }

        if (this.ingredients.length === 0) {
            this.errorMessage = 'You must add at least one ingredient.';
            return false;
        }

        if (this.instructions.length === 0) {
            this.errorMessage = 'You must add at least one instruction.';
            return false;
        }

        return true;
    }

    private async saveRecipe(redirectToOverviewWhenDone = true): Promise<void> {
        this.checkCookingTimeOk();
        this.checkPreparationTimeOk();

        if (!this.formValid()) {
            return;
        }

        this.loading = true;
        let recipeItem: RecipeItem|null = null;

        try {
            if (this.addingNewItem) {
                recipeItem = await this.$requestFactory.addRecipeRequest.execute({
                    recipe_category_id: this.recipeCategoryId,
                    title: this.recipeTitle,
                    description: this.recipeDescription,
                    cooking_time: parseInt(this.cookingTimeTemp, 10),
                    preparation_time: parseInt(this.preparationTimeTemp, 10),
                    ingredients: this.ingredients,
                    instructions: this.instructions,
                    hero_image_file_id: this.heroImageFile ? this.heroImageFile.id  : '',
                });
            } else {
                recipeItem = await this.$requestFactory.updateRecipeRequest.execute({
                    id: this.recipeId,
                    recipe_category_id: this.recipeCategoryId,
                    title: this.recipeTitle,
                    description: this.recipeDescription,
                    cooking_time: parseInt(this.cookingTimeTemp, 10),
                    preparation_time: parseInt(this.preparationTimeTemp, 10),
                    ingredients: this.ingredients,
                    instructions: this.instructions,
                    hero_image_file_id: this.heroImageFile ? this.heroImageFile.id : '',
                });
            }

            await this.$repoManager.recipe.save(recipeItem);

            await this.$store.dispatch('flagRecipeModuleUpdated');

            // Update the static view fields.
            this.setRecipeAttributesFromObject(recipeItem);
            this.errorMessage = '';

            if (redirectToOverviewWhenDone) {
                this.$router.push({name: Routes.RECIPE, params: { id: recipeItem.id }});
            }
        } catch (error) {
            this.errorMessage = 'Failed to save recipe.  Please try again later.';
        } finally {
            this.loading = false;
        }
    }

    private checkRecipeTitleOk(): void {
        if ((this.recipeTitle.length === 0) || (this.recipeTitle.length > 255)) {
            this.recipeTitleOk = Trilean.False;
        } else {
            this.recipeTitleOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkRecipeCategoryOk(): void {
        if (this.recipeCategoryId === '') {
            this.recipeCategoryOk = Trilean.False;
        } else {
            this.recipeCategoryOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkRecipeDescriptionOk(): void {
        if (this.recipeDescription.length === 0) {
            this.recipeDescriptionOk = Trilean.False;
        } else {
            this.recipeDescriptionOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkCookingTimeOk(): void {
        const currentValue: any = this.cookingTimeTemp;
        if ((isNaN(currentValue)) ||
            (currentValue === '') ||
            (currentValue < 0) ||
            (currentValue > 800))  {
            this.cookingTimeOk = Trilean.False;
        } else {
            this.cookingTimeOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkPreparationTimeOk(): void {
        const currentValue: any = this.preparationTimeTemp;
        if ((isNaN(currentValue)) ||
            (currentValue === '') ||
            (currentValue < 0) ||
            (currentValue > 800))  {
            this.preparationTimeOk = Trilean.False;
        } else {
            this.preparationTimeOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkCategoryNameOk(): void {
        if ((this.newCategoryName.length >= 2) && (this.newCategoryName.length <= 50)) {
            this.categoryNameOk = Trilean.True;
            this.addCategoryValidationError = false;
        } else {
            this.categoryNameOk = Trilean.False;
        }
    }

    private userWantsToAddNewCategory(): void {
        this.addingNewCategory = !this.addingNewCategory;
        this.newCategoryName = '';
        this.categoryNameOk = Trilean.Unknown;
        this.addCategoryValidationError = false;
    }

    private cancelAddCategory(): void {
        this.newCategoryName = '';
        this.addingNewCategory = false;
        this.addCategoryValidationError = false;
    }

    private async addCategory(): Promise<void> {
        if (this.categoryNameOk !== Trilean.True) {
            this.addCategoryValidationError = true;
            return;
        }

        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddRecipeCategoryRequest = true;
            const recipeCategoryItem = await this.$requestFactory.addRecipeCategoryRequest.execute({
                space_id: this.spaceId,
                name: this.newCategoryName,
            });

            // Save the recipe category in the local repo
            await this.$repoManager.recipeCategory.save(recipeCategoryItem);

            // Reset recipe category input text field
            this.newCategoryName = '';

            // Select the new recipe category item
            this.recipeCategoryId = recipeCategoryItem.id;

            // Reload recipe categories from the repo
            await this.loadRecipeCategories();

            // Reset flags
            this.waitingForAddRecipeCategoryRequest = false;
            this.addingNewCategory = false;

            await this.$store.dispatch('flagRecipeModuleUpdated');
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the recipe category';
        }
    }

    private async uploadPhoto(): Promise<void> {
        const uploadFile: any = this.$refs.hero_image;
        const fileName: string = uploadFile.files[0].name;

        // Ensure the selected file looks like an image.
        if (!StringHelper.filenameHasValidImageExtension(fileName)) {
            this.errorMessage = `$fileName does not appear to be a valid image file. Images ` +
                `should have a .jpg, .jpeg, .png or .gif extension.`;
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile.files[0]);

        try {
            const params: UploadFileParams = {
                formData,
            };

            this.fileUploading = true;

            const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'recipe');
            await this.$repoManager.file.save(fileItem);

            this.heroImageFile = fileItem;
            this.uploadedFiles.push(fileItem);
            this.fileUploading = false;
        } catch (error) {
            this.errorMessage = 'Error uploading file: ' + error.toString();
        } finally {
            this.fileUploading = false;
        }
    }

    private async deleteHeroImage(): Promise<void> {
        if (!this.heroImageFile) {
            return;
        }

        if (!(confirm(`Delete the uploaded hero image?  Are you sure?`))) {
            return;
        }

        try {
            this.deletingFile = true;
            const tempFile = this.heroImageFile;

            // Set the local hero image to null.
            this.heroImageFile = null;

            await this.saveRecipe(false);

            // Delete the file from the server
            const ok = await this.$requestFactory.deleteFileRequest.execute(tempFile.id);
            if (!ok) {
                this.errorMessage = 'The hero image could not be deleted.  Please try again later.';
            }

            // Remove the file from the local repo too.
            await this.$repoManager.file.delete(tempFile);
        } catch (error) {
            this.errorMessage = 'The category could not be deleted.  Please try again later.';
        } finally {
            this.deletingFile = false;
        }
    }

    private navigateToRecipesScreen(): void {
        this.$router.push({name: Routes.RECIPE_LIST});
    }

    private navigateToCategory(categoryId: string): void {
        this.$store.commit('setRecipeCategoryId', categoryId);
        this.navigateToRecipesScreen();
    }

    private getFormattedRecipeDescription(): string {
        return this.recipeDescription.replace(/(\r\n|\n|\r)/g, '<br />');
    }

    get recipeCategoryId(): string {
        return this.$store.state.recipeModule.recipeCategoryId;
    }

    set recipeCategoryId(value: string) {
        this.$store.commit('setRecipeCategoryId', value);
    }

    get addingNewItem(): boolean {
        return this.recipeId === '';
    }

    get heroImageBackgroundUrl(): string {
        if ((this.heroImageFile) && (!this.heroImagePopped)) {
            return `background-image:url('${this.heroImageFile.url}');`;
        }

        return '';
    }
}
