import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { ModuleGroupItem } from '@/domains/module/database/moduleGroup/ModuleGroupItem';

export default class ModuleGroupListRequest extends AbstractRequest {
    public async execute(): Promise<ModuleGroupItem[]> {
        return new Promise<ModuleGroupItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/module_groups`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('module_groups')))  {
                    reject(Error('ModuleGroupListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.module_groups);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load module groups from the API'));
            }
        });
    }
}
