import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import { CompletionItem } from '@/domains/assistant/database/CompletionItem';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Store } from 'vuex';
import { AiMaxResponseLength } from '@/domains/ai/types/AiMaxResponseLength';
import { AiSophisticationLevel } from '@/domains/ai/types/AiSophisticationLevel';
import StringHelper from '@/ts/Helpers/StringHelper';

export class CompletionRepo extends AbstractRepo<CompletionItem> {
    public static indexDbStoreName = 'completions';
    private store: Store<any>;
    private requestFactory: RequestFactory;

    public constructor(
        db: IDBDatabase,
        store: Store<any>,
        requestFactory: RequestFactory,
    ) {
        super(db, CompletionRepo.indexDbStoreName);
        this.store = store;
        this.requestFactory = requestFactory;
    }

    /**
     * Asks the backend to generate completions for the given prompt.
     * The sophistical level controls the AI model, and the response length controls the
     * maximum number of tokens that will be generated.
     * The number of tokens is almost 1:1 with the number of words.
     * @param spaceId
     * @param prompt
     * @param responseLength
     * @param sophisticationLevel
     */
    public async requestCompletion(
        spaceId: string,
        prompt: string,
        responseLength: AiMaxResponseLength,
        sophisticationLevel: AiSophisticationLevel,
    ): Promise<CompletionItem> {
        const completionItem = await this.requestFactory.ai.generateCompletion.execute({
            space_id: spaceId,
            prompt,
            response_length: responseLength,
            sophistication_level: sophisticationLevel,
        });

        await this.save(completionItem);

        await this.store.commit('addNewCompletion', completionItem);

        return completionItem;
    }

    public async getList(
        spaceId: string,
        search: string = '',
    ): Promise<CompletionItem[]> {
        return new Promise<CompletionItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
            objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: CompletionItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value as CompletionItem;

                    if (item.space_id === spaceId) {
                        let pass = true;

                        if (search !== '') {
                            const foundInPrompt =
                                StringHelper.searchInText(searchKeywords, item.prompt);

                            let foundInCompletion = false;

                            if (!foundInPrompt) {
                                for (const completion of item.completions) {
                                    foundInCompletion = StringHelper.searchInText(searchKeywords, completion);
                                    if (foundInCompletion) {
                                        break;
                                    }
                                }
                            }

                            pass = (foundInPrompt || foundInCompletion);
                        }

                        if (pass) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    // Completion items should always be returned in creation order
                    items.sort((a: CompletionItem, b: CompletionItem) => {
                        if (a.created_dtm < b.created_dtm) {
                            return - 1;
                        } else if (a.created_dtm > b.created_dtm) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    this.store.commit('setCompletions', items);

                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    public async clearForSpace(spaceId: string): Promise<void> {
        const allItemsInSpace = await this.getList(spaceId, '');

        for (const item of allItemsInSpace) {
            await this.delete(item);
        }

        await this.store.commit('setCompletions', []);
    }

    public async clear(): Promise<void> {
        await super.clear();
        await this.store.commit('setCompletions', []);
    }
}
