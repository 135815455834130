
import StringHelper from '@/ts/Helpers/StringHelper';

export interface MediaViewerMediaInfo {
    mediaName: string;
    mediaUrl: string;
    fileId: string;
}

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Spinner from '@/domains/ui/views/Spinner.vue';

@Component({
  components: {
      Spinner,
  },
})
export default class MediaViewer extends Vue {
    @Prop({required: true, type: String}) private mediaUrl!: string;
    @Prop({required: true, type: String}) private mediaName!: string;
    @Prop({required: true, type: Boolean}) private viewerOpen!: boolean;
    @Prop({required: false, type: Boolean, default: false}) private downloadingBinary!: boolean;

    private mediaLoaded = false;
    private windowTopBeforeScroll = 0;

    public broadcastCloseViewer(): void {
        this.$emit('close-viewer-intent', {});
        this.mediaLoaded = false;

        // Scroll to wherever the user had been previously
        // and show the scrollbars again.
        window.scrollTo(0, this.windowTopBeforeScroll);
        document.body.style.overflow = 'visible';
    }

    public broadcastDownloadImage(): void {
        if (this.downloadingBinary) {
            return;
        }

        this.$emit('download-image-intent', {});
    }

    @Watch('viewerOpen')
    public loadMediaItem() {
        if (this.viewerOpen) {
            // Remember how far down the user had scrolled before scrolling back to top.
            this.windowTopBeforeScroll = window.pageYOffset || 0;

            // Scroll to top and hide sidebars.
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';

            if (this.isImage()) {
                const theImage = new Image();
                theImage.src = this.mediaUrl;
                theImage.onload = () => {
                    this.mediaLoaded = true;
                };
            } else {
                this.mediaLoaded = true;
            }
        }
    }

    private isVideo(): boolean {
        return StringHelper.filenameHasValidVideoExtension(this.mediaUrl);
    }

    private isImage(): boolean {
        return StringHelper.filenameHasValidImageExtension(this.mediaUrl);
    }
}
