import Vue from 'vue';
import Router from 'vue-router';

import Assistant from '@/domains/assistant/views/Assistant.vue';
import Contact from '../../contacts/views/Contact.vue';
import Contacts from '../../contacts/views/Contacts.vue';
import CredentialList from '../../credentials/views/CredentialList.vue';
import ContactSendMessage from '../../contacts/views/ContactSendMessage.vue';
import Gallery from '@/domains/galleries/views/Gallery.vue';
import GalleryList from '@/domains/galleries/views/GalleryList.vue';
import GalleryEdit from '@/domains/galleries/views/GalleryEdit.vue';
import Home from '../views/Home.vue';
import Kanban from '../../kanban/views/Kanban.vue';
import Login from '../../users/views/Login.vue';
import Logout from '../../users/views/Logout.vue';
import NoteList from '@/domains/notes/views/NoteList.vue';
import Note from '@/domains/notes/views/Note.vue';
import NoteEdit from '@/domains/notes/views/NoteEdit.vue';
import PasswordReset from '../../users/views/PasswordReset.vue';
import RouterView from '@/domains/app/views/RouterView.vue';
import RecipeList from '@/domains/recipies/views/RecipeList.vue';
import Recipe from '@/domains/recipies/views/Recipe.vue';
import RecipeEdit from '@/domains/recipies/views/RecipeEdit.vue';
import Reload from '@/domains/users/views/Reload.vue';
import Register from '../../users/views/Register.vue';
import RequestDisableTwoFactorAuth from '../../users/views/RequestDisableTwoFactorAuth.vue';
import User from '../../users/views/User.vue';
import Users from '../../users/views/Users.vue';

import Admin from '../../admin/views/Admin.vue';
import SpacesAdmin from '../../admin/views/SpacesAdmin.vue';
import NoteCategoryAdmin from '../../admin/views/NoteCategoryAdmin.vue';
import RecipeCategoryAdmin from '../../admin/views/RecipeCategoryAdmin.vue';
import KanbanAdmin from '../../admin/views/KanbanAdmin.vue';

import store from '@/domains/app/store/store';
import {ModuleType} from '@/ts/Enums/ModuleType';
import CredentialEdit from '@/domains/credentials/views/CredentialEdit.vue';
import CredentialView from '@/domains/credentials/views/CredentialView.vue';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import SettingsHelper from '@/ts/Helpers/SettingsHelper';

Vue.use(Router);

export enum Routes {
    ASSISTANT = 'assistant',
    CONTACT = 'contact',
    CONTACTS = 'contacts',
    CREDENTIAL_LIST = 'credential_list',
    CREDENTIAL_ADD = 'credential_add',
    CREDENTIAL_VIEW = 'credential_view',
    CREDENTIAL_EDIT = 'credential_edit',
    CONTACT_SEND_MESSAGE = 'contact_send_message',
    CONTACT_ADD = 'contact_add',
    GALLERY = 'gallery',
    GALLERY_ADD = 'gallery_add',
    GALLERY_EDIT = 'gallery_edit',
    GALLERY_LIST = 'gallery_list',
    HOME = 'home',
    KANBAN_BOARD = 'kanban_board',
    LOGIN = 'login',
    LOGOUT = 'logout',
    NOTE = 'note',
    NOTE_LIST = 'note_list',
    NOTE_ADD = 'note_add',
    NOTE_EDIT = 'note_edit',
    PASSWORD_RESET = 'password_reset',
    REGISTER = 'register',
    PROFILE = 'profile',
    POST_LOGIN = 'note_list',
    RECIPE = 'recipe',
    RECIPE_LIST = 'recipe_list',
    RECIPE_ADD = 'recipe_add',
    RECIPE_EDIT = 'recipe_edit',
    RELOAD = 'reload',
    REQUEST_DISABLE_TWO_FACTOR_AUTH = 'request_disable_authenticator',
    USER = 'user',
    USER_ADD = 'user_add',
    USERS = 'users',
    ADMIN = 'admin',
    ADMIN_KANBAN = 'admin_kanban',
    ADMIN_SPACES = 'admin_spaces',
    ADMIN_NOTE_CATEGORIES = 'admin_note_categories',
    ADMIN_RECIPE_CATEGORIES = 'admin_recipe_categories',
}

const getSettingsHelper = (): SettingsHelper => {
    const helperFactory = Vue.prototype.$helperFactory as HelperFactory;
    return helperFactory.settingsHelper;
};

export default new Router({
    routes: [
        {
            path: '/',
            name: Routes.HOME,
            component: Home,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    // By default, we usually show the NOTES module, but it's possible the module isn't enabled.
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.NOTES)) {
                        const firstEnabledModule = getSettingsHelper().getFirstEnabledModuleForCurrentSpace();

                        // If there are no enabled modules there's nothing we can do - log the user out.
                        if (firstEnabledModule == null) {
                            next({name: Routes.LOGOUT});
                        } else {
                            // Redirect the user to the first enabled module route.
                            switch (firstEnabledModule) {
                                case ModuleType.CONTACTS:
                                    next({name: Routes.CONTACTS});
                                    break;

                                case ModuleType.CREDENTIALS:
                                    next({name: Routes.CREDENTIAL_LIST});
                                    break;

                                case ModuleType.GALLERIES:
                                    next({name: Routes.GALLERY_LIST});
                                    break;

                                case ModuleType.KANBAN:
                                    next({name: Routes.KANBAN_BOARD});
                                    break;

                                default:
                                    // tslint:disable-next-line:no-console
                                    console.log(
                                        'Warning, beforeEnter guard encountered unhandled first enabled module type',
                                        firstEnabledModule,
                                    );

                                    next({name: Routes.LOGOUT});
                                    break;
                            }
                        }
                    } else {
                        store.commit('setModuleType', ModuleType.NOTES);
                        next({name: Routes.NOTE_LIST});
                    }
                } else {
                    next();
                }
            },
        },
        /**************************
         * ASSISTANT MODULE
         *************************/
        {
            path: '/assistant',
            name: Routes.ASSISTANT,
            component: Assistant,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.ASSISTANT)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.ASSISTANT);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * CONTACTS MODULE
         *************************/
        {
            path: '/contacts',
            name: Routes.CONTACTS,
            component: Contacts,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.CONTACTS)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.CONTACTS);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/contact/:id',
            name: Routes.CONTACT,
            component: Contact,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.CONTACTS);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/contact/:id/send_message',
            name: Routes.CONTACT_SEND_MESSAGE,
            component: ContactSendMessage,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.CONTACTS);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/contact',
            name: Routes.CONTACT_ADD,
            component: Contact,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.CONTACTS);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/profile',
            name: Routes.PROFILE,
            component: User,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.dispatch('viewProfilePage');
                    store.commit('setModuleType', ModuleType.PROFILE);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * CREDENTIALS MODULE
         *************************/
        {
            path: '/credential_list',
            name: Routes.CREDENTIAL_LIST,
            component: CredentialList,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.CREDENTIALS)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.CREDENTIALS);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/credential/add',
            name: Routes.CREDENTIAL_ADD,
            component: CredentialEdit,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.CREDENTIALS)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.CREDENTIALS);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/credential/view/:id',
            name: Routes.CREDENTIAL_VIEW,
            component: CredentialView,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.CREDENTIALS);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/credential/edit/:id',
            name: Routes.CREDENTIAL_EDIT,
            component: CredentialEdit,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.CREDENTIALS);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * GALLERY MODULE
         *************************/
        {
            path: '/galleries',
            component: RouterView,
            children: [
                {
                    path: '/',
                    name: Routes.GALLERY_LIST,
                    component: GalleryList,
                },
                {
                    path: 'item/:id',
                    name: Routes.GALLERY,
                    component: Gallery,
                    beforeEnter: (to, from, next) => {
                        store.commit('setGalleryId', to.params.id);
                        store.dispatch('flagGalleryModuleUpdated');
                        next();
                    },
                },
                {
                    path: 'add',
                    name: Routes.GALLERY_ADD,
                    component: GalleryEdit,
                    beforeEnter: (to, from, next) => {
                        store.commit('setGalleryId', '');
                        store.dispatch('flagGalleryModuleUpdated');
                        next();
                    },
                },
                {
                    path: 'item/edit/:id',
                    name: Routes.GALLERY_EDIT,
                    component: GalleryEdit,
                    beforeEnter: (to, from, next) => {
                        store.commit('setGalleryId', to.params.id);
                        store.dispatch('flagGalleryModuleUpdated');
                        next();
                    },
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    // If the user tries to access a module that is not enabled, log them out.
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.GALLERIES)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.GALLERIES);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/login',
            name: Routes.LOGIN,
            component: Login,
            beforeEnter: (to, from, next) => {
                next();
            },
        },
        {
            path: '/logout',
            name: Routes.LOGOUT,
            component: Logout,
        },
        {
            path: '/password_reset',
            name: Routes.PASSWORD_RESET,
            component: PasswordReset,
        },
        {
            path: '/' + Routes.REQUEST_DISABLE_TWO_FACTOR_AUTH,
            name: Routes.REQUEST_DISABLE_TWO_FACTOR_AUTH,
            component: RequestDisableTwoFactorAuth,
        },
        /**************************
         * NOTES MODULE
         *************************/
        {
            path: '/notes',
            name: Routes.NOTE_LIST,
            component: NoteList,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.NOTES)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.NOTES);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/notes/item/:id',
            name: Routes.NOTE,
            component: Note,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setNoteId', to.params.id);
                    store.commit('setModuleType', ModuleType.NOTES);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/notes/add',
            name: Routes.NOTE_ADD,
            component: NoteEdit,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setNoteId', '');
                    store.commit('setModuleType', ModuleType.NOTES);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/item/edit/:id',
            name: Routes.NOTE_EDIT,
            component: NoteEdit,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setNoteId', to.params.id);
                    store.commit('setModuleType', ModuleType.NOTES);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * KANBAN MODULE
         *************************/
        {
            path: '/kanban_board',
            name: Routes.KANBAN_BOARD,
            component: Kanban,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.KANBAN)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.KANBAN);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * RECIPES MODULE
         **************************/
        {
            path: '/recipes',
            component: RouterView,
            children: [
                {
                    path: '/',
                    name: Routes.RECIPE_LIST,
                    component: RecipeList,
                },
                {
                    path: 'item/:id',
                    name: Routes.RECIPE,
                    component: Recipe,
                    beforeEnter: (to, from, next) => {
                        store.commit('setRecipeId', to.params.id);
                        next();
                    },
                },
                {
                    path: 'add',
                    name: Routes.RECIPE_ADD,
                    component: RecipeEdit,
                    beforeEnter: (to, from, next) => {
                        store.commit('setRecipeId', '');
                        store.dispatch('flagAdminUserModuleUpdated');
                        next();
                    },
                },
                {
                    path: 'item/edit/:id',
                    name: Routes.RECIPE_EDIT,
                    component: RecipeEdit,
                    beforeEnter: (to, from, next) => {
                        store.commit('setRecipeId', to.params.id);
                        store.dispatch('flagAdminUserModuleUpdated');
                        next();
                    },
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    if (!getSettingsHelper().moduleEnabledForCurrentSpace(ModuleType.RECIPES)) {
                        next({name: Routes.HOME});
                    } else {
                        store.commit('setModuleType', ModuleType.RECIPES);
                        next();
                    }
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        {
            path: '/register',
            name: Routes.REGISTER,
            component: Register,
        },
        {
            path: '/reload',
            name: Routes.RELOAD,
            component: Reload,
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn) {
                    store.commit('setModuleType', ModuleType.RELOAD);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
        /**************************
         * ADMIN MODULE
         *************************/
        {
            path: '/admin',
            component: RouterView,
            children: [
                {
                    path: '/',
                    name: Routes.ADMIN,
                    component: Admin,
                },
                {
                    path: 'spaces',
                    name: Routes.ADMIN_SPACES,
                    component: SpacesAdmin,
                },
                {
                    path: 'note_categories',
                    name: Routes.ADMIN_NOTE_CATEGORIES,
                    component: NoteCategoryAdmin,
                },
                {
                    path: 'recipe_categories',
                    name: Routes.ADMIN_RECIPE_CATEGORIES,
                    component: RecipeCategoryAdmin,
                },
                {
                    path: 'kanban',
                    name: Routes.ADMIN_KANBAN,
                    component: KanbanAdmin,
                },
                {
                    path: 'users',
                    name: Routes.USERS,
                    component: Users,
                },
                {
                    path: 'users/add',
                    name: Routes.USER_ADD,
                    component: User,
                    beforeEnter: (to, from, next) => {
                        store.commit('setAdminUserId', '');
                        next();
                    },
                },
                {
                    path: 'users/user/:id',
                    name: Routes.USER,
                    component: User,
                    beforeEnter: (to, from, next) => {
                        store.commit('setAdminUserId', to.params.id);
                        store.commit('setIsViewingProfilePage', false);
                        next();
                    },
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.state.userModule.userLoggedIn && store.state.userModule.isAdmin) {
                    store.commit('setModuleType', ModuleType.ADMIN);
                    next();
                } else {
                    next({name: Routes.LOGIN});
                }
            },
        },
    ],
});
