import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import { KanbanTaskItem } from './KanbanTaskItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export class KanbanTaskRepo extends AbstractRepo<KanbanTaskItem> {
    public static indexDbStoreName = 'kanbanTasks';

    public constructor(db: IDBDatabase) {
        super(db, KanbanTaskRepo.indexDbStoreName);
    }

    public async getList(
        spaceId: string,
        columnNo: number = 0,
        search: string = '',
    ): Promise<KanbanTaskItem[]> {
        return new Promise<KanbanTaskItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: KanbanTaskItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        let pass = true;

                        if ((pass) && (columnNo > 0)) {
                            pass = item.column_no === columnNo;
                        }

                        if (pass) {
                            if (search === '') {
                                items.push(item);
                            } else {
                                const foundInTitle = StringHelper.searchInText(searchKeywords, item.title);
                                let foundInBody = false;

                                if (!foundInTitle) {
                                    foundInBody = StringHelper.searchInText(searchKeywords, item.description);
                                }

                                if (foundInTitle || foundInBody) {
                                    items.push(item);
                                }
                            }
                        }
                    }

                    cursor.continue();
                } else {
                    // Kanban items should always be returned in sequence order.
                    items.sort((a: KanbanTaskItem, b: KanbanTaskItem) => {
                        if (a.sequence_no < b.sequence_no) {
                            return - 1;
                        } else if (a.sequence_no > b.sequence_no) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    /*
     * Loads a list of all completed tasks from IndexDB storage.
     */
    public async getCompletedTasks(): Promise<KanbanTaskItem[]> {
        return new Promise<KanbanTaskItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
            objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('column_no');
            request = index.openCursor(IDBKeyRange.only(8));

            const items: KanbanTaskItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;
                    items.push(item);
                    cursor.continue();
                } else {
                    items.sort((a: KanbanTaskItem, b: KanbanTaskItem) => {
                        if (a.updated_dtm < b.updated_dtm) {
                            return - 1;
                        } else if (a.updated_dtm > b.updated_dtm) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load completed ${this.getStoreName()}!`));
            };
        });
    }

    public getEmptyItem(spaceId: string, userId: string): KanbanTaskItem {
        const now = new Date();

        return {
            id: '',
            space_id: spaceId,
            created_by_user_id: userId,
            title: '',
            description: '',
            shared: false,
            column_no: 1,
            sequence_no: 2,
            created_dtm: now.getTime(),
            updated_dtm: now.getTime(),
            completed_dtm: null,
        };
    }
}
