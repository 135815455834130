
import { Component, Vue, Prop } from 'vue-property-decorator';
import {TabItem} from '@/domains/ui/views/Tabs/TabItem';

@Component({
    components: {

    },
})
export default class Tabs extends Vue {
    @Prop({required: true})
    private tabItems!: TabItem[];

    @Prop({required: false, default: ''})
    private selectedTabId!: string;

    @Prop({required: false, type: Boolean, default: false})
    private showTabsAtBottom!: boolean;

    public broadcastTabSelectionIntent(tabId: string) {
        this.$emit('tab-selected', tabId);
    }

    public tabEnabled(tabNumber: number): boolean {
        if (this.tabItems.length < tabNumber) {
            return false;
        }

        return this.tabItems[tabNumber - 1].id === this.selectedTabId;
    }
}
