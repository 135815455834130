
    import {Component, Vue} from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import Form from '@/domains/ui/views/Form.vue';
    import InputChecker from '@/domains/ui/views/InputChecker.vue';
    import Trilean from '@/ts/Trilean';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    import RepoManager from '@/ts/Database/RepoManager';
    import StringHelper from '@/ts/Helpers/StringHelper';

    @Component({
        components: {
            SpinnerButton,
            InputChecker,
            Form,
            Notification,
            ErrorMessage,
            IncludeIf,
        },
    })
    export default class VaultPasswordNotSet extends Vue {
        public errorMessage: string = '';
        public vaultPassword: string = '';
        public totpCodeRequired = false;
        public vaultPasswordOK = Trilean.Unknown;
        public vaultPasswordRepeated: string = '';
        public vaultPasswordRepeatedOK = Trilean.Unknown;
        public totpCode = '';
        public totpCodeOk = Trilean.Unknown;

        private $repoManager!: RepoManager;
        private saving = false;
        private readonly minimumPasswordLength = 10;

        public mounted(): void {
            if (this.$repoManager.credential) {
                this.loadDataFromRepos();
            } else {
                this.onReposReady();
            }
        }

        /**
         * Fires when all repos have been initialised
         */
        public async onReposReady(): Promise<void> {
            document.addEventListener('reposReady', async () => {
                this.loadDataFromRepos();
            }, false);
        }

        public checkVaultPasswordOK(): void {
            if ((this.vaultPassword.length >= this.minimumPasswordLength)
                && (StringHelper.isStrongPassword(this.vaultPassword))) {
                this.vaultPasswordOK = Trilean.True;
            } else {
                this.vaultPasswordOK = Trilean.False;
            }
        }

        public checkVaultPasswordRepeatedOK(): void {
            if ((this.vaultPasswordRepeated.length >= this.minimumPasswordLength) &&
                (this.vaultPasswordRepeated === this.vaultPassword)) {
                this.vaultPasswordRepeatedOK = Trilean.True;
            } else {
                this.vaultPasswordRepeatedOK = Trilean.False;
            }
        }

        public checkTotpCodeOk() {
            this.errorMessage = '';

            if (this.totpCodeWithoutSpaces.length === 6) {
                this.totpCodeOk = Trilean.True;
            } else {
                this.totpCodeOk = Trilean.False;
            }
        }

        public async handleSaveVaultPassword(): Promise<void> {
            if (!this.formValid) {
                return;
            }

            this.errorMessage = '';
            this.saving = true;

            try {
                await this.$repoManager.credential.setVaultPassword(this.vaultPassword, this.totpCodeWithoutSpaces);
                this.$emit('vault-password-set', true);
            } catch (error) {
                this.errorMessage = error.toString();
            } finally {
                this.saving = false;
            }
        }

        private get totpCodeWithoutSpaces(): string {
            return StringHelper.removeSpaces(this.totpCode);
        }

        private get formValid(): boolean {
            return ((this.vaultPasswordRepeatedOK === Trilean.True) &&
                ((!this.totpCodeRequired) || (this.totpCodeWithoutSpaces.length === 6)));
        }

        private async loadDataFromRepos(): Promise<void> {
            const twoFactorAuthItem = await this.$repoManager.user.getCurrentTwoFactorAuthMethodFromCache();
            if ((twoFactorAuthItem === null) || (!twoFactorAuthItem.enabled)) {
                this.totpCodeRequired = false;
                return;
            }

            if (twoFactorAuthItem.two_factor_auth_type !== 'GoogleAuthenticator') {
                this.totpCodeRequired = false;
                throw new Error(
                    `GetVaultAccess - Unhandled two factor auth type: ${twoFactorAuthItem.two_factor_auth_type}`,
                );
            }

            this.totpCodeRequired = true;
        }
    }
