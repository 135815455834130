import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import MessageItem from '../../messages/database/messages/MessageItem';

@Module
export default class ContactModule extends VuexModule {
    public contactId: string = '';
    public searchKeywords: string = '';
    public hasUnreadMessages: boolean = false;
    public requiresResponse: boolean = false;
    public contactIsFavourite: boolean = false;
    public showSharedOnly: boolean = false;
    public lastUpdated: number = 0;
    public mostRecentMessage: MessageItem|null = null;

    @Mutation
    public setContactId(value: string) {
        this.contactId = value;
    }

    @Mutation
    public setContactSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setContactModuleLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Mutation
    public setContactHasUnreadMessages(value: boolean) {
        this.hasUnreadMessages = value;
    }

    @Mutation
    public setContactRequiresResponse(value: boolean) {
        this.requiresResponse = value;
    }

    @Mutation
    public setContactIsFavourite(value: boolean) {
        this.contactIsFavourite = value;
    }

    @Mutation
    public setContactShowSharedOnly(value: boolean) {
        this.showSharedOnly = value;
    }

    @Mutation
    public setMostRecentMessage(value: MessageItem|null) {
        this.mostRecentMessage = value;
    }

    @Action
    public clearContactModuleState() {
        this.context.commit('setContactId', '');
        this.context.commit('setContactSearchKeywords', '');
        this.context.commit('setContactHasUnreadMessages', false);
        this.context.commit('setContactRequiresResponse', false);
        this.context.commit('setContactIsFavourite', false);
        this.context.commit('setContactShowSharedOnly', false);
        this.context.commit('setMostRecentMessage', null);

        this.context.dispatch('flagContactModuleUpdated');
    }

    @Action
    public flagContactModuleUpdated() {
        this.context.commit('setContactModuleLastUpdated', (new Date()).getTime());
    }
}
