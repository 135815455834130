import { AbstractRequestFactory } from '../../../../ts/Requests/AbstractRequestFactory';
import { CreateKanbanTaskRequest } from './CreateKanbanTaskRequest';
import { DeleteKanbanTaskRequest } from './DeleteKanbanTaskRequest';
import { KanbanTaskListRequest } from './KanbanTaskListRequest';
import { UpdateKanbanTaskRequest } from './UpdateKanbanTaskRequest';

export class KanbanTasksRequestFactory extends AbstractRequestFactory {
    public get createKanbanTaskRequest(): CreateKanbanTaskRequest {
        return new CreateKanbanTaskRequest(this.apiClient);
    }

    public get deleteKanbanTaskRequest(): DeleteKanbanTaskRequest {
        return new DeleteKanbanTaskRequest(this.apiClient);
    }

    public get kanbanTaskListRequest(): KanbanTaskListRequest {
        return new KanbanTaskListRequest(this.apiClient);
    }

    public get updateKanbanTaskRequest(): UpdateKanbanTaskRequest {
        return new UpdateKanbanTaskRequest(this.apiClient);
    }
}
