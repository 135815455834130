import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';

export class UserTwoFactorAuthListPublicRequest extends AbstractRequest {
    public async execute(emailAddress: string): Promise<UserTwoFactorAuthItem[]> {
        return new Promise<UserTwoFactorAuthItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/user/two_factor_auth_list`, {
                    email_address: emailAddress,
                });

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('two_factor_authentication_options')))  {
                    reject(Error('UserTwoFactorAuthListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.two_factor_authentication_options);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load two factor auth methods from the API'));
            }
        });
    }
}
