import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class DeleteFileRequest extends AbstractRequest {
    public async execute(fileId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/file/${fileId}`, {});

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DeleteFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a file failed.  Please try again later'));
            }
        });
    }
}
