
import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from '@/domains/ui/views/Modal.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import SpaceItem from '@/domains/spaces/database/SpaceItem';
import RepoManager from '@/ts/Database/RepoManager';
import {MoveEntityIntentPayload} from '@/domains/spaces/interfaces/MoveEntityIntentPayload';

@Component({
  components: {
      Modal,
      SpinnerButton,
  },
})
export default class DeleteConfirmationModal extends Vue {
    @Prop({required: true}) private entityId!: string;
    @Prop({required: true}) private entityType!: string;
    @Prop({required: true}) private entityName!: string;
    @Prop({required: true}) private modalOpen!: boolean;
    @Prop({required: true}) private moveInProgress!: boolean;

    private $repoManager!: RepoManager;

    private spaces: SpaceItem[] = [];
    private newSpaceId: string = '';

    private async mounted(): Promise<void> {
        this.newSpaceId = '';
        const currentSpaceId = this.$store.state.spaceModule.selectedSpace;
        const allSpaces = await this.$repoManager.space.getList();

        // Filter out the currently selected space - as obviously there's no point showing it in
        // the list of possible spaced to move the entity to.
        this.spaces = allSpaces.filter((spaceItem) => {
           return spaceItem.id !== currentSpaceId;
        });
    }

    public handleCloseModalIntent(): void {
        this.$emit('close-modal-intent', {});
    }

    public handleMoveEntity(): void {
        if (this.newSpaceId === '') {
            return;
        }

        this.$emit('move-entity-intent', {
            entityId: this.entityId,
            newSpaceId: this.newSpaceId,
        } as MoveEntityIntentPayload);
    }

    get buttonText(): string {
        return 'Move ' + this.entityType;
    }
}
