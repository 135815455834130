import ContactMessageSummaryItem from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryItem';

export default class ContactMessageSummaryRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: ContactMessageSummaryItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: ContactMessageSummaryItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.contact_id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(contactId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(contactId);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(contactId: string): Promise<ContactMessageSummaryItem> {
        return new Promise<ContactMessageSummaryItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(contactId);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error(`Unable to load ${this.storeName} item with id: ${contactId}`));
                }
            };

            request.onerror = () => {
                reject(new Error(`Unable to load ${this.storeName} item with id: ${contactId}`));
            };
        });
    }

    public async getList(spaceId: string): Promise<ContactMessageSummaryItem[]> {
        return new Promise<ContactMessageSummaryItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: ContactMessageSummaryItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        items.push(item);
                    }

                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: ContactMessageSummaryItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.contact_id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item with id '${item.contact_id}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptyContactMessageSummaryItem(): ContactMessageSummaryItem {
        return {
            contact_id: '',
            space_id: '',
            total_messages: 0,
            not_read: 0,
            requires_response: 0,
        };
    }

    private get storeName(): string {
        return 'contactMessageSummaryItems';
    }
}
