import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { CredentialItem } from '@/domains/credentials/database/CredentialItem';

export interface CreateCredentialRequestParams {
    space_id: string;
    vault_access_id: string;
    token_part_a: string;
    title: string;
    url: string;
    email_address: string;
    username: string;
    password: string;
    notes: string;
}

export class CreateCredentialRequest extends AbstractRequest {
    public async execute(params: CreateCredentialRequestParams): Promise<CredentialItem> {
        return new Promise<CredentialItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/credential`, params);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('credential'))
                )  {
                    reject(Error('CreateCredentialRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.credential);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to create a new credential failed.  Please try again later'));
            }
        });
    }
}
