
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
    import RequestFactory from '@/ts/Requests/RequestFactory';
    import Spinner from '@/domains/ui/views/Spinner.vue';
    import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
    import {
        ImportCredentialsRequestParams,
        ImportCredentialsResponse,
    } from '@/domains/credentials/requests/ImportCredentialRequest';
    import RepoManager from '@/ts/Database/RepoManager';

    @Component({
        components: {
            BasicButton,
            Spinner,
            ErrorMessage,
        },
    })
    export default class CredentialImport extends Vue {
        @Prop({ required: true, type: String })
        private tokenPartA!: string;

        @Prop({ required: true, type: String })
        private vaultAccessId!: string;

        @Prop({ required: true, type: String })
        private spaceId!: string;

        private $requestFactory!: RequestFactory;
        private $repoManager!: RepoManager;

        private filesToUpload = [];
        private fileUploading = false;

        public errorMessage = '';
        public importCredentialsResponse: ImportCredentialsResponse|null = null;

        public mounted() {
            this.filesToUpload = [];
            this.fileUploading = false;
        }

        public async uploadFiles(): Promise<void> {
            const uploadFiles: any = this.$refs.filesToUpload;
            this.filesToUpload = uploadFiles.files;

            if (this.filesToUpload.length > 0) {
                this.uploadNextFile(1);
            } else {
                this.errorMessage = 'Please select a file to upload';
            }
        }

        public broadcastCancelImportIntent(): void {
            this.$emit('cancel-import');
        }

        private async uploadNextFile(fileNumber: number) {
            const formData = new FormData();
            formData.append('file', this.filesToUpload[fileNumber - 1]);

            try {
                const uploadFileParams: UploadFileParams = {
                    formData,
                };

                this.fileUploading = true;

                const fileItem = await this.$requestFactory.uploadFileRequest.execute(uploadFileParams);

                this.importCredentialsResponse =
                    await this.$repoManager.credential.importCredentialsFromFile(
                        this.spaceId,
                        this.vaultAccessId,
                        this.tokenPartA,
                        fileItem.id,
                    );

                if (this.importCredentialsResponse.num_lines_successfully_imported > 0) {
                    const credentials = await this.$repoManager.credential.requestListCredentials();

                    await this.$repoManager.credential.saveAll(credentials);

                    this.$emit(
                        'credentials-imported',
                        this.importCredentialsResponse.num_lines_successfully_imported,
                    );
                }

                this.filesToUpload = [];
            } catch (error) {
                this.errorMessage = 'Error uploading file: ' + error.toString();
            } finally {
                this.fileUploading = false;
            }
        }
    }
