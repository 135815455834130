import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { KanbanTaskItem } from '@/domains/kanban/database/kanbanTasks/KanbanTaskItem';

export interface UpdateKanbanTaskParams {
    space_id: string;
    title: string;
    description: string;
    column_no: number;
    sequence_no: number;
    shared: boolean;
}

export class UpdateKanbanTaskRequest extends AbstractRequest {
    public async execute(kanbanTaskId: string, params: UpdateKanbanTaskParams): Promise<KanbanTaskItem> {
        return new Promise<KanbanTaskItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/kanban_task/${kanbanTaskId}`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('kanban_task')))  {
                    reject(Error('UpdateKanbanTaskRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.kanban_task);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a kanban task failed.  Please try again later'));
            }
        });
    }
}
