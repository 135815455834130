
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { SpaceItem } from '@/domains/spaces/database/SpaceItem';
import RepoManager from '@/ts/Database/RepoManager';
import { ModuleType } from '@/ts/Enums/ModuleType';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import {Routes} from '@/domains/app/router/router';

@Component({
    components: {},
})
export default class Navigation extends Vue {
    public menuOpen: boolean = false;
    public selectedSpace = '';
    private $repoManager!: RepoManager;
    private $helperFactory!: HelperFactory;

    public async created(): Promise<void> {
        if (this.$repoManager.space) {
            await this.loadResources();
        } else {
            await this.onReposReady();
        }
    }

    public async setSelectedSpace(): Promise<void> {
        if (!this.isLoggedIn) {
            return;
        }

        if (this.selectedSpace === '') {
            this.selectedSpace = this.selectedSpaceInStore;
            await this.$emit('add-space-intent', {});

            await this.loadModulesForSelectedSpace();
        } else {
            await this.$store.commit('setSelectedSpace', this.selectedSpace);
            await this.$store.commit('setNoteCategoryId', '');

            await this.loadModulesForSelectedSpace();

            try {
                await this.$router.push({name: Routes.HOME});
            } catch (error) {
                // For some reason an error can occur here due to a redirection happening.  Do nothing.
            }
        }
    }

    private async loadModulesForSelectedSpace(): Promise<void> {
        if (this.selectedSpace === '') {
            await this.$store.commit('setEnabledModulesForSpace', []);
        } else {
            const enabledModules = await this.$repoManager.moduleSpace.getList(this.selectedSpace);
            await this.$store.commit('setEnabledModulesForSpace', enabledModules);
        }
    }

    get appName(): string {
        return this.$store.state.settingsModule.appSettings.appName;
    }

    get selectedModule(): ModuleType {
        return this.$store.state.settingsModule.moduleType;
    }

    get isLoggedIn(): boolean {
        return this.$store.state.userModule.userLoggedIn;
    }

    get syncFinished(): boolean {
        return this.$store.state.userModule.syncFinished;
    }

    get isSocketConnected(): boolean {
        return this.$store.state.userModule.socketConnected;
    }

    get isAdmin(): boolean {
        return this.$store.state.userModule.isAdmin;
    }

    get lastSyncTimestamp(): number {
        return this.$store.state.userModule.lastSyncTimestamp;
    }

    get spaces(): SpaceItem[] {
        return this.$store.state.spaceModule.spaces;
    }

    get selectedSpaceInStore(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    get lastUpdatedTimestamp(): number {
        return this.$store.state.spaceModule.lastUpdatedTimestamp;
    }

    public dispatchStoreAction(actionName: string) {
        this.menuOpen = false;
        this.$store.dispatch(actionName);
    }

    public get isAssistantModuleEnabled(): boolean {
        return this.isSelectedModule(ModuleType.ASSISTANT);
    }

    public get isContactModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.CONTACTS);
    }

    public get isNotesModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.NOTES);
    }

    public get isGalleriesModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.GALLERIES);
    }

    public get isRecipesModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.RECIPES);
    }

    public get isKanbanModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.KANBAN);
    }

    public get isCredentialsModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.CREDENTIALS);
    }

    public get isProfileModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.PROFILE);
    }

    public get isAdminModuleActive(): boolean {
        return this.isSelectedModule(ModuleType.ADMIN);
    }

    private isSelectedModule(moduleName: string): boolean {
        return moduleName === this.selectedModule;
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadResources();
        }, false);
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.space) {
            this.loadResources();
        }
    }

    @Watch('lastUpdatedTimestamp')
    private onSpaceUpdated(): void {
        this.loadResources();
    }

    private async loadResources(): Promise<void> {
        const spaces = await this.$repoManager.space.getList();

        spaces.push({
            id: '',
            created_by_user_id: '',
            name: 'Add New Space',
            shared: false,
            created_dtm: 0,
            updated_dtm: 0,
        });

        await this.$store.commit('setSpaces', spaces);

        if (this.spaces.length > 0) {
            if (this.selectedSpaceInStore === '') {
                this.selectedSpace = this.spaces[0].id;
                await this.setSelectedSpace();
            } else {
                if (this.spaceExists(this.selectedSpaceInStore)) {
                    this.selectedSpace = this.selectedSpaceInStore;
                } else {
                    // Set the selected space to the first available.
                    await this.$store.commit('setSelectedSpace', this.spaces[0].id);
                }
            }
        }

        await this.loadModulesForSelectedSpace();
    }

    private spaceExists(spaceId: string): boolean {
        for (const space of this.spaces) {
            if (space.id === spaceId) {
                return true;
            }
        }

        return false;
    }

    private moduleEnabled(moduleTypeString: string): boolean {
        const settingsHelper = this.$helperFactory.settingsHelper;
        return settingsHelper.moduleEnabledForCurrentSpace(moduleTypeString);
    }
}
