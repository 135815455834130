import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AdminUserModule } from '@/domains/admin/store/AdminUserModule';
import { CompletionStore } from '@/domains/assistant/store/CompletionStore';
import ContactModule from '@/domains/contacts/store/ContactModule';
import { CredentialStore } from '@/domains/credentials/store/CredentialStore';
import GalleryModule from '@/domains/galleries/store/GalleryModule';
import KanbanModule from '@/domains/kanban/store/KanbanModule';
import NoteModule from '@/domains/notes/store/NoteModule';
import RecipeModule from '@/domains/recipies/store/RecipeModule';
import SettingsModule from '../../admin/store/SettingsModule';
import SpaceModule from '../../spaces/store/SpaceModule';
import UserModule from '@/domains/users/store/UserModule';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        adminUserModule: AdminUserModule,
        completionStore: CompletionStore,
        contactModule: ContactModule,
        credentialStore: CredentialStore,
        kanbanModule: KanbanModule,
        noteModule: NoteModule,
        recipeModule: RecipeModule,
        settingsModule: SettingsModule,
        spaceModule: SpaceModule,
        userModule: UserModule,
        galleryModule: GalleryModule,
    },
    plugins: [createPersistedState()],
});
