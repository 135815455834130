
    import {Component, Vue, Prop} from 'vue-property-decorator';

    import { SidebarWidth } from '@/ts/Enums/SidebarWidth';

    @Component({
        components: {},
    })
    export default class Container extends Vue {
        @Prop({required: false}) private hideSidebar!: boolean;
        @Prop({required: false}) private sidebarWidth!: SidebarWidth;

        public get width(): string {
            if (this.sidebarWidth) {
                return this.sidebarWidth + 'px';
            }

            return SidebarWidth.NORMAL + 'px';
        }
    }
