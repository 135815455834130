
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Grid from '@/domains/app/views/Grid.vue';
import GridItem from '@/domains/app/views/GridItem.vue';

import {Routes} from '@/domains/app/router/router';
import StringHash from '@/ts/StringHash';
import ApiClient from '@/ts/ApiClient';
import RecipeItem from '@/domains/recipies/database/recipies/RecipeItem';
import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';
import UserItem from '@/domains/users/database/users/UserItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Grid,
      GridItem,
  },
})
export default class Recipes extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private loaded: boolean = false;
    private waitingForAddRecipeCategoryRequest: boolean = false;
    private recipes: RecipeItem[] = [];
    private recipeCategories: RecipeCategoryItem[] = [];
    private users: UserItem[] = [];
    private errorMessage: string = '';
    private addCategoryValidationError: boolean = false;
    private recipeCategorySpaceMap!: { [key: string]: boolean };

    private categoryNameOk: Trilean = Trilean.Unknown;

    private addingNewCategory: boolean = false;
    private newCategoryName: string = '';
    private categoryNameMap: StringHash = {};
    private userDisplayNameMap: StringHash = {};
    private categoryJustAdded: boolean = false;

    private recipeImageMap: {[key: string]: string} = {};

    private mounted(): void {
        this.categoryJustAdded = false;
        this.recipeImageMap = {};
        this.recipeCategorySpaceMap = {};

        if (this.$repoManager.recipeCategory) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private get lastUpdated(): string {
        return this.$store.state.recipeModule.lastUpdated;
    }

    @Watch('lastUpdated')
    private async loadDataFromRepos(): Promise<void> {
        await this.loadRecipeCategories();
        await this.loadUsers();
        await this.loadRecipesAndImages();
        this.loaded = true;
    }

    private async loadRecipeCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            this.recipeCategorySpaceMap = {};

            const items = await this.$repoManager.recipeCategory.getList(this.spaceId);

            this.categoryNameMap = {};
            items.forEach((category) => {
                this.categoryNameMap[category.id] = category.name;
                this.recipeCategorySpaceMap[category.id] = true;
            });

            this.recipeCategories = items;
        } catch (error) {
            this.errorMessage = 'Error: Recipe categories could not be loaded.';
        }
    }

    @Watch('recipeCategoryId')
    @Watch('searchKeywords')
    private async loadRecipesAndImages(): Promise<void> {
        try {
            this.errorMessage = '';

            const recipes = await this.$repoManager.recipe.getList(
                this.recipeCategoryId,
                this.recipeCategorySpaceMap,
                this.searchKeywords,
            );

            for (const recipeIndex in recipes) {
                if (recipes.hasOwnProperty(recipeIndex)) {
                    const recipe = recipes[recipeIndex];
                    const photoKey = recipe.hero_image_file_id;

                    try {
                        const file = await this.$repoManager.file.getItem(photoKey);
                        if (file.additional_files.length > 0) {
                            this.recipeImageMap[photoKey] = file.additional_files[0];
                        }
                    } catch (error) {
                        // Ignore any Unable to load file errors.  The file object must be gone.
                        if (error.toString().indexOf('Unable to load file') < 0) {
                            this.errorMessage = error.toString();
                            break;
                        }
                    }
                }
            }

            this.recipes = recipes;
        } catch (error) {
            this.errorMessage = 'Error: Recipes could not be loaded.';
        }
    }

    private async loadUsers(): Promise<void> {
        try {
            this.errorMessage = '';
            const items = await this.$repoManager.user.getList();

            this.userDisplayNameMap = {};
            items.forEach((user) => {
                this.userDisplayNameMap[user.id] = user.display_name;
            });

            this.users = items;
        } catch (error) {
            this.errorMessage = 'Error: Users could not be loaded.';
        }
    }

    private async addCategory(): Promise<void> {
        if (this.categoryNameOk !== Trilean.True) {
            this.addCategoryValidationError = true;
            return;
        }

        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddRecipeCategoryRequest = true;
            const recipeCategoryItem = await this.$requestFactory.addRecipeCategoryRequest.execute({
                space_id: this.spaceId,
                name: this.newCategoryName,
            });

            // Save the recipe category in the local repo
            await this.$repoManager.recipeCategory.save(recipeCategoryItem);

            // Reset recipe category input text field
            this.newCategoryName = '';

            // Reload recipe categories from the repo
            await this.loadRecipeCategories();

            // Reset flags
            this.waitingForAddRecipeCategoryRequest = false;
            this.addingNewCategory = false;

            // Flag that a category was just added to prevent the Delete Category button immediately showing.
            this.categoryJustAdded = true;

            this.setRecipeCategoryId(recipeCategoryItem.id);
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the recipe category';
        }
    }

    private async deleteRecipeCategory(): Promise<void> {
        if (this.recipeCategoryId.length === 0) {
            return;
        }

        try {
            const selectedRecipeCategoryItem = this.recipeCategoryItem;

            const ok = await this.$requestFactory.deleteRecipeCategoryRequest.execute(this.recipeCategoryId);
            if (!ok) {
                this.errorMessage = 'The category could not be deleted.  Please try again later.';
            }

            await this.$repoManager.recipeCategory.delete(selectedRecipeCategoryItem);

            this.recipeCategoryId = '';

            await this.loadRecipeCategories();
            await this.loadRecipesAndImages();
        } catch (error) {
            this.errorMessage = 'The category could not be deleted.  Please try again later.';
        }
    }

    private showRecipe(recipeId: string): void {
        this.$router.push({name: Routes.RECIPE, params: {id: recipeId}});
    }

    private cancelAddCategory(): void {
        this.newCategoryName = '';
        this.addingNewCategory = false;
        this.addCategoryValidationError = false;
    }

    private setRecipeCategoryId(categoryId: string): void {
        this.recipeCategoryId = categoryId;
        this.loadRecipesAndImages();
    }

    private clearSelectedRecipeCategory(): void {
        this.recipeCategoryId = '';
        this.loadRecipesAndImages();
    }

    private getRecipeCategoryName(id: string) {
        if (this.categoryNameMap.hasOwnProperty(id)) {
            return this.categoryNameMap[id];
        } else {
            return '';
        }
    }

    private getUserDisplayName(id: string) {
        if (this.userDisplayNameMap.hasOwnProperty(id)) {
            return this.userDisplayNameMap[id];
        } else {
            return '';
        }
    }

    private timestampToDate(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    private addRecipe(): void {
        this.$router.push({name: Routes.RECIPE_ADD});
    }

    private checkCategoryNameOk(): void {
        if ((this.newCategoryName.length >= 2) && (this.newCategoryName.length <= 50)) {
            this.categoryNameOk = Trilean.True;
            this.addCategoryValidationError = false;
        } else {
            this.categoryNameOk = Trilean.False;
        }
    }

    private userWantsToAddNewCategory(): void {
        this.addingNewCategory = !this.addingNewCategory;
        this.newCategoryName = '';
        this.categoryNameOk = Trilean.Unknown;
        this.addCategoryValidationError = false;
    }

    // Getters and settings
    get recipeCategoryId(): string {
        return this.$store.state.recipeModule.recipeCategoryId;
    }

    set recipeCategoryId(value: string) {
        this.$store.commit('setRecipeCategoryId', value);
    }

    get searchKeywords(): string {
        return this.$store.state.recipeModule.recipeSearchKeywords;
    }

    set searchKeywords(recipeSearchKeywords: string) {
        this.$store.commit('setRecipeSearch', recipeSearchKeywords);
    }

    get recipeCategoryItem(): RecipeCategoryItem {
        const selectedCategoryId = this.recipeCategoryId;

        for (const index in this.recipeCategories) {
            if (this.recipeCategories.hasOwnProperty(index)) {
                const recipeCategoryItem = this.recipeCategories[index];

                if (recipeCategoryItem.id === selectedCategoryId) {
                    return recipeCategoryItem;
                }
            }
        }

        throw new Error('Unable to retrieve the recipe category item');
    }

    private getPhotoUrl(heroImageFileId: string) {
        if (!this.recipeImageMap.hasOwnProperty(heroImageFileId)) {
            return '';
        }

        return this.recipeImageMap[heroImageFileId];
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    // If the user has selected a different workspace, refresh the notes.
    @Watch('spaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.recipeCategoryId = '';
            this.loadDataFromRepos();
        }
    }

    @Watch('lastChangeLogId')
    private onChangeLogUpdated(): void {
        this.loadDataFromRepos();
    }
}
