import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { GalleryFileItem } from '@/domains/galleries/database/galleryFiles/GalleryFileItem';

export interface CreateGalleryFileParams {
    gallery_id: string;
    file_id: string;
}

export class CreateGalleryFileRequest extends AbstractRequest {
    public async execute(params: CreateGalleryFileParams): Promise<GalleryFileItem> {
        return new Promise<GalleryFileItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/gallery_files`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('gallery_file')))  {
                    reject(Error('CreateGalleryFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.gallery_file);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to create a gallery file failed.  Please try again later'));
            }
        });
    }
}
