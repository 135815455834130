import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export class DeleteModuleSpaceRequest extends AbstractRequest {
    public async execute(moduleSpaceId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/module_spaces/${moduleSpaceId}`, {});

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('status')))  {
                    reject(Error('DeleteModuleSpaceRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a module from a space failed.  Please try again later'));
            }
        });
    }
}
