import {ModuleType} from '@/ts/Enums/ModuleType';
import {ModuleSpaceItem} from '@/domains/module/database/moduleSpace/ModuleSpaceItem';
import {Routes} from '@/domains/app/router/router';

export default class SettingsHelper {
    private baseUrl: string;
    private vueStore: any;

    constructor(baseUrl: string, vueStore: any) {
        this.baseUrl = baseUrl;
        this.vueStore = vueStore;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public moduleEnabledForCurrentSpace(moduleTypeString: string): boolean {
        const enabledModules = this.vueStore.state.settingsModule.enabledModulesForSpace as ModuleSpaceItem[];

        if (enabledModules.length === 0) {
            return false;
        }

        const moduleType = moduleTypeString as ModuleType;

        for (const moduleSpaceItem of enabledModules) {
            if (moduleSpaceItem.module_type === moduleType) {
                return true;
            }
        }

        return false;
    }

    public getFirstEnabledModuleForCurrentSpace(): ModuleType|null {
        const enabledModules = this.vueStore.state.settingsModule.enabledModulesForSpace as ModuleSpaceItem[];

        if (enabledModules.length === 0) {
            return null;
        }

        return enabledModules[0].module_type;
    }

    public getRouteForFirstEnabledModuleForCurrentSpace(): Routes {
        const firstEnabledModule = this.getFirstEnabledModuleForCurrentSpace();

        // If there are no enabled modules there's nothing we can do - log the user out.
        if (firstEnabledModule == null) {
            return Routes.LOGOUT;
        }

        // Redirect the user to the first enabled module route.
        switch (firstEnabledModule) {
            case ModuleType.NOTES:
                return Routes.NOTE_LIST;

            case ModuleType.CONTACTS:
                return Routes.CONTACTS;

            case ModuleType.CREDENTIALS:
                return Routes.CREDENTIAL_LIST;

            case ModuleType.GALLERIES:
                return Routes.GALLERY_LIST;

            case ModuleType.KANBAN:
                return Routes.KANBAN_BOARD;

            default:
                // tslint:disable-next-line:no-console
                console.log(
                    'Warning, SettingsHelper::getRouteForFirstEnabledModuleForCurrentSpace encountered ' +
                    'unhandled first enabled module type',
                    firstEnabledModule,
                );

                return Routes.LOGOUT;
        }
    }
}
