
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import {Routes} from '@/domains/app/router/router';
import FileItem from '@/ts/Database/Files/FileItem';
import NoteItem from '@/domains/notes/database/notes/NoteItem';
import NoteCategoryItem from '@/domains/notes/database/noteCategories/NoteCategoryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import PopSelector from '@/domains/ui/views/Modals/PopSelector/PopSelector.vue';
import { PopSelectorItemInterface } from '@/domains/ui/views/Modals/PopSelector/PopSelectorItemInterface';
import DocumentList from '@/domains/ui/views/DocumentList.vue';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import NoteFileItem from '@/domains/notes/database/noteFIles/NoteFileItem';
import { SymetricCipherType } from '@/ts/Enums/SymetricCipherType';
import { AddNoteParams } from '@/domains/notes/requests/notes/AddNoteRequest';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      IncludeIf,
      PopSelector,
      BasicButton,
      DocumentList,
      Toggle,
  },
})
export default class NoteEdit extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $helperFactory!: HelperFactory;

    private noteTitle: string = '';
    private noteDescription: string = '';
    private noteEncrypted: boolean = false;
    private encryptionPassword: string = '';
    private encryptionPasswordRepeated: string = '';

    private loaded: boolean = false;
    private loading: boolean = false;
    private noteCategories: NoteCategoryItem[] = [];
    private errorMessage: string = '';

    // Add new category form
    private categoryNameOk: Trilean = Trilean.Unknown;
    private addingNewCategory: boolean = false;
    private newCategoryName: string = '';
    private addCategoryValidationError: boolean = false;
    private waitingForAddNoteCategoryRequest: boolean = false;

    // Popselector
    private selectedNoteCategoryName: string = '';
    private addNoteCategoryPopSelectorOpen: boolean = false;
    private categoryJustAdded: boolean = false;

    private noteTitleOk = Trilean.Unknown;
    private noteCategoryOk = Trilean.Unknown;
    private noteDescriptionOk = Trilean.Unknown;
    private encryptionPasswordOk = Trilean.Unknown;
    private encryptionPasswordRepeatedOk = Trilean.Unknown;

    // Uploading files to a note
    private filesToUpload = [];
    private fileUploading = false;
    private uploadedFiles: FileItem[] = [];
    private deletingFile = false;

    public mounted(): void {
        this.init();
    }

    private init(): void {
        this.loaded = false;

        if (this.$repoManager.note) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    get noteCategoryId(): string {
        return this.$store.state.noteModule.noteCategoryId;
    }

    set noteCategoryId(newNoteCategoryId: string) {
        this.$store.commit('setNoteCategoryId', newNoteCategoryId);
    }

    get noteId(): string {
        return this.$store.state.noteModule.noteId;
    }

    get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private async loadSelectedNoteCategory(): Promise<void> {
        if (this.noteCategoryId === '') {
            this.selectedNoteCategoryName = 'Choose';
            return;
        }

        const noteCategoryItem = await this.$repoManager.noteCategory.getItem(this.noteCategoryId);
        this.selectedNoteCategoryName = noteCategoryItem.name;
    }

    get popSelectorNoteCategories(): PopSelectorItemInterface[] {
        const items: PopSelectorItemInterface[] = [];

        for (const noteCategory of this.noteCategories) {
            items.push({
                id: noteCategory.id,
                label: noteCategory.name,
                deletable: false,
            });
        }

        return items;
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadNoteCategories();
        if (this.noteId !== '') {
            await this.loadNote();

            this.uploadedFiles = [];
            const noteFileItems = await this.$repoManager.noteFile.getList(this.noteId);
            if (noteFileItems.length > 0) {
                for (const index in noteFileItems) {
                    if (noteFileItems.hasOwnProperty(index)) {
                        const noteFileItem = noteFileItems[index];
                        const thisFile = await this.$repoManager.file.getItem(noteFileItem.file_id);
                        this.uploadedFiles.push(thisFile);
                    }
                }
            }

            this.checkNoteTitleOk();
            this.checkNoteCategoryOk();
            this.checkNoteDescriptionOk();
        } else {
            const noteItem = await this.$repoManager.note.getEmptyNoteItem(this.noteCategoryId);
            this.setNoteAttributesFromObject(noteItem);
            this.uploadedFiles = [];
            this.selectedNoteCategoryName = '';
            this.checkNoteCategoryOk();
            this.encryptionPassword = '';
            this.noteEncrypted = false;
            this.encryptionPasswordOk = Trilean.Unknown;
        }

        await this.loadSelectedNoteCategory();

        this.loaded = true;
    }

    private async loadNoteCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            this.noteCategories = await this.$repoManager.noteCategory.getList(this.selectedSpace, '');
        } catch (error) {
            this.errorMessage = 'Error: Note categories could not be loaded.';
        }
    }

    private async loadNote(): Promise<void> {
        try {
            this.errorMessage = '';
            const noteItem = await this.$repoManager.note.getItem(this.noteId);
            this.setNoteAttributesFromObject(noteItem);
        } catch (error) {
            this.errorMessage = 'Error: Note could not be loaded.';
        }
    }

    private setNoteAttributesFromObject(noteItem: NoteItem): void {
        this.noteTitle = noteItem.title;
        this.noteCategoryId = noteItem.note_category_id;
        this.noteDescription = noteItem.description;
    }

    public setNoteEncrypted(isEncrypted: boolean) {
        this.noteEncrypted = isEncrypted;
        this.encryptionPassword = '';
        this.encryptionPasswordOk = Trilean.Unknown;
        this.encryptionPasswordRepeated = '';
        this.encryptionPasswordRepeatedOk = Trilean.Unknown;
    }

    public cancelEdit(): void {
        this.navigateToNotesScreen();
    }

    /*********************************************
     * SAVING AND DELETING THE NOTE
     *********************************************/
    public async saveNote(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        try {
            this.loading = true;

            if (this.addingNewItem) {
                const addNoteParams: AddNoteParams = {
                    note_category_id: this.noteCategoryId,
                    title: this.noteTitle,
                    description: this.noteDescription,
                };

                if (this.noteEncrypted) {
                    // Ask the server to encrypt the note description.
                    const encryptDataRepsonse = await this.$requestFactory.encryptDataRequest.execute({
                        cipher_type: SymetricCipherType.AES256,
                        password: this.encryptionPassword,
                        clear_text: this.noteDescription,
                    });

                    // Populate the note encryption properties
                    addNoteParams.encryption_cipher_type = encryptDataRepsonse.cipher_type;
                    addNoteParams.encryption_integrity_hash = encryptDataRepsonse.integrity_hash;
                    addNoteParams.encryption_metadata = encryptDataRepsonse.metadata;

                    // Update the note description to be the encrypted version.
                    addNoteParams.description = encryptDataRepsonse.encrypted_string;
                }

                const noteItem = await this.$requestFactory.addNoteRequest.execute(addNoteParams);

                if (this.uploadedFiles.length > 0) {
                    const uploadedFileIds = this.uploadedFiles.map((item) => {
                        return item.id;
                    });

                    const noteFiles = await this.$requestFactory.addFilesToNoteRequest.execute({
                        note_id: noteItem.id,
                        file_ids: uploadedFileIds,
                    });

                    // Save the note file records into the db.
                    if (noteFiles.length > 0) {
                        await this.$repoManager.noteFile.saveAll(noteFiles);
                    }

                    // Finally, save the file records into the db.
                    this.$repoManager.file.saveAll(this.uploadedFiles);
                }

                await this.$repoManager.note.save(noteItem);
                await this.$store.dispatch('flagNoteModuleUpdated');

                this.$router.push({name: Routes.NOTE, params: { id: noteItem.id }});
            } else {
                const noteItem = await this.$requestFactory.updateNoteRequest.execute({
                    id: this.noteId,
                    title: this.noteTitle,
                    description: this.noteDescription,
                    note_category_id: this.noteCategoryId,
                });

                await this.$repoManager.note.save(noteItem);

                await this.$store.dispatch('flagNoteModuleUpdated');
                this.$router.push({name: Routes.NOTE, params: { id: noteItem.id }});
            }
        } catch (error) {
            this.errorMessage = 'Failed to save note.  Please try again later.';
        } finally {
            this.loading = false;
        }
    }

    public async deleteNote(): Promise<void> {
        if (!confirm('Delete this note?  Are you sure?')) {
            return;
        }

        try {
            // Load any attachments associated with the note
            const noteFiles = await this.$repoManager.noteFile.getList(this.noteId);
            let allNoteFilesDeletedOk = true;

            // For each note file, ask the server to delete it (and any associated files)
            // Also delete the note file from the local repo
            // Also delete the file from the local repo
            for (const index in noteFiles) {
                if (noteFiles.hasOwnProperty(index)) {
                    const noteFile = noteFiles[index];
                    const noteFileDeletedOk =
                        await this.$requestFactory.deleteNoteFileRequest.execute(this.noteId, noteFile.id);
                    if (!noteFileDeletedOk) {
                        allNoteFilesDeletedOk = false;
                    } else {
                        const fileToDelete = await this.$repoManager.file.getItem(noteFile.file_id);
                        await this.$repoManager.noteFile.delete(noteFile);
                        await this.$repoManager.file.delete(fileToDelete);
                    }
                }
            }

            if (!allNoteFilesDeletedOk) {
                this.errorMessage = 'The note attachments could not be deleted.  Please try again later';
                return;
            }

            const deletedOk = await this.$requestFactory.deleteNoteRequest.execute(this.noteId);
            if (!deletedOk) {
                this.errorMessage = 'The note could not be deleted.  Please try again later.';
                return;
            }

            this.loading = true;

            const noteItem = await this.$repoManager.note.getEmptyNoteItem(this.noteCategoryId);
            await this.$repoManager.note.delete(noteItem);
            await this.$store.dispatch('flagNoteModuleUpdated');

            this.navigateToNotesScreen();
        } catch (error) {
            this.errorMessage = 'The note could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    /*********************************************
     * VALIDATION
     *********************************************/
    private formValid(): boolean {
        if (this.noteTitleOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid note title.';
            return false;
        }

        if (this.noteCategoryOk !== Trilean.True) {
            this.errorMessage = 'You must select a valid note category.';
            return false;
        }

        if (this.noteDescriptionOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid note description.';
            return false;
        }

        if (this.noteEncrypted && this.encryptionPasswordRepeatedOk !== Trilean.True) {
            this.errorMessage = 'Please check your encryption password.';
            return false;
        }

        return true;
    }

    private checkNoteTitleOk(): void {
        if ((this.noteTitle.length === 0) || (this.noteTitle.length > 255)) {
            this.noteTitleOk = Trilean.False;
        } else {
            this.noteTitleOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkNoteCategoryOk(): void {
        if (this.noteCategoryId === '') {
            this.noteCategoryOk = Trilean.False;
        } else {
            this.noteCategoryOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private checkNoteDescriptionOk(): void {
        if (this.noteDescription.length === 0) {
            this.noteDescriptionOk = Trilean.False;
        } else {
            this.noteDescriptionOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    public checkCategoryNameOk(): void {
        if ((this.newCategoryName.length >= 2) && (this.newCategoryName.length <= 50)) {
            this.categoryNameOk = Trilean.True;
            this.addCategoryValidationError = false;
        } else {
            this.categoryNameOk = Trilean.False;
        }
    }

    public checkEncryptionPassword(): void {
        if (!this.noteEncrypted) {
            this.encryptionPasswordOk = Trilean.True;
            return;
        }

        if (this.encryptionPassword.length < 8) {
            this.encryptionPasswordOk = Trilean.False;
            return;
        }

        this.encryptionPasswordOk = Trilean.True;
    }

    public checkEncryptionPasswordRepeated(): void {
        if (!this.noteEncrypted) {
            this.encryptionPasswordRepeatedOk = Trilean.True;
            return;
        }

        if (this.encryptionPasswordRepeated.length < 8) {
            this.encryptionPasswordRepeatedOk = Trilean.False;
            return;
        }

        if (this.encryptionPasswordRepeated !== this.encryptionPassword) {
            this.encryptionPasswordRepeatedOk = Trilean.False;
            return;
        }

        this.encryptionPasswordRepeatedOk = Trilean.True;
    }

    /*********************************************
     * FILE UPLOADS
     *********************************************/
    private async uploadNextFile(fileNumber: number) {
        const formData = new FormData();
        formData.append('file', this.filesToUpload[fileNumber - 1]);

        try {
            const params: UploadFileParams = {
                formData,
            };

            this.fileUploading = true;

            const fileItem = await this.$requestFactory.uploadFileRequest.execute(params);

            if (!this.addingNewItem) {
                const noteFiles = await this.$requestFactory.addFilesToNoteRequest.execute({
                    note_id: this.noteId,
                    file_ids: [fileItem.id],
                });

                // Save the note file records into the db.
                if (noteFiles.length > 0) {
                    await this.$repoManager.noteFile.saveAll(noteFiles);
                }

                // Finally, save the file records into the db.
                this.$repoManager.file.save(fileItem);
            }

            this.uploadedFiles.push(fileItem);

            if (fileNumber < this.filesToUpload.length) {
                this.uploadNextFile(fileNumber + 1);
            } else {
                this.filesToUpload = [];
            }

        } catch (error) {
            this.errorMessage = 'Error uploading file: ' + error.toString();
        } finally {
            this.fileUploading = false;
        }
    }

    public async uploadFiles(): Promise<void> {
        const uploadFiles: any = this.$refs.filesToUpload;
        this.filesToUpload = uploadFiles.files;

        if (this.filesToUpload.length > 0) {
            this.uploadNextFile(1);
        } else {
            this.errorMessage = 'Please select a file to upload';
        }
    }

    /*********************************************
     * FILE DELETION
     *********************************************/
    public async deleteUploadedFile(uploadedFile: FileItem): Promise<void> {
        if (!(confirm(`Delete the uploaded file ${uploadedFile.name}?  Are you sure?`))) {
            return;
        }

        const deleteFailed = (errorMessage: string) => {
            this.deletingFile = false;
            this.errorMessage = errorMessage;
        };

        try {
            this.deletingFile = true;

            const ok = await this.deleteFileFromServer(uploadedFile);
            if (!ok) {
                deleteFailed('The file could not be deleted.  Please try again later.');
                return;
            }

            /**
             * If a file is being deleted for an existing note (i.e. we're not adding a new note)
             * then we need to delete the note file record from the server and locally as well.
             */
            if (!this.addingNewItem) {
                const noteFileItem = await this.getNoteFileForFile(uploadedFile);
                if (noteFileItem) {
                    if (await this.deleteNoteFileFromServer(noteFileItem)) {
                        await this.$repoManager.noteFile.delete(noteFileItem);
                    } else {
                        deleteFailed('The note file could not be deleted.  Please try again later.');
                        return;
                    }
                }
            }

            // Remove the file that was deleted from the uploaded items list.
            const newItems: FileItem[] = [];
            this.uploadedFiles.forEach((item) => {
                if (item.id !== uploadedFile.id) {
                    newItems.push(item);
                }
            });

            this.uploadedFiles = newItems;
        } catch (error) {
            deleteFailed('The file could not be deleted: ' + error.toString());
        } finally {
            this.deletingFile = false;
        }
    }

    private async deleteFileFromServer(fileToDelete: FileItem): Promise<boolean> {
        try {
            return await this.$requestFactory.deleteFileRequest.execute(fileToDelete.id);
        } catch (error) {
            // A record not found error is OK - return this is a true
            return this.$helperFactory.serverErrorHelper.isRecordNotFound(error);
        }
    }

    private async deleteNoteFileFromServer(noteFileToDelete: NoteFileItem): Promise<boolean> {
        try {
            return await this.$requestFactory.deleteNoteFileRequest.execute(this.noteId, noteFileToDelete.id);
        } catch (error) {
            // A record not found error is OK - return this is a true
            return this.$helperFactory.serverErrorHelper.isRecordNotFound(error);
        }
    }

    private async getNoteFileForFile(fileItem: FileItem): Promise<NoteFileItem|null> {
        const noteFiles = await this.$repoManager.noteFile.getList(this.noteId);

        for (const noteFile of noteFiles) {
            if (noteFile.file_id ===  fileItem.id) {
                return noteFile;
            }
        }

        return null;
    }

    /*********************************************
     * CATEGORY MANAGEMENT
     *********************************************/
    // This comes from the PopSelector
    public async handlePopSelectorCategoryChanged(itemId: string): Promise<void> {
        this.addNoteCategoryPopSelectorOpen = false;
        this.noteCategoryId = itemId;
        this.loadSelectedNoteCategory();
        this.checkNoteCategoryOk();
    }

    public userWantsToAddNewCategory(): void {
        this.addingNewCategory = !this.addingNewCategory;
        this.newCategoryName = '';
        this.categoryNameOk = Trilean.Unknown;
        this.addCategoryValidationError = false;
    }

    public cancelAddCategory(): void {
        this.newCategoryName = '';
        this.addingNewCategory = false;
        this.addCategoryValidationError = false;
    }

    public async addCategory(): Promise<void> {
        if (this.categoryNameOk !== Trilean.True) {
            this.addCategoryValidationError = true;
            return;
        }

        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddNoteCategoryRequest = true;
            const noteCategoryItem = await this.$requestFactory.addNoteCategoryRequest.execute({
                space_id: this.selectedSpace,
                name: this.newCategoryName,
            });

            // Save the note category in the local repo
            await this.$repoManager.noteCategory.save(noteCategoryItem);

            // Reset note category input text field
            this.newCategoryName = '';

            // Reload note categories from the repo
            await this.loadNoteCategories();

            // Reset flags
            this.waitingForAddNoteCategoryRequest = false;
            this.addingNewCategory = false;

            // Select the new note category item
            this.noteCategoryId = noteCategoryItem.id;
            await this.$store.dispatch('flagNoteModuleUpdated');
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the note category';
        }
    }

    // This comes from the PopSelector
    public async handleAddNewNoteCategoryIntent(newCategoryName: string): Promise<void> {
        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddNoteCategoryRequest = true;

            const noteCategoryItem = await this.$requestFactory.addNoteCategoryRequest.execute({
                space_id: this.spaceId,
                name: newCategoryName,
            });

            // Save the note category in the local repo
            await this.$repoManager.noteCategory.save(noteCategoryItem);

            // Reload note categories from the repo
            await this.loadNoteCategories();

            // Reset flags
            this.waitingForAddNoteCategoryRequest = false;
            this.addNoteCategoryPopSelectorOpen = false;

            // Flag that a category was just added to prevent the Delete Category button immediately showing.
            this.categoryJustAdded = true;

            this.noteCategoryId = noteCategoryItem.id;
            await this.loadSelectedNoteCategory();
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the note category';
        }
    }

    /*********************************************
     * UTILITY METHODS
     *********************************************/
    public navigateToNotesScreen(): void {
        this.$router.push({name: Routes.NOTE_LIST});
    }

    public async navigateToCategory(categoryId: string): Promise<void> {
        await this.$store.commit('setNoteCategoryId', categoryId);

        this.navigateToNotesScreen();
    }

    public getFormattedNoteDescription(): string {
        return this.noteDescription.replace(/(\r\n|\n|\r)/g, '<br />');
    }

    get addingNewItem(): boolean {
        return this.noteId === '';
    }

    // If the user has selected a different workspace, redirect back to notes screen.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.navigateToNotesScreen();
        }
    }
}
