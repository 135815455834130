
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Spinner from '@/domains/ui/views/Spinner.vue';
    import { DisplayType } from '@/ts/Enums/DisplayType';
    import { DisplayTypeHelper } from '@/ts/Enums/DisplayType';

    @Component({
        components: {
            Spinner,
        },
    })
    export default class SpinnerButton extends Vue {
        @Prop({required: true}) public buttonText!: string;
        @Prop({required: true}) public loading!: boolean;
        @Prop({required: false}) public displayType!: DisplayType;
        @Prop({required: false, type: String, default: ''}) public className!: string;

        private buttonClicked(): void {
            // If we are in a loading state, don't process the button
            if (this.loading) {
                return;
            }

            this.$emit('clicked', {});
        }

        get displayTypeClass(): string {
            const helper = new DisplayTypeHelper(this.displayType);
            return helper.className;
        }

        get disabledClass(): string {
            return this.loading ? 'disabled' : '';
        }
    }
