
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import Grid from '@/domains/app/views/Grid.vue';
    import GridItem from '@/domains/app/views/GridItem.vue';
    import ImageViewer, {MediaViewerMediaInfo} from '@/domains/ui/views/Modals/MediaViewer.vue';
    import Spinner from '@/domains/ui/views/Spinner.vue';
    import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
    import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
    import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
    import Toggle from '@/domains/ui/views/Switches/Toggle.vue';
    import RepoManager from '@/ts/Database/RepoManager';
    import RequestFactory from '@/ts/Requests/RequestFactory';
    import ApiClient from '@/ts/ApiClient';
    import HelperFactory from '@/ts/Helpers/HelperFactory';
    import {FileItem} from '@/ts/Database/Files/FileItem';
    import {GalleryFileItem} from '@/domains/galleries/database/galleryFiles/GalleryFileItem';
    import {FileName} from '@/ts/ValueObjects/FileName';
    import {UploadFileParams} from '@/ts/Requests/File/UploadFileRequest';
    import StringHelper from '@/ts/Helpers/StringHelper';
    import {GalleryItem} from '@/domains/galleries/database/galleries/GalleryItem';
    import {PublicationMode} from '@/domains/credentials/types/PublicationMode';
    import MediaViewer from '@/domains/ui/views/Modals/MediaViewer.vue';

    @Component({
  components: {
      MediaViewer,
      ErrorMessage,
      ActionHeading,
      Spinner,
      DeleteItemSpinner,
      Toggle,
      Grid,
      GridItem,
      ImageViewer,
  },
})
export default class GalleryFileGrid extends Vue {
    @Prop({required: true, type: String})
    private galleryId!: string;

    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;
    private $helperFactory!: HelperFactory;

    private errorMessage: string = '';
    private loading: boolean = true;

    // Uploading photo images
    private fileUploading = false;
    private uploadedFiles: FileItem[] = [];
    private deletingFile = false;

    // Gallery Files
    private gallery: GalleryItem|null = null;
    private galleryFiles: GalleryFileItem[] = [];
    private galleryFileMap: { [key: string ]: FileItem } = {};

    // Image Viewer
    private mediaViewerOpen = false;
    private mediaViewerImageInfo: MediaViewerMediaInfo = {
        mediaUrl: '',
        mediaName: '',
        fileId: '',
    };
    private downloadingBinary = false;

    // Photo deleting
    public deleteMode = false;

    // Toggle photo visibility
    public visibilityToggleMode = false;

    private mounted(): void {
        if (this.$repoManager.gallery) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadGallery();
        await this.loadGalleryFiles();
        this.loading = false;
    }

    public getIconName(galleryFile: GalleryFileItem): string {
        if (this.deleteMode) {
            return 'trash-alt';
        }

        if (this.gallery == null) {
            return '';
        }

        if (this.gallery.publication_mode === PublicationMode.DO_NOT_PUBLISH) {
            return '';
        }

        if (galleryFile.published) {
            return 'eye';
        }

        return 'eye-slash';
    }

    public getIconColour(galleryFile: GalleryFileItem): string {
        if (this.deleteMode) {
            return 'warning';
        }

        if (galleryFile.published) {
            return 'positive';
        }

        return 'info';
    }

    /**
     * Checks to see if file related to the the gallery file has any additional files
     * that are video files.  If so, this method returns true, otherwise it returns false.
     * @param galleryFile
     * @returns boolean
     */
    public isVideo(galleryFile: GalleryFileItem): boolean {
        if (!this.galleryFileMap.hasOwnProperty(galleryFile.id)) {
            return false;
        }

        const file = this.galleryFileMap[galleryFile.id];

        return this.fileHasVideoAdditionalFile(file);
    }

    private fileHasVideoAdditionalFile(file: FileItem): boolean {
        if (file.additional_files.length === 0) {
            return false;
        }

        const videoFiles = file.additional_files.filter((url) => {
            return StringHelper.filenameHasValidVideoExtension(url);
        });

        return videoFiles.length > 0;
    }

    public getShortenedGalleryItemTitle(galleryFileId: string): string {
        return StringHelper.shorten(this.getFileItemForGalleryFile(galleryFileId).name, 22);
    }

    public handleToggleDeleteMode() {
        this.deleteMode = !this.deleteMode;
        this.visibilityToggleMode = false;
    }

    public handleToggleVisibilityToggleMode() {
        this.visibilityToggleMode = !this.visibilityToggleMode;
        this.deleteMode = false;
    }

    private async loadGallery(): Promise<void> {
        try {
            this.gallery = await this.$repoManager.gallery.getItem(this.galleryId);
        } catch (error) {
            this.errorMessage = error.toString();
        }
    }

    public get galleryPublished(): boolean {
        if (!this.gallery) {
            return false;
        }

        return this.gallery.publication_mode !== PublicationMode.DO_NOT_PUBLISH;
    }

    private async loadGalleryFiles(): Promise<void> {
        try {
            this.galleryFileMap = {};

            // Attempt to load the gallery files from the local repos first.
            const galleryFiles = await this.$repoManager.galleryFile.getListForGallery(this.galleryId);

            if (galleryFiles.length > 0) {
                for (const galleryFile of galleryFiles) {
                    this.galleryFileMap[galleryFile.id] = await this.$repoManager.file.getItem(galleryFile.file_id);
                }

                this.galleryFiles = galleryFiles;
            } else {
                // The gallery is empty, ask the server if there are any files in the gallery.
                const galleryFilesResponse =
                    await this.$requestFactory.galleryFiles.galleryFileListRequest.execute(this.galleryId, '', '');

                if (galleryFilesResponse.gallery_files.length > 0) {
                    await this.$repoManager.galleryFile.saveAll(galleryFilesResponse.gallery_files);
                    await this.$repoManager.file.saveAll(galleryFilesResponse.files);

                    for (const galleryFile of galleryFilesResponse.gallery_files) {
                        for (const file of galleryFilesResponse.files) {
                            if (galleryFile.file_id === file.id) {
                                this.galleryFileMap[galleryFile.id] = file;
                            }
                        }
                    }
                }

                this.galleryFiles = galleryFilesResponse.gallery_files;
            }
        } catch (error) {
            this.errorMessage = `Error: Gallery could not be loaded: ${error}`;
        }
    }

    private addGalleryItems(): void {
        const galleryPhotoElement = document.getElementById('gallery_photo');
        if (!galleryPhotoElement) {
            return;
        }

        galleryPhotoElement.click();
    }

    private async uploadGalleryPhotos(): Promise<void> {
        const uploadFiles: any = this.$refs.gallery_photo;
        const numFiles = uploadFiles.files.length;

        for (let fileIndex = 0; fileIndex < numFiles; fileIndex++) {
            const fileName = new FileName(uploadFiles.files[fileIndex].name);

            try {
                // Ensure the selected file looks like an image.
                if ((!fileName.mimeType.isImage) && (!fileName.mimeType.isVideo)) {
                    this.errorMessage = `${fileName.name} does not appear to be a valid ` +
                        `media file. Files must either be an image or video file.`;

                    continue;
                }

                const formData = new FormData();
                formData.append('file', uploadFiles.files[fileIndex]);

                this.fileUploading = true;

                const params: UploadFileParams = {
                    formData,
                };

                const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'gallery');
                await this.$repoManager.file.save(fileItem);

                const galleryFile = await this.$requestFactory.galleryFiles.createGalleryFileRequest.execute({
                    gallery_id: this.galleryId,
                    file_id: fileItem.id,
                });

                await this.$repoManager.galleryFile.save(galleryFile);

                this.galleryFileMap[galleryFile.id] = fileItem;
                this.galleryFiles.push(galleryFile);
                this.fileUploading = false;
            } catch (error) {
                this.errorMessage = 'Error uploading file: ' + error.toString();
            } finally {
                this.fileUploading = false;
            }
        }
    }

    private getFileItemForGalleryFile(galleryFileId: string): FileItem {
        return this.galleryFileMap[galleryFileId];
    }

    public handleItemClicked(galleryFile: GalleryFileItem): void {
        if (this.deleteMode) {
            this.handleDeleteFileIntent(galleryFile);
            return;
        }

        if (this.visibilityToggleMode) {
            this.handleToggleVisibility(galleryFile);
            return;
        }

        const fileItem = this.getFileItemForGalleryFile(galleryFile.id);
        this.handleViewMediaItemIntent(fileItem);
    }

    private get galleryLastUpdated(): number {
        return this.$store.state.galleryModule.lastUpdated;
    }

    @Watch('galleryLastUpdated')
    private async handleGalleryUpdated(): Promise<void> {
        await this.loadGalleryFiles();
        await this.loadGallery();
    }

    private handleViewMediaItemIntent(fileItem: FileItem) {
        if (this.fileHasVideoAdditionalFile(fileItem)) {
            const videoFiles = fileItem.additional_files.filter((url) => {
                return StringHelper.filenameHasValidVideoExtension(url);
            });

            const videoFileUrl = videoFiles[0];

            this.mediaViewerImageInfo = {
                mediaUrl: videoFileUrl,
                mediaName: fileItem.name,
                fileId: fileItem.id,
            };
        } else {
            this.mediaViewerImageInfo = {
                mediaUrl: fileItem.url,
                mediaName: fileItem.name,
                fileId: fileItem.id,
            };
        }

        this.mediaViewerOpen = true;
    }

    private async handleDownloadImageIntent() {
        this.downloadingBinary = true;

        try {
            const request = this.$requestFactory.getFileBinaryRequest;
            const buffer = await request.execute(this.mediaViewerImageInfo.fileId);

            const imageBlob = new Blob([new Uint8Array(buffer, 0, buffer.byteLength)]);
            const imageURL = URL.createObjectURL(imageBlob);

            const link = document.createElement('a');
            link.href = imageURL;
            link.download = this.mediaViewerImageInfo.mediaName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            alert('Sorry, the download failed: ' + error);
        } finally {
            this.downloadingBinary = false;
        }
    }

    private async handleToggleVisibility(galleryFile: GalleryFileItem): Promise<void> {
        this.loading = true;

        try {
            const returnedGalleryFile = await this.$requestFactory.galleryFiles.updateGalleryFileRequest.execute(
                galleryFile.id,
                {
                    gallery_id: galleryFile.gallery_id,
                    published: !galleryFile.published,
                },
            );

            await this.$repoManager.galleryFile.save(returnedGalleryFile);

            // Toggle the visibility of the selected item
            this.galleryFiles = this.galleryFiles.map((item: GalleryFileItem) => {
                if (item.id === galleryFile.id) {
                    item.published = !item.published;
                }

                return item;
            });
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }

    private async handleDeleteFileIntent(galleryFileItem: GalleryFileItem): Promise<void> {
        try {
            this.loading = true;
            const fileItem = this.getFileItemForGalleryFile(galleryFileItem.id);

            await this.$requestFactory.galleryFiles.deleteGalleryFileRequest.execute(galleryFileItem.id);
            await this.$requestFactory.deleteFileRequest.execute(fileItem.id);

            await this.$repoManager.galleryFile.delete(galleryFileItem);
            await this.$repoManager.file.delete(fileItem);

            // Remove the deleted gallery file item from the gallery files array.
            this.galleryFiles = this.galleryFiles.filter((item: GalleryFileItem) => {
                return item.id !== galleryFileItem.id;
            });
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }
}
