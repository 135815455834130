import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import RecipeItem from '@/domains/recipies/database/recipies/RecipeItem';

export interface UpdateRecipeParams {
    id: string;
    recipe_category_id: string;
    title: string;
    description: string;
    cooking_time: number;
    preparation_time: number;
    ingredients: string[];
    instructions: string[];
    hero_image_file_id: string;
}

export default class UpdateRecipeRequest extends AbstractRequest {
    public async execute(params: UpdateRecipeParams): Promise<RecipeItem> {
        return new Promise<RecipeItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/recipe/${params.id}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('recipe')))  {
                    reject(Error('UpdateRecipeRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.recipe);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a recipe failed.  Please try again later'));
            }
        });
    }
}
