import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import NoteFileItem from '@/domains/notes/database/noteFIles/NoteFileItem';

export interface AddFilesToNoteParams {
    note_id: string;
    file_ids: string[];
}

export default class AddFilesToNoteRequest extends AbstractRequest {
    public async execute(params: AddFilesToNoteParams): Promise<NoteFileItem[]> {
        return new Promise<NoteFileItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/note/${params.note_id}/files`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('note_files')))  {
                    reject(Error('AddFilesToNoteRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.note_files);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add files a note failed.  Please try again later'));
            }
        });
    }
}
