import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { VaultAccessResponse } from '@/domains/vault/interfaces/VaultAccessResponse';

export class RequestVaultAccessRequest extends AbstractRequest {
    public async execute(vaultPassword: string, totpCode: string): Promise<VaultAccessResponse> {
        return new Promise<VaultAccessResponse>(async (resolve, reject) => {
            try {
                const params = {
                    vault_password: vaultPassword,
                } as any;

                if ((totpCode !== null) && (totpCode.length > 0)) {
                    params.totp_code = parseInt(totpCode, 10);
                }

                const response = await this.apiClient.post(`/private/vault/request_access`, params);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('vault_access'))
                )  {
                    reject(Error('RequestVaultAccessRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.vault_access);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to access your vault failed.  Please try again later'));
            }
        });
    }
}
