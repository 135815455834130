import { AbstractRequestFactory } from '@/ts/Requests/AbstractRequestFactory';
import { CreateCredentialRequest } from '@/domains/credentials/requests/CreateCredentialRequest';
import { ViewCredentialRequest } from '@/domains/credentials/requests/ViewCredentialRequest';
import { UpdateCredentialRequest } from '@/domains/credentials/requests/UpdateCredentialRequest';
import { DeleteCredentialRequest } from '@/domains/credentials/requests/DeleteCredentialRequest';
import { ListCredentialsRequest } from '@/domains/credentials/requests/ListCredentialsRequest';
import { ImportCredentialRequest } from '@/domains/credentials/requests/ImportCredentialRequest';

export class CredentialRequestFactory extends AbstractRequestFactory {
    public get createCredential(): CreateCredentialRequest {
        return new CreateCredentialRequest(this.apiClient);
    }

    public get deleteCredential(): DeleteCredentialRequest {
        return new DeleteCredentialRequest(this.apiClient);
    }

    public get importCredentials(): ImportCredentialRequest {
        return new ImportCredentialRequest(this.apiClient);
    }

    public get listCredentials(): ListCredentialsRequest {
        return new ListCredentialsRequest(this.apiClient);
    }

    public get updateCredential(): UpdateCredentialRequest {
        return new UpdateCredentialRequest(this.apiClient);
    }

    public get viewCredential(): ViewCredentialRequest {
        return new ViewCredentialRequest(this.apiClient);
    }
}
