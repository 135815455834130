import ApiClient from '@/ts/ApiClient';
import { ErrorObject } from '@/ts/Interfaces/ErrorObject';

export abstract class AbstractRequest {
    protected apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    protected convertResponseError(error: any, defaultMessage: string): ErrorObject {
        if ((error.hasOwnProperty('response')) && (error.response) && (error.response.hasOwnProperty('data')) &&
            (error.response.data.hasOwnProperty('error'))) {
                const dataObject = error.response.data;
                const errorMessage = dataObject.error;
                const errorCode = dataObject.hasOwnProperty('code') ? dataObject.code : '';

                if (dataObject.error.indexOf('Token is invalid') >= 0) {
                    this.broadcastSessionExpired();
                }

                return this.createErrorObject(errorMessage, errorCode);
        } else {
            return this.createErrorObject(defaultMessage, '');
        }
    }

    private broadcastSessionExpired(): void {
        const sessionExpiredEvent = new Event('SessionExpired');
        document.dispatchEvent(sessionExpiredEvent);
    }

    private createErrorObject(message: string, code: string): ErrorObject {
        return {
            message,
            code,
            toString: () => {
                return message;
            },
        };
    }
}
