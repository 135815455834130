import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { UserTwoFactorAuthMethod } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthMethod';

export class CreateUserTwoFactorAuthRequest extends AbstractRequest {
    public async execute(type: UserTwoFactorAuthMethod): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const params = {
                    type,
                };

                const response = await this.apiClient.post(`/private/two_factor_auth`, params);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('qr_code_url')))  {
                    reject(Error('CreateUserTwoFactorAuthRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.qr_code_url);
            } catch (error) {
                reject(this.convertResponseError(error,
                    `Could not create '${type}' two factor auth`));
            }
        });
    }
}
