import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Trilean from '../../../ts/Trilean';
import {VaultAccessResponse} from '@/domains/vault/interfaces/VaultAccessResponse';

@Module
export class CredentialStore extends VuexModule {
    public credentialId: string = '';
    public lastUpdated: number = 0;
    public searchKeywords: string = '';
    public vaultPasswordSet: Trilean = Trilean.Unknown;
    public vaultAccessId: string = '';
    public vaultTokenPartA: string = '';
    public vaultAccessTimeToLive: number = 0;
    public credentialSearchKeywords = '';

    @Mutation
    public setCredentialStoreId(value: string) {
        this.credentialId = value;
    }

    @Mutation
    public setCredentialStoreSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setCredentialStoreLastUpdated(value: number) {
        this.lastUpdated = value;
    }

    @Mutation
    public setVaultPasswordSet(value: Trilean) {
        this.vaultPasswordSet = value;
    }

    @Mutation
    public setVaultAccessId(value: string) {
        this.vaultAccessId = value;
    }

    @Mutation
    public setVaultTokenPartA(value: string) {
        this.vaultTokenPartA = value;
    }

    @Mutation
    public setVaultTimeToLive(value: number) {
        this.vaultAccessTimeToLive = value;
    }

    @Mutation
    public setCredentialSearchKeywords(value: string) {
        this.credentialSearchKeywords = value;
    }

    @Action
    public clearCredentialStoreState() {
        this.context.commit('setCredentialStoreId', '');
        this.context.commit('setCredentialSearchKeywords', '');
        this.context.dispatch('flagCredentialStoreUpdated');
    }

    @Action
    public flagCredentialStoreUpdated() {
        this.context.commit('setCredentialStoreLastUpdated', (new Date()).getTime());
    }

    @Action
    public setVaultAccessDetails(vaultAccessDetails: VaultAccessResponse) {
        this.context.commit('setVaultAccessId', vaultAccessDetails.vault_access_id);
        this.context.commit('setVaultTokenPartA', vaultAccessDetails.token_part_a);
        this.context.commit('setVaultTimeToLive', vaultAccessDetails.time_to_live);
    }

    @Action
    public clearVaultAccessDetails() {
        this.context.commit('setVaultAccessId', '');
        this.context.commit('setVaultTokenPartA', '');
        this.context.commit('setVaultTimeToLive', 0);
        this.context.commit('setVaultPasswordSet', Trilean.Unknown);
    }
}
