import { MimeType } from '@/ts/ValueObjects/MimeType';
import StringHelper from '@/ts/Helpers/StringHelper';

export class FileName {
    constructor(private fileName: string) {
    }

    public get mimeType(): MimeType {
        return this.getMimeType();
    }

    public get name(): string {
        return this.fileName;
    }

    private getMimeType() {
        const fileExtension = StringHelper.getFileNameExtension(this.fileName).toUpperCase();

        switch (fileExtension) {
            // IMAGE FILES
            case 'BMP':
                return new MimeType('image/bmp');

            case 'JPG':
                return new MimeType('image/jpeg');

            case 'JPEG':
                return new MimeType('image/jpeg');

            case 'PNG':
                return new MimeType('image/png');

            case 'GIF':
                return new MimeType('image/gif');

            // TEXT FILES
            case 'TXT':
                return new MimeType('text/plain');

            case 'CSV':
                return new MimeType('text/csv');

            case 'XML':
                return new MimeType('text/xml');

            // DOCUMENTS
            case 'PDF':
                return new MimeType('application/pdf');

            case 'DOC':
                return new MimeType('application/msword');

            case 'DOCX':
                return new MimeType(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

            case 'ODT':
                return new MimeType('application/vnd.oasis.opendocument.text');

            case 'RTF':
                return new MimeType('application/rtf');

            // SPREADSHEETS
            case 'XLS':
                return new MimeType('application/vnd.ms-excel');

            case 'XLSX':
                return new MimeType(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

            case 'ODS':
                return new MimeType('application/vnd.oasis.opendocument.spreadsheet');

            // PRESENTATION FILES
            case 'PPT':
                return new MimeType('application/vnd.ms-powerpoint');

            case 'PPTX':
                return new MimeType(
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation');

            case 'ODP':
                return new MimeType('application/vnd.oasis.opendocument.presentation');

            // ARCHIVES
            case 'ZIP':
                return new MimeType('application/zip');

            case 'TAR':
                return new MimeType('application/x-tar');

            case 'GZ':
                return new MimeType('application/x-tar+gzip');

            case 'RAR':
                return new MimeType('application/x-rar-compressed');

            case 'BZ':
                return new MimeType('application/x-bzip');

            case 'BZ2':
                return new MimeType('application/x-bzip2');

            // AUDIO FILES
            case 'MP3':
                return new MimeType('audio/mpeg');

            case 'WAV':
                return new MimeType('audio/wav');

            case 'MP3':
                return new MimeType('audio/mpeg');

            case 'OGG':
                return new MimeType('audio/ogg');

            case 'FLAC':
                return new MimeType('audio/x-flac');

            case 'FLAC':
                return new MimeType('audio/x-flac');

            // VIDEO FILES
            case 'MP4':
                return new MimeType('video/mp4');

            case 'MOV':
                return new MimeType('video/quicktime');

            case 'AVI':
                return new MimeType('video/x-msvideo');

            case 'WMV':
                return new MimeType('video/x-ms-wmv');

            case 'MPEG':
                return new MimeType('video/mpeg');

            case 'MKV':
                return new MimeType('video/x-matroska');

            case 'H264':
                return new MimeType('video/h264');

            case 'WEBM':
                return new MimeType('video/webm');

            default:
                throw new Error(`Cannot get mime type for extension: ${fileExtension}`);
        }
    }
}

