
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import StringListEditor from '@/domains/ui/views/StringListEditor.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import DocumentList from '@/domains/ui/views/DocumentList.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import FileItem from '@/ts/Database/Files/FileItem';
import RecipeItem from '@/domains/recipies/database/recipies/RecipeItem';
import RecipeCategoryItem from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import StringHelper from '@/ts/Helpers/StringHelper';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      DeleteItemSpinner,
      DocumentList,
      StringListEditor,
  },
})
export default class Recipe extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private recipeItem!: RecipeItem;
    private recipeTitle: string = '';
    private recipeDescription: string = '';
    private heroImageFile: FileItem|null = null;
    private preparationTimeTemp: string = '';
    private cookingTimeTemp: string = '';
    private heroImagePopped = false;

    private loaded: boolean = false;
    private loading: boolean = false;
    private recipeCategories: RecipeCategoryItem[] = [];
    private errorMessage: string = '';


    private mounted(): void {
        this.init();
    }

    @Watch(`recipeId`)
    private init(): void {
        this.loaded = false;

        this.preparationTimeTemp = '';
        this.cookingTimeTemp = '';
        this.heroImageFile = null;

        if (this.$repoManager.recipe) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private get recipeId(): string {
        return this.$store.state.recipeModule.recipeId;
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private get lastUpdated(): string {
        return this.$store.state.galleryModule.lastUpdated;
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    @Watch('lastUpdated')
    private async loadDataFromRepos(): Promise<void> {
        await this.loadRecipeCategories();
        await this.loadRecipe();

        this.loaded = true;
    }

    private async loadRecipeCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            this.recipeCategories = await this.$repoManager.recipeCategory.getList(this.spaceId);
        } catch (error) {
            this.errorMessage = 'Error: Recipe categories could not be loaded.';
        }
    }

    private async loadRecipe(): Promise<void> {
        try {
            this.errorMessage = '';
            this.recipeItem = await this.$repoManager.recipe.getItem(this.recipeId);
            this.setRecipeAttributesFromObject();

            if (this.recipeItem.hero_image_file_id.length > 0) {
                this.heroImageFile = await this.$repoManager.file.getItem(this.recipeItem.hero_image_file_id);
            }
        } catch (error) {
            this.errorMessage = 'Error: Recipe could not be loaded.';
        }
    }

    private setRecipeAttributesFromObject(): void {
        this.recipeTitle = this.recipeItem.title;
        this.recipeCategoryId = this.recipeItem.recipe_category_id;
        this.recipeDescription = this.recipeItem.description;
        this.preparationTimeTemp = this.recipeItem.preparation_time.toString();
        this.cookingTimeTemp = this.recipeItem.cooking_time.toString();
    }

    private async deleteRecipe(): Promise<void> {
        if (!confirm('Delete this recipe?  Are you sure?')) {
            return;
        }

        try {
            this.loading = true;

            // If there is a hero image file associated with this post, delete it.
            if (this.heroImageFile) {
                const fileDeletedOk = await this.$requestFactory.deleteFileRequest.execute(this.heroImageFile.id);
                await this.$repoManager.file.delete(this.heroImageFile);
            }

            const deletedOk = await this.$requestFactory.deleteRecipeRequest.execute(this.recipeId);
            if (!deletedOk) {
                this.errorMessage = 'The recipe could not be deleted.  Please try again later.';
                return;
            }

            await this.$repoManager.recipe.delete(this.recipeItem);

            await this.$store.dispatch('flagRecipeModuleUpdated');

            this.navigateToRecipesScreen();
        } catch (error) {
            this.errorMessage = 'The recipe could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    private editRecipe(): void {
        this.$router.push({name: Routes.RECIPE_EDIT, params: {
            id: this.recipeId,
        }});
    }

    private navigateToRecipesScreen(): void {
        this.$router.push({name: Routes.RECIPE_LIST});
    }

    private navigateToCategory(categoryId: string): void {
        this.$store.commit('setRecipeCategoryId', categoryId);
        this.navigateToRecipesScreen();
    }

    private getFormattedRecipeDescription(): string {
        return this.recipeDescription.replace(/(\r\n|\n|\r)/g, '<br />');
    }

    get recipeCategoryId(): string {
        return this.$store.state.recipeModule.recipeCategoryId;
    }

    set recipeCategoryId(value: string) {
        this.$store.commit('setRecipeCategoryId', value);
    }

    get heroImageBackgroundUrl(): string {
        if ((this.heroImageFile) && (!this.heroImagePopped)) {
            return `background-image:url('${this.heroImageFile.url}');`;
        }

        return '';
    }
}
