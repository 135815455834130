import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { KanbanColumnsItem } from '@/domains/kanban/database/kanbanColumns/KanbanColumnsItem';
import { ServerErrorCodes } from '@/ts/Enums/ServerErrorCodes';

export class GetKanbanColumnsRequest extends AbstractRequest {
    public async execute(): Promise<KanbanColumnsItem[]> {
        return new Promise<KanbanColumnsItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(
                    `/private/kanban_columns`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('kanban_columns')))  {
                    reject(Error('GetKanbanColumnsRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.kanban_columns);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load kanban columns from the API'));
            }
        });
    }
}
