
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';

// Components
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Container from '@/domains/app/views/Container.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import Form from '@/domains/ui/views/Form.vue';
import Grid from '@/domains/app/views/Grid.vue';
import GridItem from '@/domains/app/views/GridItem.vue';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import Selector from '@/domains/ui/views/Switches/Selector/Selector.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';

// Typescript
import ContactItem from '@/domains/contacts/database/ContactItem';
import { ContactFilterType } from '@/domains/contacts/types/ContactFilterType';
import { ContactMessageSummaryItem } from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryItem';
import { ContactsSummaryInterface } from '@/domains/contacts/interfaces/ContactsSummaryInterface';
import { getBlankContactsSummary } from '@/domains/contacts/interfaces/ContactsSummaryInterface';
import { getContactFilterTypeLabel } from '@/domains/contacts/types/ContactFilterType';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import RepoManager from '@/ts/Database/RepoManager';
import { SelectableItemInterface } from '@/domains/ui/views/Switches/Selector/SelectableItemInterface';
import StringHash from '@/ts/StringHash';
import UserItem from '@/domains/users/database/users/UserItem';


@Component({
  components: {
      ActionHeading,
      Container,
      ErrorMessage,
      Form,
      Grid,
      GridItem,
      IncludeIf,
      InputChecker,
      Notification,
      Selector,
      SidebarSection,
      SpinnerButton,
      Toggle,
  },
})
export default class Contacts extends Vue {
    private $repoManager!: RepoManager;
    private $helperFactory!: HelperFactory;

    public loaded: boolean = false;
    public contacts: ContactItem[] = [];
    public contactImageMap: {[key: string]: string} = {};
    public contactMessageSummaryMap: {[key: string]: ContactMessageSummaryItem} = {};
    public contactsSummary: ContactsSummaryInterface = getBlankContactsSummary();
    public errorMessage: string = '';
    public selectedContactFilterItem: SelectableItemInterface|null = null;
    public userDisplayNameMap: StringHash = {};
    public users: UserItem[] = [];
    private loading: boolean = false;

    public mounted(): void {
        this.contactImageMap = {};
        this.contactMessageSummaryMap = {};
        this.selectedContactFilterItem = null;

        if (this.$repoManager.contact) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    @Watch('lastUpdated')
    private async loadDataFromRepos(): Promise<void> {
        const currentTimestamp = (new Date()).getTime();

        // Prevent contacts loading twice due to
        // the "mount" event and "lastUpdated" firing too close to each other.
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.contactsSummary = getBlankContactsSummary();

        await this.loadUsers();
        await this.loadContactMessageSummary();
        await this.loadContactSummary();
        await this.setDefaultSearchFilters();

        await this.loadContacts();
        this.loaded = true;

        setTimeout(() => {
            this.loading = false;
        }, 1000);
    }

    private async loadContacts(): Promise<void> {
        this.errorMessage = '';

        try {
            const contacts = await this.$repoManager.contact.getList(
                this.spaceId,
                this.searchKeywords,
                this.searchHasUnreadMessages,
                this.searchRequiresResponse,
                this.searchContactIsFavourite,
                this.searchShowSharedOnly,
                this.contactMessageSummaryMap,
            );

            await this.loadContactImages(contacts);

            this.contacts = contacts;
        } catch (error) {
            this.errorMessage = 'Error: Contacts could not be loaded.';
        }
    }

    private async loadUsers(): Promise<void> {
        try {
            this.errorMessage = '';
            const items = await this.$repoManager.user.getList();

            this.userDisplayNameMap = {};
            items.forEach((user) => {
                this.userDisplayNameMap[user.id] = user.display_name;
            });

            this.users = items;
        } catch (error) {
            this.errorMessage = 'Error: Users could not be loaded.';
        }
    }

    /**
     * Determines the total number or shared and favourite contacts
     */
    private async loadContactSummary(): Promise<void> {
        this.errorMessage = '';
        this.contactsSummary.totalShared = 0;
        this.contactsSummary.totalFavourites = 0;

        try {
            const contacts = await this.$repoManager.contact.getList(
                this.spaceId,
                '',
                false,
                false,
                false,
                false,
                this.contactMessageSummaryMap,
            );

            for (const contact of contacts) {
                this.contactsSummary.totalShared += contact.shared ? 1 : 0;
                this.contactsSummary.totalFavourites += contact.is_favourite ? 1 : 0;
            }
        } catch (error) {
            this.errorMessage = 'Error: Contacts could not be loaded.';
        }
    }

    /**
     * Each contact will have an associated message summary, which tells us if there
     * are any unread or "requires response" messages for that contact.
     */
    private async loadContactMessageSummary(): Promise<void> {
        const summaryItems = await this.$repoManager.contactMessageSummary.getList(this.spaceId);

        // To avoid lots of repainting, only set the constactsSummary variables at the end.
        let newTotalMessagesUnread = 0;
        let newTotalMessagesNeedToRespond = 0;

        for (const summaryItem of summaryItems) {
            this.contactMessageSummaryMap[summaryItem.contact_id] = summaryItem;
            newTotalMessagesUnread += summaryItem.not_read;
            newTotalMessagesNeedToRespond += summaryItem.requires_response;
        }

        this.contactsSummary.totalMessagesUnread = newTotalMessagesUnread;
        this.contactsSummary.totalMessagesNeedToRespond = newTotalMessagesNeedToRespond;
    }

    /**
     * For each contact, if that contact has an associated primary photo file id,
     * load that file object and store the url to the thumb image in an array.
     */
    private async loadContactImages(contacts: ContactItem[]): Promise<void> {
        for (const contactIndex in contacts) {
            if (contacts.hasOwnProperty(contactIndex)) {
                const contact = contacts[contactIndex];
                const photoKey = contact.photo_primary_file_id;

                if (photoKey === '') {
                    continue;
                }

                try {
                    const file = await this.$repoManager.file.getItem(photoKey);
                    if (file.additional_files.length > 0) {
                        this.contactImageMap[photoKey] = file.additional_files[0];
                    }
                } catch (error) {
                    // Ignore any Unable to load file errors.  The file object must be gone.
                    if (error.toString().indexOf('Unable to load file') >= 0) {
                        this.errorMessage = error.toString();
                        continue;
                    }
                }
            }
        }
    }

    public get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    public get lastUpdated(): string {
        return this.$store.state.contactModule.lastUpdated;
    }

    public get searchKeywords(): string {
        return this.$store.state.contactModule.searchKeywords;
    }

    public set searchKeywords(newValue: string) {
        this.$store.commit('setContactSearchKeywords', newValue);
        this.loadContacts();
    }

    public get userHasFavouriteContacts(): boolean {
        if (this.contacts.length === 0) {
            return false;
        }

        for (const contact of this.contacts) {
            if (contact.is_favourite) {
                return true;
            }
        }

        return false;
    }

    public get searchHasUnreadMessages(): boolean {
        return this.$store.state.contactModule.hasUnreadMessages;
    }

    public async setSearchHasUnreadMessages(newValue: boolean, reloadContacts: boolean = true): Promise<void> {
        this.$store.commit('setContactHasUnreadMessages', newValue);

        if (reloadContacts) {
            await this.loadContacts();
        }
    }

    public get searchShowSharedOnly(): boolean {
        return this.$store.state.contactModule.showSharedOnly;
    }

    public async setSearchShowSharedOnly(newValue: boolean, reloadContacts: boolean = true): Promise<void> {
        this.$store.commit('setContactShowSharedOnly', newValue);

        if (reloadContacts) {
            await this.loadContacts();
        }
    }

    public get searchRequiresResponse(): boolean {
        return this.$store.state.contactModule.requiresResponse;
    }

    public async setSearchRequiresResponse(newValue: boolean, reloadContacts: boolean = true): Promise<void> {
        this.$store.commit('setContactRequiresResponse', newValue);

        if (reloadContacts) {
            await this.loadContacts();
        }
    }

    public get searchContactIsFavourite(): boolean {
        return this.$store.state.contactModule.contactIsFavourite;
    }

    public async setSearchContactIsFavourite(newValue: boolean, reloadContacts: boolean = true): Promise<void> {
        this.$store.commit('setContactIsFavourite', newValue);

        if (reloadContacts) {
            await this.loadContacts();
        }
    }

    public handleContactSelectorFilterItemChanged(selectedItem: SelectableItemInterface|null): void {
        if (selectedItem === null) {
            // Turn off all filters
            this.setSearchContactIsFavourite(false, false);
            this.setSearchShowSharedOnly(false, false);
            this.setSearchHasUnreadMessages(false, false);
            this.setSearchRequiresResponse(false, true);

            this.selectedContactFilterItem = null;

            return;
        }

        switch (selectedItem.value) {
            case ContactFilterType.FAVOURITES:
                // Turn off everything else
                this.setSearchShowSharedOnly(false, false);
                this.setSearchHasUnreadMessages(false, false);
                this.setSearchRequiresResponse(false, false);

                // Turn on favourites
                this.setSearchContactIsFavourite(true, true);
                break;

            case ContactFilterType.SHARED:
                // Turn off everything else
                this.setSearchContactIsFavourite(false, false);
                this.setSearchHasUnreadMessages(false, false);
                this.setSearchRequiresResponse(false, false);

                // Turn on shared
                this.setSearchShowSharedOnly(true, true);
                break;

            case ContactFilterType.UNREAD_MESSAGES:
                // Turn off everything else
                this.setSearchContactIsFavourite(false, false);
                this.setSearchShowSharedOnly(false, false);
                this.setSearchRequiresResponse(false, false);

                // Turn on unread messages
                this.setSearchHasUnreadMessages(true, true);
                break;

            case ContactFilterType.NEED_TO_RESPOND:
                // Turn off everything else
                this.setSearchContactIsFavourite(false, false);
                this.setSearchShowSharedOnly(false, false);
                this.setSearchHasUnreadMessages(false, false);

                // Turn on requires response
                this.setSearchRequiresResponse(true, true);
                break;
        }

        this.selectedContactFilterItem = selectedItem;
    }

    public get contactSelectorFilterItems(): SelectableItemInterface[] {
        const items: SelectableItemInterface[] = [];

        if (this.contactsSummary.totalMessagesUnread > 0) {
            items.push({
                label: getContactFilterTypeLabel(ContactFilterType.UNREAD_MESSAGES),
                value: ContactFilterType.UNREAD_MESSAGES,
                pillNumber: this.contactsSummary.totalMessagesUnread,
                icon: ['fas', 'eye-slash'],
            });
        }

        if (this.contactsSummary.totalMessagesNeedToRespond > 0) {
            items.push({
                label: getContactFilterTypeLabel(ContactFilterType.NEED_TO_RESPOND),
                value: ContactFilterType.NEED_TO_RESPOND,
                pillNumber: this.contactsSummary.totalMessagesNeedToRespond,
                icon: ['fas', 'question'],
            });
        }

        items.push({
            label: getContactFilterTypeLabel(ContactFilterType.FAVOURITES),
            value: ContactFilterType.FAVOURITES,
            pillNumber: this.contactsSummary.totalFavourites,
            icon: ['fas', 'star'],
        });

        items.push({
            label: getContactFilterTypeLabel(ContactFilterType.SHARED),
            value: ContactFilterType.SHARED,
            pillNumber: this.contactsSummary.totalShared,
            icon: ['fas', 'share-alt'],
        });

        return items;
    }

    public showContact(contactId: string): void {
        this.$router.push({name: Routes.CONTACT, params: {id: contactId}});
    }

    public getUserDisplayName(id: string) {
        if (this.userDisplayNameMap.hasOwnProperty(id)) {
            return this.userDisplayNameMap[id];
        } else {
            return '';
        }
    }

    public timestampToDate(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    public dateOfBirthFormatted(isoDate: string): string {
        return this.$helperFactory.dateHelper.dateOfBirthToFriendlyShortDate(isoDate);
    }

    public addContact(): void {
        this.$router.push({name: Routes.CONTACT_ADD});
    }

    get lastSyncTimestamp(): number {
        return this.$store.state.lastSyncTimestamp;
    }

    public contactTitle(contact: ContactItem): string {
        let result = contact.first_name + ' ' + contact.last_name;
        const birthdayIn = this.daysUntilBirthday(contact.date_of_birth);
        if (birthdayIn !== '') {
            result += ' (Birthday ' + birthdayIn + ')';
        }

        return result;
    }

    public getPhotoUrl(primaryPhotoFileId: string) {
        if (!this.contactImageMap.hasOwnProperty(primaryPhotoFileId)) {
            return '';
        }

        return this.contactImageMap[primaryPhotoFileId];
    }

    public getNumUnreadMessages(contactId: string): number {
        if (!this.contactMessageSummaryMap.hasOwnProperty(contactId)) {
            return 0;
        }

        const summaryItem = this.contactMessageSummaryMap[contactId];

        return summaryItem.not_read;
    }

    public getContactRequiresResponse(contactId: string): boolean {
        if (!this.contactMessageSummaryMap.hasOwnProperty(contactId)) {
            return false;
        }

        const summaryItem = this.contactMessageSummaryMap[contactId];

        return (summaryItem.requires_response > 0);
    }

    public getContactMessageIcon(contact: ContactItem): string {
        if (this.getNumUnreadMessages(contact.id) > 0) {
            return 'eye-slash';
        }

        if (this.getContactRequiresResponse(contact.id)) {
            return 'question';
        }

        if (!!contact.is_favourite) {
            return 'star';
        }

        return '';
    }

    public getContactMessageIconColour(contact: ContactItem): string {
        if (this.getNumUnreadMessages(contact.id) > 0) {
            return 'warning';
        }

        if (this.getContactRequiresResponse(contact.id)) {
            return 'info';
        }

        if (!!contact.is_favourite) {
            return 'attention';
        }

        return '';
    }

    private get userHasSelectedSearchCriteria(): boolean {
        return ((this.searchContactIsFavourite || this.searchShowSharedOnly
            || this.searchHasUnreadMessages || this.searchRequiresResponse));
    }

    private async setDefaultSearchFilters(): Promise<void> {
        if (this.searchHasUnreadMessages) {
            this.selectedContactFilterItem = {
                label: getContactFilterTypeLabel(ContactFilterType.UNREAD_MESSAGES),
                value: ContactFilterType.UNREAD_MESSAGES,
                pillNumber: this.contactsSummary.totalMessagesUnread,
                icon: ['fas', 'eye-slash'],
            };
        } else if (this.searchRequiresResponse) {
            this.selectedContactFilterItem = {
                label: getContactFilterTypeLabel(ContactFilterType.NEED_TO_RESPOND),
                value: ContactFilterType.NEED_TO_RESPOND,
                pillNumber: this.contactsSummary.totalMessagesNeedToRespond,
                icon: ['fas', 'question'],
            };
        } else if (this.searchContactIsFavourite) {
            this.selectedContactFilterItem = {
                label: getContactFilterTypeLabel(ContactFilterType.FAVOURITES),
                value: ContactFilterType.FAVOURITES,
                pillNumber: this.contactsSummary.totalFavourites,
                icon: ['fas', 'star'],
            };
        } else if (this.searchShowSharedOnly) {
            this.selectedContactFilterItem = {
                label: getContactFilterTypeLabel(ContactFilterType.SHARED),
                value: ContactFilterType.SHARED,
                pillNumber: this.contactsSummary.totalShared,
                icon: ['fas', 'share-alt'],
            };
        }
    }

    private daysUntilBirthday(dateOfBirth: string): string {
        if (!dateOfBirth) {
            return '';
        }

        const daysUntilBirthday = this.$helperFactory.dateHelper.daysUntilDateOfBirth(
            new Date(Date.parse(dateOfBirth)));

        let result = '';

        if (daysUntilBirthday < 31) {
            if (daysUntilBirthday === 0) {
                result = 'is today!';
            } else if (daysUntilBirthday === 1) {
                result = 'is tomorrow.';
            } else {
                result = 'in ' + daysUntilBirthday.toString() + ' days.';
            }
        }

        return result;
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }

    // If the user has selected a different space, refresh the contacts.
    @Watch('spaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }
}
