import StringHelper from '@/ts/Helpers/StringHelper';
import { EntityInterface } from '@/ts/Database/Interfaces/EntityInterface';

export class AbstractRepo<T> {
    private db: IDBDatabase;
    private storeName: string;

    public constructor(db: IDBDatabase, storeName: string) {
        this.db = db;
        this.storeName = storeName;
    }

    public async saveAll(items: EntityInterface[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: EntityInterface): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
                }
            };

            request.onerror = () => {
                reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
            };
        });
    }

    public async delete(item: EntityInterface): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.id}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    protected getStoreName(): string {
        return this.storeName;
    }

    protected getDb(): IDBDatabase {
        return this.db;
    }
}
