import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CompletionItem } from '@/domains/assistant/database/CompletionItem';

@Module
export class CompletionStore extends VuexModule {
    public completions: CompletionItem[] = [];
    public searchKeywords: string = '';

    @Mutation
    public setCompletionSearchKeywords(value: string) {
        this.searchKeywords = value;
    }

    @Mutation
    public setCompletions(newCompletions: CompletionItem[]) {
        this.completions = newCompletions;
    }

    @Mutation
    public addNewCompletion(newCompletion: CompletionItem) {
        this.completions.push(newCompletion);
    }

    @Action
    public clearAssistantModuleState() {
        this.context.commit('setCompletionSearchKeywords', '');
    }
}
