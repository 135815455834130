
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Trilean } from '@/ts/Trilean';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';
import { UserTwoFactorAuthMethod } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthMethod';
import StringHelper from '@/ts/Helpers/StringHelper';

@Component({
  components: {
      BasicButton,
      ErrorMessage,
      InputChecker,
      Spinner,
      SpinnerButton,
  },
})
export default class CreateUserTwoFactorQRCode extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private errorMessage: string = '';
    private loading: boolean = false;
    private verifying: boolean = false;
    private qrCodeUrl: string = '';
    private readyToVerify: boolean = false;

    public verify2FAPassword = '';
    private verify2FAPasswordOk: Trilean = Trilean.Unknown;

    public verify2FACode = '';
    private verify2FACodeOk: Trilean = Trilean.Unknown;

    public mounted() {
        this.loadQRCode();
    }

    public handleQRScanned(): void {
        this.readyToVerify = true;
    }

    private async loadQRCode(): Promise<void> {
        try {
            this.errorMessage = '';
            this.qrCodeUrl = '';
            this.readyToVerify = false;
            this.loading = true;

            this.qrCodeUrl = await this.$requestFactory.createUserTwoFactorAuthRequest.execute(
                UserTwoFactorAuthMethod.TOTP,
            );
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }

    public checkVerify2FAPassword(): void {
        if (this.verify2FAPassword.length > 0) {
            this.verify2FAPasswordOk = Trilean.True;
        } else {
            this.verify2FAPasswordOk = Trilean.False;
        }
    }

    public checkVerify2FACode(): void {
        if (this.verify2FACode.length >= 6) {
            if (this.twoFactorCodeAsNumber > 0) {
                this.verify2FACodeOk = Trilean.True;
            } else {
                this.verify2FACodeOk = Trilean.False;
            }
        } else {
            this.verify2FACodeOk = Trilean.False;
        }
    }

    private formValid(): boolean {
        this.checkVerify2FAPassword();
        this.checkVerify2FACode();

        if (this.verify2FAPasswordOk !== Trilean.True) {
            this.errorMessage = 'Please enter your account password';
            return false;
        }

        if (this.verify2FACodeOk !== Trilean.True) {
            this.errorMessage = 'Please enter the authenticator code';
            return false;
        }

        this.errorMessage = '';

        return true;
    }

    public async handleVerifyTwoFactorAuthIntent(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        try {
            this.verifying = true;
            this.errorMessage = '';

            await this.$requestFactory.verifyUserTwoFactorAuthRequest.execute(
                UserTwoFactorAuthMethod.TOTP,
                this.verify2FAPassword,
                this.twoFactorCodeAsNumber,
            );

            this.$emit('twoFactorAuthVerified');
        } catch (error) {
            if (error.code === 'TwoFactorAuthFailed') {
                this.errorMessage = 'Two factor authentication was not verified.  Please check ' +
                    'your password and code and try again.';
            } else {
                this.errorMessage = 'An unknown error occured.  Please try again later.';
            }
        } finally {
            this.verifying = false;
        }
    }

    private get twoFactorCodeAsNumber(): number {
        const codeWithoutSpaces = StringHelper.removeSpaces(this.verify2FACode);
        return StringHelper.toInt(codeWithoutSpaces);
    }
}
