import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export class DeleteCredentialRequest extends AbstractRequest {
    public async execute(credentialId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.delete(`/private/credential/${credentialId}`, {});

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                )  {
                    reject(Error('DeleteCredentialRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.status);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to delete a credential failed.  Please try again later'));
            }
        });
    }
}
