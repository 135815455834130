
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Notification from '@/domains/ui/views/Notification.vue';
    import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
    import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
    import {MessageItem} from '@/domains/messages/database/messages/MessageItem';
    import MessageAttachments from '@/domains/contacts/views/MessageAttachments.vue';
    import ImageViewer, { MediaViewerMediaInfo } from '@/domains/ui/views/Modals/MediaViewer.vue';
    import {MessageCreatorType} from '@/ts/ValueObjects/MessageCreatorType';
    import HelperFactory from '@/ts/Helpers/HelperFactory';
    import * as MarkdownIt from 'markdown-it';
    import MediaViewer from '@/domains/ui/views/Modals/MediaViewer.vue';

    @Component({
  components: {
      MediaViewer,
      Notification,
      IconButton,
      ActionHeading,
      SpinnerButton,
      BasicButton,
      MessageAttachments,
      ImageViewer,
  },
})
export default class ContactMessageList extends Vue {
    @Prop({required: true}) private messages!: MessageItem[];
    @Prop({required: false, type: Number, default: 0}) private numMessagesLeftToLoadFromServer!: number;
    @Prop({required: false, type: Boolean, default: false}) private loadingMoreMessages!: boolean;

    private $helperFactory!: HelperFactory;

    private imageViewerOpen = false;
    private imageViewerImageInfo: MediaViewerMediaInfo = {
        mediaUrl: '',
        mediaName: '',
        fileId: '',
    };

    private markdown: MarkdownIt = require('markdown-it')({
        breaks: true,
    });

    public getSenderClass(message: MessageItem): string {
        if (!!message.sender_contact_id) {
            return 'contact';
        } else {
            return 'user';
        }
    }

    public timestampToDate(timestamp: number): string {
        return this.$helperFactory.dateHelper.timestampToFriendlyDateWithTime(timestamp * 1000);
    }

    public markdownToHtml(markdown: string): string {
        return this.markdown.render(markdown);
    }

    public handleMessageReadClicked(message: MessageItem): void {
        this.$emit('message-read-clicked', message.id);
    }

    public handleMessageRequiresResponseClicked(message: MessageItem): void {
        this.$emit('message-requires-response-clicked', message.id);
    }

    public handleDeleteMessageIntent(message: MessageItem): void {
        this.$emit('message-delete-clicked', message.id);
    }

    public broadcastSendMessageModalIntent(messageId: string = ''): void {
        this.$emit('show-send-message-modal-intent', messageId);
    }

    public handleReplyToMessage(message: MessageItem): void {
        this.$emit('reply-to-message-intent', message.id);
    }

    public broadcastLoadMoreIntent(): void {
        this.$emit('load-more-intent');
    }

    public handleViewImageIntent(imageInfo: MediaViewerMediaInfo) {
        this.imageViewerImageInfo = imageInfo;
        this.imageViewerOpen = true;
    }

    public getMessageCreatorType(message: MessageItem): MessageCreatorType {
        if (message.sender_contact_id.length > 0) {
            return MessageCreatorType.CONTACT;
        }

        return MessageCreatorType.USER;
    }

    get totalMessages(): number {
        return this.messages.length + this.numMessagesLeftToLoadFromServer;
    }
}
