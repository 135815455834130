import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import FileItem from '@/ts/Database/Files/FileItem';

export default class FileListRequest extends AbstractRequest {
    public async execute(): Promise<FileItem[]> {
        return new Promise<FileItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/file`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('files')))  {
                    reject(Error('FileListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.files);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load files from the API'));
            }
        });
    }
}
