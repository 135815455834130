import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';
import StringHelper from '@/ts/Helpers/StringHelper';

interface RequestParams {
    enabled?: boolean;
    verified?: boolean;
}

export class UserTwoFactorAuthListRequest extends AbstractRequest {
    public async execute(enabled: boolean|null, verified: boolean|null): Promise<UserTwoFactorAuthItem[]> {
        return new Promise<UserTwoFactorAuthItem[]>(async (resolve, reject) => {
            try {
                const params: RequestParams = {};

                if (enabled !== null) {
                    params.enabled = enabled;
                }

                if (verified !== null) {
                    params.verified = verified;
                }

                const uri = StringHelper.toUriString(params);

                const response = await this.apiClient.get(`/private/two_factor_auth?${uri}`);

                if ((!response.hasOwnProperty('data')) ||
                    (!response.data.hasOwnProperty('two_factor_authentication_options')))  {
                    reject(Error('UserTwoFactorAuthListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.two_factor_authentication_options);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load two factor auth methods from the API'));
            }
        });
    }
}
