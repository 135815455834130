import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { CompletionItem } from '@/domains/assistant/database/CompletionItem';
import {AiMaxResponseLength} from '@/domains/ai/types/AiMaxResponseLength';
import {AiSophisticationLevel} from '@/domains/ai/types/AiSophisticationLevel';
import UUID from '@/ts/Database/UUID';

export interface GenerateCompletionRequestParams {
    space_id: string;
    prompt: string;
    response_length: AiMaxResponseLength;
    sophistication_level: AiSophisticationLevel;
}

export class GenerateCompletionRequest extends AbstractRequest {
    public async execute(params: GenerateCompletionRequestParams): Promise<CompletionItem> {
        return new Promise<CompletionItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/ai/completions/generate`, params);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('completions'))
                )  {
                    reject(Error('GenerateCompletionRequest - Invalid response from API'));
                    return;
                }

                const completionItems: CompletionItem[] = [];
                const completions: string[] = response.data.completions;

                // Since at present the server is not storing user completion results,
                // we need to generate a UUID and timestamp client side.
                const uuid = new UUID();
                const currentUnixTimestamp = (new Date().getTime() / 1000);

                const completionItem: CompletionItem = {
                    id: uuid.value,
                    space_id: params.space_id,
                    prompt: params.prompt,
                    completions,
                    created_dtm: currentUnixTimestamp,
                    updated_dtm: currentUnixTimestamp,
                };

                resolve(completionItem);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to generate completions failed.  Please try again later'));
            }
        });
    }
}
