
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class SuccessMessage extends Vue {
        @Prop({required: true}) private message!: string;
        @Prop({required: false, default: 0, type: Number}) private displayTimeSeconds!: number;

        private waitingToHide = false;

        public mounted() {
            this.handleDisplayTime();
        }

        @Watch('message')
        private handleDisplayTime(): void {
            if ((this.displayTimeSeconds <= 0) || (this.message === '') ||
                (this.waitingToHide)) {
                return;
            }

            this.waitingToHide = true;

            setTimeout(() => {
                this.$emit('hide');
                this.waitingToHide = false;
            }, this.displayTimeSeconds * 1000);
        }
    }
