
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import { ErrorObject } from '@/ts/Interfaces/ErrorObject';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import FileItem from '@/ts/Database/Files/FileItem';
import { GalleryItem } from '@/domains/galleries/database/galleries/GalleryItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import ValidationHelper from '@/ts/Helpers/ValidationHelper';
import StringHelper from '@/ts/Helpers/StringHelper';
import SelectOptionInterface from '@/ts/Interfaces/SelectOptionInterface';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import { CreateGalleryParams } from '@/domains/galleries/requests/galleries/CreateGalleryRequest';
import { FileName } from '@/ts/ValueObjects/FileName';
import { GalleryFileItem } from '@/domains/galleries/database/galleryFiles/GalleryFileItem';
import PopSelector from '@/domains/ui/views/Modals/PopSelector/PopSelector.vue';
import {PopSelectorItemInterface} from '@/domains/ui/views/Modals/PopSelector/PopSelectorItemInterface';

@Component({
  components: {
      PopSelector,
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Spinner,
      DeleteItemSpinner,
      Toggle,
  },
})
export default class GalleryEdit extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;
    private $helperFactory!: HelperFactory;

    private galleryItem!: GalleryItem;
    private galleryName!: string;
    private galleryDescription!: string;
    private galleryShared!: boolean;
    private coverPhotoFile: FileItem|null = null;

    private loaded: boolean = false;
    private loading: boolean = false;
    private errorMessage: string = '';
    private editMode: boolean = false;

    private galleryNameOk = Trilean.Unknown;

    // Uploading photo images
    private fileUploading = false;
    private uploadedFiles: FileItem[] = [];
    private deletingFile = false;
    private coverPhotoPopped = false;

    private mounted(): void {
        this.init();
    }

    @Watch('galleryId')
    private init() {
        if (this.$repoManager.gallery) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private get galleryId(): string {
        return this.$store.state.galleryModule.galleryId;
    }

    get publicationModeItems(): PopSelectorItemInterface[] {
        return [
            {
                id: 'do_not_publish',
                label: 'Do not publish photos',
            },
            {
                id: 'show_by_default',
                label: 'Photos are visible by default',
            },
            {
                id: 'hide_by_default',
                label: 'Photos are hidden by default',
            },
        ];
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    get lastChangeLogId(): string {
        return this.$store.state.userModule.lastChangeLogId;
    }

    get galleryBelongsToLoggedInUser(): boolean {
        return this.$store.getters.isCurrentUser(this.galleryItem.owner_user_id);
    }

    private async loadDataFromRepos(): Promise<void> {
        if (this.galleryId !== '') {
            await this.loadGallery();
        } else {
            this.galleryItem = this.$repoManager.gallery.getEmptyItem();
            this.setGalleryAttributesFromObject();
            this.coverPhotoFile = null;
            this.editMode = true;
        }
        this.loaded = true;
    }

    private async loadGallery(): Promise<void> {
        try {
            this.errorMessage = '';
            this.galleryItem = await this.$repoManager.gallery.getItem(this.galleryId);
            this.setGalleryAttributesFromObject();

            if (this.galleryItem.cover_photo_file_id.length > 0) {
                this.coverPhotoFile = await this.$repoManager.file.getItem(this.galleryItem.cover_photo_file_id);
            }
        } catch (error) {
            this.errorMessage = 'Error: Gallery could not be loaded.';
        }
    }

    private setGalleryShared(newValue: boolean): void {
        this.galleryItem.shared = newValue;
        this.galleryShared = newValue;
    }

    private setGalleryAttributesFromObject(): void {
        this.galleryName = this.galleryItem.name;
        this.galleryDescription = this.galleryItem.description;
        this.galleryShared = this.galleryItem.shared;
    }

    private editGallery(): void {
        this.editMode = true;
        this.setGalleryAttributesFromObject();
        this.checkGalleryNameOk();
    }

    private async deleteGallery(): Promise<void> {
        if (!confirm('Delete this gallery?  Are you sure?')) {
            return;
        }

        const deleteGalleryFromRepo = async () => {
            await this.$repoManager.gallery.delete(this.galleryItem);

            this.galleryItem = this.$repoManager.gallery.getEmptyItem();
            this.setGalleryAttributesFromObject();
            this.navigateToGalleriesScreen();
        };

        try {
            this.loading = true;

            const deletedOk =
                await this.$requestFactory.gallery.deleteGalleryRequest.execute(this.galleryId);

            if (!deletedOk) {
                this.errorMessage = 'The gallery could not be deleted.  Please try again later.';
                return;
            }

            deleteGalleryFromRepo();

            await this.$store.dispatch('flagGalleryModuleUpdated');
        } catch (error) {
            // If the error reported by the server is a RecordNotFound error, delete locally anyway.
            // Otherwise show the error
            if (this.$helperFactory.serverErrorHelper.isRecordNotFound(error)) {
                deleteGalleryFromRepo();
                return;
            }

            this.errorMessage = `The gallery could not be deleted.  Error was: ${error}`;
        } finally {
            this.loading = false;
        }
    }

    private cancelEdit(): void {
        this.galleryItem.name = this.galleryName;
        this.galleryItem.description = this.galleryDescription;
        this.galleryItem.shared = this.galleryShared;

        this.editMode = false;

        this.navigateToGalleriesScreen();
    }

    private formValid(): boolean {
        if (this.galleryNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid gallery first name.';
            return false;
        }

        return true;
    }

    private async saveGallery(): Promise<void> {
        this.checkGalleryNameOk();

        if (!this.formValid()) {
            return;
        }

        try {
            this.loading = true;

            if (this.addingNewItem) {
                this.galleryItem = await this.$requestFactory.gallery.createGalleryRequest.execute({
                    space_id: this.selectedSpace,
                    name: this.galleryItem.name,
                    description: this.galleryItem.description,
                    cover_photo_file_id: this.coverPhotoFile ? this.coverPhotoFile.id : '',
                    shared: this.galleryItem.shared,
                    publication_mode: this.galleryItem.publication_mode,
                } as CreateGalleryParams);
            } else {
                this.galleryItem =
                    await this.$requestFactory.gallery.updateGalleryRequest.execute(this.galleryId, {
                    space_id: this.selectedSpace,
                    name: this.galleryItem.name,
                    description: this.galleryItem.description,
                    cover_photo_file_id: this.coverPhotoFile ? this.coverPhotoFile.id : '',
                    shared: this.galleryItem.shared,
                    publication_mode: this.galleryItem.publication_mode,
                });
            }

            await this.$repoManager.gallery.save(this.galleryItem);

            // Update the static view fields.
            this.setGalleryAttributesFromObject();

            this.$store.dispatch('flagGalleryModuleUpdated');

            this.editMode = false;
            this.errorMessage = '';

            this.$router.push({name: Routes.GALLERY, params: { id: this.galleryItem.id }});
        } catch (error) {
            this.errorMessage = 'Failed to save gallery.  Please try again later.';
        } finally {
            this.loading = false;
        }
    }

    private checkGalleryNameOk(): void {
        if ((this.galleryItem.name.length === 0) || (this.galleryItem.name.length > 50)) {
            this.galleryNameOk = Trilean.False;
        } else {
            this.galleryNameOk = Trilean.True;
            this.errorMessage = '';
        }
    }

    private navigateToGalleriesScreen(): void {
        this.$router.push({name: Routes.GALLERY_LIST});
    }

    get addingNewItem(): boolean {
        return this.galleryId === '';
    }

    // If the user has selected a different workspace, redirect back to contacts screen.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.navigateToGalleriesScreen();
        }
    }

    private async uploadMediaFile(): Promise<void> {
        const uploadFile: any = this.$refs.cover_photo;
        const fileName: string = uploadFile.files[0].name;

        // Ensure the selected file looks like an image.
        if (!StringHelper.filenameHasValidMediaExtension(fileName)) {
            this.errorMessage = `$fileName does not appear to be a valid media file supported by the gallery.`;
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile.files[0]);

        try {
            const params: UploadFileParams = {
                formData,
            };

            this.fileUploading = true;

            const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'gallery');
            await this.$repoManager.file.save(fileItem);

            this.coverPhotoFile = fileItem;
            this.uploadedFiles.push(fileItem);
            this.fileUploading = false;

            await this.$store.dispatch('flagGalleryModuleUpdated');
        } catch (error) {
            this.errorMessage = 'Error uploading file: ' + error.toString();
        } finally {
            this.fileUploading = false;
        }
    }

    private async deleteCoverPhoto(): Promise<void> {
        if (!this.coverPhotoFile) {
            return;
        }

        if (!(confirm(`Delete the uploaded cover photo?  Are you sure?`))) {
            return;
        }

        try {
            this.deletingFile = true;
            const tempFile = this.coverPhotoFile;

            this.coverPhotoFile = null;

            if ((this.editMode) && (this.galleryId !== '')) {
                // If we're editing an existing gallery, save the gallery to clear the
                // foreign key to the file
                await this.saveGallery();
            }

            // Delete the file from the server
            const ok = await this.$requestFactory.deleteFileRequest.execute(tempFile.id);
            if (!ok) {
                this.errorMessage = 'The hero image could not be deleted.  Please try again later.';
            }

            // Remove the file from the local repo too.
            await this.$repoManager.file.delete(tempFile);
            await this.$store.dispatch('flagGalleryModuleUpdated');
        } catch (error) {
            this.errorMessage = 'The file could not be deleted.  Please try again later.';
        } finally {
            this.deletingFile = false;
        }
    }

    get coverPhotoBackgroundUrl(): string {
        if ((this.coverPhotoFile) && (!this.coverPhotoPopped)) {
            return `background-image:url('${this.coverPhotoFile.url}');`;
        }

        return '';
    }

    @Watch('lastChangeLogId')
    private async onChangeLogUpdated(): Promise<void> {
        // await this.loadMessages();
    }
}
