import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import StringHelper from '@/ts/Helpers/StringHelper';
import { CredentialItem } from './CredentialItem';
import { Store } from 'vuex';
import Trilean from '@/ts/Trilean';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { VaultAccessDetails } from '@/domains/vault/interfaces/VaultAccessDetails';
import { CredentialViewItem } from '@/domains/credentials/interfaces/CredentialViewItem';
import {ImportCredentialsResponse} from '@/domains/credentials/requests/ImportCredentialRequest';

export class CredentialRepo extends AbstractRepo<CredentialItem> {
    public static indexDbStoreName = 'credentials';
    private store: Store<any>;
    private credentialStore: any;
    private requestFactory: RequestFactory;

    public constructor(
        db: IDBDatabase,
        store: Store<any>,
        credentialStore: any,
        requestFactory: RequestFactory,
    ) {
        super(db, CredentialRepo.indexDbStoreName);
        this.store = store;
        this.credentialStore = credentialStore;
        this.requestFactory = requestFactory;
    }

    public async vaultPasswordIsSet(): Promise<boolean> {
        // If the Vuex Store doesn't know yet if the vault password is set, load that info from the server.
        if (this.credentialStore.vaultPasswordSet === Trilean.Unknown) {
            const isSet = await this.requestFactory.vault.checkVaultPasswordSet.execute();

            // Commit the vault password status back to the store.
            this.store.commit('setVaultPasswordSet', isSet ? Trilean.True : Trilean.False);

            return isSet;
        }

        return this.credentialStore.vaultPasswordSet === Trilean.True;
    }

    public async setVaultPassword(vaultPassword: string, totpCode: string): Promise<void> {
        if (this.credentialStore.vaultPasswordSet === Trilean.True) {
            throw Error('CredentialRepo::setVaultPassword - Vault password is already set!');
        }

        await this.requestFactory.vault.setVaultPassword.execute(vaultPassword, totpCode);

        this.store.commit('setVaultPasswordSet', Trilean.True);
    }

    /**
     * Returns true if the user currently has valid vault access.
     */
    public vaultAccessCurrentlyValid(): boolean {
        const currentUnixTimestamp = (new Date().getTime() / 1000);

        // Subtract 10 seconds off vault access validity time to ensure we have enough time to make
        // a request to the server if necessary (e.g. to save or load a credential).
        const vaultAccessValidUntilTimestamp = this.credentialStore.vaultAccessTimeToLive - 10;

        return vaultAccessValidUntilTimestamp > currentUnixTimestamp;
    }

    /**
     * Returns the current vault access details from the store.
     */
    public getVaultAccessDetails(): VaultAccessDetails|null {
        if (!this.vaultAccessCurrentlyValid()) {
            return null;
        }

        return {
            vaultAccessId: this.credentialStore.vaultAccessId,
            tokenPartA: this.credentialStore.vaultTokenPartA,
            timeToLive: this.credentialStore.vaultAccessTimeToLive,
        };
    }

    public async requestVaultAccess(vaultPassword: string, totpCode: string = ''): Promise<VaultAccessDetails> {
        const vaultAccessResponse = await this.requestFactory.vault.requestVaultAccess.execute(vaultPassword, totpCode);
        await this.store.dispatch('setVaultAccessDetails', vaultAccessResponse);

        return {
            vaultAccessId: vaultAccessResponse.vault_access_id,
            tokenPartA: vaultAccessResponse.token_part_a,
            timeToLive: vaultAccessResponse.time_to_live,
        };
    }

    public async importCredentialsFromFile(
        spaceId: string,
        vaultAccessId: string,
        tokenPartA: string,
        fileId: string,
    ): Promise<ImportCredentialsResponse> {
        return await this.requestFactory.credential.importCredentials.execute({
            space_id: spaceId,
            token_part_a: tokenPartA,
            vault_access_id: vaultAccessId,
            file_id: fileId,
        });
    }

    public async clearVaultAccess(): Promise<void> {
        await this.store.dispatch('clearVaultAccessDetails');
    }

    public async requestCreateCredential(
        spaceId: string,
        vaultAccessId: string,
        tokenPartA: string,
        title: string,
        url: string,
        emailAddress: string,
        username: string,
        password: string,
        notes: string,
    ): Promise<CredentialItem> {
        const credentialItem = await this.requestFactory.credential.createCredential.execute({
            space_id: spaceId,
            vault_access_id: vaultAccessId,
            token_part_a: tokenPartA,
            title,
            url,
            email_address: emailAddress,
            username,
            password,
            notes,
        });

        await this.save(credentialItem);

        return credentialItem;
    }

    public async requestUpdateCredential(
        credentialId: string,
        spaceId: string,
        vaultAccessId: string,
        tokenPartA: string,
        title: string,
        url: string,
        emailAddress: string,
        username: string,
        password: string,
        notes: string,
    ): Promise<CredentialItem> {
        const credentialItem = await this.requestFactory.credential.updateCredential.execute(credentialId, {
            space_id: spaceId,
            vault_access_id: vaultAccessId,
            token_part_a: tokenPartA,
            title,
            url,
            email_address: emailAddress,
            username,
            password,
            notes,
        });

        await this.save(credentialItem);

        return credentialItem;
    }

    public async requestCreateView(
        credentialId: string,
        spaceId: string,
        vaultAccessId: string,
        tokenPartA: string,
    ): Promise<CredentialViewItem> {
        return await this.requestFactory.credential.viewCredential.execute(credentialId, {
            space_id: spaceId,
            vault_access_id: vaultAccessId,
            token_part_a: tokenPartA,
        });
    }

    public async requestDeleteCredential(credentialItem: CredentialItem): Promise<boolean> {
        const result = await this.requestFactory.credential.deleteCredential.execute(credentialItem.id);

        await this.delete(credentialItem);

        return result;
    }

    public async requestListCredentials(): Promise<CredentialItem[]> {
        return await this.requestFactory.credential.listCredentials.execute();
    }

    public setSearchKeywords(keywords: string): void {
        this.store.commit('setCredentialSearchKeywords', keywords);
    }

    public getSearchKeywords(): string {
        return this.credentialStore.credentialSearchKeywords;
    }

    public async getList(
        spaceId: string,
        search: string = '',
    ): Promise<CredentialItem[]> {
        return new Promise<CredentialItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: CredentialItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        let pass = true;

                        if (search !== '') {
                            const foundInTitle =
                                StringHelper.searchInText(searchKeywords, item.title);

                            const foundInUrl =
                                StringHelper.searchInText(searchKeywords, item.url);

                            pass = (foundInTitle || foundInUrl);
                        }

                        if (pass) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    // Credentials should always be returned in alphabetical order.
                    items.sort((a: CredentialItem, b: CredentialItem) => {
                        const lowerA = a.title.toLowerCase();
                        const lowerB = b.title.toLowerCase();

                        if (lowerA < lowerB) {
                            return - 1;
                        } else if (lowerA > lowerB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });


                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }
}
