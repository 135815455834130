
import { Component, Vue, Prop } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import RepoManager from '@/ts/Database/RepoManager';
import { Routes } from '@/domains/app/router/router';

@Component({
  components: {
      Container,
      Notification,
      Spinner,
  },
})
export default class Logout extends Vue {
    private $repoManager!: RepoManager;

    private mounted(): void {
        if (this.$repoManager.note) {
            this.removeAllData();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.removeAllData();
        }, false);
    }

    private async removeAllData(): Promise<void> {
        try {
            // Clear all data from the repos
            await this.$repoManager.note.clear();
            await this.$repoManager.noteCategory.clear();
            await this.$repoManager.user.clear();
            await this.$repoManager.file.clear();
            await this.$repoManager.noteFile.clear();
            await this.$repoManager.recipe.clear();
            await this.$repoManager.recipeCategory.clear();
            await this.$repoManager.space.clear();
            await this.$repoManager.galleryFile.clear();
            await this.$repoManager.gallery.clear();
            await this.$repoManager.credential.clear();
            await this.$repoManager.credential.clearVaultAccess();
            await this.$repoManager.moduleGroup.clear();
            await this.$repoManager.moduleSpace.clear();
            await this.$repoManager.contact.clear();
            await this.$repoManager.kanbanTask.clear();
            await this.$repoManager.kanbanColumns.clear();
            await this.$repoManager.message.clear();

            // Reset the stores login status
            this.$store.dispatch('logoutUser');

            setTimeout(() => {
                // Move the user back to the login screen.
                this.$router.push({name: Routes.LOGIN});
            }, 500);
        } catch (error) {
            this.$router.push({name: Routes.LOGIN});
        }
    }
}
