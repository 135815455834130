import FileListRequest from '@/ts/Requests/File/FileListRequest';

export default class Address {
    private addressLine1: string;
    private addressLine2: string;
    private suburb: string;
    private postcode: string;
    private state: string;
    private country: string;

    constructor(
        addressLine1: string,
        addressLine2: string,
        suburb: string,
        postcode: string,
        state: string,
        country: string,
    ) {
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.suburb = suburb;
        this.postcode = postcode;
        this.state = state;
        this.country = country;
    }

    public toString(delimiter: string = '<br>'): string {
        const parts = [
            this.addressLine1,
            this.addressLine2,
            this.suburb + ' ' + this.postcode,
            this.state + ' ' + this.country,
        ];

        const result = parts.filter((item) => {
            return item.trim().length > 1;
        }).join(delimiter);

        return result;
    }
}
