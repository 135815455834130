
import { Component, Vue, Watch } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RequestFactory from '@/ts/Requests/RequestFactory';
import RepoManager from '@/ts/Database/RepoManager';
import { SpaceItem } from '@/domains/spaces/database/SpaceItem';
import { RecipeCategoryItem } from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Modal from '@/domains/ui/views/Modal.vue';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';
import { Trilean } from '@/ts/Trilean';
import SpaceRepo from '@/domains/spaces/database/SpaceRepo';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';

@Component({
  components: {
      ActionHeading,
      Modal,
      InputChecker,
      SpinnerButton,
      ErrorMessage,
      DeleteConfirmationModal,
  },
})
export default class RecipeCategoryAdmin extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private loaded = false;
    private errorMessage = '';
    private categories: RecipeCategoryItem[] = [];
    private editCategoryModalOpen = false;
    private currentCategoryItem: RecipeCategoryItem|null = null;
    private categoryNameOk: Trilean = Trilean.Unknown;
    private spaceIdOK: Trilean = Trilean.Unknown;
    private categoryName = '';
    private spaceId = '';
    private categoryNameLastChangedTime = 0;
    private savingCategory = false;
    private deleteModalOpen = false;
    private deleteInProgress = false;
    private categoryRecipeMap: { [recipeCategoryId: string]: number } = {};
    private selectedSpaceId: string = '';

    public mounted() {
        if (this.$repoManager.user) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    public navigateToAdminMenu(): void {
        this.$router.push({name: Routes.ADMIN});
    }

    public async editCategory(categoryId: string): Promise<void> {
        try {
            this.currentCategoryItem = await this.$repoManager.recipeCategory.getItem(categoryId);
            this.setModelFromItem(this.currentCategoryItem);
            this.editCategoryModalOpen = true;
        } catch (error) {
            this.errorMessage = error.toString();
        }
    }

    public async handleUpdateCategory(): Promise<void> {
        if (!this.currentCategoryItem) {
            return;
        }

        if (this.categoryName === '') {
            this.categoryNameOk = Trilean.False;
        }

        if (this.spaceId === '') {
            this.spaceIdOK = Trilean.False;
        }

        if ((this.categoryName === Trilean.False) || (this.spaceIdOK === Trilean.False)) {
            return;
        }

        try {
            this.savingCategory = true;

            const categoryItem = await this.$requestFactory.updateRecipeCategoryRequest.execute({
                id: this.currentCategoryItem.id,
                name: this.categoryName,
                space_id: this.spaceId,
            });

            await this.$repoManager.recipeCategory.save(categoryItem);
            await this.loadCategories();
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.savingCategory = false;
            this.editCategoryModalOpen = false;
        }
    }

    public async handledeleteCategoryIntent(categoryId: string): Promise<void> {
        this.currentCategoryItem = await this.$repoManager.recipeCategory.getItem(categoryId);
        this.deleteModalOpen = true;
    }

    public async deleteCategory(categoryId: string): Promise<void> {
        if (!this.currentCategoryItem) {
            return;
        }

        try {
            await this.$requestFactory.deleteRecipeCategoryRequest.execute(categoryId);
            await this.$repoManager.recipeCategory.delete(this.currentCategoryItem);
            await this.loadCategories();

            this.currentCategoryItem = null;
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.deleteModalOpen = false;
        }
    }

    public numRecipesInCategory(categoryId: string): number {
        return this.categoryRecipeMap[categoryId];
    }

    get spaces(): SpaceItem[] {
        return this.$store.state.spaceModule.spaces;
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadCategories();
        this.loaded = true;
    }

    private async loadCategories(): Promise<void> {
        const categories = await this.$repoManager.recipeCategory.getList(this.selectedSpaceId, '');
        this.categoryRecipeMap = {};

        // For each space, load the recipe categories so we can list how many categories are in each space.
        for (const categoryItem of categories) {
            this.categoryRecipeMap[categoryItem.id] =
                await this.$repoManager.recipe.countRecipesInCategory(categoryItem.id);
        }

        this.categories = categories;
    }

    private setModelFromItem(item: RecipeCategoryItem): void {
        this.categoryName = item.name;
        this.spaceId = item.space_id;
    }

    @Watch('selectedSpaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.recipeCategory) {
            this.loadDataFromRepos();
        }
    }
}
