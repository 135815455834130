
    // Components
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import AppFooter from '@/domains/app/views/AppFooter.vue';
    import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
    import Form from '@/domains/ui/views/Form.vue';
    import InputChecker from '@/domains/ui/views/InputChecker.vue';
    import Modal from '@/domains/ui/views/Modal.vue';
    import Navigation from '@/domains/app/views/Navigation.vue';
    import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
    
    // Typescript
    import ApiClient from '@/ts/ApiClient';
    import { AppSettings } from '@/domains/app/interfaces/AppSettings';
    import HelperFactory from '@/ts/Helpers/HelperFactory';
    import RepoManager from '@/ts/Database/RepoManager';
    import RequestFactory from '@/ts/Requests/RequestFactory';
    import { Routes } from '@/domains/app/router/router';
    import { Trilean } from '@/ts/Trilean';
    import {ModuleGroupItem} from '@/domains/module/database/moduleGroup/ModuleGroupItem';
    import {ModuleType} from '@/ts/Enums/ModuleType';

    @Component({
        components: {
            AppFooter,
            IncludeIf,
            Navigation,
            Modal,
            SpinnerButton,
            Form,
            InputChecker,
        },
    })
    export default class App extends Vue {
        private $requestFactory!: RequestFactory;
        private $http!: ApiClient;
        private $helperFactory!: HelperFactory;
        private $repoManager!: RepoManager;

        private addNewSpaceModalOpen = false;
        private appSettings: AppSettings|null = null;
        private newSpaceName: string = '';
        private spaceNameOk: Trilean = Trilean.Unknown;
        private newSpaceShared = false;
        private newSpaceNameLastChangedTime = 0;
        private moduleGroups: ModuleGroupItem[] = [];
        private enabledModulesForSpace: { [key: string]: boolean } = {};

        public mounted(): void {
            const myWin: any = window;
            this.appSettings = myWin.appSettings;
            this.$store.commit('setAppSettings', this.appSettings);

            this.onSessionExpired();
        }

        get isAdmin(): boolean {
            return this.$store.state.userModule.isAdmin;
        }

        get isLoggedIn(): boolean {
            return this.$store.state.userModule.userLoggedIn;
        }

        public created(): void {
            this.$helperFactory.breakPoint.init(this.$store);
        }

        @Watch('isLoggedIn')
        public loggedOut(): void {
            if (!this.isLoggedIn) {
                // If we're not on the logout page already take the user there so all their data is removed etc.
                if (!this.$route.path.indexOf(Routes.LOGOUT)) {
                    this.$router.push({name: Routes.LOGOUT});
                }
            }
        }

        public async checkSpaceNameAvailable(): Promise<void> {
            if (this.newSpaceName === '') {
                this.spaceNameOk = Trilean.False;
                return;
            }

            const exists = await this.$requestFactory.spaceExistsRequest.execute(this.newSpaceName);
            this.spaceNameOk = exists ? Trilean.False : Trilean.True;
        }

        private onSessionExpired(): void {
            document.addEventListener('SessionExpired', () => {
                this.$router.push({name: Routes.LOGOUT});
            }, false);
        }

        private async handleAddSpaceIntent(): Promise<void> {
            this.moduleGroups = await this.$repoManager.moduleGroup.getList();
            this.enabledModulesForSpace = {};

            // Default all module groups to ON for a new space.
            for (const moduleGroup of this.moduleGroups) {
                this.enabledModulesForSpace[moduleGroup.module_type] = true;
            }

            this.addNewSpaceModalOpen = true;
            this.newSpaceName = '';
            this.newSpaceShared = true;
            this.spaceNameOk = Trilean.Unknown;
        }

        private async handleAddSpace(): Promise<void> {
            if (this.newSpaceName === '') {
                this.spaceNameOk = Trilean.False;
            }

            if (this.spaceNameOk === Trilean.False) {
                return;
            }

            try {
                const spaceItem = await this.$requestFactory.addSpaceRequest.execute({
                    name: this.newSpaceName,
                    shared: this.newSpaceShared,
                });

                await this.$repoManager.space.save(spaceItem);

                for (const moduleType of Object.keys(this.enabledModulesForSpace)) {
                    if (this.enabledModulesForSpace[moduleType]) {
                        const moduleSpaceItem = await this.$repoManager.moduleSpace.enableModuleSpace(
                            moduleType as ModuleType,
                            spaceItem.id,
                        );

                        await this.$repoManager.moduleSpace.save(moduleSpaceItem);
                    }
                }


                this.addNewSpaceModalOpen = false;

                this.$store.commit('setSelectedSpace', spaceItem.id);
                this.$store.commit('setSpaceLastUpdatedTimestamp', (new Date()).getTime());
            } catch (error) {
                // TODO handle error
            }
        }

        private handleSpaceNameChanged() {
            this.newSpaceNameLastChangedTime = (new Date()).getTime();

            setTimeout(() => {
                const diff = (new Date()).getTime() - this.newSpaceNameLastChangedTime;

                if (diff >= 750) {
                    this.checkSpaceNameAvailable();
                }
            }, 750);
        }
    }
