
    // Components
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    
    import SelectorItem from '@/domains/ui/views/Switches/Selector/SelectorItem.vue';

    // Typescript
    import { SelectableItemInterface } from '@/domains/ui/views/Switches/Selector/SelectableItemInterface';
    import { SelectorItemType } from '@/domains/ui/views/Switches/Selector/SelectorItemType';
    
    @Component({
        components: {
            SelectorItem,
        },
    })
    export default class Selector extends Vue {
        @Prop({required: true, type: String})
        private defaultLabel!: string;

        @Prop({required: true, type: Array})
        private items!: SelectableItemInterface[];

        @Prop({required: true})
        public selectedItem!: SelectableItemInterface|null;

        public isOpen: boolean = false;

        get firstItemLabel(): string {
            if (this.selectedItem) {
                return this.selectedItem.label;
            } else {
                return this.defaultLabel;
            }
        }

        get selectorItemTypeOpen(): SelectorItemType {
            return SelectorItemType.Open;
        }

        get selectorItemTypeClose(): SelectorItemType {
            return SelectorItemType.Close;
        }

        get selectorItemTypeSelect(): SelectorItemType {
            return SelectorItemType.Select;
        }

        public handleItemSelected(value: string) {
            for (const item of this.items) {
                if (item.value === value) {
                    this.isOpen = false;
                    this.broadcastSelectionChanged(item);
                    break;
                }
            }
        }

        public handleClearSelectedItem(): void {
            this.broadcastSelectionChanged(null);
        }

        private broadcastSelectionChanged(item: SelectableItemInterface|null): void {
            this.$emit('item-changed', item);
        }
    }
