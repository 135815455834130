
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DisplayType } from '@/ts/Enums/DisplayType';
import { DisplayTypeHelper } from '@/ts/Enums/DisplayType';
import { ModalWidthType } from '@/ts/Enums/ModalWidthType';

@Component({
  components: {
  },
})
export default class Modal extends Vue {
    @Prop({required: true}) public open!: boolean;
    @Prop({required: false}) public displayType!: DisplayType;
    @Prop({required: false}) public widthType!: ModalWidthType;

    private widthClass: string = ModalWidthType.STANDARD;

    public mounted() {
        if (!!this.widthType) {
            this.widthClass = this.widthType;
        }
    }

    get displayTypeClass(): string {
        const helper = new DisplayTypeHelper(this.displayType);
        return helper.className;
    }

    public broadcastCloseModal(): void {
        this.$emit('close-modal-intent', {});
    }
}
