
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class Notification extends Vue {
        @Prop({ required: true, type: String })
        private message!: string;
    }
