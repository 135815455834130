import Vue from 'vue';
import App from './domains/app/views/App.vue';
import router from './domains/app/router/router';
import store from '@/domains/app/store/store';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import { WebsocketManager } from '@/ts/WebsocketManager';
import { TokenRefreshManager } from '@/ts/TokenRefreshManager';
import './registerServiceWorker';

// START OF FONT AWESOME ----------------------------
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faHandPointRight } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {MaintenanceService} from '@/ts/MaintenanceService';

// @ts-ignore
library.add(faArrowUp);
// @ts-ignore
library.add(faCheck);
// @ts-ignore
library.add(faTimes);
// @ts-ignore
library.add(faShareAlt);
// @ts-ignore
library.add(faSpinner);
// @ts-ignore
library.add(faPlus);
// @ts-ignore
library.add(faArrowLeft);
// @ts-ignore
library.add(faPencilAlt);
// @ts-ignore
library.add(faTrashAlt);
// @ts-ignore
library.add(faClock);
// @ts-ignore
library.add(faImage);
// @ts-ignore
library.add(faEye);
// @ts-ignore
library.add(faEyeSlash);
// @ts-ignore
library.add(faQuestion);
// @ts-ignore
library.add(faQuestionCircle);
// @ts-ignore
library.add(faReply);
// @ts-ignore
library.add(faStar);
// @ts-ignore
library.add(faFile);
// @ts-ignore
library.add(faLock);
// @ts-ignore
library.add(faBinoculars);
// @ts-ignore
library.add(faHandPointRight);
// @ts-ignore
library.add(faClipboard);
// @ts-ignore
library.add(faUpload);
// @ts-ignore
library.add(faPeopleArrows);
// @ts-ignore
library.add(faCloudDownloadAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// Use the API URL defined in the .env.local file if present, otherwise default to the live URL.
const BASE_URL = process.env.VUE_APP_BASE_URL || 'https://sleepingmind.app/';
const API_URL = BASE_URL + 'api';
const apiClient = new ApiClient(API_URL);

// Check for the presence of a token and
const tempState: any = store.state;
if (tempState.hasOwnProperty('userModule')) {
    if (tempState.userModule.hasOwnProperty('token')) {
        apiClient.setToken(tempState.userModule.token);
    }
}

const requestFactory = new RequestFactory(apiClient);
const repoManager = new RepoManager(store, requestFactory);
const helperFactory = new HelperFactory(BASE_URL, store);

Vue.use(apiClient);
Vue.use(repoManager);
Vue.use(requestFactory);
Vue.use(helperFactory);

store.dispatch('initialiseRequestFactory', requestFactory);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

setTimeout(() => {
    const websocketManager = new WebsocketManager(helperFactory, store);
    websocketManager.checkSockConnection();

    const tokenRefreshManager = new TokenRefreshManager(helperFactory, requestFactory, store);
    tokenRefreshManager.updateTokenPeriodically();

    const maintenanceService = new MaintenanceService(repoManager, store);
    maintenanceService.start();
}, 300);
