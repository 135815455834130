import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import StringHelper from '@/ts/Helpers/StringHelper';
import { GalleryItem } from './GalleryItem';
import { PublicationMode } from '@/domains/credentials/types/PublicationMode';

export class GalleryRepo extends AbstractRepo<GalleryItem> {
    public static indexDbStoreName = 'galleries';

    public constructor(db: IDBDatabase) {
        super(db, GalleryRepo.indexDbStoreName);
    }

    public async getList(
        spaceId: string,
        search: string = '',
        showSharedOnly: boolean,
        contactId: string = '',
    ): Promise<GalleryItem[]> {
        return new Promise<GalleryItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: GalleryItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        let pass = true;

                        if (pass) {
                            pass = item.contact_id === contactId;
                        }

                        if ((pass) && (showSharedOnly)) {
                            pass = item.shared;
                        }

                        if ((pass) && (search !== '')) {
                            const foundInName =
                                StringHelper.searchInText(searchKeywords, item.name);

                            const foundInDescription =
                                StringHelper.searchInText(searchKeywords, item.description);

                            pass = (foundInName || foundInDescription);
                        }

                        if (pass) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    // Galleries should always be returned in alphabetical order.
                    items.sort((a: GalleryItem, b: GalleryItem) => {
                        const lowerA = a.name.toLowerCase();
                        const lowerB = b.name.toLowerCase();

                        if (lowerA < lowerB) {
                            return - 1;
                        } else if (lowerA > lowerB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });


                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    public getEmptyItem(noteCategoryId: string = ''): GalleryItem {
        return {
            id: '',
            space_id: '',
            owner_user_id: '',
            name: '',
            description: '',
            contact_id: '',
            cover_photo_file_id: '',
            shared: false,
            publication_mode: PublicationMode.DO_NOT_PUBLISH,
            created_dtm: 0,
            updated_dtm: 0,
        };
    }
}
