import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import SpaceItem from '@/domains/spaces/database/SpaceItem';

export interface UpdateSpaceParams {
    id: string;
    name: string;
    shared: boolean;
}

export default class UpdateSpaceRequest extends AbstractRequest {
    public async execute(params: UpdateSpaceParams): Promise<SpaceItem> {
        return new Promise<SpaceItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.put(`/private/space/${params.id}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('space')))  {
                    reject(Error('UpdateSpaceRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.space);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a space failed.  Please try again later'));
            }
        });
    }
}
