import RecipeItem from '@/domains/recipies/database/recipies/RecipeItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export default class RecipeRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: RecipeItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: RecipeItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error('Failed to insert ${this.storeName}'));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<RecipeItem> {
        return new Promise<RecipeItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error('Unable to load recipe item with id: ' + id));
                }
            };

            request.onerror = () => {
                reject(new Error('Unable to load recipe item with id: ' + id));
            };
        });
    }

    public async getList(
        recipeCategoryId: string = '',
        recipeCategoryMap: { [recipeCategoryId: string]: boolean },
        search: string = '',
    ): Promise<RecipeItem[]> {
        const searchUpperCase = search.toUpperCase();

        return new Promise<RecipeItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;

            const searchingWithinCategory = (recipeCategoryId !== '');

            if (searchingWithinCategory) {
                const index = store.index('category_id');
                request = index.openCursor(IDBKeyRange.only(recipeCategoryId));
            } else {
                request = this.db.transaction([this.storeName], 'readonly')
                    .objectStore(this.storeName).openCursor();
            }

            const items: RecipeItem[] = [];
            const searchKeywords = search.split(' ');

            const shouldContinueLoop = ((currentRecipeCategoryId: string) => {
                if (searchingWithinCategory) {
                    return true;
                }

                if (recipeCategoryMap.hasOwnProperty(currentRecipeCategoryId)) {
                    return true;
                }

                return false;
            });

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (shouldContinueLoop(item.recipe_category_id)) {
                        if (search === '') {
                            items.push(item);
                        } else {
                            const foundInTitle = StringHelper.searchInText(searchKeywords, item.title);
                            const foundInBody = StringHelper.searchInText(searchKeywords, item.description);

                            if (foundInTitle || foundInBody) {
                                items.push(item);
                            }
                        }
                    }
                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: RecipeItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.title}' could not be deleted`));
            };
        });
    }

    public async countRecipesInCategory(categoryId: string): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            const index = store.index('category_id');
            const request = index.count(categoryId);

            request.onsuccess = (event: Event) => {
                resolve(request.result);
            };

            request.onerror = () => {
                reject(new Error(`Failed to perform count for store ${this.storeName}!`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptyRecipeItem(recipeCategoryId: string = ''): RecipeItem {
        return {
            id: '',
            recipe_category_id: recipeCategoryId,
            created_by_user_id: '',
            title: '',
            description: '',
            preparation_time: 0,
            cooking_time: 0,
            ingredients: [],
            instructions: [],
            hero_image_file_id: '',
            created_dtm: 0,
            updated_dtm: 0,
        };
    }

    private get storeName(): string {
        return 'recipes';
    }
}
