
import { Component, Vue } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue'; // @ is an alias to /src
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Sync from '@/ts/Database/Sync';
import { UserTwoFactorAuthMethod } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthMethod';
import Container from '@/domains/app/views/Container.vue';
import { LoginParams } from '@/ts/Requests/Auth/Interfaces/LoginParams';
import StringHelper from '@/ts/Helpers/StringHelper';
import { LoginRequest } from '@/ts/Requests/Auth/LoginRequest';

@Component({
  components: {
      Form,
      ErrorMessage,
      SpinnerButton,
      Container,
  },
})
export default class Login extends Vue {
    private $http!: ApiClient;
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private errorMessage: string = '';
    private loading: boolean = false;
    private step = 1;

    // Model fields
    private emailAddress: string = '';
    private password: string = '';
    private totpCode: string = '';
    private allowRoaming: boolean = true;
    private useTOTP: boolean = false;

    private async login(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        const loginParams: LoginParams = {
            email_address: this.emailAddress,
            password: this.password,
            allow_roaming: this.allowRoaming,
        };

        if (this.useTOTP) {
            loginParams.totp_code = this.twoFactorCodeAsNumber;
        }

        const request = new LoginRequest(this.$http);

        try {
            this.errorMessage = '';
            this.loading = true;

            const loginResponse = await request.execute(loginParams);

            await this.$store.commit('setSessionData', loginResponse);
            await this.$store.commit('setUserId', loginResponse.user_id);
            await this.$store.commit('setAllowRoaming', this.allowRoaming);
            this.$http.setToken(loginResponse.token);

            this.totpCode = '';

            await this.sync();
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.loading = false;
        }
    }

    private formValid(): boolean {
        if (this.emailAddress.length < 5) {
            this.errorMessage = 'You must enter a valid email address.';
            return false;
        }

        if (this.step > 1) {
            if (this.password.length === 0) {
                this.errorMessage = 'Please enter your password.';
                return false;
            }

            if (this.useTOTP) {
                if (this.totpCode.length === 0) {
                    this.errorMessage = 'Please enter your Authenticator Code.';
                    return false;
                }
            }
        }

        return true;
    }

    private async sync(): Promise<void> {
        await this.$store.commit('setSyncFinished', false);

        const sync = new Sync(this.$http, this.$repoManager, this.$requestFactory);
        this.loading = true;

        try {
            const lastChangeLogId = await sync.execute();

            await this.$store.commit('setLastSyncTimestamp', (new Date()).getTime());
            await this.$store.commit('setLastChangeLogId', lastChangeLogId);
            await this.$store.commit('setSyncFinished', true);

            // Give the navigation time to load before going to the home route - this will prevent the route
            // guards there thinking modules are not enabled when they infact are.
            setTimeout(async () => {
                await this.$router.push({name: Routes.HOME});
            }, 500);
        } catch (error) {
            this.errorMessage = 'An error occurred whilst trying to load your data.  Please try again later.';

            if (!error.toString().indexOf('navigation guard')) {
                /* tslint:disable */
                console.log("Sync Error: ", error.toString());
                /* tslint:enable */
            }
        } finally {
            this.loading = false;
        }
    }

    public async checkTotpMethods(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        this.useTOTP = false;
        await this.$repoManager.user.cacheTwoFactorAuthMethod(null);

        try {
            this.loading = true;

            const userTwoFactorAuthList =
                await this.$requestFactory.userTwoFactorAuthListPublicRequest.execute(this.emailAddress);

            if (userTwoFactorAuthList.length > 0) {
                for (const userTwoFactorAuth of userTwoFactorAuthList) {
                    if (userTwoFactorAuth.two_factor_auth_type === UserTwoFactorAuthMethod.TOTP) {
                        this.useTOTP = true;
                        await this.$repoManager.user.cacheTwoFactorAuthMethod(userTwoFactorAuth);
                        break;
                    }
                }
            }

            this.step++;
        } catch (error) {
            if (error.code === 'RecordNotFound') {
                this.errorMessage = 'Sorry, we have no user registered to that email address.';
            } else {
                this.errorMessage = error.toString();
            }
        } finally {
            this.loading = false;
        }
    }

    private get twoFactorCodeAsNumber(): number {
        const codeWithoutSpaces = StringHelper.removeSpaces(this.totpCode);
        return StringHelper.toInt(codeWithoutSpaces);
    }
}
