import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import SpaceItem from '@/domains/spaces/database/SpaceItem';
import ContactItem from '@/domains/contacts/database/ContactItem';

export interface MoveEntityToSpaceParams {
    fromSpaceId: string;
    toSpaceId: string;
    entityType: string;
    entityId: string;
}

export default class MoveEntityToSpaceRequest extends AbstractRequest {
    public async execute(params: MoveEntityToSpaceParams): Promise<ContactItem> {
        return new Promise<ContactItem>(async (resolve, reject) => {
            if (params.entityType !== 'CONTACT') {
                throw new Error(`MoveEntityToSpaceRequest - Unsupported entity type: ${params.entityType}`);
            }

            try {
                const response = await this.apiClient.patch(
                    `/private/space/move_entity/${params.fromSpaceId}/${params.toSpaceId}`,
                    {
                        entity_type: params.entityType.toUpperCase(),
                        entity_id: params.entityId,
                    },
                );

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('item')))  {
                    reject(Error('MoveEntityToSpaceRequest - Invalid response from API'));
                    return;
                }

                // Note in the future when we want to support other entity types,
                // use a boolean ContactItem|GalleryItem|etc for the return types and a switch condition here.
                const contactItem = response.data.item as ContactItem;
                resolve(contactItem);
            } catch (error) {
                reject(this.convertResponseError(error,
                    `Your request to move an entity to another space failed.  Error was: ${error}`));
            }
        });
    }
}
