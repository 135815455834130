export interface ContactsSummaryInterface {
    totalMessagesUnread: number;
    totalMessagesNeedToRespond: number;
    totalFavourites: number;
    totalShared: number;
}

export function getBlankContactsSummary(): ContactsSummaryInterface {
    return {
        totalMessagesUnread: 0,
        totalMessagesNeedToRespond: 0,
        totalFavourites: 0,
        totalShared: 0,
    };
}
