
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import ContactEdit from '@/domains/contacts/views/ContactEdit.vue';
import ContactOverview from '@/domains/contacts/views/ContactOverview.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import ContactMessageList from '@/domains/ui/views/ContactMessageList.vue';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import FileItem from '@/ts/Database/Files/FileItem';
import ContactItem from '@/domains/contacts/database/ContactItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import { UploadFileParams } from '@/ts/Requests/File/UploadFileRequest';
import ValidationHelper from '@/ts/Helpers/ValidationHelper';
import StringHelper from '@/ts/Helpers/StringHelper';
import SelectOptionInterface from '@/ts/Interfaces/SelectOptionInterface';
import Address from '@/ts/ValueObjects/Address';
import HelperFactory from '@/ts/Helpers/HelperFactory';
import { MessageItem } from '@/domains/messages/database/messages/MessageItem';
import { ContactMessageSummaryItem } from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryItem';
import { MessageListRequestParams } from '@/domains/messages/requests/messages/MessageListRequest';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import MoveEntityToSpaceModal from '@/domains/spaces/views/MoveEntityToSpaceModal.vue';
import {MoveEntityIntentPayload} from '@/domains/spaces/interfaces/MoveEntityIntentPayload';

interface SendMessageEvent {
    subject: string;
    message: string;
    attachments: FileItem[];
}

@Component({
  components: {
      MoveEntityToSpaceModal,
      Container,
      ContactEdit,
      ContactOverview,
      SidebarSection,
      Notification,
      Form,
      ErrorMessage,
      ActionHeading,
      Spinner,
      DeleteItemSpinner,
      ContactMessageList,
      IncludeIf,
      DeleteConfirmationModal,
  },
})
export default class Contact extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $helperFactory!: HelperFactory;

    private contactId: string = '';
    private contactItem!: ContactItem;
    private primaryPhotoFile: FileItem|null = null;
    private contactShared: boolean = false;

    private loaded: boolean = false;
    private loading: boolean = false;
    private errorMessage: string = '';
    private editMode: boolean = false;
    private isFavourite: boolean = false;

    private contactFirstNameOk = Trilean.Unknown;
    private contactLastNameOk = Trilean.Unknown;
    private contactEmailPrimaryOk = Trilean.Unknown;
    private contactEmailSecondaryOk = Trilean.Unknown;
    private contactDateOfBirthOk = Trilean.Unknown;

    // Uploading photo image to a contact
    private fileUploading = false;
    private uploadedFiles: FileItem[] = [];
    private deletingFile = false;
    private primaryPhotoPopped = false;

    private messages: MessageItem[] = [];
    private sendMessageModalOpen = false;
    private sendingMessageInProgress = false;
    private previousMessage: string = '';
    private messageSummaryItem: ContactMessageSummaryItem|null = null;
    private loadingMoreMessages: boolean = false;

    // Delete message confirmation modal
    private deleteMessageId = '';
    private deleteMessageModalOpen = false;
    private deleteMessageInProgress = false;
    private deleteMessageName = '';

    // Move entity modal
    private entityMoveModalOpen = false;
    private entityMoveInProgress = false;

    private mounted(): void {
        this.contactId = this.$route.params.id;
        if (!this.contactId) {
            this.contactId = '';
        }

        if (this.$repoManager.contact) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    get dateOfBirthFormatted(): string {
        return this.$helperFactory.dateHelper.isoDateToLocaleString(this.contactItem.date_of_birth);
    }

    get contactAddress(): string {
        const address = new Address(
            this.contactItem.address_line1,
            this.contactItem.address_line2,
            this.contactItem.suburb,
            this.contactItem.postcode,
            this.contactItem.state,
            this.contactItem.country,
        );

        return address.toString('<br>');
    }

    get lastChangeLogId(): string {
        return this.$store.state.userModule.lastChangeLogId;
    }

    private async loadDataFromRepos(): Promise<void> {
        if (this.contactId !== '') {
            await this.loadContact();
            await this.loadMessages();

            try {
                this.messageSummaryItem = await this.$repoManager.contactMessageSummary.getItem(this.contactId);
            } catch (error) {
                this.messageSummaryItem = this.$repoManager.contactMessageSummary.getEmptyContactMessageSummaryItem();
            }
        } else {
            this.contactItem = this.$repoManager.contact.getEmptyContactItem();
            this.editMode = true;
        }

        this.loaded = true;
    }

    private async loadContact(): Promise<void> {
        try {
            this.errorMessage = '';
            this.contactItem = await this.$repoManager.contact.getItem(this.contactId);
            this.isFavourite = this.contactItem.is_favourite;

            if (this.contactItem.photo_primary_file_id.length > 0) {
                this.primaryPhotoFile = await this.$repoManager.file.getItem(this.contactItem.photo_primary_file_id);
            }
        } catch (error) {
            this.errorMessage = 'Error: Contact could not be loaded.';
        }
    }

    private async loadMessages(): Promise<void> {
        try {
            this.messages = await this.$repoManager.message.getList(this.contactId);
        } catch (error) {
            this.errorMessage = 'Error: Contact messages could not be loaded.';
        }
    }

    private async deleteContact(): Promise<void> {
        if (!confirm('Delete this contact?  Are you sure?')) {
            return;
        }

        const deleteContactFromRepo = async () => {
            await this.$repoManager.contact.delete(this.contactItem);

            this.contactId = '';
            this.contactItem = this.$repoManager.contact.getEmptyContactItem();
            this.navigateToContactsScreen();
        };

        try {
            this.loading = true;

            const deletedOk = await this.$requestFactory.deleteContactRequest.execute(this.contactId);
            if (!deletedOk) {
                this.errorMessage = 'The contact could not be deleted.  Please try again later.';
                return;
            }

            deleteContactFromRepo();
        } catch (error) {
            // If the error reported by the server is a RecordNotFound error, delete locally anyway.
            // Otherwise show the error
            if (this.$helperFactory.serverErrorHelper.isRecordNotFound(error)) {
                deleteContactFromRepo();
                return;
            }

            this.errorMessage = 'The contact could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    public handleFileUploaded(fileItem: FileItem): void {
        this.primaryPhotoFile = fileItem;
        this.uploadedFiles.push(fileItem);
    }

    public handleClearPrimaryPhoto(): void {
        this.primaryPhotoFile = null;
    }

    private async saveContact(updatedContactItem: ContactItem, finishedEditing: boolean): Promise<void> {
        try {
            this.loading = true;

            if (this.addingNewItem) {
                this.contactItem = await this.$requestFactory.addContactRequest.execute({
                    space_id: this.selectedSpace,
                    first_name: updatedContactItem.first_name,
                    last_name: updatedContactItem.last_name,
                    email_primary: updatedContactItem.email_primary,
                    email_secondary: updatedContactItem.email_secondary,
                    phone_primary: updatedContactItem.phone_primary,
                    phone_secondary: updatedContactItem.phone_secondary,
                    date_of_birth: StringHelper.paddISODate(updatedContactItem.date_of_birth),
                    address_line1: updatedContactItem.address_line1,
                    address_line2: updatedContactItem.address_line2,
                    suburb: updatedContactItem.suburb,
                    postcode: updatedContactItem.postcode,
                    state: updatedContactItem.state,
                    country: updatedContactItem.country,
                    geo_lat: 0,
                    geo_lng: 0,
                    birthday_reminder1_days_in_advance:
                        StringHelper.toInt(updatedContactItem.birthday_reminder1_days_in_advance),
                    birthday_reminder2_days_in_advance:
                        StringHelper.toInt(updatedContactItem.birthday_reminder2_days_in_advance),
                    notes: updatedContactItem.notes,
                    url1: '',
                    url2: '',
                    photo_primary_file_id: this.primaryPhotoFile ? this.primaryPhotoFile.id : '',
                    is_favourite: this.isFavourite,
                    shared: updatedContactItem.shared,
                });
            } else {
                this.contactItem = await this.$requestFactory.updateContactRequest.execute({
                    id: updatedContactItem.id,
                    space_id: this.selectedSpace,
                    first_name: updatedContactItem.first_name,
                    last_name: updatedContactItem.last_name,
                    email_primary: updatedContactItem.email_primary,
                    email_secondary: updatedContactItem.email_secondary,
                    phone_primary: updatedContactItem.phone_primary,
                    phone_secondary: updatedContactItem.phone_secondary,
                    date_of_birth: StringHelper.paddISODate(updatedContactItem.date_of_birth),
                    address_line1: updatedContactItem.address_line1,
                    address_line2: updatedContactItem.address_line2,
                    suburb: updatedContactItem.suburb,
                    postcode: updatedContactItem.postcode,
                    state: updatedContactItem.state,
                    country: updatedContactItem.country,
                    geo_lat: 0,
                    geo_lng: 0,
                    birthday_reminder1_days_in_advance:
                        StringHelper.toInt(updatedContactItem.birthday_reminder1_days_in_advance),
                    birthday_reminder2_days_in_advance:
                        StringHelper.toInt(updatedContactItem.birthday_reminder2_days_in_advance),
                    notes: updatedContactItem.notes,
                    url1: '',
                    url2: '',
                    photo_primary_file_id: this.primaryPhotoFile ? this.primaryPhotoFile.id : '',
                    is_favourite: this.isFavourite,
                    shared: updatedContactItem.shared,
                });
            }

            await this.$repoManager.contact.save(this.contactItem);

            if (finishedEditing) {
                this.editMode = false;
                this.errorMessage = '';

                this.navigateToContactsScreen();
                this.$router.push({name: Routes.CONTACT, params: { id: this.contactId }});
            }
        } catch (error) {
            this.errorMessage = 'Failed to save contact.  Please try again later.';
        } finally {
            this.loading = false;
        }
    }

    private async handleMoveEntityIntent(payload: MoveEntityIntentPayload) {
        this.entityMoveInProgress = true;

        const currentSpaceId = this.$store.state.spaceModule.selectedSpace;

        try {
            const updatedContactItem = await this.$requestFactory.moveEntityToSpaceRequest.execute({
                fromSpaceId: currentSpaceId,
                toSpaceId: payload.newSpaceId,
                entityType: 'CONTACT',
                entityId: this.contactId,
            });

            this.entityMoveModalOpen = false;

            await this.$repoManager.contact.save(updatedContactItem);

            // Move the user back to the contact list screen because the contact has moved to a different space.
            await this.$router.push({ name: Routes.CONTACTS });
        } catch (error) {
            this.entityMoveModalOpen = false;
            alert(error);
        }
    }

    private navigateToContactsScreen(): void {
        this.$router.push({name: Routes.CONTACTS});
    }

    get addingNewItem(): boolean {
        return this.contactId === '';
    }

    get contactTitle(): string {
        return this.contactItem.first_name + ' ' + this.contactItem.last_name;
    }

    get totalMessages(): number {
        return this.messageSummaryItem ?
            this.messageSummaryItem.total_messages : 0;
    }

    get numMessagesLeftToLoadFromServer(): number {
        return this.totalMessages - this.messages.length;
    }

    // If the user has selected a different workspace, redirect back to contacts screen.
    @Watch('selectedSpace')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.navigateToContactsScreen();
        }
    }

    private async uploadPhoto(): Promise<void> {
        const uploadFile: any = this.$refs.primary_photo;
        const fileName: string = uploadFile.files[0].name;

        // Ensure the selected file looks like an image.
        if (!StringHelper.filenameHasValidImageExtension(fileName)) {
            this.errorMessage = `$fileName does not appear to be a valid image file. ` +
                `Images should have a .jpg, .jpeg, .png or .gif extension.`;
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile.files[0]);

        try {
            const params: UploadFileParams = {
                formData,
            };

            this.fileUploading = true;

            const fileItem = await this.$requestFactory.uploadFileRequest.execute(params, 'contact');
            await this.$repoManager.file.save(fileItem);

            this.primaryPhotoFile = fileItem;
            this.uploadedFiles.push(fileItem);
            this.fileUploading = false;
        } catch (error) {
            this.errorMessage = 'Error uploading file: ' + error.toString();
        } finally {
            this.fileUploading = false;
        }
    }

    get primaryPhotoBackgroundUrl(): string {
        if ((this.primaryPhotoFile) && (!this.primaryPhotoPopped)) {
            return `background-image:url('${this.primaryPhotoFile.url}');`;
        }

        return '';
    }

    private async handleMessageRead(messageId: string): Promise<void> {
        const message = this.findMessageById(messageId);

        // Store the old value so we can revert to it if the request fails
        const oldValue = message.read;

        // Set the new value
        message.read = message.read === 0 ? 1 : 0;

        try {
            // Update the Read value on the server
            await this.$requestFactory.setMessageReadRequest.execute(message.id, message.read === 1);

            // Update the local repo
            await this.$repoManager.message.save(message);

            // Reload the contact message summary
            const summaryItems = await this.$requestFactory.contactMessageSummaryListRequest.execute();
            await this.$repoManager.contactMessageSummary.saveAll(summaryItems);
        } catch (error) {
            message.read = oldValue;
            this.$repoManager.message.save(message);
        }
    }

    private async handleMessageResponseRequired(messageId: string): Promise<void> {
        const message = this.findMessageById(messageId);

        // Store the old value so we can revert to it if the request fails
        const oldValue = message.requires_response;

        message.requires_response = message.requires_response === 0 ? 1 : 0;

        try {
            // Update the Read value on the server
            await this.$requestFactory.setMessageRequiresResponseRequest.execute(
                message.id,
                message.requires_response === 1,
            );

            // Update the local repo
            await this.$repoManager.message.save(message);

            this.reloadContactMessageSummary();
        } catch (error) {
            message.read = oldValue;
            this.$repoManager.message.save(message);
        }
    }

    private async handleDeleteMessageIntent(messageId: string): Promise<void> {
        const message = this.findMessageById(messageId);
        this.deleteMessageId = message.id;
        this.deleteMessageName = this.$helperFactory.dateHelper.timestampToFriendlyDateWithTime(message.created_dtm);
        this.deleteMessageModalOpen = true;
    }

    private async handleConfirmDeleteMessage(messageId: string): Promise<void> {
        const message = this.findMessageById(messageId);

        this.deleteMessageInProgress = true;
        try {
            await this.$requestFactory.deleteMessageRequest.execute(messageId);
            await this.$repoManager.message.delete(message);
            this.loadMessages();
        } catch (error) {
            this.errorMessage = 'Sorry, an error occured whilst trying to delete the message! ' + error.toString();
        } finally {
            this.deleteMessageInProgress = false;
            this.deleteMessageModalOpen = false;
        }
    }

    private async reloadContactMessageSummary(): Promise<void> {
        const summaryItems = await this.$requestFactory.contactMessageSummaryListRequest.execute();
        await this.$repoManager.contactMessageSummary.saveAll(summaryItems);

        this.messageSummaryItem = await this.$repoManager.contactMessageSummary.getItem(this.contactId);
    }

    /**
     * Locates a message in the messages array by message Id.
     * If no message is found, an error will be thrown.
     * i.e. There should always be a matching message.
     */
    private findMessageById(messageId: string): MessageItem {
        for (const message of this.messages) {
            if (message.id === messageId) {
                return message;
            }
        }

        throw Error(`Could not find message with Id '${messageId}'!`);
    }

    private handleSendMessageIntent(messageId: string) {
        if (messageId === '') {
            if (this.messages.length > 0) {
                this.$store.commit('setMostRecentMessage', this.messages[0]);
            } else {
                this.previousMessage = '';
                this.$store.commit('setMostRecentMessage', null);
            }
        } else {
            const previousMessage = this.findMessageById(messageId);
            this.$store.commit('setMostRecentMessage' , previousMessage);
        }

        this.$router.push({name: Routes.CONTACT_SEND_MESSAGE, params: { id: this.contactId }});
    }

    private handleReplyToMessageIntent(messageId: string) {
        const previousMessage = this.findMessageById(messageId);
        this.$store.commit('setMostRecentMessage' , previousMessage);
        this.$router.push({name: Routes.CONTACT_SEND_MESSAGE, params: { id: this.contactId }});
    }


    private lastMessageCreatedStamp(): number {
        if (this.messages.length === 0) {
            return 0;
        }

        return this.messages[this.messages.length - 1].created_dtm;
    }

    private async handleLoadMoreIntent(): Promise<void> {
        this.loadingMoreMessages = true;

        const requestParams: MessageListRequestParams = {
            contact_id: this.contactId,
            space_id: '',
            search: '',
            page: 1,
            items_per_page: 10,
            created_before_timestamp: this.lastMessageCreatedStamp(),
        };

        try {
            const messages = await this.$requestFactory.messageListRequest.execute(requestParams);
            if (messages.length > 0) {
                await this.$repoManager.message.saveAll(messages);
                await this.loadMessages();
            }
        } catch (error) {
            this.errorMessage = 'Sorry, something went wrong whilst trying to load the ' +
                ' messages: ' + error.toString();
        } finally {
            this.loadingMoreMessages = false;
        }
    }

    private async toggleFavouriteContact(): Promise<void> {
        const originalIsFavouriteValue = this.isFavourite;

        try {
            this.isFavourite = !this.isFavourite;
            await this.$requestFactory.setContactIsFavouriteRequest.execute(this.contactId, this.isFavourite);
            this.contactItem.is_favourite = this.isFavourite;
            await this.$repoManager.contact.save(this.contactItem);

            this.$forceUpdate();
        } catch (error) {
            this.errorMessage = error.toString();
            this.isFavourite = originalIsFavouriteValue;
        }
    }

    public goBackToContactsList(): void {
        this.$router.push({name: Routes.CONTACTS, params: {}});
    }

    @Watch('lastChangeLogId')
    private async onChangeLogUpdated(): Promise<void> {
        await this.loadMessages();
    }
}
