import Axios, {AxiosRequestConfig, Method} from 'axios';

/**
 * A client to talk to the Core API
 */
export default class ApiClient {
    private apiUrl: string;
    private token: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
        this.token = '';
    }

    public install(vue: any) {
        Object.defineProperty(vue.prototype, '$http', { value: this });
    }

    public setToken(token: string) {
        this.token = token;
    }

    public async get(url: string): Promise<any> {
        return this.request(url, 'GET', {});
    }

    public async getBinary(url: string): Promise<any> {
        return this.requestBinary(url);
    }

    public async post(url: string, params: object): Promise<any> {
        return this.request(url, 'POST', params);
    }

    public async patch(url: string, params: object): Promise<any> {
        return this.request(url, 'PATCH', params);
    }

    public async put(url: string, params: object): Promise<any> {
        return this.request(url, 'PUT', params);
    }

    public async delete(url: string, params: object): Promise<any> {
        return this.request(url, 'DELETE', params);
    }

    public async uploadFile(url: string, formData: FormData): Promise<any> {
        return this.request(url, 'POST', formData, this.formMultipartContentType);
    }

    private async request(
        url: string,
        methodString: string,
        params: object,
        contentType: string = 'application/json',
    ): Promise<any> {
        let data: any;

        const method = ApiClient.methodStringToAxiosMethod(methodString);

        if (contentType === this.formMultipartContentType) {
            data = params;
        } else if (method !== 'GET') {
            data = JSON.stringify(params);
        }

        const requestUrl = this.apiUrl + url;

        const config: AxiosRequestConfig = {
            url: requestUrl,
            method,
            data,
            headers: {
                'token': this.token,
                'Content-Type': contentType,
            },
        };

        return Axios(config);
    }

    private async requestBinary(url: string): Promise<any> {
        const requestUrl = this.apiUrl + url;

        const config: AxiosRequestConfig = {
            url: requestUrl,
            method: ApiClient.methodStringToAxiosMethod('GET'),
            responseType: 'arraybuffer',
            headers: {
                token: this.token,
            },
        };

        return Axios(config);
    }

    get formMultipartContentType(): string {
        return 'multipart/form-data';
    }

    public static methodStringToAxiosMethod(methodString: string): Method {
        methodString = methodString.toUpperCase();

        switch (methodString) {
            case 'DELETE':
                return 'DELETE';

            case 'GET':
                return 'GET';

            case 'PATCH':
                return 'PATCH';

            case 'POST':
                return 'POST';

            case 'PUT':
                return 'PUT';

            default:
                throw new Error(`ApiClient - Unsupported request type: ${methodString}`);
        }
    }
}
