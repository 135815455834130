
import { Component, Vue } from 'vue-property-decorator';
import Form from '@/domains/ui/views/Form.vue'; // @ is an alias to /src
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

import ApiClient from '@/ts/ApiClient';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Sync from '@/ts/Database/Sync';
import Container from '@/domains/app/views/Container.vue';
import Spinner from '@/domains/ui/views/Spinner.vue';
import HelperFactory from '@/ts/Helpers/HelperFactory';

@Component({
    components: {
        Spinner,
        Form,
        ErrorMessage,
        SpinnerButton,
        Container,
    },
})
export default class Reload extends Vue {
    private $http!: ApiClient;
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private errorMessage: string = '';
    private loading: boolean = false;
    private $helperFactory!: HelperFactory;

    public mounted(): void {
        this.sync();
    }

    private async sync(): Promise<void> {
        await this.$store.commit('setSyncFinished', false);

        const sync = new Sync(this.$http, this.$repoManager, this.$requestFactory);
        this.loading = true;

        try {
            const lastChangeLogId = await sync.execute();

            await this.$store.commit('setLastSyncTimestamp', (new Date()).getTime());
            await this.$store.commit('setLastChangeLogId', lastChangeLogId);
            await this.$store.commit('setSyncFinished', true);

            this.loading = false;

            // Give the navigation time to load before going to the home route - this will prevent the route
            // guards there thinking modules are not enabled when they infact are.
            setTimeout(async () => {
                const route = this.$helperFactory.settingsHelper.getRouteForFirstEnabledModuleForCurrentSpace();
                await this.$router.push({name: route});
            }, 500);
        } catch (error) {
            this.errorMessage = 'An error occurred whilst trying to load your data.  Please try again later.';

            if (!error.toString().indexOf('navigation guard')) {
                /* tslint:disable */
                console.log("Sync Error: ", error.toString());
                /* tslint:enable */
            }
        } finally {
            this.loading = false;
        }
    }
}
