import RepoManager from '@/ts/Database/RepoManager';
import { Store } from 'vuex';

export class MaintenanceService {
    private repoManager: RepoManager;
    private readonly store: Store<any>;
    private intervalRef: number;


    constructor(repoManager: RepoManager, store: Store<any>) {
        this.repoManager = repoManager;
        this.store = store;
        this.intervalRef = 0;
    }

    public start(): void {
        // Run the cleaning process every hour.
        const reRunInterval = 1000 * 60 * 60;
        this.intervalRef = setInterval(async () => {
            await this.cleanOldTasks();
        }, reRunInterval);
    }

    /**
     * Deletes any Kanban tasks that are completed and
     * @private
     */
    private async cleanOldTasks(): Promise<void> {
        // Get all kanban tasks that are in the "completed" column, across all spaces.
        const completedTasks = await this.repoManager.kanbanTask.getCompletedTasks();
        if (completedTasks.length === 0) {
            return;
        }

        // We want to delete any completed tasks that were completed MORE than 14 days ago - this
        // mirrors the backend logic.
        const now = Math.floor((new Date()).getTime() / 1000);
        const deleteTasksThreshold = now - (86_400 * 14);

        let tasksDeleted = false;

        for (const task of completedTasks) {
            if (task.completed_dtm !== null) {
                if (deleteTasksThreshold > task.updated_dtm) {
                    await this.repoManager.kanbanTask.delete(task);
                    tasksDeleted = true;
                }
            }
        }

        if (tasksDeleted) {
            await this.store.dispatch('flagKanbanModuleUpdated');
        }
    }
}
