import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import SpaceItem from '@/domains/spaces/database/SpaceItem';

export interface AddSpaceParams {
    name: string;
    shared: boolean;
}

export default class AddSpaceRequest extends AbstractRequest {
    public async execute(params: AddSpaceParams): Promise<SpaceItem> {
        return new Promise<SpaceItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/space`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('space')))  {
                    reject(Error('AddSpaceRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.space);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add a space failed.  Please try again later'));
            }
        });
    }
}
