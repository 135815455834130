
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import Grid from '@/domains/app/views/Grid.vue';
import GridItem from '@/domains/app/views/GridItem.vue';
import Toggle from '@/domains/ui/views/Switches/Toggle.vue';

import {Routes} from '@/domains/app/router/router';
import StringHash from '@/ts/StringHash';
import ApiClient from '@/ts/ApiClient';
import { GalleryItem } from '@/domains/galleries/database/galleries/GalleryItem';
import UserItem from '@/domains/users/database/users/UserItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';
import StringHelper from '@/ts/Helpers/StringHelper';
import HelperFactory from '@/ts/Helpers/HelperFactory';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      Grid,
      GridItem,
      Toggle,
  },
})
export default class GalleryList extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;
    private $helperFactory!: HelperFactory;

    private loaded: boolean = false;
    private galleries: GalleryItem[] = [];
    private users: UserItem[] = [];
    private errorMessage: string = '';
    private userDisplayNameMap: StringHash = {};
    private galleryImageMap: {[key: string]: string} = {};

    private mounted(): void {
        this.galleryImageMap = {};

        if (this.$repoManager.gallery) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    /**
     * Fires when all repos have been initialised
     */
    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    private async loadDataFromRepos(): Promise<void> {
        await this.loadGalleries();
        this.loaded = true;
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    private get searchKeywords(): string {
        return this.$store.state.galleryModule.searchKeywords;
    }

    private set searchKeywords(newValue: string) {
        this.$store.commit('setGallerySearchKeywords', newValue);
        this.loadGalleries();
    }

    private get searchShowSharedOnly(): boolean {
        return this.$store.state.galleryModule.showSharedOnly;
    }

    private async setSearchShowSharedOnly(newValue: boolean): Promise<void> {
        this.$store.commit('setGalleryShowSharedOnly', newValue);
        await this.loadGalleries();
    }

    private get lastUpdated(): string {
        return this.$store.state.galleryModule.lastUpdated;
    }

    @Watch('lastUpdated')
    private async loadGalleries(): Promise<void> {
        try {
            this.errorMessage = '';

            const galleries = await this.$repoManager.gallery.getList(
                this.spaceId,
                this.searchKeywords,
                this.searchShowSharedOnly,
            );

            await this.loadGalleryImages(galleries);

            this.galleries = galleries;
        } catch (error) {
            this.errorMessage = 'Error: Galleries could not be loaded';
        }
    }

    /**
     * For each gallery, if that gallery has an associated cover photo file id,
     * load that file object and store the url to the thumb image in an array.
     */
    private async loadGalleryImages(galleries: GalleryItem[]): Promise<void> {
        for (const gallery of galleries) {
            const photoKey = gallery.cover_photo_file_id;

            if (photoKey === '') {
                continue;
            }

            try {
                const file = await this.$repoManager.file.getItem(photoKey);
                if (file.additional_files.length > 0) {
                    this.galleryImageMap[photoKey] = file.additional_files[0];
                }
            } catch (error) {
                // Ignore any Unable to load file errors.  The file object must be gone.
                if (error.toString().indexOf('Unable to load file') >= 0) {
                    this.errorMessage = error.toString();
                    continue;
                }
            }
        }
    }

    private showGallery(galleryId: string): void {
        this.$router.push({name: Routes.GALLERY, params: {id: galleryId}});
    }

    private async addGallery(): Promise<void> {
        await this.$store.dispatch('flagGalleryModuleUpdated');
        this.$router.push({name: Routes.GALLERY_ADD});
    }

    // If a sync has occured - refresh the UI.
    get lastSyncTimestamp(): number {
        return this.$store.state.lastSyncTimestamp;
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.gallery) {
            this.loadDataFromRepos();
        }
    }

    get selectedSpace(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    // If the user has selected a different space, refresh the galleries.
    @Watch('selectedSpace')
    private onGalleryChanged(): void {
        if (this.$repoManager.gallery) {
            this.loadDataFromRepos();
        }
    }

    private getPhotoUrl(coverPhotoFileId: string) {
        if (!this.galleryImageMap.hasOwnProperty(coverPhotoFileId)) {
            return '';
        }

        return this.galleryImageMap[coverPhotoFileId];
    }

    private getShortenedGalleryTitle(title: string): string {
        return StringHelper.shorten(title, 32);
    }
}
