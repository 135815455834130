import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { LoginResponse } from './Interfaces/LoginResponse';
import { LoginParams } from './Interfaces/LoginParams';

export class LoginRequest extends AbstractRequest {
    public async execute(params: LoginParams): Promise<LoginResponse> {
        return new Promise<LoginResponse>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/user/login`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('token')))  {
                    reject(Error('LoginRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your login request failed.  Please try again later'));
            }
        });
    }
}
