import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export class CheckVaultPasswordSetRequest extends AbstractRequest {
    public async execute(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/vault/vault_password_set`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('vault_password_is_set')))  {
                    reject(Error('CheckVaultPasswordSetRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.vault_password_is_set);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to check whether your vault password is set or not failed.  Please try again later'));
            }
        });
    }
}
