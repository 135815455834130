import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import ContactItem from '@/domains/contacts/database/ContactItem';

export interface AddContactParams {
    space_id: string;
    first_name: string;
    last_name: string;
    email_primary?: string;
    email_secondary?: string;
    phone_primary?: string;
    phone_secondary?: string;
    date_of_birth?: string;
    address_line1?: string;
    address_line2?: string;
    suburb?: string;
    postcode?: string;
    state?: string;
    country?: string;
    geo_lat?: number;
    geo_lng?: number;
    birthday_reminder1_days_in_advance?: number;
    birthday_reminder2_days_in_advance?: number;
    notes?: string;
    url1?: string;
    url2?: string;
    photo_primary_file_id?: string;
    is_favourite: boolean;
    shared: boolean;
}

export default class AddContactRequest extends AbstractRequest {
    public async execute(params: AddContactParams): Promise<ContactItem> {
        return new Promise<ContactItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.post(`/private/contact`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('contact')))  {
                    reject(Error('AddContactRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.contact);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to add a contact failed.  Please try again later'));
            }
        });
    }
}
