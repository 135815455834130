import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import MessageItem from '@/domains/messages/database/messages/MessageItem';

export default class ContactRecentMessageListRequest extends AbstractRequest {
    public async execute(): Promise<MessageItem[]> {
        return new Promise<MessageItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/contact/messages/recent`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('messages')))  {
                    reject(Error('ContactRecentMessageListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.messages);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load contact recent message list from the API'));
            }
        });
    }
}
