
import { Component, Vue, Prop } from 'vue-property-decorator';
import ContactItem from '@/domains/contacts/database/ContactItem';
import HelperFactory from '@/ts/Helpers/HelperFactory';

@Component({
  components: {},
})
export default class ContactOverview extends Vue {
    @Prop({required: true})
    private contactItem!: ContactItem;

    @Prop({required: true, type: String})
    private contactAddress!: string;

    private $helperFactory!: HelperFactory;

    public dateOfBirthFormatted(isoDate: string): string {
        return this.$helperFactory.dateHelper.dateOfBirthToFriendlyShortDate(isoDate);
    }
}
