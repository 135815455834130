import { AbstractRequest } from '@/ts/Requests/AbstractRequest';

export default class GetFileBinaryRequest extends AbstractRequest {
    public async execute(fileId: string): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.getBinary(`/private/file/binary/${fileId}`);
                resolve(response.data);
            } catch (error) {
                reject(this.convertResponseError(error,
                    `Could not load image binary from the API: ${error}`));
            }
        });
    }
}
