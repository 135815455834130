import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import ContactMessageSummaryItem from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryItem';

export default class ContactMessageSummaryListRequest extends AbstractRequest {
    public async execute(): Promise<ContactMessageSummaryItem[]> {
        return new Promise<ContactMessageSummaryItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/contact/messages/summary`);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('message_summary_items')))  {
                    reject(Error('ContactMessageSummaryListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.message_summary_items);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load contact message summary from the API'));
            }
        });
    }
}
