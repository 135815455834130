
import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from '@/domains/ui/views/Modal.vue';
import Form from '@/domains/ui/views/Form.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';

@Component({
  components: {
      Modal,
      SpinnerButton,
  },
})
export default class DeleteConfirmationModal extends Vue {
    @Prop({required: true}) private itemId!: string;
    @Prop({required: true}) private itemType!: string;
    @Prop({required: true}) private itemName!: string;
    @Prop({required: true}) private deleteModalOpen!: boolean;
    @Prop({required: true}) private deleteInProgress!: boolean;

    public handleCloseModalIntent(): void {
        this.$emit('close-modal-intent', {});
    }

    public handleDeleteItem(): void {
        // TODO - handle delete item
        this.$emit('delete-item-intent', this.itemId);
    }

    get buttonText(): string {
        return 'Yes, delete this ' + this.itemType;
    }
}
