
    // Components
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

    // Typescript
    import { SelectorItemType } from '@/domains/ui/views/Switches/Selector/SelectorItemType';
    
    @Component({
        components: {},
    })
    export default class SelectorItem extends Vue {
        @Prop({required: true, type: Number})
        private itemType!: SelectorItemType;

        @Prop({required: true, type: String})
        private label!: string;

        @Prop({required: false, type: String})
        private value!: string|null;

        @Prop({required: false, type: Number})
        private pillNumber!: number|null;

        @Prop({required: false})
        private itemIcon!: [string, string]|null;

        public get icon(): [string, string] {
            if (this.itemIcon) {
                return this.itemIcon;
            }

            return [
                'fas',
                this.itemType === SelectorItemType.Open ? 'pencil-alt' :
                this.itemType === SelectorItemType.Close ? 'arrow-up' :
                'binoculars',
            ];
        }

        public get showPill(): boolean {
            if ((this.pillNumber !== null) && (this.pillNumber !== undefined)) {
                return true;
            }

            return false;
        }

        public handleItemClick(): void {
            switch (this.itemType) {
                case SelectorItemType.Open:
                    this.$emit('open');
                    break;

                case SelectorItemType.Close:
                    this.$emit('close');
                    break;

                case SelectorItemType.Select:
                    this.$emit('select', this.value);
                    break;
            }
        }
    }
