export class MimeType {
    constructor(private mimeType: string) {
    }

    get isImage(): boolean {
        switch (this.mimeType) {
            case 'image/jpeg':
                return true;
            case 'image/jpg':
                return true;
            case 'image/png':
                return true;
            case 'image/gif':
                return true;

            default:
                return false;
        }
    }

    get isVideo(): boolean {
        switch (this.mimeType) {
            case 'video/mp4':
                return true;
            case 'video/quicktime':
                return true;
            case 'video/x-msvideo':
                return true;
            case 'video/x-ms-wmv':
                return true;
            case 'video/mpeg':
                return true;
            case 'video/x-matroska':
                return true;
            case 'video/h264':
                return true;
            case 'video/webm':
                return true;

            default:
                return false;
        }
    }
}
