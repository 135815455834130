
import { Component, Vue, Watch } from 'vue-property-decorator';
import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import RequestFactory from '@/ts/Requests/RequestFactory';
import RepoManager from '@/ts/Database/RepoManager';
import { SpaceItem } from '@/domains/spaces/database/SpaceItem';
import { RecipeCategoryItem } from '@/domains/recipies/database/recipeCategories/RecipeCategoryItem';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import Modal from '@/domains/ui/views/Modal.vue';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';
import { Trilean } from '@/ts/Trilean';
import SpaceRepo from '@/domains/spaces/database/SpaceRepo';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SuccessMessage from '@/domains/ui/views/Messages/SuccessMessage.vue';
import KanbanColumnsItem from '@/domains/kanban/database/kanbanColumns/KanbanColumnsItem';

@Component({
  components: {
      ActionHeading,
      Modal,
      InputChecker,
      SpinnerButton,
      ErrorMessage,
      SuccessMessage,
      DeleteConfirmationModal,
  },
})
export default class KanbanAdmin extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private loaded = false;
    private errorMessage = '';
    private successMessage = '';
    private spaceId = '';
    private spaceIdOK: Trilean = Trilean.Unknown;
    private kanbanColumns: KanbanColumnsItem|null = null;
    private column1 = '';
    private column1Ok: Trilean = Trilean.Unknown;
    private column2 = '';
    private column2Ok: Trilean = Trilean.Unknown;
    private column3 = '';
    private column3Ok: Trilean = Trilean.Unknown;
    private column4 = '';
    private column4Ok: Trilean = Trilean.Unknown;
    private column5 = '';
    private column5Ok: Trilean = Trilean.Unknown;
    private column6 = '';
    private column6Ok: Trilean = Trilean.Unknown;
    private column7 = '';
    private column7Ok: Trilean = Trilean.Unknown;
    private column8 = '';
    private column8Ok: Trilean = Trilean.Unknown;

    private savingColumns = false;
    private selectedSpaceId: string = '';

    public navigateToAdminMenu(): void {
        this.$router.push({name: Routes.ADMIN});
    }

    public async loadKanbanColumnsForSelectedSpace(): Promise<void> {
        try {
            const kanbanColumnList = await this.$repoManager.kanbanColumns.getList(this.selectedSpaceId);
            if (kanbanColumnList.length === 0) {
                this.setDefaultColumnNames();
                return;
            }

            this.kanbanColumns = kanbanColumnList[0];
            this.column1 = this.kanbanColumns.column1;
            this.column2 = this.kanbanColumns.column2;
            this.column3 = this.kanbanColumns.column3;
            this.column4 = this.kanbanColumns.column4;
            this.column5 = this.kanbanColumns.column5;
            this.column6 = this.kanbanColumns.column6;
            this.column7 = this.kanbanColumns.column7;
            this.column8 = this.kanbanColumns.column8;

            this.checkAllColumns();
        } catch (error) {
            this.errorMessage = error.toString();
        }
    }

    public async saveColumnNames(): Promise<void> {
        if ((!this.column1Ok) || (!this.column2Ok) || (!this.column3Ok) || (!this.column4Ok) ||
         (!this.column5Ok) || (!this.column6Ok) || (!this.column7Ok) || (!this.column8Ok)) {
             this.errorMessage = 'Please enter a name for all column names!';
             return;
         }

        try {
            this.savingColumns = true;

            this.kanbanColumns = await this.$requestFactory.kanbanColumns.setKanbanColumnsRequest.execute({
                space_id: this.selectedSpaceId,
                column1: this.column1,
                column2: this.column2,
                column3: this.column3,
                column4: this.column4,
                column5: this.column5,
                column6: this.column6,
                column7: this.column7,
                column8: this.column8,
            });

            this.kanbanColumns.column1 = this.column1;
            this.kanbanColumns.column2 = this.column2;
            this.kanbanColumns.column3 = this.column3;
            this.kanbanColumns.column4 = this.column4;
            this.kanbanColumns.column5 = this.column5;
            this.kanbanColumns.column6 = this.column6;
            this.kanbanColumns.column7 = this.column7;
            this.kanbanColumns.column8 = this.column8;

            await this.$repoManager.kanbanColumns.save(this.kanbanColumns);

            this.errorMessage = '';
            this.successMessage = 'The Kanban column names were updated successfully!';
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.savingColumns = false;
        }
    }

    public checkColumn1Ok(): void {
        if (this.column1.length === 0) {
            this.column1Ok = Trilean.False;
        } else {
            this.column1Ok = Trilean.True;
        }
    }

    public checkColumn2Ok(): void {
        if (this.column2.length === 0) {
            this.column2Ok = Trilean.False;
        } else {
            this.column2Ok = Trilean.True;
        }
    }

    public checkColumn3Ok(): void {
        if (this.column3.length === 0) {
            this.column3Ok = Trilean.False;
        } else {
            this.column3Ok = Trilean.True;
        }
    }

    public checkColumn4Ok(): void {
        if (this.column4.length === 0) {
            this.column4Ok = Trilean.False;
        } else {
            this.column4Ok = Trilean.True;
        }
    }

    public checkColumn5Ok(): void {
        if (this.column5.length === 0) {
            this.column5Ok = Trilean.False;
        } else {
            this.column5Ok = Trilean.True;
        }
    }

    public checkColumn6Ok(): void {
        if (this.column6.length === 0) {
            this.column6Ok = Trilean.False;
        } else {
            this.column6Ok = Trilean.True;
        }
    }

    public checkColumn7Ok(): void {
        if (this.column7.length === 0) {
            this.column7Ok = Trilean.False;
        } else {
            this.column7Ok = Trilean.True;
        }
    }

    public checkColumn8Ok(): void {
        if (this.column8.length === 0) {
            this.column8Ok = Trilean.False;
        } else {
            this.column8Ok = Trilean.True;
        }
    }

    private checkAllColumns(): void {
        this.checkColumn1Ok();
        this.checkColumn2Ok();
        this.checkColumn3Ok();
        this.checkColumn4Ok();
        this.checkColumn5Ok();
        this.checkColumn6Ok();
        this.checkColumn7Ok();
        this.checkColumn8Ok();
    }

    private setDefaultColumnNames() {
        this.column1 = 'Backlog';
        this.column2 = 'This Year';
        this.column3 = 'This Month';
        this.column4 = 'This Week';
        this.column5 = 'Today';
        this.column6 = 'In Progress';
        this.column7 = 'Waiting';
        this.column8 = 'Done';

        this.checkAllColumns();
    }

    get spaces(): SpaceItem[] {
        return this.$store.state.spaceModule.spaces;
    }

    @Watch('selectedSpaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.kanbanColumns) {
            this.loadKanbanColumnsForSelectedSpace();
        }
    }
}
