import { render, staticRenderFns } from "./GridItem.vue?vue&type=template&id=ae5fecd4&scoped=true&"
import script from "./GridItem.vue?vue&type=script&lang=ts&"
export * from "./GridItem.vue?vue&type=script&lang=ts&"
import style0 from "./GridItem.vue?vue&type=style&index=0&id=ae5fecd4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae5fecd4",
  null
  
)

export default component.exports