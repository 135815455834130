
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import IncludeIf from '@/domains/ui/views/IncludeIf.vue';
import PopSelector from '@/domains/ui/views/Modals/PopSelector/PopSelector.vue';
import { PopSelectorItemInterface } from '@/domains/ui/views/Modals/PopSelector/PopSelectorItemInterface';
import {Routes} from '@/domains/app/router/router';
import StringHash from '@/ts/StringHash';
import ApiClient from '@/ts/ApiClient';
import NoteItem from '@/domains/notes/database/notes/NoteItem';
import NoteCategoryItem from '@/domains/notes/database/noteCategories/NoteCategoryItem';
import UserItem from '@/domains/users/database/users/UserItem';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import Trilean from '@/ts/Trilean';


@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      IncludeIf,
      PopSelector,
      BasicButton,
  },
})
export default class NoteList extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private loaded: boolean = false;
    private waitingForAddNoteCategoryRequest: boolean = false;
    private notes: NoteItem[] = [];
    private noteCategories: NoteCategoryItem[] = [];
    private users: UserItem[] = [];
    private errorMessage: string = '';
    private addCategoryValidationError: boolean = false;
    private noteCategorySpaceMap!: { [key: string]: boolean };

    private categoryNameOk: Trilean = Trilean.Unknown;

    private addingNewCategory: boolean = false;
    private newCategoryName: string = '';
    private categoryNameMap: StringHash = {};
    private userDisplayNameMap: StringHash = {};
    private categoryJustAdded: boolean = false;
    private addNoteCategoryPopSelectorOpen: boolean = false;
    private selectedNoteCategoryName: string = '';

    public mounted(): void {
        this.categoryJustAdded = false;
        this.noteCategorySpaceMap = {};

        if (this.$repoManager.noteCategory) {
            this.loadDataFromRepos();
        } else {
            this.onReposReady();
        }
    }

    /**
     * Fires when all repos have been initialised
     */
    public async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            this.loadDataFromRepos();
        }, false);
    }

    public async loadDataFromRepos(): Promise<void> {
        await this.loadNoteCategories();
        await this.loadUsers();
        await this.loadNotes();
        await this.loadSelectedNoteCategory();
        this.loaded = true;
    }

    public async handlePopSelectorCategoryChanged(itemId: string): Promise<void> {
        this.addNoteCategoryPopSelectorOpen = false;
        this.setNoteCategoryId(itemId);
        this.loadSelectedNoteCategory();
    }

    // This comes from the PopSelector
    public async handleAddNewNoteCategoryIntent(newCategoryName: string): Promise<void> {
        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddNoteCategoryRequest = true;

            const noteCategoryItem = await this.$requestFactory.addNoteCategoryRequest.execute({
                space_id: this.spaceId,
                name: newCategoryName,
            });

            // Save the note category in the local repo
            await this.$repoManager.noteCategory.save(noteCategoryItem);

            // Reload note categories from the repo
            await this.loadNoteCategories();

            // Reset flags
            this.waitingForAddNoteCategoryRequest = false;
            this.addNoteCategoryPopSelectorOpen = false;

            // Flag that a category was just added to prevent the Delete Category button immediately showing.
            this.categoryJustAdded = true;

            this.setNoteCategoryId(noteCategoryItem.id);
            await this.loadSelectedNoteCategory();
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the note category';
        }
    }

    private async loadSelectedNoteCategory(): Promise<void> {
        if (this.noteCategoryId === '') {
            this.selectedNoteCategoryName = 'All Categories';
            return;
        }

        const noteCategoryItem = await this.$repoManager.noteCategory.getItem(this.noteCategoryId);
        this.selectedNoteCategoryName = noteCategoryItem.name;
    }

    private async loadNoteCategories(): Promise<void> {
        try {
            this.errorMessage = '';
            const items = await this.$repoManager.noteCategory.getList(this.spaceId, '');

            this.categoryNameMap = {};
            this.noteCategorySpaceMap = {};

            items.forEach((category) => {
                this.categoryNameMap[category.id] = category.name;
                this.noteCategorySpaceMap[category.id] = true;
            });

            this.noteCategories = items;
        } catch (error) {
            this.errorMessage = 'Error: Note categories could not be loaded.';
        }
    }

    private async loadNotes(): Promise<void> {
        try {
            this.errorMessage = '';
            this.notes = await this.$repoManager.note.getList(
                this.noteCategoryId,
                this.noteCategorySpaceMap,
                this.searchKeywords,
            );
        } catch (error) {
            this.errorMessage = 'Error: Notes could not be loaded.';
        }
    }

    private async loadUsers(): Promise<void> {
        try {
            this.errorMessage = '';
            const items = await this.$repoManager.user.getList();

            this.userDisplayNameMap = {};
            items.forEach((user) => {
                this.userDisplayNameMap[user.id] = user.display_name;
            });

            this.users = items;
        } catch (error) {
            this.errorMessage = 'Error: Users could not be loaded.';
        }
    }

    private async addCategory(): Promise<void> {
        if (this.categoryNameOk !== Trilean.True) {
            this.addCategoryValidationError = true;
            return;
        }

        this.errorMessage = '';

        try {
            // Add the new category on the server
            this.waitingForAddNoteCategoryRequest = true;
            const noteCategoryItem = await this.$requestFactory.addNoteCategoryRequest.execute({
                space_id: this.spaceId,
                name: this.newCategoryName,
            });

            // Save the note category in the local repo
            await this.$repoManager.noteCategory.save(noteCategoryItem);

            // Reset note category input text field
            this.newCategoryName = '';

            // Reload note categories from the repo
            await this.loadNoteCategories();

            // Reset flags
            this.waitingForAddNoteCategoryRequest = false;
            this.addingNewCategory = false;

            // Flag that a category was just added to prevent the Delete Category button immediately showing.
            this.categoryJustAdded = true;

            this.setNoteCategoryId(noteCategoryItem.id);
        } catch (error) {
            this.errorMessage = 'There was an error whilst trying to add the note category';
        }
    }

    private async deleteNoteCategory(): Promise<void> {
        if (this.noteCategoryId.length === 0) {
            return;
        }

        try {
            const selectedNoteCategoryItem = this.noteCategoryItem;

            const ok = await this.$requestFactory.deleteNoteCategoryRequest.execute(this.noteCategoryId);
            if (!ok) {
                this.errorMessage = 'The category could not be deleted.  Please try again later.';
            }

            await this.$repoManager.noteCategory.delete(selectedNoteCategoryItem);

            this.noteCategoryId = '';

            await this.loadDataFromRepos();
        } catch (error) {
            this.errorMessage = 'The category could not be deleted.  Please try again later.';
        }
    }

    private showNote(noteId: string): void {
        this.$router.push({name: Routes.NOTE, params: {id: noteId}});
    }

    private cancelAddCategory(): void {
        this.newCategoryName = '';
        this.addingNewCategory = false;
        this.addCategoryValidationError = false;
    }

    private setNoteCategoryId(categoryId: string): void {
        this.noteCategoryId = categoryId;
        if (this.noteCategoryId === '') {
            this.selectedNoteCategoryName = 'All Categories';
        }
        this.loadNotes();
    }

    // Invoked when a category is changed via the drop list.
    private categoryChanged(): void {
        this.loadNotes();
    }

    private clearSelectedNoteCategory(): void {
        this.noteCategoryId = '';
        this.loadNotes();
    }

    private getNoteCategoryName(id: string) {
        if (this.categoryNameMap.hasOwnProperty(id)) {
            return this.categoryNameMap[id];
        } else {
            return '';
        }
    }

    private getUserDisplayName(id: string) {
        if (this.userDisplayNameMap.hasOwnProperty(id)) {
            return this.userDisplayNameMap[id];
        } else {
            return '';
        }
    }

    private timestampToDate(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    private addNote(): void {
        this.$router.push({name: Routes.NOTE_ADD});
    }

    private search(keywords: string): void {
        this.searchKeywords = keywords;
        this.loadNotes();
    }

    private checkCategoryNameOk(): void {
        if ((this.newCategoryName.length >= 2) && (this.newCategoryName.length <= 50)) {
            this.categoryNameOk = Trilean.True;
            this.addCategoryValidationError = false;
        } else {
            this.categoryNameOk = Trilean.False;
        }
    }

    private userWantsToAddNewCategory(): void {
        this.addingNewCategory = !this.addingNewCategory;
        this.newCategoryName = '';
        this.categoryNameOk = Trilean.Unknown;
        this.addCategoryValidationError = false;
    }

    // Getters and settings
    get noteCategoryId(): string {
        return this.$store.state.noteModule.noteCategoryId;
    }

    set noteCategoryId(value: string) {
        this.$store.commit('setNoteCategoryId', value);
    }

    get searchKeywords(): string {
        return this.$store.state.noteModule.searchKeywords;
    }

    set searchKeywords(value: string) {
        this.$store.commit('setNoteSearchKeywords', value);
        this.loadNotes();
    }

    get lastChangeLogId(): string {
        return this.$store.state.userModule.lastChangeLogId;
    }

    get noteCategoryItem(): NoteCategoryItem {
        const selectedCategoryId = this.noteCategoryId;

        for (const index in this.noteCategories) {
            if (this.noteCategories.hasOwnProperty(index)) {
                const noteCategoryItem = this.noteCategories[index];

                if (noteCategoryItem.id === selectedCategoryId) {
                    return noteCategoryItem;
                }
            }
        }

        throw new Error('Unable to retrieve the note category item');
    }

    get popSelectorNoteCategories(): PopSelectorItemInterface[] {
        const items: PopSelectorItemInterface[] = [];

        for (const noteCategory of this.noteCategories) {
            items.push({
                id: noteCategory.id,
                label: noteCategory.name,
                deletable: false,
            });
        }

        return items;
    }

    // If a sync has occured - refresh the UI.
    get lastSyncTimestamp(): number {
        return this.$store.state.userModule.lastSyncTimestamp;
    }

    private get lastUpdated(): string {
        return this.$store.state.noteModule.lastUpdated;
    }

    private get spaceId(): string {
        return this.$store.state.spaceModule.selectedSpace;
    }

    @Watch('lastSyncTimestamp')
    private onLastSyncTimestampChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }

    @Watch('lastUpdated')
    private onLastUpdatedChanged(): void {
        if (this.$repoManager.space) {
            this.loadNotes();
        }
    }

    // If the user has selected a different workspace, refresh the notes.
    @Watch('spaceId')
    private onSpaceChanged(): void {
        if (this.$repoManager.space) {
            this.loadDataFromRepos();
        }
    }

    @Watch('lastChangeLogId')
    private onChangeLogUpdated(): void {
        this.loadDataFromRepos();
    }
}
