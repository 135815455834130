import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LoginResponse } from '@/ts/Requests/Auth/Interfaces/LoginResponse';
import UserRoleType from '@/ts/Enums/UserRoleType';
import RequestFactory from '../../../ts/Requests/RequestFactory';
import {UserTwoFactorAuthItem} from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';

@Module
export default class UserModule extends VuexModule {
    public isAdmin: boolean = false;
    public isViewingProfilePage: boolean = false;
    public lastChangeLogId: number = 0;
    public lastSyncTimestamp: number = 0;
    public allowRoaming: boolean = false;
    public token: string = '';
    public userId: string = '';
    public userDisplayName: string = '';
    public userRoleType: string = '';
    public socketConnected: boolean = false;
    public syncFinished: boolean = false;
    public userLoggedIn: boolean = false;
    public twoFactorAuthMethod: UserTwoFactorAuthItem|null = null;

    private requestFactory: RequestFactory|null = null;

    /******************************
     * MUTATIONS
     *****************************/
    @Mutation
    public setSessionData(data: LoginResponse) {
        this.token = data.token;
        this.userDisplayName = data.display_name;
        this.userRoleType = data.role_type;
        this.isAdmin = this.userRoleType === UserRoleType.ADMINISTRATOR;
        this.userLoggedIn = this.token.length > 0;
    }

    @Mutation
    public setToken(value: string) {
        this.token = value;
    }

    @Mutation
    public setUserId(value: string) {
        this.userId = value;
    }

    @Mutation
    public setIsAdmin(value: boolean) {
        this.isAdmin = value;
    }

    @Mutation
    public setUserLoggedIn(value: boolean) {
        this.userLoggedIn = value;
    }

    @Mutation
    public setAllowRoaming(value: boolean) {
        this.allowRoaming = value;
    }

    @Mutation
    public setIsViewingProfilePage(value: boolean) {
        this.isViewingProfilePage = value;
    }

    @Mutation
    public setLastSyncTimestamp(value: number) {
        this.lastSyncTimestamp = value;
    }

    @Mutation
    public setLastChangeLogId(value: number) {
        this.lastChangeLogId = value;
    }

    @Mutation
    public setSocketConnected(value: boolean) {
        this.socketConnected = value;
    }

    @Mutation
    public setSyncFinished(value: boolean) {
        this.syncFinished = value;
    }

    @Mutation
    public setRequestFactory(value: RequestFactory) {
        this.requestFactory = value;
    }

    @Mutation
    public setTwoFactorAuthMethod(value: UserTwoFactorAuthItem|null) {
        this.twoFactorAuthMethod = value;
    }

    /******************************
     * ACTIONS
     *****************************/
    @Action
    public async checkMustLogout(): Promise<void> {
        if (this.requestFactory === null) {
            return;
        }

        // Attempt to load the logged in user's details.
        // If we cannot load the user, flag that a logout must occur.
        try {
            await this.requestFactory.userDetailsRequest.execute();
        } catch (error) {
            this.context.dispatch('logoutUser', {});
        }
    }

    @Action
    public logoutUser() {
        this.context.commit('setToken', '');
        this.context.commit('setUserId', '');
        this.context.commit('setIsAdmin', false);
        this.context.commit('setUserLoggedIn', false);
        this.context.commit('setSyncFinished', false);
    }

    @Action
    public viewProfilePage() {
        this.context.commit('setAdminUserId', this.userId);
        this.context.commit('setIsViewingProfilePage', true);
    }

    @Action
    public initialiseRequestFactory(requestFactory: RequestFactory) {
        this.context.commit('setRequestFactory', requestFactory);
    }

    get isCurrentUser() {
        return (testUserId: string): boolean => {
            return this.userId === testUserId;
        };
    }
}
