import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { GalleryFileItem } from '@/domains/galleries/database/galleryFiles/GalleryFileItem';

export interface UpdateGalleryFileParams {
    gallery_id: string;
    published: boolean;
}

export class UpdateGalleryFileRequest extends AbstractRequest {
    public async execute(galleryFileId: string, params: UpdateGalleryFileParams): Promise<GalleryFileItem> {
        return new Promise<GalleryFileItem>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.patch(`/private/gallery_files/${galleryFileId}`, params);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('gallery_file')))  {
                    reject(Error('UpdateGalleryFileRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.gallery_file);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to update a gallery file failed.  Please try again later'));
            }
        });
    }
}
