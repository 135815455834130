import { AbstractRepo } from '@/ts/Database/AbstractRepo';
import { ModuleSpaceItem } from '@/domains/module/database/moduleSpace/ModuleSpaceItem';
import { ModuleType } from '@/ts/Enums/ModuleType';
import RequestFactory from '@/ts/Requests/RequestFactory';

export default class ModuleSpaceRepo extends AbstractRepo<ModuleSpaceItem> {
    public static indexDbStoreName = 'module_spaces';
    private requestFactory: RequestFactory;

    public constructor(db: IDBDatabase, requestFactory: RequestFactory) {
        super(db, ModuleSpaceRepo.indexDbStoreName);
        this.requestFactory = requestFactory;
    }

    public async getList(spaceId: string = ''): Promise<ModuleSpaceItem[]> {
        return new Promise<ModuleSpaceItem[]>(async (resolve, reject) => {
            const store = this.getDb().transaction([this.getStoreName()], 'readonly').
                objectStore(this.getStoreName());

            let request: IDBRequest;

            const searchingWithinSpace = (spaceId !== '');

            if (searchingWithinSpace) {
                const index = store.index('space_id');
                request = index.openCursor(IDBKeyRange.only(spaceId));
            } else {
                request = this.getDb().transaction([this.getStoreName()], 'readonly')
                    .objectStore(this.getStoreName()).openCursor();
            }

            const items: ModuleSpaceItem[] = [];

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;
                    items.push(item);
                    cursor.continue();
                } else {
                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.getStoreName()}!`));
            };
        });
    }

    /**
     * Checks to see if the specified module type is enabled for the space
     * @param moduleType
     * @param spaceId
     */
    public async enabledForSpace(moduleType: ModuleType, spaceId: string): Promise<boolean> {
        const items = await this.getList(spaceId);

        if (items.length === 0) {
            return false;
        }

        for (const moduleSpaceItem of items) {
            if (moduleSpaceItem.module_type === moduleType) {
                return true;
            }
        }

        return false;
    }

    /**
     * Loads the ModuleSpaceItem for the given moduleType and space.  If none can be found, null is returned.
     * @param moduleType
     * @param spaceId
     */
    public async getForSpaceAndType(moduleType: ModuleType, spaceId: string): Promise<ModuleSpaceItem|null> {
        const items = await this.getList(spaceId);

        if (items.length === 0) {
            return null;
        }

        for (const moduleSpaceItem of items) {
            if (moduleSpaceItem.module_type === moduleType) {
                return moduleSpaceItem;
            }
        }

        return null;
    }

    /**
     * Enables the module for the specified space.  If first ensures that the module is NOT enabled for the space.
     * A request will be made to the API to create the module for the space, and after that we space
     * the returned moduleSpaceItem to the local db.
     * @param moduleType
     * @param spaceId
     */
    public async enableModuleSpace(moduleType: ModuleType, spaceId: string): Promise<ModuleSpaceItem> {
        const alreadyExists = await this.enabledForSpace(moduleType, spaceId);

        if (alreadyExists) {
            throw new Error(`The moduleType '${moduleType}' is already enabled for this space`);
        }

        try {
            const moduleSpaceItem = await this.requestFactory.createModuleSpaceRequest.execute({
                space_id: spaceId,
                module_type: moduleType,
            });

            await this.save(moduleSpaceItem);

            return moduleSpaceItem;
        } catch (error) {
            throw new Error(`Failed to create module space item: ${error}`);
        }
    }

    /**
     * Deletes the specified module space item from the space.  It first ensures that the module is currently
     * enabled for the space.  The module will be disabled for the space via the API and then removed locally.
     * @param moduleType
     * @param spaceId
     */
    public async deleteModuleSpace(moduleType: ModuleType, spaceId: string): Promise<void> {
        const moduleSpaceItem = await this.getForSpaceAndType(moduleType, spaceId);
        if (moduleSpaceItem === null) {
            throw new Error(`A moduleSpaceItem for type '${moduleType}' and space '${spaceId}' could not be found`);
        }

        try {
            await this.requestFactory.deleteModuleSpaceRequest.execute(moduleSpaceItem.id);
            await this.delete(moduleSpaceItem);
        } catch (error) {
            throw new Error(`Failed to delete module space item: ${error}`);
        }
    }
}
