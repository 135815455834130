import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import MessageItem from '@/domains/messages/database/messages/MessageItem';
import StringHelper from '@/ts/Helpers/StringHelper';

export interface MessageListRequestParams {
    contact_id: string;
    space_id: string;
    search: string;
    page: number|null;
    items_per_page: number|null;
    created_before_timestamp: number|null;
}

export class MessageListRequest extends AbstractRequest {
    public async execute(params: MessageListRequestParams): Promise<MessageItem[]> {
        return new Promise<MessageItem[]>(async (resolve, reject) => {
            try {
                let url = '/private/message';
                const urlParams = StringHelper.toUriString(params);

                if (urlParams !== '') {
                    url += '?' + urlParams;
                }

                const response = await this.apiClient.get(url);

                if ((!response.hasOwnProperty('data')) || (!response.data.hasOwnProperty('messages'))) {
                    reject(Error('MessageListRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.messages);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Could not load messages from the API'));
            }
        });
    }
}
