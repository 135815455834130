export interface ContactItem {
    id: string;
    space_id: string;
    created_by_user_id: string;
    first_name: string;
    last_name: string;
    email_primary: string;
    email_secondary: string;
    phone_primary: string;
    phone_secondary: string;
    date_of_birth: string;
    address_line1: string;
    address_line2: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
    geo_lat: number;
    geo_lng: number;
    birthday_reminder1_days_in_advance: number;
    birthday_reminder2_days_in_advance: number;
    notes: string;
    url1: string;
    url2: string;
    photo_primary_file_id: string;
    created_dtm: number;
    updated_dtm: number;
    is_favourite: boolean;
    shared: boolean;
}

export function getContactFullName(contact: ContactItem): string {
    return contact.first_name + ' ' + contact.last_name;
}

export default ContactItem;
