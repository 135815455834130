import ContactItem from '@/domains/contacts/database/ContactItem';
import StringHelper from '@/ts/Helpers/StringHelper';
import ContactMessageSummaryItem from '@/domains/contacts/database/contactMessageSummary/ContactMessageSummaryItem';

export default class ContactRepo {
    private db: IDBDatabase;

    public constructor(db: IDBDatabase) {
        this.db = db;
    }

    public async saveAll(items: ContactItem[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const promises: Array<Promise<void>> = [];

            items.forEach((item) => {
                promises.push(this.save(item));
            });

            try {
                await Promise.all(promises);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async save(item: ContactItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let request: IDBRequest;

            const exists = await this.exists(item.id);
            if (exists) {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .put(item);
            } else {
                request = this.db.transaction([this.storeName], 'readwrite')
                    .objectStore(this.storeName)
                    .add(item);
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(Error(`Failed to insert ${this.storeName}`));
            };
        });
    }

    public async exists(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            request.onerror = () => {
                reject(false);
            };
        });
    }

    public async getItem(id: string): Promise<ContactItem> {
        return new Promise<ContactItem>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readonly')
                .objectStore(this.storeName).get(id);

            request.onsuccess = (event) => {
                if (request.result) {
                    resolve(request.result);
                } else {
                    reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
                }
            };

            request.onerror = () => {
                reject(new Error(`Unable to load ${this.storeName} item with id: ${id}`));
            };
        });
    }

    public async getList(
        spaceId: string,
        search: string = '',
        hasUnreadMessages: boolean,
        requiresResponse: boolean,
        contactIsFavourite: boolean,
        showSharedOnly: boolean,
        contactMessageSummaryMap: {[key: string]: ContactMessageSummaryItem},
    ): Promise<ContactItem[]> {
        return new Promise<ContactItem[]>(async (resolve, reject) => {
            const store = this.db.transaction([this.storeName], 'readonly').
                objectStore(this.storeName);

            let request: IDBRequest;
            const index = store.index('space_id');
            request = index.openCursor(IDBKeyRange.only(spaceId));

            const items: ContactItem[] = [];
            const searchKeywords = search.split(' ');

            request.onsuccess = (event: Event) => {
                const cursor = request.result;

                if (cursor) {
                    const item = cursor.value;

                    if (item.space_id === spaceId) {
                        let pass = true;

                        if ((pass) && (hasUnreadMessages) && (requiresResponse)) {
                            // If the user is looking for both unread messages and requires response,
                            // allow either to create a pass.
                            if (!contactMessageSummaryMap.hasOwnProperty(item.id)) {
                                pass = false;
                            } else {
                                const contactMessageSummaryItem = contactMessageSummaryMap[item.id];
                                pass = ((contactMessageSummaryItem.not_read > 0) ||
                                    (contactMessageSummaryItem.requires_response > 0));
                            }
                        } else if ((pass) && (hasUnreadMessages)) {
                            if (!contactMessageSummaryMap.hasOwnProperty(item.id)) {
                                pass = false;
                            } else {
                                const contactMessageSummaryItem = contactMessageSummaryMap[item.id];
                                pass = contactMessageSummaryItem.not_read > 0;
                            }
                        } else if ((pass) && (requiresResponse)) {
                            if (!contactMessageSummaryMap.hasOwnProperty(item.id)) {
                                pass = false;
                            } else {
                                const contactMessageSummaryItem = contactMessageSummaryMap[item.id];
                                pass = contactMessageSummaryItem.requires_response > 0;
                            }
                        }

                        if ((pass) && (contactIsFavourite)) {
                            pass = !!item.is_favourite;
                        }

                        if ((pass) && (showSharedOnly)) {
                            pass = !!item.shared;
                        }

                        if ((pass) && (search !== '')) {
                            const foundInFirstName =
                                StringHelper.searchInText(searchKeywords, item.first_name);

                            const foundInLastName =
                                StringHelper.searchInText(searchKeywords, item.last_name);

                            const foundInEmailPrimary =
                                StringHelper.searchInText(searchKeywords, item.email_primary);

                            const foundInEmailSecondary =
                                StringHelper.searchInText(searchKeywords, item.email_secondary);

                            const foundInPhonePrimary =
                                StringHelper.searchInText(searchKeywords, item.phone_primary);

                            const foundInPhoneSecondary =
                                StringHelper.searchInText(searchKeywords, item.phone_secondary);

                            pass = (foundInFirstName || foundInLastName || foundInEmailPrimary ||
                                foundInEmailSecondary || foundInPhonePrimary ||
                                foundInPhoneSecondary);
                        }

                        if (pass) {
                            items.push(item);
                        }
                    }

                    cursor.continue();
                } else {
                    // Contacts should always be returned in alphabetical order.
                    items.sort((a: ContactItem, b: ContactItem) => {
                        const lowerA = a.first_name.toLowerCase();
                        const lowerB = b.first_name.toLowerCase();

                        if (lowerA < lowerB) {
                            return - 1;
                        } else if (lowerA > lowerB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });


                    resolve(items);
                }
            };

            request.onerror = () => {
                reject(new Error(`Failed to load ${this.storeName}!`));
            };
        });
    }

    public async delete(item: ContactItem): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).delete(item.id);

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} item '${item.first_name} ${item.last_name}' could not be deleted`));
            };
        });
    }

    public async clear(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const request = this.db.transaction([this.storeName], 'readwrite')
                .objectStore(this.storeName).clear();

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = () => {
                reject(Error(`The ${this.storeName} store could not be cleared`));
            };
        });
    }

    public getEmptyContactItem(noteCategoryId: string = ''): ContactItem {
        return {
            id: '',
            space_id: '',
            created_by_user_id: '',
            first_name: '',
            last_name: '',
            email_primary: '',
            email_secondary: '',
            phone_primary: '',
            phone_secondary: '',
            date_of_birth: '',
            address_line1: '',
            address_line2: '',
            suburb: '',
            postcode: '',
            state: '',
            country: '',
            geo_lat: 0,
            geo_lng: 0,
            birthday_reminder1_days_in_advance: 0,
            birthday_reminder2_days_in_advance: 0,
            notes: '',
            url1: '',
            url2: '',
            photo_primary_file_id: '',
            created_dtm: 0,
            updated_dtm: 0,
            is_favourite: false,
            shared: false,
        };
    }

    private get storeName(): string {
        return 'contacts';
    }
}
