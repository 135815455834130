
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeleteItemSpinner from '@/domains/ui/views/DeleteItemSpinner.vue';
import FileItem from '@/ts/Database/Files/FileItem';

@Component({
  components: {
      DeleteItemSpinner,
  },
})
export default class DocumentList extends Vue {
    @Prop({required: true}) private documents!: FileItem[];
    @Prop() private allowDelete!: boolean;
    @Prop() private deletingFile!: boolean;

    private deleteUploadedFile(document: FileItem): void {
        this.$emit('delete:document', document);
    }
}
