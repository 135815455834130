import { AbstractRequest } from '@/ts/Requests/AbstractRequest';
import { CredentialItem } from '@/domains/credentials/database/CredentialItem';

export class ListCredentialsRequest extends AbstractRequest {
    public async execute(): Promise<CredentialItem[]> {
        return new Promise<CredentialItem[]>(async (resolve, reject) => {
            try {
                const response = await this.apiClient.get(`/private/credential`);

                if ((!response.hasOwnProperty('data'))
                    || (!response.data.hasOwnProperty('status'))
                    || (!response.data.status)
                    || (!response.data.hasOwnProperty('credentials'))
                )  {
                    reject(Error('ListCredentialsRequest - Invalid response from API'));
                    return;
                }

                resolve(response.data.credentials);
            } catch (error) {
                reject(this.convertResponseError(error,
                    'Your request to list your credentials failed.  Please try again later'));
            }
        });
    }
}
