
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/domains/app/views/Container.vue';
import Form from '@/domains/ui/views/Form.vue';
import SidebarSection from '@/domains/app/views/SidebarSection.vue';
import Notification from '@/domains/ui/views/Notification.vue';
import SpinnerButton from '@/domains/ui/views/Buttons/SpinnerButton.vue';
import ErrorMessage from '@/domains/ui/views/ErrorMessage.vue';
import SuccessMessage from '@/domains/ui/views/Messages/SuccessMessage.vue';
import ActionHeading from '@/domains/ui/views/ActionHeading.vue';
import InputChecker from '@/domains/ui/views/InputChecker.vue';
import BasicButton from '@/domains/ui/views/Buttons/BasicButton.vue';
import IconButton from '@/domains/ui/views/Buttons/IconButton.vue';
import CreateUserTwoFactorQRCode from '@/domains/twoFactorAuth/views/CreateUserTwoFactorQRCode.vue';
import DisableTwoFactorAuth from '@/domains/twoFactorAuth/views/DisableTwoFactorAuth.vue';
import EnableTwoFactorAuth from '@/domains/twoFactorAuth/views/EnableTwoFactorAuth.vue';
import DeleteConfirmationModal from '@/domains/ui/views/Modals/DeleteConfirmationModal.vue';

import {Routes} from '@/domains/app/router/router';
import ApiClient from '@/ts/ApiClient';
import UserItem from '@/domains/users/database/users/UserItem';
import UserRoleType from '@/ts/Enums/UserRoleType';
import UserRoleTypeInterface from '@/ts/Interfaces/UserRoleTypeInterface';
import RepoManager from '@/ts/Database/RepoManager';
import RequestFactory from '@/ts/Requests/RequestFactory';
import { Trilean } from '@/ts/Trilean';
import { UserTwoFactorAuthItem } from '@/domains/users/database/userTwoFactorAuth/UserTwoFactorAuthItem';
import { UserTwoFactorAuthMethod } from '../database/userTwoFactorAuth/UserTwoFactorAuthMethod';

@Component({
  components: {
      Container,
      SidebarSection,
      Notification,
      Form,
      SpinnerButton,
      ErrorMessage,
      ActionHeading,
      InputChecker,
      BasicButton,
      IconButton,
      SuccessMessage,
      CreateUserTwoFactorQRCode,
      EnableTwoFactorAuth,
      DisableTwoFactorAuth,
      DeleteConfirmationModal,
  },
})
export default class User extends Vue {
    private $repoManager!: RepoManager;
    private $requestFactory!: RequestFactory;
    private $http!: ApiClient;

    private userItem!: UserItem;
    private roleType: string = '';
    public displayName: string = '';
    public emailAddress: string = '';
    public password: string = '';
    public passwordRepeat: string = '';

    private userTwoFactorAuthMethods: UserTwoFactorAuthItem[] = [];

    public loaded: boolean = false;
    public loading: boolean = false;
    public errorMessage: string = '';
    public successMessage: string = '';
    public editMode: boolean = false;

    public displayNameOk: Trilean = Trilean.Unknown;
    public emailOk: Trilean = Trilean.Unknown;
    public passwordOk: Trilean = Trilean.Unknown;
    public passwordRepeatOk: Trilean = Trilean.Unknown;

    public showCreateTwoFactorQRCode: boolean = false;
    public showEnableTwoFactorPanel: boolean = false;
    public showDisableTwoFactorPanel: boolean = false;

    public delete2FAModalOpen: boolean = false;
    public delete2FAInProgress: boolean = false;

    public mounted(): void {
        this.loaded = false;
        this.init();
    }

    @Watch('userId')
    @Watch('lastUpdated')
    public async init(): Promise<void> {
        this.displayNameOk = Trilean.Unknown;
        this.emailOk = Trilean.Unknown;
        this.passwordOk = Trilean.Unknown;
        this.passwordRepeatOk = Trilean.Unknown;
        this.showEnableTwoFactorPanel = false;
        this.showDisableTwoFactorPanel = false;

        if (this.$repoManager.user) {
            await this.loadResources();
        } else {
            this.onReposReady();
        }
    }

    private async onReposReady(): Promise<void> {
        document.addEventListener('reposReady', async () => {
            await this.loadResources();
        }, false);
    }

    public get userId(): string {
        return this.$store.state.adminUserModule.userId;
    }

    public get lastUpdated(): number {
        return this.$store.state.adminUserModule.lastUpdated;
    }

    public get adminIsViewingUser(): boolean {
        return !this.$store.state.userModule.isViewingProfilePage;
    }

    public get twoFactorAuthIsEnabled(): boolean {
        for (const userTwoFactorAuthMethod of this.userTwoFactorAuthMethods) {
            if ((userTwoFactorAuthMethod.verified) && (userTwoFactorAuthMethod.enabled)) {
                return true;
            }
        }

        return false;
    }

    public get hasUnenabledAuthenticator(): boolean {
        for (const userTwoFactorAuthMethod of this.userTwoFactorAuthMethods) {
            if ((!userTwoFactorAuthMethod.enabled) &&
                (userTwoFactorAuthMethod.two_factor_auth_type === UserTwoFactorAuthMethod.TOTP)) {
                return true;
            }
        }

        return false;
    }

    public get hasVerifiedAuthenticator(): boolean {
        for (const userTwoFactorAuthMethod of this.userTwoFactorAuthMethods) {
            if ((userTwoFactorAuthMethod.verified) &&
                (userTwoFactorAuthMethod.two_factor_auth_type === UserTwoFactorAuthMethod.TOTP)) {
                return true;
            }
        }

        return false;
    }

    private async loadResources(): Promise<void> {
        await this.loadDataFromRepos();
        await this.loadTwoFactorAuthList();
    }

    private async loadDataFromRepos(): Promise<void> {
        if (this.userId !== '') {
            await this.loadUser();
        } else {
            this.userItem = this.$repoManager.user.getEmptyUserItem(this.storeRoleType);
            this.setUserAttributesFromObject();
            this.editMode = true;
        }
        this.loaded = true;
    }

    private async loadTwoFactorAuthList(): Promise<void> {
        await this.$repoManager.user.cacheTwoFactorAuthMethod(null);
        try {
            this.userTwoFactorAuthMethods =
                await this.$requestFactory.userTwoFactorAuthListRequest.execute(null, null);

            for (const twoFactorAuthItem of this.userTwoFactorAuthMethods) {
                if (twoFactorAuthItem.enabled) {
                    await this.$repoManager.user.cacheTwoFactorAuthMethod(twoFactorAuthItem);
                }
            }
        } catch (error) {
            this.errorMessage = error.toString();
        }
    }

    get roles(): UserRoleTypeInterface[] {
        return [
            { type: UserRoleType.ADMINISTRATOR, name: 'Administrator' },
            { type: UserRoleType.GENERAL_USER, name: 'General User' },
        ];
    }

    private async loadUser(): Promise<void> {
        try {
            this.errorMessage = '';
            this.userItem = await this.$repoManager.user.getItem(this.userId);
            this.setUserAttributesFromObject();
        } catch (error) {
            this.errorMessage = 'Error: Note could not be loaded.';
        }
    }

    private setUserAttributesFromObject(): void {
        this.roleType = this.userItem.role_type;
        this.displayName = this.userItem.display_name;
        this.emailAddress = this.userItem.email_address;
    }

    private editUser(): void {
        this.editMode = true;

        this.checkDisplayNameAvailable();
        this.checkEmailOk();
    }

    private async deleteUser(): Promise<void> {
        if (!confirm('Delete this user?  Are you sure?')) {
            return;
        }

        try {
            // ***************** TODO **********************
            const deletedOk = await this.$requestFactory.deleteUserRequest.execute(this.userId);
            if (!deletedOk) {
                this.errorMessage = 'The user could not be deleted.  Please try again later.';
            }

            this.loading = true;

            await this.$repoManager.user.delete(this.userItem);
            await this.$store.dispatch('flagAdminUserModuleUpdated');

            this.userItem = this.$repoManager.user.getEmptyUserItem();
            this.navigateToUsersScreen();
        } catch (error) {
            this.errorMessage = 'The user could not be deleted.  Error was: ' + error.toString();
        } finally {
            this.loading = false;
        }
    }

    private cancelEdit(): void {
        this.userItem.role_type = this.roleType;
        this.userItem.display_name = this.displayName;
        this.userItem.email_address = this.emailAddress;

        this.editMode = false;

        if (this.addingNewItem) {
            this.navigateToUsersScreen();
        }
    }

    private formValid(): boolean {
        if (this.displayNameOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid display name.';
            return false;
        }

        if (this.emailOk !== Trilean.True) {
            this.errorMessage = 'You must enter a valid email address.';
            return false;
        }

        if (this.addingNewItem) {
            if (this.passwordOk !== Trilean.True) {
                this.errorMessage = 'Please enter a valid password.';
                return false;
            }

            if (this.passwordRepeatOk !== Trilean.True) {
                this.errorMessage = 'Please ensure your passwords match.';
                return false;
            }
        }

        return true;
    }

    private async saveUser(): Promise<void> {
        if (!this.formValid()) {
            return;
        }

        try {
            this.loading = true;

            if (this.addingNewItem) {
                this.userItem = await this.$requestFactory.addUserRequest.execute({
                    display_name: this.userItem.display_name,
                    email_address: this.userItem.email_address,
                    password: this.password,
                    role_type: this.userItem.role_type,
                });
            } else {
                this.userItem = await this.$requestFactory.updateUserRequest.execute({
                    id: this.userId,
                    display_name: this.userItem.display_name,
                    email_address: this.userItem.email_address,
                    role_type: this.userItem.role_type,
                });
            }

            await this.$repoManager.user.save(this.userItem);

            await this.$store.dispatch('flagAdminUserModuleUpdated');

            // Update the static view fields.
            this.setUserAttributesFromObject();

            this.editMode = false;
            this.errorMessage = '';
        } catch (error) {
            this.errorMessage = 'Failed to save user.  Please try again later.';
        } finally {
            this.loading = false;
        }
    }

    public handleCreateQRCode(): void {
        this.showCreateTwoFactorQRCode = true;
    }

    public async handleTwoFactorAuthVerified(): Promise<void> {
        this.showCreateTwoFactorQRCode = false;
        await this.loadTwoFactorAuthList();
        this.editMode = false;
    }

    public handleTwoFactorAuthDisabled(): void {
        this.showDisableTwoFactorPanel = false;
        this.loadTwoFactorAuthList();
        this.editMode = false;
        this.successMessage = 'Two factor authentication has been disabled.';
    }

    public handleTwoFactorAuthEnabled(): void {
        this.showEnableTwoFactorPanel = false;
        this.loadTwoFactorAuthList();
        this.editMode = false;
        this.successMessage = 'Two factor authentication has been enabled.';
    }

    public handleDeleteTwoFactorAuthIntent(): void {
        this.delete2FAModalOpen = true;
    }

    public async handleDeleteTwoFactorAuth(type: UserTwoFactorAuthMethod): Promise<void> {
        this.delete2FAInProgress = true;

        try {
            await this.$requestFactory.deleteUserTwoFactorAuthRequest.execute(type);
            await this.loadTwoFactorAuthList();
        } catch (error) {
            this.errorMessage = error.toString();
        } finally {
            this.delete2FAInProgress = false;
            this.delete2FAModalOpen = false;
        }
    }

    private async checkDisplayNameAvailable(): Promise<void> {
        if (this.userItem.display_name === '') {
            return;
        }

        if ((!this.addingNewItem) && (this.userItem.display_name === this.displayName)) {
            this.displayNameOk = Trilean.True;
            this.errorMessage = '';
            return;
        }

        try {
            if (await this.$requestFactory.displayNameCheckRequest.execute(encodeURI(this.userItem.display_name))) {
                this.displayNameOk = Trilean.True;
                this.errorMessage = '';
            } else {
                this.displayNameOk = Trilean.False;
            }
        } catch (error) {
            this.errorMessage = error.toString();
            this.displayNameOk = Trilean.False;
        }
    }

    private async checkEmailOk(): Promise<void> {
        if (this.userItem.email_address === '') {
            return;
        }

        if ((!this.addingNewItem) && (this.userItem.email_address === this.emailAddress)) {
            this.emailOk = Trilean.True;
            this.errorMessage = '';
            return;
        }

        try {
            if (await this.$requestFactory.emailCheckRequest.execute(encodeURI(this.userItem.email_address))) {
                this.emailOk = Trilean.True;
                this.errorMessage = '';
            } else {
                this.emailOk = Trilean.False;
            }
        } catch (error) {
            this.errorMessage = error.toString();
            this.emailOk = Trilean.False;
        }
    }

    private checkPassword(): void {
        this.passwordOk = (this.password.length > 7) ? Trilean.True : Trilean.False;
        if (this.passwordOk === Trilean.False) {
            this.errorMessage = 'You password must be at least 8 characters long.';
        } else {
            this.errorMessage = '';

            if (this.passwordRepeat !== '') {
                this.passwordRepeat = '';
                this.passwordRepeatOk = Trilean.False;
            }
        }
    }

    private checkPasswordRepeat(): void {
        this.passwordRepeatOk = (this.password === this.passwordRepeat) ? Trilean.True : Trilean.False;
        if (this.passwordRepeatOk === Trilean.False) {
            this.errorMessage = 'You passwords do not match.  Please enter them again carefully.';
        } else {
            this.errorMessage = '';
        }
    }

    private navigateToUsersScreen(): void {
        this.$router.push({name: Routes.USERS});
    }

    private navigateToRole(role: string): void {
        this.$store.commit('setAdminUserModuleRoleType', role);
        this.navigateToUsersScreen();
    }

    get storeRoleType(): string {
        return this.$store.state.userModule.selectedRole;
    }

    get addingNewItem(): boolean {
        return this.userId === '';
    }
}
